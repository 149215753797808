import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUserContext } from '../utils/hooks/UserContext'
import Loader from '../Coach/Common/Loader';
import { PulseLoader } from "react-spinners";

const ProtectedRoutes = ({ children, path, auth = [], ...props }) => {
    const { isLoading, user } = useUserContext();
    if (isLoading) {
        return (
        // <div><Loader /></div>
        <div
          style={{
            // textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <p>
            <PulseLoader color="#00504E" size={20} />
          </p>
        </div>
        )
    }

    if (!user) {
        return (<Navigate to="/" replace state={{ from: path }} />)
    }

    if(!auth?.includes(user?.userType)){
        return (<Navigate to="/not-found" replace state={{ from: path }} />)
    }

    return children;
}

export default ProtectedRoutes  