import React from 'react';
import {Button,Col,Dropdown, Modal }from 'react-bootstrap';
import home_banner from '../../../assets/images/home_banner.png';
import dummy_coach1 from '../../../assets/images/dummy/dummy_coach1.png';
import { useUserContext } from "../../../utils/hooks/UserContext";

function removeHTMLTags(str) {
    if (str) {
        return str.replace(/<[^>]+>/g, ''); // Remove HTML tags
    } else {
        return ''; // Return an empty string if str is undefined
    }
}

function BlogModalComponent(props) {
    const { user, handleLogout } = useUserContext();
    const isCoachUser = user?.userType === 'coach';
    const defaultUserImage = (e) => {
        // e.target.src = 'https://api.vaffix.com/uploads/images/avatar_dummy.webp';
        e.target.src = dummy_coach1;
    }
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        {/* {console.log(props.singleBlogDetails?.title, 'props modal mohit')}
        {console.log(user?.userType, "Checked by mohit")} */}
            <div className="asf_card" style={{border: "0px"}}>
                <div className="asf_card_header casestudy-main">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="user_img_sm_rnd">
                                
                                {props.singleBlogDetails?.profile_pic ? (
                                    
                                    <img width={45} height={45} className="rounded-circle" src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${props.singleBlogDetails?.profile_pic}`} alt="" style={{ borderRadius: '50%' }} onError={(e) => defaultUserImage(e)} />
                                ) : (
                                    <img width={45} height={45} className="rounded-circle" src={dummy_coach1} alt="" />
                                )}
                            </div>
                            <div className="ms-2">
                                <h5 className="fs-16 fw-500 m-0">
                                {props.singleBlogDetails?.name}
                                    <span className="hrs position-relative fs-10 fw-500 ps-3 ms-3 text_light">{props.singleBlogDetails?.created_at}</span>
                                </h5>
                                <span className="hrs position-relative fs-10 fw-500 ps-3">{props.singleBlogDetails?.professions}</span>
                                <span className="hrs position-relative fs-10 fw-500 ps-3 ms-3">{props.singleBlogDetails?.interests}</span>
                                <span className="hrs position-relative fs-10 fw-500 ps-3 ms-3">{props.singleBlogDetails?.gender}</span>
                            </div>
                        </div>
                        <Col sm='auto'>
                        {isCoachUser && (
                            <Dropdown>
                            <Dropdown.Toggle
                                variant="ellipsis"
                                as={Button}
                                className="p-0 h-auto fs-24 fw-700"
                            >
                                &#8943;
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>Edit</Dropdown.Item>
                                <Dropdown.Item>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown>
                        )}
                            {/* <Dropdown >
                                <Dropdown.Toggle variant="ellipsis" as={Button} className="p-0 h-auto fs-24 fw-700">
                                    &#8943;
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>Edit</Dropdown.Item>
                                    <Dropdown.Item>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </Col>
                    </div>
                </div>
                <div className="">
                    <div className="p10">
                        <h5 className="fs-20 fw-600">{props.singleBlogDetails?.title}</h5>
                        <div className='text-center my-3'>
                            {/* <img className='mx-auto' style={{width: "300px", height: "150px"}} src={home_banner} alt='' /> */}
                            {/* <img className='mx-auto' style={{width: "300px", height: "150px"}} src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${props.singleBlogDetails?.image}`} alt='' /> */}
                            {props.singleBlogDetails?.image ? (
                                <img className='mx-auto' style={{width: "300px", height: "150px"}} src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${props.singleBlogDetails?.image}`} alt='' />
                            ) : (
                                <img className='mx-auto' style={{width: "300px", height: "150px"}} src={home_banner} alt='' />
                            )}
                        </div>
                        <p className="fs-14 fw-400">{removeHTMLTags(props.singleBlogDetails?.content)}</p>
                    </div>
                </div>
            </div>
      
    </Modal>
  );
}


export default BlogModalComponent;