import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import dummy_coach1_sqr from "../../../assets/images/dummy/dummy_coach1_sqr.png";
import Pic from "../../../assets/images/icons/user.png";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { useUserContext } from "../../../utils/hooks/UserContext";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';

function ScheduleSessionComponent(){
    const { user } = useUserContext()

    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    console.log(user);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return(
        <>
            <section className="white_bg py-3 py-lg-5">
                <Container className="">
                    <Row className="g-5 justify-content-center">
                        <Col lg="6">
                            <div className="hdng_a">
                                <h6 className="text-uppercase">Schedule Session</h6>
                                <h2>Book Your Time Slot</h2>
                            </div>
                            <div className="d-flex align-items-center my-3">
                                <span className="user_img">
                                    <img className="rounded-circle" height="50" width="50" src={dummy_coach1_sqr} />
                                </span>
                                <div>
                                    <h5 className="fs-14 fw-500 m-0">Kathy Pearce</h5>
                                    <small className="fs-10 fw-500">Saturday, 1st Aug 2024</small>
                                </div>
                            </div>

                            <div className="my-3">
                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                    <span className="fs-10 fw-500">EICC25421</span>
                                    <p className="fs-12 fw-600 m-0">15 Days</p>
                                </div>
                                <div className="d-flex w-100 justify-content-between align-items-center text-end">
                                    <h5 className="m-0 text_brand_color2">Emotional Intelligence complete c...</h5>
                                    <p className="fs-12 fw-600 m-0">1st Aug 2024</p>
                                </div>
                            </div>

                            <div className="schedule_session_slot_card">
                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                    <div className="">
                                        <span className="fs-10 mb-1 fw-500">EICC25421</span>
                                        <h6 className="fs-12 fw-500 mb-1 text_brand_color2">Basic Emotional Intelligence</h6>
                                        <p className="fs-12 fw-500 m-0">30 minutes</p>
                                    </div>
                                    <div className="">
                                        <p className="fs-12 fw-600 mb-1">23 AUG 2024</p>
                                        <p className="fs-12 fw-500 mb-1">15:00 - 16:00</p>
                                        <Button variant="success">Edit</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="schedule_session_slot_card">
                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                    <div className="">
                                        <span className="fs-10 mb-1 fw-500">EICC25421</span>
                                        <h6 className="fs-12 fw-500 mb-1 text_brand_color2">Basic Emotional Intelligence</h6>
                                        <p className="fs-12 fw-500 m-0">30 minutes</p>
                                    </div>
                                    <div className="">
                                        <Button variant="success">Select Slot</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="schedule_session_slot_card">
                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                    <div className="">
                                        <span className="fs-10 mb-1 fw-500">EICC25421</span>
                                        <h6 className="fs-12 fw-500 mb-1 text_brand_color2">Basic Emotional Intelligence</h6>
                                        <p className="fs-12 fw-500 m-0">30 minutes</p>
                                    </div>
                                    <div className="">
                                        <Button variant="success">Select Slot</Button>
                                    </div>
                                </div>
                            </div>

                            <Button className="mt-3" variant="success">Book Session</Button>
                        </Col>
                        <Col lg="6" className="bg-body-tertiary py-4">
                            <Form className="session-schedule-user">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="">
                                            <label className="form-label fs-14">Select a Date & Time</label>
                                            {/* <input type="date" data-date-inline-picker="true" className="form-control" /> */}
                                            <DatePicker
                                                selected={startDate}
                                                onChange={onChange}
                                                startDate={startDate}
                                                endDate={endDate}
                                                excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                                                selectsRange
                                                inline
                                            />
                                        </div>
                                        <div className="">
                                            <label className="form-label fs-14">Time Zone</label>
                                            <select className="form-control ms-0 fs-14">
                                                <option>Indian Standard Time (+05:30)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="">
                                            <label className="form-label fs-14">Tuesday, July 16</label>
                                            <div>
                                                <input type="checkbox" className="d-none" id="eight" name="eight" value="08:00 am" />
                                                <label for="eight" className="check-box-btn"> 08:00 am</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" className="d-none" id="ten" name="ten" value="10:00 am" />
                                                <label for="ten" className="check-box-btn"> 10:00 am</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" className="d-none" id="three" name="three" value="03:00 pm" />
                                                <label for="three" className="check-box-btn"> 03:00 pm</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" className="d-none" id="four" name="four" value="04:00 pm" />
                                                <label for="four" className="check-box-btn active"> 04:00 pm</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" className="d-none" id="five" name="five" value="05:00 pm" />
                                                <label for="five" className="check-box-btn"> 05:00 pm</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="fs-14 btn btn-success btn-md w-100 mt-2">Confirm Slot</button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ScheduleSessionComponent;