import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import live_session_img1 from '../../../assets/images/dummy/live_session_img1.png';
import dummy_coach1_sqr from '../../../assets/images/dummy/dummy_coach1_sqr.png';
import mental_coach from '../../../assets/images/dummy/mental_coach.png';
import { NavLink } from "react-router-dom";
import BlogModalComponent from "./blog_modal";
import { getAllBlogs } from "../../../utils/api";
import send_wht from "../../../assets/images/icons/send_wht.png";
import quote_grn from '../../../assets/images/icons/quote_grn.png';
import noblogs from "../../../assets/images/no-blogs.png";

function LatestBlogComponent() {


    const [allBlogs, setAllBlogs] = useState([]);

    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData();
        window.scrollTo(0, 200);
    }, [])

    const getData = async () => {
        const data = await getAllBlogs();
        setAllBlogs(data.data.data.latest)
    }
    const defaultImage = (e) => {
        e.target.src = 'https://nadmin.qoach.io/assets/images/blog.png';
    }
    const defaultUserImage = (e) => {
        // e.target.src = 'https://api.vaffix.com/uploads/images/avatar_dummy.webp';
        e.target.src = dummy_coach1_sqr;
    }
    console.log("allBlogsallBlogsallBlogs", allBlogs)
    return (
        <>
            <section className="brand_bg_color4 py-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            <div className="text-white hdng_a poppins_fnt">
                                <h6 className="text-white text-uppercase">NEWS & COMMUNITY</h6>
                                <h2 className="text-white">Our Latest Blogs</h2>
                                <p className="fs-16 fw-400 text_white_color_half">Lorem ipsum dolor sit amet consectetur. Tempus amet mauris velit sed. Non sodales diam non viverra dui.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className="pt-5">
                <Container>
                    <Row className="blogs_hdng mb-3">
                        <Col>
                            <h4>Latest</h4>
                        </Col>
                    </Row>
                    <Row className="g-4">
                        {allBlogs && allBlogs.length > 0 ?
                            allBlogs.map((blog, index) => {
                                if (blog.is_popular == 'Yes') {
                                    return (
                                        <Col lg="4" sm="6">
                                            <div className="latest_blog_card">
                                                <div className="latest_blog_card_header">
                                                    <Row className="align-items-center">
                                                        <Col>
                                                            <div className="d-flex align-items-center">
                                                                <div className="">
                                                                    {blog.profile_pic ? (
                                                                        <img src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`} alt="" onError={(e) => defaultUserImage(e)} />
                                                                    ) : (
                                                                        <img src={dummy_coach1_sqr} alt="" />
                                                                    )}
                                                                </div>
                                                                <span className="ms-2 fs-10 fw-600">{blog.name}</span>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-auto">
                                                            <span className="fs-14 fw-400">
                                                                {blog.created_at ? (new Date(blog.created_at).toLocaleDateString('en-IN', {
                                                                    year: 'numeric',
                                                                    month: 'short',
                                                                    day: 'numeric'
                                                                })) : ''}
                                                            </span>
                                                        </Col>
                                                    </Row>

                                                </div>
                                                {blog.image ? (
                                                    <img className="latest_blog_img" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" />
                                                ) : (
                                                    <img src={mental_coach} alt="" />
                                                )}
                                                {/* <img className="latest_blog_img" src={mental_coach} alt="" /> */}
                                                <div className="latest_blog_card_body">
                                                    <h5 className="fs-14 fw-600">{blog.title}</h5>
                                                    <p>{(blog.content.replace(/<[^>]+>/g, '')).substring(0, 200)}</p>

                                                    <NavLink to={`/blog-details/${blog.id}`}>Read More</NavLink>
                                                </div>
                                            </div>
                                        </Col>)
                                }
                            }) :
                            <div className="text-center">
                                <img src={noblogs} alt="no blogs" />
                                <p>No Published Blogs Yet</p>
                            </div>
                        }
                        {/* <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col> */}
                        {/* <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col>

                        <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col> */}

                        {/* <Col lg="4" sm="6">
                            <div className="latest_blog_card">
                                <div className="latest_blog_card_header">
                                    <Row className="align-items-center">
                                        <Col>
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <img src={dummy_coach1_sqr} alt="" />
                                                </div>
                                                <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <span className="fs-14 fw-400">19 Jan 2022</span>
                                        </Col>
                                    </Row>
                                    
                                </div>
                                <img className="latest_blog_img" src={mental_coach} alt="" />
                                <div className="latest_blog_card_body">
                                    <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                    <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                    <NavLink>Read More</NavLink>
                                </div>
                            </div>
                        </Col> */}

                    </Row>
                    <hr />
                </Container>
            </section>



            {/* Testimonials Start */}
            <section className="white_bg py-3 py-lg-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12" className="">
                            <div className="text-start hdng_a">
                                <h6 className="text-uppercase">Testimonials</h6>
                                <h2>Some Honest Reviews</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-4">
                        <Col lg="12">

                            <OwlCarousel className="owl-theme" loop items="1" dots={true}>
                                <div className="item">
                                    <div>
                                        <Row className="align-items-center">
                                            <Col md="5">
                                                <div className="testimonial_blog_card latest_blog_card">
                                                    <div className="latest_blog_card_header">
                                                        <Row className="align-items-center">
                                                            <Col>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="">
                                                                        <img src={dummy_coach1_sqr} alt="" />
                                                                    </div>
                                                                    <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <span className="fs-14 fw-400">19 Jan 2022</span>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                    <img className="latest_blog_img" src={mental_coach} alt="" onError={(e) => defaultImage(e)} />
                                                    <div className="latest_blog_card_body">
                                                        <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                                        <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                                        <NavLink>Read More</NavLink>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="7">
                                                <div className="testimonial_blog_quotes_card">
                                                    <div>
                                                        <div className="d-flex mb-2">
                                                            <span>
                                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                                            </span>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                <h5>Cathy Wickersham</h5>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mb-2">
                                                            <span>
                                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                                            </span>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                <h5>Cathy Wickersham</h5>
                                                            </div>
                                                        </div>
                                                        <Form>
                                                            <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment" value="" />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                <Form.Control name="eail" type="text" placeholder="Enter Mail" value="" />
                                                            </Form.Group>
                                                            <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                                                <Button variant="success" size="sm" className="px-4">Submit
                                                                    <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                                                    </svg>
                                                                </Button>
                                                            </Form.Group>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="item">
                                    <div>
                                        <Row className="align-items-center">
                                            <Col md="5">
                                                <div className="testimonial_blog_card latest_blog_card">
                                                    <div className="latest_blog_card_header">
                                                        <Row className="align-items-center">
                                                            <Col>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="">
                                                                        <img src={dummy_coach1_sqr} alt="" />
                                                                    </div>
                                                                    <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <span className="fs-14 fw-400">19 Jan 2022</span>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                    <img className="latest_blog_img" src={mental_coach} alt="" onError={(e) => defaultImage(e)} />
                                                    <div className="latest_blog_card_body">
                                                        <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                                        <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                                        <NavLink>Read More</NavLink>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="7">
                                                <div className="testimonial_blog_quotes_card">
                                                    <div>
                                                        <div className="d-flex mb-2">
                                                            <span>
                                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                                            </span>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                <h5>Cathy Wickersham</h5>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mb-2">
                                                            <span>
                                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                                            </span>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                <h5>Cathy Wickersham</h5>
                                                            </div>
                                                        </div>
                                                        <Form>
                                                            <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment" value="" />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                <Form.Control name="eail" type="text" placeholder="Enter Mail" value="" />
                                                            </Form.Group>
                                                            <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                                                <Button variant="success" size="sm" className="px-4">Submit
                                                                    <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                                                    </svg>
                                                                </Button>
                                                            </Form.Group>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="item">
                                    <div>
                                        <Row className="align-items-center">
                                            <Col md="5">
                                                <div className="testimonial_blog_card latest_blog_card">
                                                    <div className="latest_blog_card_header">
                                                        <Row className="align-items-center">
                                                            <Col>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="">
                                                                        <img src={dummy_coach1_sqr} alt="" />
                                                                    </div>
                                                                    <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-auto">
                                                                <span className="fs-14 fw-400">19 Jan 2022</span>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                    <img className="latest_blog_img" src={mental_coach} alt="" onError={(e) => defaultImage(e)} />
                                                    <div className="latest_blog_card_body">
                                                        <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                                        <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                                        <NavLink>Read More</NavLink>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="7">
                                                <div className="testimonial_blog_quotes_card">
                                                    <div>
                                                        <div className="d-flex mb-2">
                                                            <span>
                                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                                            </span>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                <h5>Cathy Wickersham</h5>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mb-2">
                                                            <span>
                                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                                            </span>
                                                            <div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                <h5>Cathy Wickersham</h5>
                                                            </div>
                                                        </div>
                                                        <Form>
                                                            <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment" value="" />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                <Form.Control name="eail" type="text" placeholder="Enter Mail" value="" />
                                                            </Form.Group>
                                                            <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                                                <Button variant="success" size="sm" className="px-4">Submit
                                                                    <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                                                    </svg>
                                                                </Button>
                                                            </Form.Group>

                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                            </OwlCarousel>

                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Testimonials End */}


            {/* <section className="py-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            {
                                allBlogs && allBlogs.length > 0 ?
                                    allBlogs.map((blog) => (
                                        <Row>
                                            <Col lg="6" className="order-last order-md-first  mt-3">
                                                <div className="blogs_card">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="blog_user">
                                                            <span>                                                                
                                                                {blog.profile_pic ? (
                                                                    <img src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`} alt="" style={{ borderRadius: '50%' }}/>
                                                                ) : (
                                                                    <img src={live_session_img1} alt="" />
                                                                )}
                                                            </span>
                                                            {console.log(blog, 'Mohit')}
                                                            <p className="fs-16 fw-400 mb-0 ms-2">{blog.name ? blog.name : 'Jack Mick'}</p>
                                                        </div>
                                                        <div className="blog_dt">
                                                            <p className="fs-16 fw-600 mb-0 text_brand_color8 opacity_7">{new Date(blog.created_at).toLocaleDateString('en-IN', {
                                                                    year: 'numeric',
                                                                    month: 'short',
                                                                    day: 'numeric'
                                                                })} 
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <h4 className="fs-20 fw-600 text_brand_color8">{blog.title}</h4>
                                                    <p className="m-0">{blog.content.replace(/<[^>]+>/g, '')}</p>
                                                    <NavLink className="text_brand_color8 fw-500" onClick={(e) => showModal(e, blog)}>Read More</NavLink>
                                                </div>
                                            </Col>
                                            <Col lg="6" className="mt-3">
                                                {blog.image ? (
                                                    <img width="100%" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" />
                                                ) : (
                                                    <img width="100%" src={`${mental_coach}`} alt="" />
                                                )}
                                            </Col>
                                        </Row>
                                    ))

                                    : ''
                            }
                            <hr />
                        </Col>
                    </Row>
                </Container>
            </section> */}
        </>
    )
}

export default LatestBlogComponent;