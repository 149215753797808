
import React from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import live_session_img1 from '../../../../assets/images/dummy/live_session_img1.png';
import dummy_coach1_sqr from '../../../../assets/images/dummy/dummy_coach1_sqr.png';


const CoachDetailsFreeLiveSessionComponent = ({ userData }) => {
    return (
        <>

            <Container className="py-4">
                <Row className="g-3 justify-content-center">
                    <Col className="col-auto">
                        <div className="fls_card">
                            <div className="fls_card_header bg_transparent p-0">
                                <div className="d-flex justify-content-between">
                                    <label className="live_label">Live</label>
                                    <label className="joined_label">50+ joined</label>
                                </div>
                            </div>
                            <div className="fls_card_body">
                                <img src={live_session_img1} alt="" />
                                <Button variant="success" className="px-5">Let me in</Button>
                            </div>
                            <div className="fls_card_footer">
                                <div className="">
                                    <div className="mb-1">
                                        <div className="d-flex align-items-center pic_labels sm">
                                            <div className="pic_labels_img">
                                                <img src={dummy_coach1_sqr} alt="" />
                                            </div>
                                            <div className="ms-1">
                                                <h5 className="text-black fw-500 m-0">Jack Mick</h5>
                                                <label className="">Project Manager</label>
                                                <label>IT Recruiter</label>
                                                <label>Career</label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs-10 fw-500 text-black m-0">#finanace#commerce#expert <span>+3more</span> </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-auto">
                        <div className="fls_card">
                            <div className="fls_card_header">
                                <p className="fs-14 fw-500 mb-1">Upcoming Live Session</p>
                                <p className="fs-10 fw-500 m-0">25 March’23 @ 08:00 - 09:00 IST</p>
                            </div>
                            <div className="fls_card_body">
                                <img src={live_session_img1} alt="" />
                                <Button variant="brown" className="px-5">I am intrested</Button>
                            </div>
                            <div className="fls_card_footer">
                                <div className="">
                                    <div className="mb-1">
                                        <div className="d-flex align-items-center pic_labels sm">
                                            <div className="pic_labels_img">
                                                <img src={dummy_coach1_sqr} alt="" />
                                            </div>
                                            <div className="ms-1">
                                                <h5 className="text-black fw-500 m-0">Jack Mick</h5>
                                                <label className="">Project Manager</label>
                                                <label>IT Recruiter</label>
                                                <label>Career</label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs-10 fw-500 text-black m-0">#finanace#commerce#expert <span>+3more</span> </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CoachDetailsFreeLiveSessionComponent;