import React, { useRef, useState, useEffect } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    Nav,
    Row,
    Tab
} from "react-bootstrap";
import { toast } from "react-toastify";
import Modal from "react-awesome-modal";
import { NavLink } from "react-router-dom";
// import dummy_coach1_sqr from "./../../../../assets/images/dummy/dummy_coach1_sqr.png";
import dummy_coach1_sqr from "./../../../assets/images/dummy/dummy_coach1.png";
import mental_coach from "./../../../assets/images/dummy/mental_coach.png";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { allCategories, createBlog } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Loader/Loader";

function DraftComponent() {

    const [modalVisiable, setModalVisiable] = useState(false);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [blogDate, setBlogDate] = useState('');
    const [images, setImage] = useState('');
    const [content, setContent] = useState('');
    const [categories, setCategories] = useState([]);
    const [isLoading,setisLoading] = useState(false)
    const navigate = useNavigate();
    const [editorLoaded, setEditorLoaded] = useState(false);

    const getData = async () => {
        const allcat = await allCategories();
        if (allcat) {
            setCategories(allcat.data.data)
        }
        setEditorLoaded(true); // Set editorLoaded to true when the component is fully rendered
    }
    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        getData();
    }, []);

    const saveBlogs = async (type) => {
        const formData = new FormData()
        formData.append('title', title)
        formData.append('category_id', category)
        formData.append('blog_date', blogDate)
        formData.append('images', images)
        formData.append('content', content)
        formData.append('publish_status', type)

        const newdata = await createBlog(formData);
        // console.log(newdata.data.status);
        if (newdata.data.status) {
            toast.success('Blog created successfully')
            navigate("/coach/my-blogs");
        } else {
            toast.success('Somthing went wrong')
        }
    }
    const handleFileChange = (event) => {
        // console.log(event.target.files[0]);
        const files = event.target.files[0];
        setImage(files);
    };
    const handleChange = (event, editor) => {
        const data = editor.getData();
        if(data){
            setContent(data)
        }
    };
    return (
        <>
            <div className="bg-body-tertiary p-4">
                <div className="d-flex align-items-center ">
                    <b className="d-block text-center w-100">Create blog</b>
                </div>
                <div className="my-5">
                    <div className="mb-3">
                        <label className="form-label fw-500" for="title">Title</label>
                        <input type="text" className="form-control fs-14" id="title" name="title" placeholder="title" onChange={(e) => setTitle(e.target.value)} value={title} required />
                    </div>
                    {/* <div className="mb-3 form-group">
                        <label for="user_id" className="form-label fw-500">Select User</label>
                        <select name="user_id" id="user_id"
                            className="form-control fs-14" required>
                            <option value="">Choose User</option>
                        </select>
                    </div> */}
                    <div className="mb-3 form-group">
                        <label for="category_id" className="form-label fw-500">Category</label>
                        <select name="category" id="category_id"
                            className="form-control fs-14" required onChange={(e) => setCategory(e.target.value)} value={category} >
                            <option value="">Select</option>
                            {
                                categories && categories.length > 0 ?
                                    categories.map((post) => (
                                        <option value={post.id}>{post.name}</option>
                                    ))
                                    : ''
                            }
                        </select>
                    </div>
                    <div className="mb-3 form-group">
                        <label for="blog_date" className="form-label fw-500">Blog Date</label>
                        <input type="date" name="blogdate" value={blogDate} id="blogdate" onChange={(e) => setBlogDate(e.target.value)} className="form-control fs-14 batch_timing_to" />
                        <label className="d-block error-label batch-timing-to-error" for=""></label>
                    </div>
                    <div className="mb-3 form-group">
                        <label className="form-label fw-500" for="car_model">Blog Image</label>
                        <input
                            type="file" accept="image/png, image/jpeg"
                            className="form-control fs-14"
                            id="images"
                            name="images"
                            placeholder="Enter image"
                            onChange={(e) => handleFileChange(e)}
                        />
                        <small className="mt-2 fs-12">We recommend uploading image size 1920 x 1080 pixels.</small>
                    </div>
                    <div className="form-group">
                        <label for="" className="form-label fw-500">Content</label>
                        {/* <textarea name="content" id="content" className="form-control" required> </textarea>
                        <label
                            className="d-block error-label"
                            for="content"
                            id="nmt_text_label"
                        ></label> */}
                        {editorLoaded && (

                        <CKEditor
                            className="fs-14"
                            editor={ClassicEditor}
                            // data="<p>Hello from CKEditor&nbsp;5!</p>"
                            onReady={(editor) => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={handleChange}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                    )}

                    </div>
                    <div className="mt-3 text-end">
                        {/* <button className="btn btn-success me-2">
                            <svg className="me-2" width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.1971 6.51893C19.3577 2.80086 15.5222 0.217773 11.0996 0.217773C6.67708 0.217773 2.84159 2.80086 1.00212 6.51893C0.884709 6.75376 0.884709 7.02772 1.00212 7.22341C2.84159 10.9415 6.67708 13.5246 11.0996 13.5246C15.5222 13.5246 19.3577 10.9415 21.1971 7.22341C21.3145 6.98858 21.3145 6.75376 21.1971 6.51893ZM11.0996 11.1763C8.71223 11.1763 6.79449 9.25856 6.79449 6.87117C6.79449 4.48377 8.71223 2.56603 11.0996 2.56603C13.487 2.56603 15.4048 4.48377 15.4048 6.87117C15.4048 9.25856 13.487 11.1763 11.0996 11.1763Z" fill="white" />
                            </svg>
                            Preview</button> */}
                        <button className="btn btn-success" onClick={(e) => saveBlogs('Draft')} style={{marginRight: "15px"}} > { isLoading ? <Loader/> : "Save Draft"} </button>
                        <button className="btn btn-success" onClick={(e) => saveBlogs('Published')}> { isLoading ? <Loader/> : "Save"} </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DraftComponent;
