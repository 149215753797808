import React, { useRef, useState, useEffect } from "react";
import pencil from "../../../assets/images/pencil.png";
import eraser from "../../../assets/images/eraser.png";
import cleaning from "../../../assets/images/cleaning.png";
import font from "../../../assets/images/font.png";
import minus from "../../../assets/images/minus.png";

export default function CanvasWhiteBoard() {
  const [isDrawing, setIsDrawing] = useState(false);
  const [inputBoxes, setInputBoxes] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [activeInputBox, setActiveInputBox] = useState(null);
  const [text, setText] = useState("");
  const [color, setColor] = useState("#3B3B3B");
  const [size, setSize] = useState("3");
  const [shape, setShape] = useState("");
  const [addShape, setAddShape] = useState(false);
  const canvasRef = useRef(null);
  const ctx = useRef(null);
  const timeout = useRef(null);
  const [cursor, setCursor] = useState("default");

  useEffect(() => {
    const canvas = canvasRef.current;
    ctx.current = canvas.getContext("2d");

    //Resizing
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;

    //Load from locastorage
    // const canvasimg = localStorage.getItem("canvasimg");
    // if (canvasimg) {
    //   var image = new Image();
    //   ctx.current = canvas.getContext("2d");
    //   image.onload = function () {
    //     ctx.current.drawImage(image, 0, 0);
    //     setIsDrawing(false);
    //   };
    //   image.src = canvasimg;
    // }
  }, [ctx]);

  const startPosition = ({ nativeEvent }) => {
    if (isTyping) {
      // Handle creating a new input box
      const newInputBox = {
        id: Date.now(),
        x: nativeEvent.clientX,
        y: nativeEvent.clientY,
        text: "",
      };
      setInputBoxes((prevInputBoxes) => [...prevInputBoxes, newInputBox]);
      setActiveInputBox(newInputBox.id);
    } else if (addShape) {
      drawShapeOnCanvas({ nativeEvent });
    } else {
      setIsDrawing(true); // Enable drawing
      const canvas = canvasRef.current;
      ctx.current = canvas.getContext("2d");
      ctx.current.beginPath();
      ctx.current.moveTo(nativeEvent.clientX, nativeEvent.clientY);
    }
  };

  const finishedPosition = () => {
    if (isDrawing) {
      // Handle finishing drawing
      setIsDrawing(false);
      ctx.current.beginPath();
    }
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) {
      return;
    }
    const canvas = canvasRef.current;
    ctx.current = canvas.getContext("2d");
    ctx.current.lineWidth = size;
    ctx.current.lineCap = "round";
    ctx.current.strokeStyle = color;

    ctx.current.lineTo(nativeEvent?.clientX, nativeEvent?.clientY);
    ctx.current.stroke();
    ctx.current.beginPath();
    ctx.current.moveTo(nativeEvent?.clientX, nativeEvent?.clientY);

    if (timeout.current !== undefined) clearTimeout(timeout.current);
    timeout.current = setTimeout(function () {
      var base64ImageData = canvas.toDataURL("image/png");
      // localStorage.setItem("canvasimg", base64ImageData);
    }, 400);
  };

  const clearCanvas = () => {
    // localStorage.removeItem("canvasimg");
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    setInputBoxes([]);
    //Passing clear screen
    if (timeout.current !== undefined) clearTimeout(timeout.current);
    timeout.current = setTimeout(function () {
      var base64ImageData = canvas.toDataURL("image/png");
      // localStorage.setItem("canvasimg", base64ImageData);
    }, 400);
  };

  const getPen = () => {
    // console.log("pen called");
    setCursor("default");
    setSize("3");
    setColor("#3B3B3B");
    setIsTyping(false);
  };

  const eraseCanvas = () => {
    setCursor("grab");
    setSize("40");
    setColor("#FFFFFF");

    if (!isDrawing) {
      return;
    }
  };

  const handleTextInput = (e, inputBoxId) => {
    setInputBoxes((prevInputBoxes) =>
      prevInputBoxes.map((box) =>
        box.id === inputBoxId ? { ...box, text: e.target.value, size } : box
      )
    );
  };

  const removeInputBox = (inputBoxId) => {
    setInputBoxes((prevInputBoxes) =>
      prevInputBoxes.filter((box) => box.id !== inputBoxId)
    );
    setActiveInputBox(null); // Reset active input box on removal
  };

  const drawShape = (shapeType, startX, startY, endX, endY) => {
    // console.log("draw shape");
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.lineWidth = size;
    context.strokeStyle = color;
    context.fillStyle = "transparent"; // Set fillStyle to transparent for shape borders

    context.beginPath();
    if (shapeType === "rectangle") {
      context.rect(startX, startY, endX - startX, endY - startY);
      context.stroke();
    } else if (shapeType === "circle") {
      const radius = Math.sqrt(
        Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
      );
      context.arc(startX, startY, radius, 0, Math.PI * 2); // Draw full circle (360 degrees)
      context.stroke();
    }
    context.closePath();
  };

  const drawShapeOnCanvas = ({ nativeEvent }) => {
    if (shape !== "") {
      // console.log("inside");
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const startX = nativeEvent.clientX - rect.left;
      const startY = nativeEvent.clientY - rect.top;

      const endX = nativeEvent.clientX - rect.left;
      const endY = nativeEvent.clientY - rect.top;

      drawShape(shape, startX, startY, endX, endY);
    }
  };

  return (
    <>
      <div className="canvas-btn">
        <div className="canvas-btn-holder">
          <img
            src={pencil}
            alt=""
            onClick={getPen}
            className="btn-width canvas-icon"
            title="Pencil"
          />
          {/* <button onClick={getPen} className="btn-width">
            Pencil
          </button> */}
          <img
            src={eraser}
            alt=""
            onClick={eraseCanvas}
            className="btn-width canvas-icon"
            title="Erase Canvas"
          />
          <img
            src={cleaning}
            alt=""
            onClick={clearCanvas}
            className="btn-width canvas-icon"
            title="Clear Canvas"
          />
          {/* <button onClick={()=> setIsTyping(!isTyping) }> TEXT</button> */}
          <img
            src={font}
            alt=""
            onClick={() => {
              setIsDrawing(false);
              setIsTyping(!isTyping);
            }}
            className="btn-width canvas-icon"
            title="Add Text"
          />

          {/* <button onClick={clearCanvas} className="btn-width">
            Clear
          </button> */}
          {/* <div>
            <button onClick={eraseCanvas} className="btn-width">
              Eraser
            </button>
          </div> */}

          <div className="btn-width canvas-icon">
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              title="Choose Color"
            />
          </div>

          <div>
            <select
              className="btn-width canvas-icon"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              title="Pencil Size"
            >
              <option> 1 </option>
              <option> 3 </option>
              <option> 5 </option>
              <option> 10 </option>
              <option> 15 </option>
              <option> 20 </option>
              <option> 25 </option>
              <option> 30 </option>
            </select>
          </div>

          {/* <div>
            <select
              className="btn-width canvas-icon"
              value={shape}
              onChange={(e) => {
                setAddShape(true);
                setShape(e.target.value);
              }}
              title="Choose Shape"
            >
              <option value="">None</option>
              <option value="rectangle">Rectangle</option>
              <option value="circle">Circle</option>
            </select>
          </div> */}
        </div>
      </div>

      {inputBoxes.map((inputBox) => (
        <div
          key={inputBox.id}
          className={activeInputBox === inputBox.id ? "active-input" : ""}
          style={{
            position: "absolute",
            top: inputBox.y,
            left: inputBox.x,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            className="custom-input"
            value={inputBox.text}
            onChange={(e) => handleTextInput(e, inputBox.id)}
            placeholder="Type"
            style={{ fontSize: `${inputBox.size}px` }}
          />
          <img
            src={minus}
            alt=""
            style={{ cursor: "pointer", marginTop: "-35px" }}
            height={20}
            onClick={() => removeInputBox(inputBox.id)}
          />
        </div>
      ))}

      <canvas
        style={{ cursor: cursor }}
        onMouseDown={startPosition}
        onMouseUp={finishedPosition}
        onMouseMove={addShape ? drawShapeOnCanvas : draw}
        ref={canvasRef}
      />
    </>
  );
}
