import React from 'react'

const LikeFill = () => {
    return (
        <div><svg width="28" height="25" viewBox="0 0 28 25" fill="#964B00" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9998 22.7928C-9.33302 9.89626 7.00029 -4.10374 13.9998 4.74899C21.0003 -4.10374 37.3336 9.89626 13.9998 22.7928Z" stroke="#964B00" stroke-width="3" />
        </svg></div>
    )
}

export default LikeFill