import React, { useState, useEffect } from "react";
import { Button, Col, Container, Image, Nav, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useUserContext } from "../../../utils/hooks/UserContext";
import Pic from "../../../assets/images/icons/user.png";
import share from "../../../assets/images/icons/share.png";
import pencil from "../../../assets/images/icons/pencil.svg";
import banner from "../../../assets/images/coach_details_banner.png";
import following from "../../../assets/images/icons/following.svg";
import loction_grn from "../../../assets/images/icons/loction_grn.png";
import camera_grn from "../../../assets/images/icons/camera_grn.png";
import trash from "../../../assets/images/icons/trash.svg";
import plus_grn from "../../../assets/images/icons/plus_grn.png";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

function CoachMyProfileComponent() {
  const { user } = useUserContext();
  const [showSocial, setShowSocial] = useState(false);

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <>
      <section className="white_bg pb-6">
        <Container>
          <div className="profile_details_banner mb-3">
            <div className="profile_details_banner_bg">
              {user?.background_img && (
                <img
                  className="profile_banner_img"
                  src={`${process.env.REACT_APP_COACH_BG_FOLDER_PATH}${user?.background_img}`}
                  alt=""
                />
              )}
              {!user?.background_img && (
                <img className="profile_banner_img" src={banner} alt="" />
              )}
              <Button variant="" className="uplod_camera_btn hide">
                <img className="uplod_icn" src={camera_grn} alt="" />
              </Button>
            </div>
            <div className="profile_details_banner_content">
              <Row className="align-items-end justify-content-between">
                <Col>
                  <div className="profile_bg_img">
                    {user?.profile_pic && (
                      <img
                        className="profile_img"
                        src={
                          user.login_type == "Linkedin"
                            ? user.profile_pic
                            : `${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${user?.profile_pic}`
                        }
                        alt=""
                      />
                    )}
                    {!user?.profile_pic && (
                      <img
                        className="profile_img"
                        src={
                          user.login_type == "Linkedin" ? user.profile_pic : Pic
                        }
                        alt=""
                      />
                    )}
                    <Button variant="" className="uplod_camera_btn hide">
                      <img className="uplod_icn" src={camera_grn} alt="" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  {window.innerWidth > 500 && (
                    <div className="coach_details">
                      <Row className={`align-items-end justify-content-between ${window.innerWidth > 768 ? '' : "text-center"}`}>
                        <Col className="col-lg-5 col-md-5 col-sm-12">
                          <h4 className="fs-28 fw-600 m-0">{user?.name}</h4>
                          <div className="coach_details_label">
                            {user?.tags?.map((i) => {
                              return <label>{i}</label>;
                            })}
                          </div>
                        </Col>
                        <Col className="col-lg-5 col-md-5 col-sm-12">
                          <Nav className={`${window.innerWidth > 768 ? '' : "justify-content-center"}`}>
                            <Nav.Item className="mx-1">
                              <img
                                className="mx-2"
                                height={15}
                                src={loction_grn}
                                alt=""
                              />
                              <span className="fs-14 fw-500">
                                {user?.state_name}, {user?.country_name}
                              </span>
                            </Nav.Item>
                            <Nav.Item className="mx-1">
                              <img
                                className="mx-2"
                                height={15}
                                src={following}
                                alt=""
                              />
                              <span className="fs-14 fw-500">
                                {user?.followers_count} followers
                              </span>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col className="col-lg-2 col-md-2 col-sm-12">
                          <Nav className={`${window.innerWidth > 768 ? '' : "justify-content-center"}`}>
                            <Nav.Item onClick={() => setShowSocial(!showSocial)} className="me-3">
                              {/* <Button
                                variant=""
                                className=""
                                onClick={() => setShowSocial(!showSocial)}
                              > */}
                              <img height={20} src={share} alt="" />
                              {/* </Button> */}
                              {showSocial === true && (
                                <div className="share">
                                  <div className="mobile-share-details-link">
                                    <FacebookShareButton
                                      url={`${window.location}`}
                                    >
                                      <FacebookIcon size={40} round={true} />
                                    </FacebookShareButton>
                                    <WhatsappShareButton
                                      url={`${window.location}`}
                                    >
                                      <WhatsappIcon size={40} round={true} />
                                    </WhatsappShareButton>

                                    <EmailShareButton
                                      url={`${window.location}`}
                                    >
                                      <EmailIcon size={40} round={true} />
                                    </EmailShareButton>
                                    <LinkedinShareButton
                                      url={`${window.location}`}
                                    >
                                      <LinkedinIcon size={40} round={true} />
                                    </LinkedinShareButton>
                                    <TelegramShareButton
                                      url={`${window.location}`}
                                    >
                                      <TelegramIcon size={40} round={true} />
                                    </TelegramShareButton>
                                    <TwitterShareButton
                                      url={`${window.location}`}
                                    >
                                      <TwitterIcon size={40} round={true} />
                                    </TwitterShareButton>
                                  </div>
                                </div>
                              )}
                            </Nav.Item>
                            <Nav.Item>
                              <NavLink to="/coach">
                                {/* <Button variant=""> */}
                                <img height={20} src={pencil} alt="" />
                                {/* </Button> */}
                              </NavLink>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {window.innerWidth < 500 && (
                    <div className="coach_details">
                      <Row className="align-items-end justify-content-between">
                        <Col className="col-lg-auto">
                          <Nav
                            className=""
                            style={{
                              width: "100px",
                              marginTop: "-35px",
                              marginLeft: "180px",
                              marginBottom: "-10px",
                            }}
                          >
                            <Nav.Item>
                              <Button
                                variant=""
                                className=""
                                onClick={() => setShowSocial(!showSocial)}
                              >
                                <img height={20} src={share} alt="" />
                              </Button>
                              {showSocial === true && (
                                <div className="share">
                                  <div
                                    className="mobile-share-details-link"
                                    style={{ top: "65%", right: "-8%" }}
                                  >
                                    <FacebookShareButton
                                      url={`${window.location}`}
                                    >
                                      <FacebookIcon size={40} round={true} />
                                    </FacebookShareButton>
                                    <WhatsappShareButton
                                      url={`${window.location}`}
                                    >
                                      <WhatsappIcon size={40} round={true} />
                                    </WhatsappShareButton>

                                    <EmailShareButton
                                      url={`${window.location}`}
                                    >
                                      <EmailIcon size={40} round={true} />
                                    </EmailShareButton>
                                    <LinkedinShareButton
                                      url={`${window.location}`}
                                    >
                                      <LinkedinIcon size={40} round={true} />
                                    </LinkedinShareButton>
                                    <TelegramShareButton
                                      url={`${window.location}`}
                                    >
                                      <TelegramIcon size={40} round={true} />
                                    </TelegramShareButton>
                                    <TwitterShareButton
                                      url={`${window.location}`}
                                    >
                                      <TwitterIcon size={40} round={true} />
                                    </TwitterShareButton>
                                  </div>
                                </div>
                              )}
                            </Nav.Item>
                            <Nav.Item>
                              <NavLink to="/coach">
                                <Button variant="">
                                  <img height={20} src={pencil} alt="" />
                                </Button>
                              </NavLink>
                            </Nav.Item>
                          </Nav>

                          <h4
                            className={
                              window.innerWidth < 500
                                ? "fs-22 fw-600 mt-2"
                                : "fs-40 fw-600 m-0"
                            }
                          >
                            {user?.name}
                          </h4>
                        </Col>
                      </Row>
                      <Col className="col-lg-auto">
                        <div className="coach_details_label">
                          {user?.tags?.map((i) => {
                            return <label>{i}</label>;
                          })}
                        </div>
                      </Col>
                      <Row className="justify-content-center">
                        <Col className="col-lg-auto">
                          <Nav className="">
                            <Nav.Item className="mx-1">
                              <img
                                className="mx-4"
                                height={15}
                                src={loction_grn}
                                alt=""
                              />
                              <br />
                              <span className="fs-12 fw-500">
                                {user?.state_name}, {user?.country_name}
                              </span>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col>
                          <Nav className="">
                            <Nav.Item className="mx-1">
                              <img
                                className="mx-3"
                                height={15}
                                src={following}
                                alt=""
                              />
                              <br />
                              <span className="fs-12 fw-500">
                                {user?.followers_count} followers
                              </span>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          {/* <Row className="justify-content-center">
            <Col className="col-auto">
              <div className="about_bg_img">
                {user?.profile_pic && (
                  <img
                    className="about_me_img"
                    src={
                      user.login_type == "Linkedin"
                        ? user.profile_pic
                        : `${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${user?.profile_pic}`
                    }
                    alt=""
                  />
                )}
                {!user?.profile_pic && (
                  <img className="profile_img" src={Pic} alt="" />
                )}
                <Button variant="" className="uplod_camera_btn hide">
                  <img className="uplod_icn" src={camera_grn} alt="" />
                </Button>
              </div>
            </Col>
          </Row> */}
          <Row className="mt-4">
            <Col>
              <div className="d-flex align-items-end justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov">
                  About Me
                  <NavLink to="/coach/about-yourself" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>

                <Button variant="" className="hide">
                  <img height={25} src={pencil} alt="" />
                </Button>
              </div>
              {
                user?.about ?
                  <p
                    className="fs-16 fw-400"
                    dangerouslySetInnerHTML={{ __html: user?.about }}
                  />
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <div className="d-flex align-items-end justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov">
                  My Story
                  <NavLink to="/coach/about-yourself" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button variant="" className="hide">
                  <img height={25} src={pencil} alt="" />
                </Button>
              </div>
              {
                user?.story ?
                  <p
                    className="fs-16 fw-400"
                    dangerouslySetInnerHTML={{ __html: user?.story }}
                  />
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-1 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Education
                  <NavLink to="/coach/professional-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button size="sm" variant="" className="p-0 hide">
                  <img height={25} src={plus_grn} alt="" />
                </Button>
              </div>
              {
                user?.educations && user?.educations.length > 0 ?
                  user?.educations?.map((i) => {
                    return (
                      <div className="mb-1 d-flex align-items-center justify-content-between">
                        <div>
                          <p className="fs-18 fw-600 text_brand_color4 m-0">
                            {i?.course}
                          </p>
                          <small className="d-block fs-14 fw-400 text_brand_color4">
                            {i?.university_colleage}
                          </small>
                        </div>
                        <Nav className="">
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <Image height={15} src={trash} alt="" />
                            </Button>
                          </Nav.Item>
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <img height={15} src={pencil} alt="" />
                            </Button>
                          </Nav.Item>
                        </Nav>
                      </div>
                    );
                  })
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-1 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Experience
                  <NavLink to="/coach/professional-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button size="sm" variant="" className="p-0 hide">
                  <img height={25} src={plus_grn} alt="" />
                </Button>
              </div>
              {

                user?.experiences && user?.experiences.length > 0 ?
                  user?.experiences?.map((i) => {
                    return (
                      <div className="mb-1 d-flex align-items-center justify-content-between">
                        <div>
                          <p className="fs-18 fw-600 text_brand_color4 m-0">
                            {i?.title}
                          </p>
                          <small className="d-block fs-14 fw-400 text_brand_color4">
                            {i?.company_name}
                          </small>
                          <small className="d-block fs-14 fw-400 text_brand_color4">
                            {i?.start_date} - {i?.end_date}
                          </small>
                        </div>
                        <Nav className="">
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <Image height={15} src={trash} alt="" />
                            </Button>
                          </Nav.Item>
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <img height={15} src={pencil} alt="" />
                            </Button>
                          </Nav.Item>
                        </Nav>
                      </div>
                    );
                  })
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-1 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Certificates
                  <NavLink to="/coach/professional-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button size="sm" variant="" className="p-0 hide">
                  <img height={25} src={plus_grn} alt="" />
                </Button>
              </div>
              {
                user?.certificates && user?.certificates.length > 0 ?
                  user?.certificates?.map((i) => {
                    return (
                      <div className="mb-1 d-flex align-items-center justify-content-between">
                        <div>
                          <p className="fs-18 fw-600 text_brand_color4 m-0">
                            <u>{i?.name}</u>
                          </p>
                          <small className="d-block fs-14 fw-400 text_brand_color4">
                            {i?.organization}
                          </small>
                        </div>
                        <Nav className="">
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <Image height={15} src={trash} alt="" />
                            </Button>
                          </Nav.Item>
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <img height={15} src={pencil} alt="" />
                            </Button>
                          </Nav.Item>
                        </Nav>
                      </div>
                    );
                  })
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-1 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Tags
                  <NavLink to="/coach/coaching-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button size="sm" variant="" className="p-0 hide">
                  <img height={25} src={pencil} alt="" />
                </Button>
              </div>
              <div className="mb-1 d-flex align-items-center">
                {
                  user?.tags && user?.tags.length > 0 ?
                    user?.tags?.map((i) => {
                      return (
                        <div>
                          <p className="fs-14 fw-400 text_brand_color4 m-0 px-1">
                            #{i}
                          </p>
                        </div>
                      );
                    })
                    :
                    <div className="my-2 text-center">
                      <p className="m-0 fs-14">Data not found</p>
                    </div>
                }
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-1 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Skills
                  <NavLink to="/coach/coaching-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button size="sm" variant="" className="p-0 hide">
                  <img height={25} src={plus_grn} alt="" />
                </Button>
              </div>

              <Nav>
                {
                  user?.skills && user?.skills.length > 0 ?
                    user?.skills?.map((i) => {
                      return (
                        <Nav.Item className="me-2 mb-2">
                          <Button variant="added_language" className="h-100 fs-14">{i?.name}</Button>
                        </Nav.Item>
                      );
                    })
                    :
                    <div className="my-2 text-center">
                      <p className="m-0 fs-14">Data not found</p>
                    </div>
                }
              </Nav>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-1 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Specialties
                  <NavLink to="/coach/about-yourself" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button size="sm" variant="" className="p-0 hide">
                  <img height={25} src={plus_grn} alt="" />
                </Button>
              </div>
              {
                user?.specialties && user?.specialties.length > 0 ?
                  JSON.parse(user?.specialties)?.map((i, index) => {
                    return (
                      <div>
                        <div className="specialties_list">
                          <div className="mb-1 d-flex justify-content-between">
                            <div>
                              <div className="d-flex align-items-center">
                                <h5 className="m-0 fs-18 fw-600 mb-1">
                                  <span>{index + 1}. </span>
                                  {i?.speciality}
                                </h5>
                              </div>
                              <p className="fs-14">{i?.description}</p>
                            </div>
                            <Nav className="">
                              <Nav.Item>
                                <Button variant="" className="p-1 hide">
                                  <Image height={15} src={trash} alt="" />
                                </Button>
                              </Nav.Item>
                              <Nav.Item>
                                <Button variant="" className="p-1 hide">
                                  <img height={15} src={pencil} alt="" />
                                </Button>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

          <Row>
            <Col md="6" className="mt-4">
              <div className="d-flex align-items-end justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov">
                  Benefits
                  <NavLink to="/coach/coaching-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button variant="" className="hide">
                  <img height={25} src={pencil} alt="" />
                </Button>
              </div>
              {
                user?.benefits && user?.benefits.length > 0 ?
                  user?.benefits?.map((i) => {
                    return (
                      <ul className="bc_list_flex">
                        <li>{i}</li>
                      </ul>
                    );
                  })
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
            <Col md="6" className="mt-4">
              <div className="d-flex align-items-end justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov">
                  Clients
                  <NavLink to="/coach/coaching-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button variant="" className="hide">
                  <img height={25} src={pencil} alt="" />
                </Button>
              </div>
              {
                user?.professions && user?.professions.length > 0 ?
                  user?.professions?.map((i) => {
                    return (
                      <ul className="bc_list_flex">
                        <li>{i}</li>
                      </ul>
                    );
                  })
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-2 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Pricing
                  <NavLink to="/coach/coaching-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
                <Button size="sm" variant="" className="p-0 hide">
                  <img height={25} src={plus_grn} alt="" />
                </Button>
              </div>
              {
                user?.programs && user?.programs.length > 0 ?
                  user?.programs?.map((i) => {
                    return (
                      <div className="mb-1 d-flex align-items-center justify-content-between">
                        <div>
                          <h4 className="fs-24 fw-700 text_brand_color2 m-0">
                            ${i?.price}
                          </h4>
                          <p className="fs-20 fw-500 text_brand_color7 m-0">
                            {i?.session_title}
                          </p>
                          <p className="fs-14 fw-400 text_brand_color4">
                            {i?.no_of_session} Live meeting on online platform for{" "}
                            {i?.duration} days
                          </p>
                        </div>
                        <Nav className="">
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <Image height={15} src={trash} alt="" />
                            </Button>
                          </Nav.Item>
                          <Nav.Item>
                            <Button variant="" className="p-1 hide">
                              <img height={15} src={pencil} alt="" />
                            </Button>
                          </Nav.Item>
                        </Nav>
                      </div>
                    );
                  })
                  :
                  <div className="my-2 text-center">
                    <p className="m-0 fs-14">Data not found</p>
                  </div>
              }
            </Col>
          </Row>

              {/* OLD CODES of TESTIMONAIL HIDED */}

          {/* <Row className="mt-4">
            <Col className="col-12 mx-auto">
              <div className="mb-2 d-flex align-items-center justify-content-between">
                <h4 className="text_brand_color1 fs-28 fw-600 font-volkhov m-0">
                  Testimonial
                  <NavLink to="/coach/professional-details" className="ms-2">
                    <img height={14} src={pencil} alt="" />
                  </NavLink>
                </h4>
              </div>

              <Row>
                
              </Row>
              <ol>
                {
                  user?.testimonials && user?.testimonials.length > 0 ?
                    user?.testimonials?.map((i) => {
                      return (
                        <li>
                          <Row className="ps-2.5">
                            <Col md="8">
                              <p className="fs-16 fw-400">{i?.description}</p>
                            </Col>
                            <Col md="4">
                              <div className="d-flex align-items-center">
                                <img
                                  width="60"
                                  height="60"
                                  class="rounded-circle"
                                  src={
                                    process.env.REACT_APP_COACH_TESTIMONIAL_PATH +
                                    i.image
                                  }
                                  alt=""
                                />
                                <p className="text_brand_color7 fs-20 fw-700 m-0 ms-2">
                                  {i?.name}, {i?.company_name}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </li>
                      );
                    })
                    :
                    <div className="my-2 text-center">
                      <p className="m-0 fs-14">Data not found</p>
                    </div>
                }
              </ol>
            </Col>
          </Row> */}
        </Container>
      </section>
    </>
  );
}

export default CoachMyProfileComponent;
