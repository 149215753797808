import React, { useState } from "react";
import close from '../../../assets/images/icons/close.png';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { forgetPassword, coachSignUp } from "../../../utils/api";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from 'react-toastify';


const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    // userType: Yup.string().required("User type is required"),
});

function ForgetModalComponenet(props) {
    const [isLoading, setisLoading] = useState(false)

    const onSubmitHandler = async (values) => {
        setisLoading(true)
        try {
            const formData = new FormData();
            formData.append("emailid", values.email);
            // formData.append("user_type", values.userType);
            formData.append("user_type", 'user');
            const { data } = await forgetPassword(formData);

            if (data?.status) {
                toast.success(data?.message)
                props.onHide()
            } else {
                toast.error(data?.message || 'Something went wrong');
            }

        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message)
        } finally {
            setisLoading(false)
        }
    };


    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="brand_bg_color1 my_light_modal">
                    <div className="">
                        <Row className="justify-content-center g-0">
                            <Col lg="12">
                                <div className="ls_modal_conatiner px-4">
                                    <Button className="ls_mdl_close_btn p-0" onClick={props.onHide}>
                                        <img alt="" src={close} />
                                    </Button>
                                    <div className="hdng_a text-start">
                                        <h6 className="text-uppercase">Forget Password</h6>
                                    </div>
                                    <Formik
                                        initialValues={{ email: "", userType: "user" }}
                                        validationSchema={validationSchema}
                                        onSubmit={onSubmitHandler}
                                    >
                                        {({ values, errors, touched, handleChange, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                {/* {["radio"].map((type) => (
                                                    <Row key={`user_coach-${type}`} className="user_coach_rd_btn">
                                                        <Col>
                                                            <Form.Check
                                                                label="User"
                                                                name="userType"
                                                                type={type}
                                                                id={`user_coach-${type}-1`}
                                                                value="user"
                                                                onChange={handleChange}
                                                                isInvalid={touched.userType && !!errors.userType}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check
                                                                label="Coach"
                                                                name="userType"
                                                                type={type}
                                                                id={`user_coach-${type}-2`}
                                                                value="coach"
                                                                onChange={handleChange}
                                                                isInvalid={touched.userType && !!errors.userType}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))} */}
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        name="email"
                                                        type="email"
                                                        placeholder="xyz@abe.com"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isInvalid={touched.email && !!errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="d-grid">
                                                    <Button disabled={isLoading} variant="success" className="" type="submit">
                                                        Send Email
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </>

    )
}
export default ForgetModalComponenet;