import React, { useEffect, useState } from "react";
import { Button, Container, Col, Form, Nav, Row, Image } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";
import trash from "../../../assets/images/icons/trash.svg";
import { useFormik } from "formik";
import Modal from "react-awesome-modal";
import * as Yup from "yup";
import {
  coachAddCertificate,
  coachAddTestimonial,
  coachDeleteTestimonial,
  coachAddEducation,
  coachAddExperience,
  coachDeleteCertificate,
  coachDeleteEducation,
  coachDeleteExperience,
  coachUpdate,
  publishProfile,
} from "../../../utils/api";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

const initialValuesEdu = {
  course: "",
  university_colleage: "",
};

const initialtestimonial = {
  name: "",
  company_name: "",
  description: "",
  image: "",
};

const initialValuesExp = {
  title: "",
  company_name: "",
  start_date: "",
  end_date: "",
  workexperience: "",
};

const initialValuesCerti = {
  name: "",
  organization: "",
};

const educationSchema = Yup.object().shape({
  course: Yup.string().required("Course is required"),
  university_colleage: Yup.string().required("University/College is required"),
});

const MAX_FILE_SIZE = 5242880; //100KB

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const testimonialSchema = Yup.object().shape({
  name: Yup.string().required("name is required").max(30),
  company_name: Yup.string().required("Company is required").max(30),
  description: Yup.string().required("description is required").max(250),
  image: Yup.mixed()
    .required("Image is Required")
    .test("is-valid-type", "Not a valid image type", (value) =>
      isValidFileType(value && value.name.toLowerCase(), "image")
    )
    .test(
      "is-valid-size",
      "Max allowed size is 100KB",
      (value) => value && value.size <= MAX_FILE_SIZE
    ),
});

const experienceSchema = Yup.object().shape({
  title: Yup.string().required("Title is required").max(50),
  company_name: Yup.string().required("Company is required").max(50),
  start_date: Yup.string().required("Start Date is required"),
  end_date: Yup.string().required("End Date is required"),
});

const certificateSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").max(50),
  organization: Yup.string()
    .required("Issuing Organization is required")
    .max(20),
});

function CoachProfileProfessionalDetailsComponent(props) {
  const [educations, seteducations] = useState([]);
  const [experiences, setexperiences] = useState([]);
  const [certificates, setcertificates] = useState([]);
  const [testimonials, settestimonials] = useState([]);
  const navigate = useNavigate();
  const { getUserFromToken, user } = useUserContext();
  const [workexperience, setworkexperience] = useState(null);
  const [eduVisible, setEduvisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [expVisible, setExpVisible] = useState(false);
  const [testiVisible, setTestiVisible] = useState(false);
  const [certiVisible, setCertiVisible] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [allYears, setAllYears] = useState([]);

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    
    seteducations(user?.educations);
    settestimonials(user?.testimonials || []);
    setexperiences(user?.experiences);
    setcertificates(user?.certificates);
    setworkexperience(user?.workexperience);
    setAllYears([
      "2024",
      "2023",
      "2022",
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006",
      "2005",
      "2004",
      "2003",
      "2002",
      "2001",
    ]);
  }, [user]);

  const onClickSave = async (values) => {
    try {
      setisLoading(true);
      if (workexperience > 0) {
        const formData = new FormData();
        formData.append("workexperience", workexperience);
        await coachUpdate(formData);
      }

      // handle certificate
      const newCertificate = certificates?.filter((i) => i?.status === "new");
      for (let index = 0; index < newCertificate?.length; index++) {
        const element = newCertificate[index];
        const formData = new FormData();
        formData.append("name", element?.name);

        formData.append("organization", element?.organization);

        await coachAddCertificate(formData);
      }

      const deletedCertificate = certificates?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedCertificate?.length > 0) {
        const formData = new FormData();
        formData.append(
          "certificate_ids",
          `[${deletedCertificate?.map((i) => i?.id)}]`
        );

        await coachDeleteCertificate(formData);
      }

      // handle education
      const newEducation = educations?.filter((i) => i?.status === "new");
      for (let index = 0; index < newEducation?.length; index++) {
        const element = newEducation[index];
        const formData = new FormData();
        formData.append("course", element?.course);
        formData.append("university_colleage", element?.university_colleage);
        formData.append("passing_year", element.passing_year);
        await coachAddEducation(formData);
      }

      const deletedEducation = educations?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedEducation?.length > 0) {
        const formData = new FormData();
        formData.append(
          "education_ids",
          `[${deletedEducation?.map((i) => i?.id)}]`
        );

        await coachDeleteEducation(formData);
      }

      // handal testimonial
      const newTestimonial = testimonials?.filter((i) => i?.status === "new");
      for (let index = 0; index < newTestimonial?.length; index++) {
        const element = newTestimonial[index];
        const formData = new FormData();
        formData.append("name", element?.name);
        formData.append("company_name", element?.company_name);
        formData.append("description", element?.description);
        formData.append("testimonial_img", element?.image);
        await coachAddTestimonial(formData);
      }

      const deletedTestimonial = testimonials?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedTestimonial?.length > 0) {
        const formData = new FormData();
        formData.append(
          "tesimonial_ids",
          `[${deletedTestimonial?.map((i) => i?.id)}]`
        );

        await coachDeleteTestimonial(formData);
      }

      // handle experience
      const newExperience = experiences?.filter((i) => i?.status === "new");
      for (let index = 0; index < newExperience?.length; index++) {
        const element = newExperience[index];
        const formData = new FormData();
        formData.append("title", element?.title);
        formData.append("company_name", element?.company_name);
        formData.append("start_date", element?.start_date);
        formData.append("end_date", element?.end_date);
        formData.append("workexperience", element?.workexperience);

        await coachAddExperience(formData);
      }

      const deletedExperience = experiences?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedExperience?.length > 0) {
        const formData = new FormData();
        formData.append(
          "experience_ids",
          `[${deletedExperience?.map((i) => i?.id)}]`
        );

        await coachDeleteExperience(formData);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
    getUserFromToken();
    const publish_profile = await publishProfile();
    if (publish_profile) {
      toast.success("Profile updated successfully!");
      setisLoading(false);
    }
    setisLoading(false);
    navigate("/coach/profile");
  };
  const onClickPreview = async (values) => {
    try {
      setisLoading(true);
      if (workexperience > 0) {
        const formData = new FormData();
        formData.append("workexperience", workexperience);
        await coachUpdate(formData);
      }

      // handle certificate
      const newCertificate = certificates?.filter((i) => i?.status === "new");
      for (let index = 0; index < newCertificate?.length; index++) {
        const element = newCertificate[index];
        const formData = new FormData();
        formData.append("name", element?.name);

        formData.append("organization", element?.organization);

        await coachAddCertificate(formData);
      }

      const deletedCertificate = certificates?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedCertificate?.length > 0) {
        const formData = new FormData();
        formData.append(
          "certificate_ids",
          `[${deletedCertificate?.map((i) => i?.id)}]`
        );

        await coachDeleteCertificate(formData);
      }

      // handle education
      const newEducation = educations?.filter((i) => i?.status === "new");
      for (let index = 0; index < newEducation?.length; index++) {
        const element = newEducation[index];
        const formData = new FormData();
        formData.append("course", element?.course);
        formData.append("university_colleage", element?.university_colleage);
        formData.append("passing_year", element.passing_year);
        await coachAddEducation(formData);
      }

      const deletedEducation = educations?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedEducation?.length > 0) {
        const formData = new FormData();
        formData.append(
          "education_ids",
          `[${deletedEducation?.map((i) => i?.id)}]`
        );

        await coachDeleteEducation(formData);
      }

      // handal testimonial
      const newTestimonial = testimonials?.filter((i) => i?.status === "new");
      for (let index = 0; index < newTestimonial?.length; index++) {
        const element = newTestimonial[index];
        const formData = new FormData();
        formData.append("name", element?.name);
        formData.append("company_name", element?.company_name);
        formData.append("description", element?.description);
        formData.append("testimonial_img", element?.image);
        await coachAddTestimonial(formData);
      }

      const deletedTestimonial = testimonials?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedTestimonial?.length > 0) {
        const formData = new FormData();
        formData.append(
          "tesimonial_ids",
          `[${deletedTestimonial?.map((i) => i?.id)}]`
        );

        await coachDeleteTestimonial(formData);
      }

      // handle experience
      const newExperience = experiences?.filter((i) => i?.status === "new");
      for (let index = 0; index < newExperience?.length; index++) {
        const element = newExperience[index];
        const formData = new FormData();
        formData.append("title", element?.title);
        formData.append("company_name", element?.company_name);
        formData.append("start_date", element?.start_date);
        formData.append("end_date", element?.end_date);
        formData.append("workexperience", element?.workexperience);

        await coachAddExperience(formData);
      }

      const deletedExperience = experiences?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedExperience?.length > 0) {
        const formData = new FormData();
        formData.append(
          "experience_ids",
          `[${deletedExperience?.map((i) => i?.id)}]`
        );

        await coachDeleteExperience(formData);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setisLoading(false);
    }
    getUserFromToken();
    setisLoading(false);
    navigate("/coach/preview");
  };

  const handleSubmitEducation = (values, { resetForm }) => {
    formikEdu.setValues({
      ...formikEdu.values,
      course: "",
      university_colleage: "",
      passing_year: "",
    });

    seteducations([
      ...educations,
      {
        id: new Date()?.getTime(),
        course: values?.course,
        university_colleage: values?.university_colleage,
        status: "new",
        passing_year: values.passing_year,
      },
    ]);
    resetForm();
  };

  const formikEdu = useFormik({
    initialValues: initialValuesEdu,
    validationSchema: educationSchema,
    onSubmit: handleSubmitEducation,
  });

  const onRemoveEducation = (id) => {
    const results = JSON.parse(JSON.stringify(educations))?.map((i) => {
      if (id === i?.id) {
        i.status = "deleted";
      }
      return i;
    });
    seteducations(results);
    setEduvisible(false);
    setVisible(false);
  };

  const handleSubmitExpereience = (values, { resetForm }) => {
    formikExp.setValues({
      ...formikExp.values,
      title: "",
      company_name: "",
      start_date: "",
      end_date: new Date().toISOString(),
      workexperience: "",
    });

    setexperiences([
      ...experiences,
      {
        id: new Date()?.getTime(),
        title: values?.title,
        company_name: values?.company_name,
        start_date: values?.start_date,
        end_date: values?.end_date,
        workexperience: values?.workexperience,
        status: "new",
      },
    ]);
    resetForm();
  };

  const onRemoveExperience = (id) => {
    const results = JSON.parse(JSON.stringify(experiences))?.map((i) => {
      if (id === i?.id) {
        i.status = "deleted";
      }
      return i;
    });
    setexperiences(results);
    setExpVisible(false);
    setVisible(false);
  };

  const formikExp = useFormik({
    initialValues: initialValuesExp,
    validationSchema: experienceSchema,
    onSubmit: handleSubmitExpereience,
  });

  const handleSubmitTestmonial = (values, { resetForm }) => {
    formikTestimonial.setValues({
      ...formikTestimonial.values,
      name: "",
      company_name: "",
      description: "",
      image: "",
    });

    settestimonials([
      ...testimonials,
      {
        id: new Date()?.getTime(),
        name: values?.name,
        company_name: values?.company_name,
        description: values?.description,
        image: values?.image,
        status: "new",
      },
    ]);
    resetForm();
  };

  const onRemovetestimonial = (id) => {
    const results = JSON.parse(JSON.stringify(testimonials))?.map((i) => {
      if (id === i?.id) {
        i.status = "deleted";
      }
      return i;
    });
    settestimonials(results);
    setTestiVisible(false);
    setVisible(false);
  };

  const formikTestimonial = useFormik({
    initialValues: initialtestimonial,
    validationSchema: testimonialSchema,
    onSubmit: handleSubmitTestmonial,
  });

  const handleSubmitCertificate = (values, { resetForm }) => {
    formikExp.setValues({
      ...formikExp.values,
      title: "",
      company_name: "",
      start_date: "",
      end_date: "",
      name: "",
    });

    setcertificates([
      ...certificates,
      {
        id: new Date()?.getTime(),
        name: values?.name,
        organization: values?.organization,
        status: "new",
      },
    ]);
    resetForm();
  };

  const onRemoveCertificate = (id) => {
    const results = JSON.parse(JSON.stringify(certificates))?.map((i) => {
      if (id === i?.id) {
        i.status = "deleted";
      }
      return i;
    });
    setcertificates(results);
    setCertiVisible(false);
    setVisible(false);
  };

  const formikCerti = useFormik({
    initialValues: initialValuesCerti,
    validationSchema: certificateSchema,
    onSubmit: handleSubmitCertificate,
  });

  const handleDelete = (id, delete_type) => {
    if (delete_type === "education") {
      setDeleteId(id);
      setEduvisible(true);
      setVisible(true);
    } else if (delete_type === "experience") {
      setDeleteId(id);
      setExpVisible(true);
      setVisible(true);
    } else if (delete_type === "testimonial") {
      setDeleteId(id);
      setTestiVisible(true);
      setVisible(true);
    } else if (delete_type === "certificate") {
      setDeleteId(id);
      setCertiVisible(true);
      setVisible(true);
    }
  };

  return (
    <>
      <section className="coach_my_activity py-3">
        <Container>
          <Row className="align-items-center justify-content-center mb-5">
            {window.innerWidth < 500 ? (
              <div>
                <Col className="col">
                  <Nav
                    variant="pills"
                    className="wizard_nav justify-content-center"
                  >
                    <Nav.Item className="active">
                      <NavLink to="/coach/professional-details">
                        <span className="wn_num">4</span>
                        <span>Professional Details</span>
                      </NavLink>
                    </Nav.Item>
                  </Nav>
                </Col>
              </div>
            ) : (
              <Col className="col">
                <Nav
                  variant="pills"
                  className="wizard_nav justify-content-between"
                >
                  <Nav.Item className="active">
                    <NavLink to="/coach">
                      <span className="wn_num">1</span>
                      <span> Personal Information</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item className="active">
                    <NavLink to="/coach/about-yourself">
                      <span className="wn_num">2</span>
                      <span>About Yourself</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item className="active">
                    <NavLink to="/coach/coaching-details">
                      <span className="wn_num">3</span>
                      <span>Coaching Details</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item className="active">
                    <NavLink to="/coach/professional-details">
                      <span className="wn_num">4</span>
                      <span>Professional Details</span>
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Col>
            )}
          </Row>
          <div className="professional_overlay">
            <Form
              className="profile_edit_forms mt-4 px-3 py-2"
              onSubmit={formikEdu.handleSubmit}
            >
              <Col sm="12" className="mb-4">
                <Form.Label className="title">Education</Form.Label>
                <>
                  {educations
                    ?.filter((i) => i?.status !== "deleted")
                    ?.map((education) => (
                      <Row>
                        <Col className="col-12 mx-auto">
                          <div className="pr_dtls_prvw">
                            <div>
                              <p className="fs-16 fw-500 text_brand_color4 m-0">
                                {education.course}
                              </p>
                              <small className="d-block fs-14 fw-400 text_brand_color4">
                                {education.university_colleage} (
                                {education?.passing_year?.substring(0, 4)})
                              </small>
                            </div>
                            <Button
                              variant=""
                              onClick={() =>
                                handleDelete(education?.id, "education")
                              }
                            >
                              <Image src={trash} />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  <Row className="d-flex justify-content-end">
                    <Col
                      className={
                        window.innerWidth < 500
                          ? "col-auto w-100"
                          : "col-auto mt-3 input-width"
                      }
                    >
                      <Form.Group as={Col} controlId="">
                        <Form.Control
                          type="text"
                          placeholder="Course"
                          name={`course`}
                          value={formikEdu.values.course}
                          onChange={formikEdu.handleChange}
                          isInvalid={
                            formikEdu?.touched?.course &&
                            !!formikEdu?.errors?.course
                          }
                        />

                        <Form.Control.Feedback type="invalid">
                          {formikEdu?.errors?.course &&
                            formikEdu?.touched?.course && (
                              <div>{formikEdu?.errors?.course}</div>
                            )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      className={
                        window.innerWidth < 500
                          ? "col-auto mt-3 w-100"
                          : "col-auto mt-3 input-width"
                      }
                    >
                      <Form.Group as={Col} controlId="">
                        <Form.Control
                          type="text"
                          placeholder="University/College"
                          name={`university_colleage`}
                          value={formikEdu.values.university_colleage}
                          onChange={formikEdu.handleChange}
                          isInvalid={
                            formikEdu?.touched?.university_colleage &&
                            !!formikEdu?.errors?.university_colleage
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formikEdu?.errors?.university_colleage &&
                            formikEdu?.touched?.university_colleage && (
                              <div>
                                {formikEdu?.errors?.university_colleage}
                              </div>
                            )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col
                      className={
                        window.innerWidth < 500
                          ? "mb-3 col-auto mt-3 w-100"
                          : "mb-3 col-auto mt-3"
                      }
                    >
                      <Button
                        type="submit"
                        variant="success"
                        className="px-4"
                        style={{
                          marginLeft: window.innerWidth < 500 && "250px",
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </>
              </Col>
            </Form>
          </div>

          {/* EXPERIENCE */}
          <div className="professional_overlay">
            <Form onSubmit={formikExp.handleSubmit} className="px-3 py-2">
              <Col sm="12" className="mb-4">
                <Form.Label className="title">Experience</Form.Label>

                <>
                  {experiences
                    ?.filter((i) => i?.status !== "deleted")
                    ?.map((experience, index) => (
                      <Row>
                        <Col className="col-12 mx-auto">
                          <div className="pr_dtls_prvw">
                            <div>
                              <p className="fs-16 fw-500 text_brand_color4 m-0">
                                {experience.title}
                              </p>
                              <small className="d-block fs-12 fw-400 text_brand_color4">
                                {experience.company_name}
                              </small>
                              <small className="d-block fs-12 fw-400 text_brand_color4">
                                {experience.start_date}
                              </small>
                              <small className="d-block fs-12 fw-400 text_brand_color4">
                                {experience.endtDate}
                              </small>
                            </div>
                            <Button
                              variant=""
                              onClick={() =>
                                handleDelete(experience?.id, "experience")
                              }
                            >
                              <Image src={trash} />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}

                  <Row className="align-items-end">
                    <Col
                      className={
                        window.innerWidth < 500 ? "col-auto mt-3 w-100" : ""
                      }
                    >
                      <Row className="d-flex justify-content-start">
                        <Form.Group
                          as={Col}
                          sm="6"
                          className="mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type="text"
                            placeholder="Title"
                            name={`title`}
                            value={formikExp.values.title}
                            onChange={formikExp.handleChange}
                            isInvalid={
                              formikExp?.touched?.title &&
                              !!formikExp?.errors?.title
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formikExp?.errors?.title &&
                              formikExp?.touched?.title && (
                                <div>{formikExp?.errors?.title}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm="6"
                          className="mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type="text"
                            placeholder="Company"
                            name={`company_name`}
                            value={formikExp.values.company_name}
                            onChange={formikExp.handleChange}
                            isInvalid={
                              formikExp?.touched?.company_name &&
                              !!formikExp?.errors?.company_name
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formikExp?.errors?.company_name &&
                              formikExp?.touched?.company_name && (
                                <div>{formikExp?.errors?.company_name}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm="3"
                          className="mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type="number"
                            placeholder="Enter your experience"
                            name={`workexperience`}
                            value={formikExp.workexperience}
                            onChange={(e) =>
                              setworkexperience(e?.target?.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formikExp?.errors?.workexperience &&
                              formikExp?.touched?.workexperience && (
                                <div>{formikExp?.errors?.workexperience}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm="3"
                          className="mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type="date"
                            placeholder="Start Date"
                            name={`start_date`}
                            value={formikExp.values.start_date}
                            onChange={formikExp.handleChange}
                            focused
                            isInvalid={
                              formikExp?.touched?.start_date &&
                              !!formikExp?.errors?.start_date
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formikExp?.errors?.start_date &&
                              formikExp?.touched?.start_date && (
                                <div>{formikExp?.errors?.start_date}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm="3"
                          className="mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type="date"
                            placeholder="End Date"
                            name={`end_date`}
                            value={
                                formikExp.values.end_date ? formikExp.values.end_date : new Date().toISOString().split('T')[0]
                              }
                            onChange={formikExp.handleChange}
                            isInvalid={
                              formikExp?.touched?.end_date &&
                              !!formikExp?.errors?.end_date
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formikExp?.errors?.end_date &&
                              formikExp?.touched?.end_date && (
                                <div>{formikExp?.errors?.end_date}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm="1"
                          className="mb-3"
                          controlId=""
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="px-4"
                            style={{
                              marginLeft: window.innerWidth < 500 && "250px",
                            }}
                          >
                            Add
                          </Button>
                        </Form.Group>
                      </Row>
                    </Col>
                  </Row>
                  <Col className="mb-3 col-auto"></Col>
                </>
              </Col>
            </Form>
          </div>

          {/* CERTIFICATES */}
          <div className="professional_overlay">
            <Form onSubmit={formikCerti.handleSubmit} className="px-3 py-2">
              <Col sm="12" className="mb-4">
                <Form.Label className="title">Certificates</Form.Label>
                <>
                  {certificates
                    ?.filter((i) => i?.status !== "deleted")
                    ?.map((certificate) => (
                      <Row>
                        <Col className="col-12 mx-auto">
                          <div className="pr_dtls_prvw">
                            <div>
                              <p className="fs-16 fw-500 text_brand_color4 m-0">
                                <u>{certificate.name}</u>
                              </p>
                              <small className="d-block fs-12 fw-400 text_brand_color4">
                                {certificate.organization}
                              </small>
                            </div>
                            <Button
                              variant=""
                              onClick={() =>
                                handleDelete(certificate?.id, "certificate")
                              }
                            >
                              <Image src={trash} />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  <Row>
                    <Col
                      className={
                        window.innerWidth < 500 ? "col-auto mt-3 w-100" : ""
                      }
                    >
                      <Form.Group as={Col} className="" controlId="">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name={`name`}
                          value={formikCerti.values.name}
                          onChange={formikCerti.handleChange}
                          isInvalid={
                            formikCerti?.touched?.name &&
                            !!formikCerti?.errors?.name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formikCerti?.errors?.name &&
                            formikCerti?.touched?.name && (
                              <div>{formikCerti?.errors?.name}</div>
                            )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      className={
                        window.innerWidth < 500
                          ? "col-auto mt-3 w-100 mb-2"
                          : ""
                      }
                    >
                      <Form.Group as={Col} className="" controlId="">
                        <Form.Control
                          type="text"
                          placeholder="Issuing Organization"
                          name={`organization`}
                          value={formikCerti.values.organization}
                          onChange={formikCerti.handleChange}
                          isInvalid={
                            formikCerti?.touched?.organization &&
                            !!formikCerti?.errors?.organization
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formikCerti?.errors?.organization &&
                            formikCerti?.touched?.organization && (
                              <div>{formikCerti?.errors?.organization}</div>
                            )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="mb-3 col-auto">
                      <Button
                        variant="success"
                        type="submit"
                        className="px-4"
                        style={{
                          marginLeft: window.innerWidth < 500 && "250px",
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </>
              </Col>
            </Form>
          </div>

          <Row className="d-flex justify-content-center">
            <Col sm="12" className="w-100 d-flex justify-content-center mb-4">
              <Button
                variant="outline-success"
                className="px-4 me-3"
                onClick={onClickPreview}
              >
                Preview
              </Button>
            </Col>
          </Row>

          <Col sm="12">
            <div className="d-flex justify-content-between">
              <Button
                variant="outline-success"
                className="px-4"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <div>
                <Button
                  variant="success"
                  className="px-4"
                  onClick={onClickSave}
                >
                  {isLoading ? <Loader /> : "Publish"}
                </Button>
              </div>
            </div>
          </Col>
        </Container>
      </section>

      <Modal
        visible={visible}
        onClickAway={() => setVisible(false)}
        width="500px"
        height="250px"
        effect="fadeInUp"
      >
        <Row>
          <Col>
            <div className="modal">
              <h3
                classname="changeColor"
                style={{ color: "black", marginLeft: "13px" }}
              >
                Are You sure you wan to delete ?
              </h3>

              <Row>
                <Col>
                  <br />
                  <Button
                    className=""
                    color="secondary"
                    variant="contained"
                    style={{
                      margin: "10px",
                      backgroundColor: "#00504E",
                      color: "#fff",
                      width: "5rem",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      if (eduVisible === true) {
                        onRemoveEducation(deleteId);
                      } else if (testiVisible === true) {
                        onRemovetestimonial(deleteId);
                      } else if (certiVisible === true) {
                        onRemoveCertificate(deleteId);
                      } else if (expVisible === true) {
                        onRemoveExperience(deleteId);
                      }
                    }}
                  >
                    <h5 style={{ margin: "auto" }}> Yes </h5>
                  </Button>
                  <Button
                    className=""
                    variant="contained"
                    color="primary"
                    style={{
                      margin: "10px",
                      marginLeft: "4px",
                      // backgroundColor: "#00504E",
                      border: "1px solid #00504E",
                      color: "#111",
                      width: "5rem",
                      textAlign: "center",
                    }}
                    onClick={() => setVisible(false)}
                  >
                    <h5 style={{ margin: "auto" }}>No </h5>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default CoachProfileProfessionalDetailsComponent;
