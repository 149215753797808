import React, { useState, useEffect } from "react";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { userActivityList } from "../../../utils/api";

const UserLikeCommentActivitiesComponent = () => {
    const [allLikesComments, setAllLikesComments] = useState([]);
    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        try {
            const data = await userActivityList();
            console.log(data.data.data);
            setAllLikesComments(data.data.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const removeHTMLTags = (str) => {
        if(str){
            return str.replace(/<[^>]+>/g, '');
        }
    };
    return (
        <>
            <div className="mb-5">
                <div className="border-bottom">
                    <b>Activities</b>
                    {/* <div className="position-relative d-flex align-items-center user-info mt-3 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="fs-16 fw-600 btn btn-success btn-md">Join</button>
                        </div>
                    </div> */}
                    {allLikesComments.map((user) => (
                        <div key={user.id} className="position-relative d-flex align-items-center user-info mt-3 mb-2 justify-content-between">
                            <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                                <img
                                    width={'50px'}
                                    height={'50px'}
                                    alt="user pic"
                                    className="object-fit-cover rounded-circle user_img me-2 position-relative z-2"
                                    src={user.image}
                                    onError={defaultUserImage}
                                />
                                <div className="user-name lh-1">
                                    <span className="fs-16 fw-500">{user.title}</span>
                                    <div className="d-flex mt-1">
                                        <small className="fs-12 me-1 d-inline-block overflow-hidden">{user.content ? removeHTMLTags(user.content).substring(0, 125) : ''}...</small>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <button className="fs-16 fw-600 btn btn-success btn-md">Join</button>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};

export default UserLikeCommentActivitiesComponent;
