import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";

function SearchEverythingComponent({ onInputChange }) {
    const [inputValue, setInputValue] = useState("");
    const [typingText, setTypingText] = useState('');
    const isMounted = useRef(true); // Use ref to keep track of mounting state

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        onInputChange(value); // Call the parent's callback function
    };

    const getData = async () => {
        const speed = 200;
        const text = 'I am not able to scale up my IT business. How to generate leads and increase Sales. Please Suggest A Coach. \nPlease write your problem here';
        const myArray = text.split("");
        const fullText = myArray.join('');
        
        while (isMounted.current) {
            for (let i = 0; i < fullText.length && isMounted.current; i++) {
                setTypingText(fullText.substring(0, i + 1));
                await new Promise((resolve) => setTimeout(resolve, speed));
            }
            await new Promise((resolve) => setTimeout(resolve, speed * 5)); // Wait before resetting
            setTypingText('');
        }
    };

    useEffect(() => {
        getData();
        return () => {
            isMounted.current = false; // Set to false when component unmounts
        };
    }, []);

    return (
        <>
            <Form.Control
                className="fs-16 bg-transparent"
                as="textarea"
                type="text"
                placeholder={typingText}
                value={inputValue}
                onChange={handleInputChange}
            />
        </>
    );
}

export default SearchEverythingComponent;
