import React, { useState, useEffect } from "react";
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import LoginComponent from "../../Pages/Login/newindex";
import { useUserContext } from '../../../utils/hooks/UserContext';

function HomeLayoutMenuList() {
    const { user, handleLogout } = useUserContext();
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [isAuth, setisAuth] = useState(user)

    useEffect(() => {
        setisAuth(user)
    }, [user])

    const onClickLogout = () => {
        handleLogout()
        setisAuth(null)
    }

    return (
        <>
            <Nav className="justify-content-end flex-grow-1 pe-3">
                {/* <NavDropdown className='p-0' title="Browse Coach" id={`offcanvasNavbarDropdown-expand-${expand}`} >
                    <NavDropdown.Item href="#action3">Browse Coach</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action5">Something</NavDropdown.Item>
                </NavDropdown> */}
                <NavLink className="nav-link" to="/browse-coach/list-view">Browse Coach</NavLink>
                <NavLink className="nav-link" to="/about">About Us</NavLink>
                <NavLink className="nav-link" to="/activity-stream">Activity Stream</NavLink>
                <NavLink className="nav-link" to="/become-a-coach">Become  a Coach</NavLink>
                {!isAuth && <NavLink className="nav-link login_btn" onClick={() => setLoginModalShow(true)}>Login</NavLink>}
                {isAuth && <NavLink className="nav-link login_btn" onClick={() => onClickLogout()}>Logout</NavLink>}
            </Nav>
            <LoginComponent show={loginModalShow} onHide={() => setLoginModalShow(false)} />
        </>
    )
}

export default HomeLayoutMenuList;