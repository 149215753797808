import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Player, ControlBar, PlayToggle } from 'video-react';
import { coachGetMyShortVideo } from "../../../utils/api";
import ShortVideoUploadComponent from "../../components/shortVideoUpload";
import ShortVideoCoach from "../../components/ShortVideoCoach";
import { useUserContext } from "../../../utils/hooks/UserContext";
import Pic from '../../../assets/images/icons/user.png';

const CoachMyActivityShortVideoComponent = () => {
    const { user } = useUserContext()
    const [shortVideoModalShow, setShortVideoModalShow] = React.useState(false);
    const [VideoModalShow, setVideoModalShow] = React.useState(false);
    const [activeIndex, setActiveIndex] = useState(0)

    const [video, setVideo] = useState([]);

    const getData = async () => {
        const { data } = await coachGetMyShortVideo()
        setVideo(data?.data)
    }

    useEffect(() => {
        getData()
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
    }, [])

    const onClickVideo = (id) => {
        setActiveIndex(id)
        setVideoModalShow(true)
    }

    return (
        <>
            <Row className="activity_short_video justify-content-center g-3">
                <Col className="mx-auto" sm="9">
                    <NavLink onClick={() => setShortVideoModalShow(true)}>
                        <Row className="mt-3">
                            <Col className="col-auto">
                                <div className="sp_user">
                                    {user?.profile_pic && <img src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + user?.profile_pic} alt="" />}
                                    {!user?.profile_pic && <img src={Pic} alt="" />}
                                </div>
                            </Col>
                            <Col>
                                <div className="start_post_card">
                                    <span>Post a short Video</span>
                                    <div>
                                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.09766 7.33533V21.8208C1.09766 23.8501 2.74647 25.4995 4.77637 25.4995H22.5699C24.5993 25.4995 26.2487 23.8507 26.2487 21.8208V4.67823C26.2487 2.64889 24.5998 0.999512 22.5699 0.999512H4.77637C2.74703 0.999512 1.09766 2.64833 1.09766 4.67823V7.33533ZM22.5697 23.8565H4.77617C3.65151 23.8565 2.7407 22.9457 2.7407 21.821L2.74031 8.15185H24.6052L24.6056 21.821C24.6056 22.9452 23.6893 23.8565 22.5697 23.8565ZM6.79757 2.64255H11.4771L13.8855 6.5083H9.20601L6.79757 2.64255ZM13.414 2.64255H18.0885L20.4969 6.5083H15.8176L13.414 2.64255ZM24.6058 4.67803V6.50789H22.4345L20.026 2.64255H22.5699C23.6895 2.64255 24.6058 3.55382 24.6058 4.67803ZM4.77555 2.64255H4.85958L7.26801 6.5083H2.73402V4.67861C2.73928 3.55357 3.65642 2.64255 4.77555 2.64255Z" fill="#00504E" stroke="#00504E" stroke-width="0.5" />
                                            <path d="M17.0595 12.9848L17.0594 12.9854L12.353 10.2231L12.3529 10.2231C11.7776 9.88582 11.0945 9.88697 10.5165 10.2088L10.5165 10.2087L10.5135 10.2105C9.93903 10.5408 9.59375 11.1376 9.59375 11.7987V17.6672C9.59375 18.328 9.93832 18.9248 10.5131 19.2551C10.7979 19.4206 11.11 19.4999 11.4266 19.4999C11.7464 19.4999 12.0643 19.4133 12.3535 19.2425C12.3536 19.2424 12.3537 19.2424 12.3537 19.2423L17.3465 16.312C17.3466 16.3119 17.3468 16.3118 17.3469 16.3117C17.9156 15.9808 18.2537 15.3897 18.2537 14.7302L18.2536 14.7276C18.2468 14.0751 17.9096 13.4853 17.3477 13.1544L17.0595 12.9848ZM16.5228 14.8906L16.5111 14.8974L11.5182 17.8279C11.4696 17.8554 11.432 17.8574 11.4043 17.854C11.3722 17.8501 11.345 17.8375 11.329 17.8282C11.3091 17.8167 11.2841 17.7991 11.2646 17.7736C11.2479 17.7519 11.2305 17.7187 11.2305 17.6613C11.2305 17.6613 11.2305 17.6613 11.2305 17.6613L11.2309 11.7989V11.7988C11.2309 11.7456 11.2475 11.7119 11.2658 11.6878C11.286 11.6611 11.3115 11.6426 11.3287 11.6323C11.3426 11.6247 11.3794 11.6088 11.4265 11.6088C11.4514 11.6088 11.4798 11.6144 11.5128 11.6347L11.5128 11.6348L11.5172 11.6374L16.5111 14.5684C16.5112 14.5685 16.5112 14.5685 16.5113 14.5685C16.5565 14.5952 16.5776 14.6266 16.5894 14.6545C16.6026 14.6855 16.6052 14.7154 16.6053 14.7296C16.6051 14.7535 16.6022 14.7843 16.5901 14.8138C16.5804 14.8375 16.563 14.8652 16.5228 14.8906Z" fill="#00504E" stroke="#00504E" stroke-width="0.5" />
                                            <path d="M17.7515 21.9407V21.9404C17.7509 21.4888 17.3824 21.1191 16.9299 21.1191H10.4153C9.96329 21.1191 9.59375 21.488 9.59375 21.9407C9.59375 22.3928 9.96265 22.7623 10.4153 22.7623H16.9299C17.382 22.7623 17.7515 22.3934 17.7515 21.9407Z" fill="#00504E" stroke="#00504E" stroke-width="0.5" />
                                        </svg>
                                        <span className="text_brand_color2 ms-2">Video</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </NavLink>
                </Col>

                {
                    video && video.length > 0 ?
                        video?.map((i, index) => {
                            return (
                                <Col lg="4">
                                    <div className="my_video" onClick={() => onClickVideo(index)}>
                                        <Player controls={false} autoPlay={false} src={process.env.REACT_APP_COACH_SHORT_VIDEO_PATH + i?.file_url} className='pt-0'>
                                            <ControlBar disableCompletely={true} autoHide={false} disableDefaultControls={false}>
                                                <PlayToggle />
                                            </ControlBar>
                                        </Player>
                                        <div className="activity_short_video_cntnt">
                                            <div className="activity_short_video_uploader">
                                                {user?.profile_pic && <img src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${user?.profile_pic}`} alt="" />}
                                                {!user?.profile_pic && <img src={Pic} alt="" />}
                                                <h5 className="text-white fs-14 fw-400">{user?.name}</h5>
                                            </div>
                                            <div>
                                                <p className="fs-14 fw-500 m-0 tags-text">{i?.tags?.split(',')?.map((i) => {
                                                    return <label>{" "}#{i?.trim()}</label>
                                                })}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                        :
                        <div className="text-center my-5 pt-5">
                            <p>No short video available yet.</p>
                        </div>
                }

            </Row >
            <ShortVideoUploadComponent getData={getData} show={shortVideoModalShow} onHide={() => setShortVideoModalShow(false)} />
            {video?.length > 0 && <ShortVideoCoach activeIndex={activeIndex} getData={getData} videos={video} show={VideoModalShow} setVideoModalShow={setVideoModalShow} onHide={() => { setVideoModalShow(false); setVideo([]); getData() }} />}
        </>

    )
}

export default CoachMyActivityShortVideoComponent;