import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Container,
  Col,
  Form,
  Image,
  Nav,
  Row,
  Dropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { debounce } from "lodash"; // Import lodash debounce function
import excl_i from "../../../assets/images/icons/excl_i.png";
import trash from "../../../assets/images/icons/trash.svg";
import { useNavigate, NavLink } from "react-router-dom";
import { useUserContext } from "../../../utils/hooks/UserContext";
import {
  coachAddSpeciality,
  coachBGImage,
  coachDeleteSpeciality,
  coachUpdate,
  searchSpecialtiesByName,
} from "../../../utils/api";
import Camera from "../../../assets/images/Camera";
import Upload from "rc-upload";
import banner from "../../../assets/images/coach_details_banner.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Loader from "../../../Loader/Loader";

const initialValues = {
  speciality: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  speciality: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required").max(300),
});

const DropdownSuggestions = ({ show, suggestions, handleSuggestionSelect }) => (
  <Dropdown show={show} className="mb-0">
    <Dropdown.Menu show={show}>
      {suggestions.length > 0 &&
        suggestions.map((suggestion) => (
          <Dropdown.Item
            key={suggestion.id}
            onClick={() => handleSuggestionSelect(suggestion)}
          >
            {suggestion.name}
          </Dropdown.Item>
        ))}
    </Dropdown.Menu>
  </Dropdown>
);

const CoachProfileAboutYourSelfComponent = (props) => {
  const navigate = useNavigate();
  const { getUserFromToken, user } = useUserContext();
  const [specialties, setspecialties] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [about, setAbout] = useState(user.about ? user.about : "");
  const [story, setStory] = useState(user.story ? user.story : "");
  const [isLoading, setisLoading] = useState(false)

  const MAX_CHARACTER_COUNT = 1500;
  const Min_CHARACTER_COUNT = 100;

  const [aboutError, setAboutError] = useState(false);
  const [storyError, setStoryError] = useState(false);
  const [img, setImage] = useState(null);

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    
    // const json = JSON.stringify(user?.specialties)
    // console.log(Array.isArray(JSON.parse(user?.specialties)), "----------------------- LIVE");
    setspecialties(JSON.parse(user?.specialties));
  }, [user]);

  const handleDescription = (e) => {
    setAbout(e);
  };

  const handleStory = (e) => {
    setStory(e);
  };

  const searchSpecialties = async (query) => {
    try {
      const { data } = await searchSpecialtiesByName(query, 1, 5);
      setSuggestions(data?.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const debouncedSearch = useRef(debounce(searchSpecialties, 300)).current;

  const handleSpecialityChange = (event) => {
    const inputValue = event.target.value;
    formik.handleChange(event); // Handle the formik change event

    // Trigger the debounce search to get suggestions based on the input value
    debouncedSearch(inputValue);
  };

  const onClickSave = async (values) => {
    try {
      // const about = log();
      // const story = storyLog();
      setisLoading(true)

      if (
        (about && about?.length > MAX_CHARACTER_COUNT) ||
        !about ||
        (about && about?.length < Min_CHARACTER_COUNT)
      ) {
        setAboutError(true);
        return;
      } else {
        setAboutError(false);
      }

      if (
        (story && story?.length > MAX_CHARACTER_COUNT) ||
        (story && story?.length < Min_CHARACTER_COUNT)
      ) {
        setStoryError(true);
        return;
      } else {
        setStoryError(false);
      }

      if (about) {
        const formdata = new FormData();
        formdata.append("about", about);
        await coachUpdate(formdata);
      }

      if (story) {
        const formdata = new FormData();
        formdata.append("story", story);
        await coachUpdate(formdata);
      }

      if (img) {
        const imgData = new FormData();
        imgData.append("background_img", img);
        await coachBGImage(imgData);
      }

      // handle speciality
      const newSpecialities = specialties?.filter((i) => i?.status === "new");
      for (let index = 0; index < newSpecialities?.length; index++) {
        const element = newSpecialities[index];
        const formData = new FormData();

        if (element?.speciality_id) {
          formData.append("speciality_id", element?.speciality_id);
        }

        if (element?.speciality && !element?.speciality_id) {
          formData.append("speciality", element?.speciality);
        }

        formData.append("description", element?.description);

        await coachAddSpeciality(formData);
      }

      const deletedSpecialities = specialties?.filter(
        (i) => i?.status === "deleted"
      );

      if (deletedSpecialities?.length > 0) {
        const formData = new FormData();
        formData.append(
          "speciality_ids",
          `[${deletedSpecialities?.map((i) => i?.id)}]`
        );

        await coachDeleteSpeciality(formData);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setisLoading(false)
    }

    getUserFromToken();
    setisLoading(false)
    navigate("/coach/coaching-details");
  };

  const onClickNext = () => {
    navigate("/coach/coaching-details");
  };

  const editorRef = useRef(null);
  const editorStoryRef = useRef(null);

  const log = () => {
    if (editorRef?.current) {
      return editorRef?.current?.getContent();
    }
    return null;
  };
  const onChangeImage = (file) => {
    setImage(file);
  };

  const storyLog = () => {
    if (editorStoryRef?.current) {
      return editorStoryRef?.current?.getContent();
    }
    return null;
  };

  const handleSubmit = (values, { resetForm }) => {
    formik.setValues({
      ...formik.values,
      speciality: "",
      description: "",
    });

    let newSpeciality = {};

    if (selectedSuggestion) {
      newSpeciality = {
        id: new Date()?.getTime(),
        speciality_id: selectedSuggestion?.id,
        speciality: selectedSuggestion.name,
        description: values.description,
        status: "new",
      };
    } else {
      newSpeciality = {
        id: new Date()?.getTime(),
        speciality: values.speciality,
        description: values.description,
        status: "new",
      };
    }

    setSelectedSuggestion(null);
    setSuggestions([]);
    setspecialties([...specialties, newSpeciality]);
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const onClickRemove = (id) => {
    const results = JSON.parse(JSON.stringify(specialties))?.map((i) => {
      if (id === i?.id) {
        i.status = "deleted";
      }
      return i;
    });
    setspecialties(results);
  };

  const getImage = () => {
    if (img) {
      return URL.createObjectURL(img);
    }

    if (!user?.background_img) {
      return banner;
    }

    return `${process.env.REACT_APP_COACH_BG_FOLDER_PATH}${user?.background_img}`;
  };

  const tooltipStory = (
    <Tooltip id="tooltip">Share your story in 500 characters or less.</Tooltip>
  );

  const tooltipAbout = (
    <Tooltip id="tooltip">
      Write something about you in up to 500 characters.
    </Tooltip>
  );

  return (
    <>
      <section className="coach_my_activity py-3">
        <Container>
          <Row className="align-items-center justify-content-center mb-5">
            {window.innerWidth < 500 ? (
              <div>
                <Col className="col">
                  <Nav
                    variant="pills"
                    className="wizard_nav justify-content-center"
                  >
                    <Nav.Item className="active">
                      <NavLink to="/coach/about-yourself">
                        <span className="wn_num">2</span>
                        <span>About Yourself</span>
                      </NavLink>
                    </Nav.Item>
                  </Nav>
                </Col>
              </div>
            ) : (
              <Col className="col">
                <Nav
                  variant="pills"
                  className="wizard_nav justify-content-between"
                >
                  <Nav.Item className="active">
                    <NavLink to="/coach">
                      <span className="wn_num">1</span>
                      <span> Personal Information</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item className="active">
                    <NavLink to="/coach/about-yourself">
                      <span className="wn_num">2</span>
                      <span>About Yourself</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item>
                    <NavLink to="/coach/coaching-details">
                      <span className="wn_num">3</span>
                      <span>Coaching Details</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item>
                    <NavLink to="/coach/professional-details">
                      <span className="wn_num">4</span>
                      <span>Professional Details</span>
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Col>
            )}
          </Row>
          <Row className="g-3">
            <Col sm="12" lg="12">
              <Upload onStart={onChangeImage}>
                <div className="a_y_user_img">
                  <Image
                    src={getImage()}
                    rounded
                    alt=""
                    className="profile_banner_img "
                  />
                  <Button variant="" className="upload_img_btn">
                    <Camera />
                  </Button>
                </div>
              </Upload>
            </Col>
            <Col sm="12" lg="12" className="mt-4">
              <div className="d-flex justify-content-between">
                <Form.Label className="title">About</Form.Label>
                <span>
                  <OverlayTrigger placement="top" overlay={tooltipAbout}>
                    <Image src={excl_i} />
                  </OverlayTrigger>
                </span>
              </div>
              {/* <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={user?.about}
                init={{
                  height: 230,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "advcode",
                    "advtable",
                    "autolink",
                    "checklist",
                    "export",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "powerpaste",
                    "fullscreen",
                    "formatpainter",
                    "insertdatetime",
                    "media",
                    "table",
                    "help",
                    "wordcount",
                  ],
                  toolbar: " bold italic backcolor |" + "bullist",
                }}
              /> */}

              <SunEditor
                // width="120%"
                setDefaultStyle="font-family: Arial; font-size: 16px;"
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["paragraphStyle", "blockquote"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      // "strike",
                      // "subscript",
                      // "superscript",
                    ],
                    // ["fontColor", 
                    //   // "hiliteColor"
                    // ],
                    ["align", "list", "lineHeight"],
                    // ["outdent", "indent"],

                    // ["table", "horizontalRule", "link", "image", "video"],
                    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                    // ['imageGallery'], // You must add the "imageGalleryUrl".
                    // ["fullScreen", "showBlocks", "codeView"],
                    // ["preview", "print"],
                    // ["removeFormat"],

                    // ["save", "template"],
                    // '/', Line break
                  ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                  defaultTag: "div",
                  height: 120,
                  showPathLabel: false,
                  // font: sortedFontOptions
                }}
                name="about"
                setContents={about}
                onChange={handleDescription}
              />
              {aboutError && (
                <div className="text-danger">
                  About is Required & About section required minimum 250 and
                  maximum 1500 characters
                </div>
              )}
              {/* {console.info("aboutError++ ", aboutError, storyError)} */}
            </Col>

            <Col sm="12" lg="12" className="mt-4">
              <div className="d-flex justify-content-between">
                <Form.Label className="title">Story</Form.Label>
                <span>
                  <OverlayTrigger placement="top" overlay={tooltipStory}>
                    <Image src={excl_i} />
                  </OverlayTrigger>{" "}
                </span>
              </div>
              {/* <Editor
                onInit={(evt, editor) => (editorStoryRef.current = editor)}
                initialValue={user?.story}
                init={{
                  height: 230,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "advcode",
                    "advtable",
                    "autolink",
                    "checklist",
                    "export",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "powerpaste",
                    "fullscreen",
                    "formatpainter",
                    "insertdatetime",
                    "media",
                    "table",
                    "help",
                    "wordcount",
                  ],
                  toolbar: " bold italic backcolor |" + "bullist",
                }}
              /> */}

              <SunEditor
                // width="120%"
                setDefaultStyle="font-family: Arial; font-size: 16px;"
                setOptions={{
                  uttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["paragraphStyle", "blockquote"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      // "strike",
                      // "subscript",
                      // "superscript",
                    ],
                    // ["fontColor", 
                    //   // "hiliteColor"
                    // ],
                    ["align", "list", "lineHeight"],
                    // ["outdent", "indent"],

                    // ["table", "horizontalRule", "link", "image", "video"],
                    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                    // ['imageGallery'], // You must add the "imageGalleryUrl".
                    // ["fullScreen", "showBlocks", "codeView"],
                    // ["preview", "print"],
                    // ["removeFormat"],

                    // ["save", "template"],
                    // '/', Line break
                  ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                  defaultTag: "div",
                  height: 120,
                  showPathLabel: false,
                  borderRadius: 12,
                  // font: sortedFontOptions
                }}
                style={{
                  borderRadius: "12px",
                }}
                name="story"
                setContents={story}
                onChange={handleStory}
              />

              {storyError && (
                <div className="text-danger">
                  Story section required minimum 250 and maximum 1500 characters  
                </div>
              )}
            </Col>

            <Col sm="12" lg="12" className="mt-4">
              <div className="d-flex justify-content-between">
                <Form.Label className="title m-0">Specialties</Form.Label>
                <span>
                  <Image src={excl_i} />
                </span>
              </div>
              <Form onSubmit={formik?.handleSubmit}>
                <>
                  {
                    // ?.filter((i) => i?.status !== "deleted")
                  specialties
                    ?.map((specialty, index) => (
                      <Row key={index}>
                        {/* <Col className="col-11 mx-auto"> */}
                        <Col className="">
                          <div className="mt-2 d-flex align-items-center justify-content-between">
                            <div>
                              <h5 className="fs-20 fw-500 text_brand_color7">
                                {specialty?.speciality}
                              </h5>
                              <p className="fs-14 fw-400 text_brand_color8">
                                {specialty?.description}
                              </p>
                            </div>
                            <Button
                              variant=""
                              onClick={() => onClickRemove(specialty?.id)}
                            >
                              <Image src={trash} />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}

                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      type="text"
                      name="speciality"
                      value={formik.values.speciality}
                      onChange={handleSpecialityChange}
                      onClick={() => setShowSuggestions(true)}
                      placeholder="Enter Specialty"
                      isInvalid={
                        formik.touched.speciality && !!formik.errors.speciality
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.speciality &&
                        formik.touched.speciality && (
                          <div>{formik.errors.speciality}</div>
                        )}
                    </Form.Control.Feedback>

                    {/* Show suggestions dropdown */}
                    {showSuggestions && suggestions?.length > 0 && (
                      <DropdownSuggestions
                        show={showSuggestions}
                        suggestions={suggestions}
                        handleSuggestionSelect={(suggestion) => {
                          formik.setFieldValue("speciality_id", suggestion?.id);
                          formik.setFieldValue("speciality", suggestion?.name);
                          setSelectedSuggestion(suggestion);
                          setShowSuggestions(false);
                        }}
                      />
                    )}
                  </Form.Group>

                  <Form.Group className="" controlId="">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      placeholder="Description"
                      isInvalid={
                        (formik.touched.description &&
                          !!formik.errors.description) ||
                        formik.values.description.length >= 250
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {(formik.errors.description &&
                        formik.touched.description) ||
                        (formik.values.description.length >= 250 && (
                          <div>
                            {formik.values.description.length >= 250
                              ? "Max character reached 250"
                              : formik.errors.description}
                          </div>
                        ))}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="text-center mb-3 mt-3" controlId="">
                    <Button variant="success" className="px-4" type="submit">
                      Add
                    </Button>
                  </div>
                </>
              </Form>
            </Col>

            <Col className="mt-4">
              <div className="d-flex justify-content-between">
                <Button
                  variant="outline-success"
                  className="px-4"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <div className="text-end">
                  {/* {window.innerWidth > 500 && ( */}
                  <Button
                    className="px-5 me-2"
                    variant="outline-success"
                    type="button"
                    onClick={() => onClickNext()}
                  >
                    Next
                  </Button>
                  {/* )} */}
                  <Button
                    className={window.innerWidth < 500 ? "px-3" : "px-5"}
                    variant="success"
                    type="submit"
                    onClick={onClickSave}
                  >
                  {isLoading ? <Loader/> : "Save & Continue"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CoachProfileAboutYourSelfComponent;
