
import React from "react";

import { Accordion, Button, Col, Container, ListGroup, Row } from "react-bootstrap";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import live_session_img1 from '../../../../assets/images/dummy/live_session_img1.png';
import live_session_img2 from '../../../../assets/images/dummy/live_session_img2.png';
import quote_grn from '../../../../assets/images/icons/quote_grn.png';
import dummy_coach1_sqr from '../../../../assets/images/dummy/dummy_coach1_sqr.png';

const CoachDetailsAboutMobScreenComponent = ({ userData }) => {
    return (
        <>
            <section className="coach_details_mob_">
                <Container className="coach_details_about">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="text-left hdng_a">
                                    <h6 className="m-0">ABOUT</h6>
                                    <h2 className="m-0">My Introduction</h2>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="fs-16 fw-400" dangerouslySetInnerHTML={{ __html: userData?.about }} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className="text-left hdng_a">
                                    <h6 className="m-0">MY STORY</h6>
                                    <h2 className="m-0">Reflections on My Life</h2>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="fs-16 fw-400" dangerouslySetInnerHTML={{ __html: userData?.about }} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div className="text-left hdng_a">
                                    <h6 className="m-0">professional</h6>
                                    <h2 className="m-0">Details</h2>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="edu_we_certi_tags_card">
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <h5 className="fs-20 fw-600 text_brand_color7 m-0">Education</h5>
                                            {userData?.educations?.map((i) => {
                                                return (
                                                    <div className="mt-2">
                                                        <p className="fs-16 fw-500 m-0">{i?.course}</p>
                                                        <div className="text-end m-0">
                                                            <p className="fs-12 m-0 opacity_7">{i?.university_colleage}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            <h5 className="fs-20 fw-600 text_brand_color7 m-0">Work Experience</h5>
                                            {userData?.experiences?.map((i) => {
                                                return (
                                                    <div className="mt-2">
                                                        <p className="fs-16 fw-500 m-0">{i?.title}</p>
                                                        <div className="text-end m-0">
                                                            <p className="fs-12 m-0 opacity_7">{i?.company_name}</p>
                                                            <p className="fs-12 m-0 opacity_7">{i?.start_date} - {i?.end_date}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            <h5 className="fs-20 fw-600 text_brand_color7 m-0">Certificates</h5>
                                            {userData?.certificates?.map((i) => {
                                        return (
                                            <div className="mt-2">
                                                <p className="fs-16 fw-500 m-0">{i?.name}</p>
                                                <div className="text-end m-0">
                                                    <p className="fs-12 m-0 opacity_7">{i?.organization}</p>
                                                </div>
                                            </div>
                                            )
                                        })}
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            <h5 className="fs-20 fw-600 text_brand_color7 m-0">Tags</h5>
                                            {userData?.tags?.map((i) => {
                                        return (
                                            <div className="mt-2">
                                                <p className="fs-16 fw-400 m-0">#{i}</p>
                                            </div>
                                            )
                                        })}
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>

                <Container className="py-4">
                    <Row>
                        <Col>
                            <div className="text-left hdng_a">
                                <h6 className="text-uppercase">Skills</h6>
                                <h2 className="m-0">Area of my Expertise </h2>
                            </div>
                            {
                                userData?.skills?.map((i) => {
                                    return<div className="mt-3">
                                <Button className="m-1 ml-0" size="sm" variant="outline_color4">{i?.name}</Button>
                            </div>
                            })
                        }
                        </Col>
                    </Row>
                </Container>

                <Container className="py-4">
                    <Row>
                        <Col>
                            <div className="text-left hdng_a">
                                <h6 className="text-uppercase">specialties</h6>
                                <h2 className="m-0">What you can expect </h2>
                            </div>
                            <div className="plus_link_accordion mt-3">
                                <Accordion className="pls_link_accordian">
                                {userData?.specialties?.map((i, index) => {
                                    return (
                                    <Accordion.Item eventKey={i}>
                                        <Accordion.Header>
                                        <span>{index + 1}.</span> {i?.speciality}</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                            <p className="m-0">{i?.description}</p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                     )
                                    })}
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="py-4 coach_details_about">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="text-left hdng_a">
                                    <h6 className="text-uppercase">BENIFITS</h6>
                                    <h2 className="m-0">Why choose me </h2>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                            {userData?.benefits?.map((i) => {
                            return (
                                <ul className="dt_arrow_list">
                                    <li>{i}</li>
                                </ul>
                                )
                            })}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className="text-left hdng_a">
                                    <h6 className="text-uppercase">MY CLIENTS</h6>
                                    <h2 className="m-0">Whom I can help</h2>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                            {userData?.professions?.map((i) => {
                                return (
                                <ul className="dt_arrow_list">
                                    <li>{i}</li>
                                </ul>
                                )
                            })}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>

                <section className="brand_bg_color1 py-4">
                    <Container>
                        <Row className="align-items-center">
                            <Col>
                                <div className="text-left hdng_a">
                                    <h6 className="text-uppercase">Pricing</h6>
                                    <h2 className="m-0">What you will pay</h2>
                                </div>
                            </Col>
                        </Row>
                        {userData?.programs?.map((i) => {
                        return (
                            <>
                        <Row className="mt-3">
                            <Col>
                                <h5 className="fs-20 fw-500 text_brand_color7">{i?.session_title}</h5>
                                <p className="fs-18 fw-500 m-0 brand_text_color7">{i?.no_of_session} Live meeting on online platform for {i?.duration} days</p>
                            </Col>
                            <Col className="col-auto">
                                <h4 className="fs-30 fw-500 text_brand_color2 m-0">{i?.price}</h4>
                            </Col>
                        </Row>
                        <hr />
                        </>
                        )
                    })}
                    </Container>
                </section>

                {/* <Container className="coach_details_testimonial white_bg py-4">
                    <Row>
                        <Col>
                            <div className="text-left hdng_a">
                                <h6 className="text-uppercase">Testimonial</h6>
                                <h2 className="m-0">What my client say</h2>
                            </div>
                        </Col>
                    </Row>

                    <OwlCarousel className='owl-theme' loop margin={0} dots={false} items="3" nav={true} responsive={
                        {
                            '1': {
                                items: 1
                            },
                            '800': {
                                items: 1
                            },
                            '1025': {
                                items: 1
                            }
                        }

                    } >
                        <div className='item'>
                            <div className="coach_details_testimonial_card pt-3">
                                <div className="coach_details_testimonial_card_img">
                                    <img alt="" src={live_session_img1} />
                                </div>
                                <div className="coach_details_testimonial_card_body">
                                    <div className="d-flex align-items-start">
                                        <img className="quote_grn" alt="" src={quote_grn} />
                                        <div className="ms-1">
                                            <p className="mb-2 fs-16 fw-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <h5 className="fs-20 fw-700 text_brand_color7">MENTAL COACH</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="coach_details_testimonial_card pt-3">
                                <div className="coach_details_testimonial_card_img">
                                    <img alt="" src={live_session_img2} />
                                </div>
                                <div className="coach_details_testimonial_card_body">
                                    <div className="d-flex align-items-start">
                                        <img className="quote_grn" alt="" src={quote_grn} />
                                        <div className="ms-1">
                                            <p className="mb-2 fs-16 fw-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <h5 className="fs-20 fw-700 text_brand_color7">MENTAL COACH</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="coach_details_testimonial_card pt-3">
                                <div className="coach_details_testimonial_card_img">
                                    <img alt="" src={live_session_img1} />
                                </div>
                                <div className="coach_details_testimonial_card_body">
                                    <div className="d-flex align-items-start">
                                        <img className="quote_grn" alt="" src={quote_grn} />
                                        <div className="ms-1">
                                            <p className="mb-2 fs-16 fw-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <h5 className="fs-20 fw-700 text_brand_color7">MENTAL COACH</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item'>
                            <div className="coach_details_testimonial_card pt-3">
                                <div className="coach_details_testimonial_card_img">
                                    <img alt="" src={live_session_img2} />
                                </div>
                                <div className="coach_details_testimonial_card_body">
                                    <div className="d-flex align-items-start">
                                        <img className="quote_grn" alt="" src={quote_grn} />
                                        <div className="ms-1">
                                            <p className="mb-2 fs-16 fw-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <h5 className="fs-20 fw-700 text_brand_color7">MENTAL COACH</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>

                </Container> */}
            </section>
        </>
    )
}

export default CoachDetailsAboutMobScreenComponent;