import React, { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import close from '../../../assets/images/icons/close.png';
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { coachEditPost, coachEditPostFormData } from '../../../utils/api';

const validationSchema = Yup.object().shape({
    media: Yup.mixed().test('mediaRequired', 'Either photo or video is required', (value) => {
        if (typeof value === 'string') {
            // Validate if the string value is a valid URL or any other string validation you need
            return value; // Replace isValidURL with your string validation logic
        } else if (value instanceof File) {
            // Validate if the file is an image or video
            return value.type.includes('image') || value.type.includes('video');
        }
        return false;
    }),
});


function EditsocialFeedsPostComponent(props) {

    // FIles States
    const [imagePreview, setImagePreview] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [file, setfile] = useState(null)
    const [descriptionError, setDescriptionError] = useState('');
    const [editData, seteditData] = useState(null)
    const [initialValues, setinitialValues] = useState(null)


    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        seteditData(props?.editData)
        if (props?.editData?.type === "Photo") {
            setImagePreview(`${process.env.REACT_APP_COACH_POST_IMAGE_PATH}${props?.editData?.file_url}`)
            setinitialValues({
                media: props?.editData?.file_url,
            })
        }

        if (props?.editData?.type === "Video") {
            setVideoPreview(`${process.env.REACT_APP_COACH_POST_VIDEO_PATH}${props?.editData?.file_url}`)
            setinitialValues({
                media: props?.editData?.file_url,
            })
        }


        return () => {
            setImagePreview(null);
            setVideoPreview(null);
            setinitialValues(null)
        }

    }, [props?.editData])



    // FIle Picker Ref because we are not useing the standard File picker input
    const filePicekerRef = useRef(null);
    const editorRef = useRef(null);

    const log = () => {
        if (editorRef.current) {
            return editorRef.current?.getContent()
        }

        return null
    };



    const previewFile = (e) => {
        // Reading New File (open file Picker Box)
        const reader = new FileReader();

        // Gettting Selected File (user can select multiple but we are choosing only one)
        const selectedFile = e.target.files[0];
        setfile(selectedFile)
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }

        // As the File loaded then set the stage as per the file type
        reader.onload = (readerEvent) => {
            if (selectedFile.type.includes("image")) {
                setImagePreview(readerEvent.target.result);
                setVideoPreview(null);
            } else if (selectedFile.type.includes("video")) {
                setVideoPreview(readerEvent.target.result);
                setImagePreview(null);
            }
        };
    }

    const clearFiles = () => {
        filePicekerRef.current.value = null
        setImagePreview(null);
        setVideoPreview(null);
    }

    const onSubmit = async (values, { setSubmitting }) => {
        const description = editorRef.current?.getContent();

        if (!description) {
            setDescriptionError('Description is required.');
            setSubmitting(false);
            return;
        }

        const formData = new FormData()
        formData.append('description', description)

        if (file && imagePreview) {
            formData.append('type', 'Photo')
            formData.append('isDocChanged', 'yes')
            formData.append('file', file)
        }

        if (file && videoPreview) {
            formData.append('type', 'Video')
            formData.append('isDocChanged', 'yes')
            formData.append('file', file)
        }

        if (!file) {
            formData.append('isDocChanged', 'no')
        }

        if (file) {
            await coachEditPostFormData(props?.editData?.id, formData)
        } else {
            await coachEditPost(props?.editData?.id, formData)
        }

        props.getData()

        // Clear form and close modal after successful submission
        setSubmitting(false);
        setImagePreview(null);
        setVideoPreview(null);
        setDescriptionError('');
        setfile(null)
        props.onHide();
    }

    return (
        <>
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <div className="brand_bg_color1 my_light_modal">
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm="12">
                                <div className="ls_modal_conatiner">
                                </div>
                            </Col>
                            <Col>
                                {!initialValues && <Spinner />}
                                {initialValues && <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ errors, touched, setFieldValue }) => (
                                        <Form>
                                            <Editor
                                                apiKey='2by1nu9a4wza7hdxmzqx2gkss7b5808hrdanyf6b9mi7xmdo'
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                initialValue={editData?.description}
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: ['advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                                    ],
                                                    toolbar: ' bold italic backcolor |' + 'bullist'
                                                }}
                                            />

                                            {descriptionError && <div className="text-danger">{descriptionError}</div>}


                                            <div>
                                                <div className="">
                                                    <input
                                                        ref={filePicekerRef}
                                                        accept="image/*, video/*"
                                                        onChange={(e) => {
                                                            setFieldValue("media", e.currentTarget.files[0]);
                                                            previewFile(e);
                                                        }}
                                                        type="file"
                                                        hidden
                                                    />
                                                </div>
                                                <div className="preview">
                                                    {imagePreview != null && <img src={imagePreview} alt="" />}
                                                    {videoPreview != null && <video controls src={videoPreview}></video>}
                                                    {(imagePreview || videoPreview) && (
                                                        <button className="preview_close_btn" onClick={clearFiles}>
                                                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18.3713 0.724455L11.3455 8.52748L3.54244 1.5016C2.82419 0.854895 1.71766 0.912885 1.07095 1.63113C0.424245 2.34937 0.482235 3.4559 1.20048 4.10261L9.0035 11.1285L1.97763 18.9315C1.33092 19.6497 1.38891 20.7563 2.10715 21.403C2.8254 22.0497 3.93192 21.9917 4.57863 21.2735L11.6045 13.4704L19.4075 20.4963C20.1258 21.143 21.2323 21.085 21.879 20.3668C22.5257 19.6485 22.4677 18.542 21.7495 17.8953L13.9465 10.8694L20.9723 3.06641C21.619 2.34817 21.5611 1.24164 20.8428 0.59493C20.1246 -0.0517789 19.018 0.00621183 18.3713 0.724455Z" fill="#00504E" />
                                                            </svg>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mt-3'>
                                                <Button
                                                    variant=''
                                                    onClick={() => filePicekerRef.current.click()}
                                                >
                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M23.24 22.6295C22.35 24.0395 20.79 24.9795 19 24.9795H5C2.24 24.9795 0 22.7395 0 19.9795V17.3895L3.98 13.4095C4.54 12.8495 5.46 12.8495 6.03 13.4095L11.61 18.9895C12.93 20.3095 15.09 20.3095 16.41 18.9895L16.99 18.4095C17.54 17.8595 18.49 17.8595 19.04 18.4095L23.25 22.6195L23.24 22.6295ZM20.93 8.34949L21.63 6.60949L23.37 5.90949C23.75 5.75949 24 5.38949 24 4.97949C24 4.56949 23.75 4.19949 23.37 4.04949L21.63 3.34949L20.93 1.60949C20.78 1.22949 20.41 0.979492 20 0.979492C19.59 0.979492 19.22 1.22949 19.07 1.60949L18.38 3.33949L16.65 3.99949C16.27 4.13949 16.01 4.50949 16 4.91949C16 5.32949 16.23 5.69949 16.61 5.85949L18.37 6.59949L19.07 8.34949C19.22 8.72949 19.59 8.97949 20 8.97949C20.41 8.97949 20.78 8.72949 20.93 8.34949ZM24 7.80949V19.9795C24 20.1695 23.99 20.3495 23.97 20.5295L20.44 16.9995C19.1 15.6595 16.91 15.6595 15.56 16.9995L14.98 17.5795C14.44 18.1195 13.55 18.1195 13.01 17.5795L7.43 11.9995C6.09 10.6595 3.9 10.6595 2.55 11.9995L0 14.5695V5.97949C0 3.21949 2.24 0.979492 5 0.979492H17.17L16.85 1.77949L15.94 2.11949C14.79 2.55949 14.02 3.63949 14 4.86949C13.98 6.09949 14.7 7.20949 15.83 7.68949L16.82 8.10949L17.21 9.08949C17.67 10.2395 18.76 10.9795 20 10.9795C21.24 10.9795 22.33 10.2395 22.79 9.08949L23.17 8.13949L24 7.80949ZM9 6.47949C9 5.64949 8.33 4.97949 7.5 4.97949C6.67 4.97949 6 5.64949 6 6.47949C6 7.30949 6.67 7.97949 7.5 7.97949C8.33 7.97949 9 7.30949 9 6.47949ZM17 11.4795C17 11.0195 16.69 10.6195 16.24 10.5095L14.82 10.1595L14.47 8.73949C14.36 8.29949 13.96 7.97949 13.5 7.97949C13.04 7.97949 12.64 8.28949 12.53 8.73949L12.18 10.1495L10.78 10.4695C10.33 10.5695 10.01 10.9695 10.01 11.4295C10.01 11.8895 10.31 12.2895 10.75 12.4095L12.18 12.7995L12.54 14.2295C12.65 14.6695 13.05 14.9895 13.51 14.9895C13.97 14.9895 14.37 14.6795 14.48 14.2295L14.83 12.8095L16.25 12.4595C16.69 12.3495 17.01 11.9495 17.01 11.4895L17 11.4795Z" fill="#00504E" />
                                                    </svg>
                                                    <span className="text_brand_color2 ms-2">Photo/video</span>
                                                </Button>
                                                <Button variant='success' size='md' type="submit">update</Button>
                                            </div>
                                            {/* Validation messages */}
                                            {errors.description && touched.description && (
                                                <div className="text-danger">{errors.description}</div>
                                            )}
                                            {errors.media && touched.media && (
                                                <div className="text-danger">{errors.media}</div>
                                            )}
                                        </Form>
                                    )}
                                </Formik>}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal>
        </>
    )
}

export default EditsocialFeedsPostComponent;