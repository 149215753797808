import React, { useRef, useState, useEffect } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    Nav,
    Row,
    Tab
} from "react-bootstrap";

import Modal from "react-awesome-modal";
import { NavLink } from "react-router-dom";

import Draft from "./draft";
import Published from "./published";
import Pending from "./pending";

function CoachBlogComponent() {

    const [modalVisiable, setModalVisiable] = useState(false);

    const getData = () => {
        
    }
    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        getData();
    }, []);

    return (
        <>
            <section className="coach_my_activity py-3">
                <div className="text-start hdng_a mb-3 d-flex justify-content-between">
                    <h2>My blogs</h2>
                    <NavLink to={"create"} className="btn btn-success">Create blog</NavLink>
                </div>
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col lg="11">
                            <Tab.Container id="" defaultActiveKey="published">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="col">
                                        <Nav variant="pills" className="border_tab_nav_link justify-content-center">
                                            <Nav.Item className="ms-3">
                                                <Nav.Link eventKey="published">
                                                    <span>Published</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="ms-3">
                                                <Nav.Link eventKey="pending">
                                                    <span>Pending</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="ms-3">
                                                <Nav.Link eventKey="draft">
                                                    <span>Draft</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Tab.Content className="my-4">
                                    <Tab.Pane eventKey="published">
                                        <Published />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pending">
                                        <Pending />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="draft">
                                        <Draft />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default CoachBlogComponent;
