import React, { useRef, useState, useEffect } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    Nav,
    Row,
    Tab
} from "react-bootstrap";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment); // or globalizeLocalizer
function CoachCalendarComponent() {

    const [modalVisiable, setModalVisiable] = useState(false);

    const getData = () => {

    }
    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData();
    }, []);
    // const localizer = momentLocalizer(moment) // or globalizeLocalizer
    const myEventsList = [
        {
            id: 0,
            title: 'Board meeting',
            start: new Date(2024, 4, 15, 9, 0, 0),
            end: new Date(2024, 4, 15, 13, 0, 0),
        },
        {
            id: 1,
            title: 'Training session',
            start: new Date(2024, 4, 16, 14, 0, 0),
            end: new Date(2024, 4, 16, 17, 0, 0),
        },
        {
            id: 2,
            title: 'Team building event',
            start: new Date(2024, 4, 17, 10, 0, 0),
            end: new Date(2024, 4, 17, 12, 0, 0),
        },
        {
            id: 3,
            title: 'Product launch',
            start: new Date(2024, 4, 18, 16, 0, 0),
            end: new Date(2024, 4, 18, 19, 0, 0),
        },
        {
            id: 4,
            title: 'Client meeting',
            start: new Date(2024, 4, 19, 11, 0, 0),
            end: new Date(2024, 4, 19, 12, 0, 0),
        },
    ];
    return (
        <>
            <section className="coach_my_activity py-3">
                {/* <div className="text-start hdng_a mb-3 d-flex justify-content-between">
                    <h2>My Calendar</h2>
                </div> */}
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col lg="12">
                            <div className="myCustomHeight">
                                <Calendar
                                    localizer={localizer}
                                    events={myEventsList}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 500 }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default CoachCalendarComponent;
