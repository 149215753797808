import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import ActivityStreamSocialFeedsComponent from "./as_socialFeeds";
import ActivityStreamShortVideoComponent from "./as_shortVideo";
import ActivityStreamFreeLiveSessionComponent from "./as_freeLiveSession";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const ActivityStreamComponent = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const activeTabQueryParam = queryParams.get("active");

    const [activeTab, setActiveTab] = useState(activeTabQueryParam || "social_feeds");

    useEffect(() => {
        if (activeTabQueryParam) {
            setActiveTab(activeTabQueryParam);
        } else {
            setActiveTab("free_live_session");
        }
}, [activeTabQueryParam]);

    return (
        <>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                title="Engage and Learn with Qoach | Social Feeds, Short Videos, Free Live Sessions"
                meta={[
                    { name: 'description', content: "Immerse yourself in a dynamic learning experience with Qoach. Access social feeds, short videos, and free live sessions to expand your knowledge and skills. Join us today and take your personal and professional growth to new heights!" },
                ]}
            />
            <section className="activity_stream py-3">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="11">
                            <Tab.Container id="" defaultActiveKey={activeTab}>
                                <Row className="align-items-center d-flex justify-content-center">
                                    {/* <Col>
                                        <InputGroup className="">
                                            <Form.Control className='fs-14' type="text" placeholder="Search" />
                                            <Button variant="outline-secondary" id="button-addon1">
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.6978 18.8505L15.8269 14.9779C18.7233 11.1073 17.9335 5.62144 14.0629 2.72501C10.1922 -0.171427 4.7064 0.618336 1.80997 4.48898C-1.08647 8.35963 -0.296703 13.8455 3.57394 16.7419C6.68316 19.0686 10.9536 19.0686 14.0629 16.7419L17.9355 20.6145C18.4221 21.1011 19.2112 21.1011 19.6978 20.6145C20.1844 20.1278 20.1844 19.3388 19.6978 18.8522L19.6978 18.8505ZM8.85068 15.9944C5.41064 15.9944 2.62196 13.2058 2.62196 9.76572C2.62196 6.32568 5.41064 3.537 8.85068 3.537C12.2907 3.537 15.0794 6.32568 15.0794 9.76572C15.0757 13.2042 12.2892 15.9908 8.85068 15.9944Z" fill="#14183E"/>
                                                </svg>
                                            </Button>
                                        </InputGroup>
                                    </Col> */}
                                    <Col className="col-auto">
                                        <Nav variant="pills" className="as_nav_link justify-content-center">
                                            <Nav.Item>
                                                <Nav.Link eventKey="social_feeds">
                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.5811 15.3137C11.7186 15.9903 11.2811 16.6503 10.6045 16.7878C10.5203 16.8053 10.437 16.8128 10.3545 16.8128C9.77195 16.8128 9.25112 16.4045 9.13029 15.812C8.73112 13.8437 7.20029 12.3128 5.23195 11.9128C4.55529 11.7753 4.11862 11.1162 4.25529 10.4387C4.39279 9.76199 5.05112 9.32283 5.72945 9.46283C8.68362 10.0628 10.9811 12.3595 11.5811 15.3137ZM5.48112 14.3128C4.79112 14.3128 4.23112 14.8728 4.23112 15.5628C4.23112 16.2528 4.79112 16.8128 5.48112 16.8128C6.17112 16.8128 6.73112 16.2528 6.73112 15.5628C6.73112 14.8728 6.17112 14.3128 5.48112 14.3128ZM20.0645 5.56283V16.3962C20.0645 18.9228 18.0086 20.9795 15.4811 20.9795H4.64779C2.12029 20.9795 0.0644531 18.9228 0.0644531 16.3962V5.56283C0.0644531 3.03616 2.12029 0.979492 4.64779 0.979492H15.4811C18.0086 0.979492 20.0645 3.03616 20.0645 5.56283ZM17.5645 5.56283C17.5645 4.41366 16.6295 3.47949 15.4811 3.47949H4.64779C3.49945 3.47949 2.56445 4.41366 2.56445 5.56283V16.3962C2.56445 17.5453 3.49945 18.4795 4.64779 18.4795H15.4811C16.6295 18.4795 17.5645 17.5453 17.5645 16.3962V5.56283ZM5.62945 5.22949C4.94362 5.14449 4.32195 5.63616 4.24029 6.32283C4.15779 7.00783 4.64779 7.62949 5.33279 7.71199C9.54362 8.21533 12.8336 11.5087 13.3336 15.7195C13.4095 16.3553 14.0236 16.8128 14.6486 16.8128C15.482 16.8128 15.8986 16.1095 15.817 15.4245C15.1803 10.062 10.9903 5.87033 5.62945 5.22949Z" fill="#FBF3E4" />
                                                    </svg>
                                                    <span>Social Feeds</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="short_video">
                                                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.814453 6.051V18.1222C0.814453 19.8364 2.20712 21.2295 3.92172 21.2295H18.7497C20.4638 21.2295 21.857 19.8368 21.857 18.1222V3.83676C21.857 2.12263 20.4643 0.729492 18.7497 0.729492H3.92172C2.20759 0.729492 0.814453 2.12216 0.814453 3.83676V6.051ZM18.7495 19.777H3.92155C3.00734 19.777 2.26699 19.0366 2.26699 18.1224L2.26666 6.77311H20.4041L20.4044 18.1224C20.4044 19.0361 19.6595 19.777 18.7495 19.777ZM5.6811 2.18203H9.48249L11.4376 5.32015H7.63622L5.6811 2.18203ZM11.1947 2.18203H14.992L16.9471 5.32015H13.1459L11.1947 2.18203ZM20.4046 3.83659V5.31981H18.6599L16.7048 2.18203H18.7497C19.6597 2.18203 20.4046 2.92284 20.4046 3.83659ZM3.92104 2.18203H3.96792L5.92303 5.32015H2.26143V3.83717C2.26575 2.92259 3.01142 2.18203 3.92104 2.18203Z" fill="#14183E" stroke="#14183E" stroke-width="0.5" />
                                                        <path d="M14.0606 10.6847L14.0605 10.6853L10.1874 8.41206L10.1872 8.41199C9.69477 8.1233 9.10975 8.12445 8.61557 8.39961L8.61556 8.39959L8.61257 8.40131C8.12091 8.684 7.8252 9.19507 7.8252 9.76096V14.6514C7.8252 15.2169 8.12027 15.728 8.61212 16.0107C8.85605 16.1524 9.12331 16.2203 9.3942 16.2203C9.66827 16.2203 9.94045 16.1461 10.1876 16.0001C10.1878 16 10.1879 15.9999 10.1881 15.9998L14.3484 13.558C14.3486 13.5579 14.3487 13.5579 14.3489 13.5578C14.8357 13.2745 15.1251 12.7682 15.1251 12.2039L15.1251 12.2013C15.1192 11.6431 14.8306 11.138 14.3496 10.8548L14.0606 10.6847ZM13.6194 12.3019L13.6102 12.3073L9.44979 14.7492C9.41787 14.7672 9.39571 14.7675 9.38066 14.7657C9.36131 14.7633 9.3441 14.7555 9.33381 14.7495C9.31971 14.7413 9.30394 14.7299 9.2923 14.7147C9.28345 14.7032 9.27248 14.6843 9.27248 14.6465L9.27281 9.76109V9.76107C9.27281 9.72689 9.283 9.70749 9.29346 9.6937C9.30578 9.67745 9.32185 9.66547 9.33331 9.65851C9.33621 9.65698 9.34341 9.65351 9.35378 9.65039C9.36538 9.64689 9.37911 9.64437 9.39417 9.64437C9.40173 9.64437 9.40908 9.64529 9.41653 9.64734C9.4239 9.64937 9.43312 9.65297 9.44424 9.65981L9.44421 9.65985L9.44865 9.66246L13.6102 12.105C13.6102 12.105 13.6103 12.105 13.6103 12.1051C13.6394 12.1222 13.6514 12.141 13.6582 12.1571C13.6664 12.1763 13.6681 12.1953 13.6681 12.2033C13.668 12.2205 13.6658 12.2401 13.6586 12.2577C13.6535 12.2701 13.6445 12.286 13.6194 12.3019Z" fill="#14183E" stroke="#14183E" stroke-width="0.5" />
                                                        <path d="M14.775 18.2224V18.2221C14.7745 17.8228 14.4488 17.4961 14.0487 17.4961H8.61987C8.22018 17.4961 7.89355 17.8221 7.89355 18.2224C7.89355 18.6221 8.21961 18.9487 8.61987 18.9487H14.0487C14.4484 18.9487 14.775 18.6227 14.775 18.2224Z" fill="#14183E" stroke="#14183E" stroke-width="0.5" />
                                                    </svg>
                                                    <span>Short Videos</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="free_live_session">
                                                    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.93093 15.2191C4.10739 15.2191 4.22497 15.1602 4.34275 15.0426C4.57811 14.8073 4.57811 14.4543 4.34275 14.2192C0.872298 10.7487 0.872298 5.16035 4.34275 1.74899C4.57811 1.51363 4.57811 1.1607 4.34275 0.925541C4.10739 0.690184 3.75446 0.690184 3.5193 0.925541C-0.363173 4.80762 -0.363173 11.1606 3.51911 15.0429C3.63669 15.1605 3.81315 15.2194 3.93093 15.2194L3.93093 15.2191Z" fill="#14183E" />
                                                        <path d="M16.8718 0.925142C16.6365 1.1605 16.6365 1.51343 16.8718 1.74859C20.3423 5.21904 20.3423 10.8074 16.8718 14.2188C16.6365 14.4541 16.6365 14.8071 16.8718 15.0422C16.9894 15.1598 17.1659 15.2187 17.2837 15.2187C17.4012 15.2187 17.5777 15.1598 17.6955 15.0422C21.5778 11.1599 21.5778 4.80712 17.6955 0.86585C17.4601 0.689579 17.1072 0.689579 16.8718 0.924935V0.925142Z" fill="#14183E" />
                                                        <path d="M6.87091 3.39527C6.63555 3.15991 6.28262 3.15991 6.04746 3.39527C3.51803 5.9247 3.51803 10.042 6.04746 12.5716C6.16504 12.6891 6.34151 12.748 6.45929 12.748C6.57686 12.748 6.75333 12.6891 6.87111 12.5716C7.10647 12.3362 7.10647 11.9833 6.87111 11.7481C4.81239 9.6894 4.81239 6.33662 6.87111 4.2775C7.10627 4.04215 7.10627 3.63032 6.87091 3.39517L6.87091 3.39527Z" fill="#14183E" />
                                                        <path d="M15.164 3.39527C14.9287 3.15991 14.5757 3.15991 14.3406 3.39527C14.1052 3.63062 14.1052 3.98356 14.3406 4.21871C16.3993 6.27743 16.3993 9.6302 14.3406 11.6893C14.1052 11.9247 14.1052 12.2776 14.3406 12.5128C14.4582 12.6303 14.6346 12.6892 14.7524 12.6892C14.87 12.6892 15.0464 12.6303 15.1642 12.5128C17.6935 10.0422 17.6935 5.92449 15.164 3.39547L15.164 3.39527Z" fill="#14183E" />
                                                        <path d="M13.5764 7.98402C13.5764 6.33693 12.2823 5.04297 10.6354 5.04297C8.98829 5.04297 7.69434 6.33713 7.69434 7.98402C7.69434 9.63111 8.9885 10.9251 10.6354 10.9251C12.2823 10.9251 13.5764 9.63091 13.5764 7.98402ZM8.87072 7.98402C8.87072 6.98411 9.63547 6.21935 10.6354 6.21935C11.6353 6.21935 12.4001 6.98411 12.4001 7.98402C12.4001 8.98393 11.6353 9.74869 10.6354 9.74869C9.63527 9.74869 8.87072 8.98393 8.87072 7.98402Z" fill="#14183E" />
                                                    </svg>
                                                    <span>Free Live Session</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Tab.Content className="my-4">
                                    <Tab.Pane eventKey="social_feeds">
                                        <ActivityStreamSocialFeedsComponent />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="short_video">
                                        <ActivityStreamShortVideoComponent />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="free_live_session">
                                        <ActivityStreamFreeLiveSessionComponent />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ActivityStreamComponent