import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, NavLink, Row } from 'react-bootstrap';
import { useUserContext } from "../../../utils/hooks/UserContext";
import { useFormik, Field, FormikProvider } from "formik";
import * as Yup from 'yup'
import { getCountries, getStates, userUpdate } from "../../../utils/api";
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import Upload from 'rc-upload';
import { Link } from 'react-router-dom';
import { userImage } from "../../../utils/api";
import Pic from '../../../assets/images/icons/user.png';
import Loader from "../../../Loader/Loader";
import { formDataAPI } from "../../../utils/api/axios";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    professions: Yup.string().notRequired(),
    interests: Yup.string().notRequired(),
    gender: Yup.string().notRequired(),
    language: Yup.string().notRequired(),
});

const initialValues = {
    name: '',
    professions: '',
    interests: '',
    gender: '',
    language: '',
};

const EditProfileComponent = (props) => {

    const { user, getUserFromToken } = useUserContext();
    const [countries, setcountries] = useState([])
    const [states, setstates] = useState([])
    const [phone, setPhone] = useState("");
    const [language, setLanguage] = useState("")
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setselectedState] = useState(null)
    const [isLoading, setisLoading] = useState(false)
    const [editProfileShow, setEditProfileShow] = useState(false);
    const [pageName, setPageName] = useState('');
    const [img, setImage] = useState(null)

    const onChangeImage = async (file) => {
        setImage(file)

        const formData = new FormData();
        formData.append('profile_pic', file)

        await userImage(formData)
        getUserFromToken()
    }

    // const getData = async () => {

    //     const country = await getCountries()
    //     setcountries(country?.data?.data?.map((i) => ({ value: i?.id, label: i?.name })))

    //     const state = await getStates(selectedCountry?.value)
    //     setstates(state?.data?.data?.map((i) => ({ value: i?.id, label: i?.name })))
    //     console.log(state);
    //     console.log(selectedCountry);
    // }

    const getAllCountries = async () => {
        const country = await getCountries()
        setcountries(country?.data?.data?.map((i) => ({ value: i?.id, label: i?.name })))
    }

    const getAllStates = async (country) => {
        let state = "";
        if (country != "") {
            state = await getStates(country)
        } else {
            state = await getStates(selectedCountry?.value)
        }
        setstates(state?.data?.data?.map((i) => ({ value: i?.id, label: i?.name })))
    }

    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        const url = window.location.pathname;
        const parts = url.split('/');
        const lastPart = parts[parts.length - 1];
        if (lastPart === "profile") {
            setPageName('profile')
        }
        // getData()
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        setPhone(user?.phone)
        if (user?.country_id) {
            setSelectedCountry({ value: user?.country_id, label: user?.country_name })
        }

        if (user?.state_id) {
            setselectedState({ value: user?.state_id, label: user?.state_name })
        }
    }, [user])

    const handleFormSubmit = async (values) => {
        setisLoading(true)
        const { name, professions, interests, gender } = values

        // handle general info
        const formdata = new FormData();
        if (name) {
            formdata.append('name', name)
        }

        if (phone) {
            formdata.append('phone', Number(phone))
        }

        if (selectedCountry?.value) {
            formdata.append('country_id', selectedCountry?.value)
        }

        if (selectedState?.value) {
            formdata.append('state_id', selectedState?.value)
        }

        if (professions) {
            formdata.append('professions', professions)
        }
        if (interests) {
            formdata.append('interests', interests)
        }
        if (gender) {
            formdata.append('gender', gender)
        }
        if (language) {
            formdata.append('language', language)
        }

        try {
            await userUpdate(formdata)
            setisLoading(false)
            setPageName('profile'); 
            window.scrollTo(0, 0)
        } catch (error) {
            console.info("errors++ ", error)
        }

        getUserFromToken()
        setisLoading(false)
        props.onHide()
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleFormSubmit
    });

    useEffect(() => {
        for (const [key, value] of Object.entries(user || {})) {
            formik?.setFieldValue(key, value)
        }
    }, [user])

    const getImage = () => {
        if (img) {
            return URL.createObjectURL(img)
        }

        if (!user?.profile_pic) {
            return Pic
        }

        return `${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${user?.profile_pic}`
    }
    return (
        <>
            {/* <Modal {...props} size="lg" centered>
                <Modal.Body> */}
            <Form className={`profile_edit_forms p-lg-3 ${pageName === 'profile' ? 'view-profile' : ''}`} onSubmit={formik?.handleSubmit}>
                <FormikProvider value={formik}>
                    <Row>
                        <Col sm="12" lg="12">
                            <Row className="mb-5 align-items-center">
                                <Col className="col-auto text-center" sm="12" lg="4">
                                    {
                                        pageName === 'profile' ?
                                            <div className="profile_img">
                                                <img alt="" src={getImage()} />
                                            </div>
                                            :
                                            <Upload onStart={onChangeImage} >
                                                <div className="profile_img">
                                                    <img alt="" src={getImage()} />
                                                    <Button className="prfl_pic_change_btn">
                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_2479_3673)">
                                                                <path d="M15.74 3.34333L13.6625 0.649167C13.5068 0.447183 13.3068 0.283623 13.078 0.171077C12.8491 0.058532 12.5975 4.14574e-06 12.3425 0L7.9075 0C7.65262 0.000131611 7.40116 0.0587207 7.17247 0.171261C6.94377 0.283801 6.74394 0.447295 6.58833 0.649167L4.51 3.34333C3.33085 3.39441 2.21686 3.89838 1.40002 4.7503C0.583182 5.60222 0.12647 6.73642 0.125 7.91667L0.125 15.4167C0.126323 16.6318 0.609634 17.7969 1.46889 18.6561C2.32814 19.5154 3.49316 19.9987 4.70833 20H15.5417C16.7568 19.9987 17.9219 19.5154 18.7811 18.6561C19.6404 17.7969 20.1237 16.6318 20.125 15.4167V7.91667C20.1235 6.73642 19.6668 5.60222 18.85 4.7503C18.0331 3.89838 16.9191 3.39441 15.74 3.34333ZM17.625 15.4167C17.625 15.9692 17.4055 16.4991 17.0148 16.8898C16.6241 17.2805 16.0942 17.5 15.5417 17.5H4.70833C4.1558 17.5 3.62589 17.2805 3.23519 16.8898C2.84449 16.4991 2.625 15.9692 2.625 15.4167V7.91667C2.625 7.36413 2.84449 6.83423 3.23519 6.44353C3.62589 6.05283 4.1558 5.83333 4.70833 5.83333H15.5417C16.0942 5.83333 16.6241 6.05283 17.0148 6.44353C17.4055 6.83423 17.625 7.36413 17.625 7.91667V15.4167Z" fill="#00504E" />
                                                                <path d="M10.1244 7.08301C9.21785 7.08301 8.33171 7.35182 7.57799 7.85544C6.82426 8.35906 6.2368 9.07488 5.8899 9.91238C5.543 10.7499 5.45224 11.6714 5.62909 12.5605C5.80593 13.4496 6.24245 14.2663 6.88344 14.9072C7.52444 15.5482 8.34111 15.9848 9.23019 16.1616C10.1193 16.3385 11.0408 16.2477 11.8783 15.9008C12.7158 15.5539 13.4316 14.9664 13.9353 14.2127C14.4389 13.459 14.7077 12.5728 14.7077 11.6663C14.7064 10.4512 14.2231 9.28615 13.3638 8.4269C12.5045 7.56764 11.3395 7.08433 10.1244 7.08301ZM10.1244 13.7497C9.71231 13.7497 9.30952 13.6275 8.96691 13.3986C8.62431 13.1696 8.35728 12.8443 8.1996 12.4636C8.04192 12.0829 8.00066 11.664 8.08105 11.2599C8.16143 10.8558 8.35985 10.4846 8.65121 10.1932C8.94257 9.90184 9.31379 9.70342 9.71791 9.62304C10.122 9.54265 10.5409 9.58391 10.9216 9.74159C11.3023 9.89927 11.6277 10.1663 11.8566 10.5089C12.0855 10.8515 12.2077 11.2543 12.2077 11.6663C12.2077 12.2189 11.9882 12.7488 11.5975 13.1395C11.2068 13.5302 10.6769 13.7497 10.1244 13.7497Z" fill="#00504E" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_2479_3673">
                                                                    <rect width="20" height="20" fill="white" transform="translate(0.125)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </Button>
                                                </div>
                                            </Upload>
                                    }
                                </Col>
                                <Col sm="12" lg="8">
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Name*</Form.Label>
                                        <Field
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            as={Form.Control}
                                            disabled={pageName === 'profile' ? true : false}
                                        />
                                        {formik.touched.name && formik.errors.name && <div name="name" component="div" className="text-danger">{formik.errors?.name}</div>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Email*</Form.Label>
                                        <Field
                                            type="text"
                                            placeholder="Enter Email"
                                            name="emailid"
                                            disabled
                                            as={Form.Control}
                                        />
                                        {formik.touched.emailid && formik.errors.emailid && <div name="emailid" component="div" className="text-danger">{formik.errors?.emailid}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" lg="6">
                            {/* <Form.Group className="mb-3" controlId="">
                                <Form.Label>Country</Form.Label>
                                <Select
                                    defaultValue={selectedCountry}
                                    onChange={setSelectedCountry}
                                    options={countries}
                                    placeholder="Select a country..."
                                />
                            </Form.Group> */}
                            <Form.Group className="mb-3 select-boxes">
                                <Form.Label>Country</Form.Label>
                                <Select
                                    name="country"
                                    value={selectedCountry}
                                    onChange={option => {
                                        setSelectedCountry(option);
                                        setselectedState(null)
                                        formik.setFieldValue('country', option ? option.value : '');
                                        // getData()
                                        getAllStates(option ? option.value : '')
                                    }}
                                    options={countries}
                                    placeholder={pageName === 'profile' ? "-------" : "Select a country..."}
                                    onBlur={() => formik.setFieldTouched('country')}
                                />
                                {formik.touched.country && formik.errors.country && (
                                    <div className="text-danger">
                                        {formik.errors.country}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm="12" lg="6">
                            {/* <Form.Group className="mb-3" controlId="">
                                <Form.Label>State</Form.Label>
                                <Select
                                    defaultValue={selectedState}
                                    onChange={setselectedState}
                                    options={states}
                                    placeholder="Select a state..."
                                />
                            </Form.Group> */}
                            <Form.Group className="mb-3 select-boxes">
                                <Form.Label>State</Form.Label>
                                <Select
                                    name="state"
                                    value={selectedState}
                                    onChange={option => {
                                        setselectedState(option);
                                        formik.setFieldValue('state', option ? option.value : '');
                                    }}
                                    options={states}
                                    placeholder={pageName === 'profile' ? "-------" : "Select a state..."}
                                    onBlur={() => formik.setFieldTouched('state')}
                                />
                                {formik.touched.state && formik.errors.state && (
                                    <div className="text-danger">
                                        {formik.errors.state}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col sm="12" lg="6">
                            <Form.Group className="mb-3" controlId="">
                                <Form.Label>Phone Number</Form.Label>
                                {
                                    pageName === 'profile' ?
                                        <p>{phone ?? "-------"}</p>
                                        :
                                        <PhoneInput
                                            country={phone === null ? selectedCountry !== null ? selectedCountry.label === "India" ? "in" : "us" : "us" : "us"}
                                            enableSearch={true}
                                            value={phone}
                                            onChange={(phone) => setPhone(phone)}
                                            name="phone"
                                            as={Form.Control}
                                            onlyCountries={['us', 'in']}
                                        /> 
                                }
                            </Form.Group>
                        </Col>
                        <Col sm="12" lg="6">
                            {/* <Form.Group className="mb-3" controlId="">
                                <Form.Label>Gender</Form.Label>
                                <div className="d-flex">
                                    <Form.Check
                                        type="radio"
                                        label="Male"
                                        name="gender"
                                        id="male"
                                        className="me-4"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Female"
                                        name="gender"
                                        id="female"
                                    />
                                </div>
                                {formik.touched.gender && formik.errors.gender && (
                                    <div name="gender" component="div" className="text-danger">
                                        {formik.errors?.gender}
                                    </div>
                                )}
                            </Form.Group> */}
                            <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                {
                                    pageName === 'profile' ?
                                        <p>{formik.values.gender ?? "-------"}</p>
                                        :
                                        <>
                                            <div className="d-flex mt-2">
                                                <Form.Check
                                                    type="radio"
                                                    label="Male"
                                                    name="gender"
                                                    id="Male"
                                                    value="Male"
                                                    onChange={formik.handleChange}
                                                    className="me-4"
                                                    checked={formik.values.gender === 'Male'}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Female"
                                                    name="gender"
                                                    id="Female"
                                                    value="Female"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.gender === 'Female'}
                                                />
                                            </div>
                                            {formik.touched.gender && formik.errors.gender && (
                                                <div className="text-danger">
                                                    {formik.errors.gender}
                                                </div>
                                            )}
                                        </>
                                }
                            </Form.Group>
                        </Col>
                        <Col sm="6" lg="6" className="mb-2">
                            <Form.Group className="mb-3" controlId="">
                                <Form.Label>Professions</Form.Label>
                                <Field
                                    type="text"
                                    placeholder={pageName === 'profile' ? "-------" : "Enter Professions"}
                                    name="professions"
                                    as={Form.Control}
                                    disabled={pageName === 'profile' ? true : false}
                                />
                                {formik.touched.professions && formik.errors.professions && <div name="professions" component="div" className="text-danger">{formik.errors?.professions}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm="6" lg="6" className="mb-2">
                            <Form.Group className="mb-3" controlId="">
                                <Form.Label>Language</Form.Label>
                                <Field
                                    type="text"
                                    placeholder={pageName === 'profile' ? "-------" : "Enter language"}
                                    name="language"
                                    as={Form.Control}
                                    disabled={pageName === 'profile' ? true : false}
                                />
                                {formik.touched.language && formik.errors.language && <div name="language" component="div" className="text-danger">{formik.errors?.language}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm="12" lg="12" className="mb-2">
                            <Form.Group className="mb-3" controlId="">
                                <Form.Label>interests in</Form.Label>
                                <Field
                                    type="text"
                                    placeholder={pageName === 'profile' ? "-------" : "Please enter your intetests seperated by commas."}
                                    name="interests"
                                    as={Form.Control}
                                    disabled={pageName === 'profile' ? true : false}
                                />
                                {formik.touched.interests && formik.errors.interests && <div name="interests" component="div" className="text-danger">{formik.errors?.interests}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm="12" lg="12">
                            <div className="text-end">
                                {
                                    pageName === 'profile' ?
                                        <Link to="/user/edit-profile" className="btn btn-success px-5" onClick={()=>{ setPageName(''); window.scrollTo(0, 0)}}>Edit Profile</Link>
                                        :
                                        <>
                                            <Link to="/user/profile" className="btn btn-success px-5 me-3" onClick={()=>{ setPageName('profile'); window.scrollTo(0, 0)}}>Back</Link>
                                            <Button className="px-5" variant="success" type="submit"> { isLoading ? <Loader /> : "Save" } </Button>
                                        </>
                                }
                            </div>
                        </Col>
                    </Row>


                </FormikProvider>
            </Form>

            {/* </Modal.Body>
            </Modal> */}
        </>
    )
}

export default EditProfileComponent;