
import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import moment from 'moment';
import send_img from '../../../../assets/images/icons/send.png';
import { addComment, addLike, getCommentPostById, getPostByCoachId } from "../../../../utils/api";
import LikeFill from "../../../../assets/images/icons/like_fill";
import LikeEmpty from "../../../../assets/images/icons/like_empty";
import Comment from "../../../../Coach/Common/Comments";
import { useUserContext } from "../../../../utils/hooks/UserContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Pic from '../../../../assets/images/icons/user.png';

const CoachDetailsSocialFeedsComponent = ({ userData }) => {

    const { user } = useUserContext()
    const navigate = useNavigate()
    const [posts, setPosts] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [comments, setComments] = useState([])
    const [comment, setcomment] = useState("")

    const getData = async () => {
        try {
            const { data } = await getPostByCoachId(userData?.coach_id)
            setPosts(data?.data)
        } catch (error) {
            console.info("error++ ", error)
        }
    }

    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData()
    }, [])

    const onClickComment = async (id) => {
        const { data } = await getCommentPostById(id)
        setComments(data?.data || [])
        setShowModal(!showModal)
    }

    const onChangeComment = (e, id) => {
        setcomment({ ...comment, [id]: e?.target?.value })
    }

    const onClickSend = async (id) => {
        if (!user) {
            // navigate('/')
            toast.error('Please login to do this action')
            return
        }

        const formData = new FormData();
        formData.append('post_id', id)
        formData.append('comments', comment[id])
        await addComment(formData)

        setcomment({ ...comment, [id]: "" })
        getData()
    }

    const onClickLike = async (id) => {

        if (!user) {
            // navigate('/')
            toast.error('Please login to do this action')
            return
        }

        const formData = new FormData();
        formData.append('post_id', id)

        await addLike(formData)
        getData()
    }

    return (
        <>
            <Modal dialogClassName="modal-dialog-centered"
                show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    {comments?.length < 1 && <div>No comments found</div>}
                    {comments?.length > 0 && comments.map(comment => (
                        <Comment
                            key={comment?.id}
                            commentId={comment?.id}
                            commenterName={comment?.name}
                            description={comment?.comments}
                            commenterId={comment?.user_id}
                            reload={() => onClickComment(comment?.social_id)}
                            commenterPic={comment?.profile_pic}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            <Container className="py-4">
                <Row>
                    {posts?.map((post) => {
                        return (
                            <Col md={12} >
                                <div className="social_feeds_card">
                                    <div className="social_feeds_card_header">
                                        <div className="d-flex align-items-center pic_labels sm">
                                            <div className="pic_labels_img">
                                                {post?.coach_profile && <img src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + post?.coach_profile} alt="" />}
                                                {!post?.coach_profile && <img src={Pic} alt="" />}
                                            </div>
                                            <div className="ms-1 w-100">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5 className="text-black fw-500 m-0">{post?.coach_name} <label className="text_light fs-10 ms-2">{moment(post?.created_at).fromNow(true)}</label></h5>
                                                        {post?.skills?.map((i) => {
                                                            return <label>{i?.name}</label>
                                                        })}
                                                    </div>
                                                    {/* <Button variant="" className="p-0 h-auto fs-24 fw-700">&#8943;</Button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="social_feeds_card_body">
                                        <div className="sf_body">
                                            <p className="fs-16 fw-400 m-0" dangerouslySetInnerHTML={{ __html: post?.description }} />
                                        </div>
                                        <div className="sf_body_img">
                                            {post?.type === "Photo" && <img className="social_feed_img" src={process.env.REACT_APP_COACH_POST_IMAGE_PATH + post?.file_url} alt="" />}
                                            {post?.type === "Video" && <video className="social_feed_img" controls src={process.env.REACT_APP_COACH_POST_VIDEO_PATH + post?.file_url} alt=""></video>}
                                        </div>
                                        <div className="lcs_row">
                                            <Row>
                                                <Col>
                                                    <div onClick={() => onClickLike(post?.social_id)} className="text-center">
                                                        {post?.is_liked ? <LikeFill /> : <LikeEmpty />} <span className="fs-12 fw-400 "> {post?.like_count} likes</span>
                                                    </div>
                                                </Col>
                                                <Col onClick={() => onClickComment(post?.social_id)} >
                                                    <div className="text-center">
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M23 11.6462C23.004 13.186 22.6442 14.7051 21.95 16.0795C21.1268 17.7266 19.8614 19.1119 18.2954 20.0804C16.7293 21.0488 14.9246 21.5621 13.0833 21.5629C11.5435 21.5669 10.0245 21.2071 8.65 20.5129L2 22.7295L4.21667 16.0795C3.52242 14.7051 3.16265 13.186 3.16667 11.6462C3.16738 9.80491 3.68071 8.00019 4.64917 6.43417C5.61763 4.86815 7.00296 3.60269 8.65 2.77953C10.0245 2.08528 11.5435 1.72551 13.0833 1.72953H13.6667C16.0984 1.86368 18.3952 2.89008 20.1173 4.61219C21.8394 6.33431 22.8658 8.63112 23 11.0629V11.6462Z" stroke="#964B00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg> <span className="fs-12 fw-400"> {post?.comment_count} comments</span>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="text-center">
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.4994 19.2308C17.4994 21.9901 15.26 24.2295 12.5007 24.2295H5.50258C2.74331 24.2295 0.503906 21.9901 0.503906 19.2308V12.2327C0.503906 9.4734 2.74331 7.23399 5.50258 7.23399H6.50232C7.05217 7.23399 7.50205 7.68387 7.50205 8.23373C7.50205 8.78358 7.05217 9.23346 6.50232 9.23346H5.50258C3.85302 9.23346 2.50338 10.5831 2.50338 12.2327V19.2308C2.50338 20.8804 3.85302 22.23 5.50258 22.23H12.5007C14.1503 22.23 15.4999 20.8804 15.4999 19.2308C15.4999 18.681 15.9498 18.2311 16.4997 18.2311C17.0495 18.2311 17.4994 18.681 17.4994 19.2308ZM23.6278 6.12429L18.2192 0.535768C17.8393 0.135874 17.1995 0.125876 16.8096 0.515773C16.4197 0.90567 16.3997 1.5355 16.7896 1.9254L21.9282 7.224H13.5005C10.7412 7.224 8.50179 9.4634 8.50179 12.2227V17.2213C8.50179 17.7712 8.95167 18.2211 9.50152 18.2211C10.0514 18.2211 10.5013 17.7712 10.5013 17.2213V12.2227C10.5013 10.5731 11.8509 9.22347 13.5005 9.22347H21.9182L16.7796 14.5221C16.3997 14.922 16.3997 15.5518 16.7996 15.9317C16.9895 16.1216 17.2495 16.2116 17.4994 16.2116C17.7493 16.2116 18.0193 16.1116 18.2192 15.9117L23.6178 10.3432C24.7875 9.17348 24.7875 7.27398 23.6278 6.11429V6.12429Z" fill="#964B00" />
                                                        </svg> <span className="fs-12 fw-400"> Share</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {user?.userType !== "coach" && <div className="comment_area">
                                        <Row className="g-2 align-items-center">
                                            <Col className="col-auto">
                                                <div className="userData_img_sm_rnd">
                                                    {user?.profile_pic && <img width={40} height={40} src={`${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${user?.profile_pic}`} className="img_fit_cover rounded-circle-video" alt="" />}
                                                    {!user?.profile_pic && <img width={40} height={40} src={Pic} className="img_fit_cover rounded-circle-video" alt="" />}
                                                </div>
                                            </Col>
                                            <Col>
                                                <InputGroup className="write_comment_txt_ar">
                                                    <Form.Control className='fs-14' value={comment[post?.social_id]} onChange={(e) => onChangeComment(e, post?.social_id)} placeholder="Write a comment" as="textarea" />
                                                    <Button variant="outline-secondary" id="button-addon1" onClick={() => onClickSend(post?.social_id)}>
                                                        <img src={send_img} alt="" />
                                                    </Button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </div>}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </>
    )
}

export default CoachDetailsSocialFeedsComponent;