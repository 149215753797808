import React, { useRef, useState, useEffect } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import Modal from "react-awesome-modal";
import { NavLink } from "react-router-dom";
import calendar from "../../../assets/images/icons/calendar.png";
import dummy_coach1_sqr from "../../../assets/images/dummy/dummy_coach1_sqr.png";

import "./Calendar.css";
import { Scheduler } from "@aldabil/react-scheduler";
import { getCoachBookedSlots } from "../../../utils/api";
import Lottie from "react-lottie";
import noDataLottie from "../../../assets/lottieFiles/noData.json";
const EVENTS = [
  {
    event_id: 1,
    title: "Event 1",
    start: new Date(new Date(new Date().setHours(9)).setMinutes(0)),
    end: new Date(new Date(new Date().setHours(10)).setMinutes(0)),
    disabled: true,
    admin_id: [1, 2, 3, 4],
  },
  {
    event_id: 2,
    title: "Event 2",
    start: new Date(new Date(new Date().setHours(10)).setMinutes(0)),
    end: new Date(new Date(new Date().setHours(12)).setMinutes(0)),
    admin_id: 2,
    color: "#50b500",
  },
  {
    event_id: 3,
    title: "Event 3",
    start: new Date(new Date(new Date().setHours(11)).setMinutes(0)),
    end: new Date(new Date(new Date().setHours(12)).setMinutes(0)),
    admin_id: 1,
    editable: false,
    deletable: false,
  },
  {
    event_id: 4,
    title: "Event 4",
    start: new Date(
      new Date(new Date(new Date().setHours(9)).setMinutes(30)).setDate(
        new Date().getDate() - 2
      )
    ),
    end: new Date(
      new Date(new Date(new Date().setHours(11)).setMinutes(0)).setDate(
        new Date().getDate() - 2
      )
    ),
    admin_id: 2,
    color: "#900000",
  },
  {
    event_id: 5,
    title: "Event 5",
    start: new Date(
      new Date(new Date(new Date().setHours(10)).setMinutes(30)).setDate(
        new Date().getDate() - 2
      )
    ),
    end: new Date(
      new Date(new Date(new Date().setHours(14)).setMinutes(0)).setDate(
        new Date().getDate() - 2
      )
    ),
    admin_id: 2,
    editable: true,
  },
  {
    event_id: 6,
    title: "Event 6",
    start: new Date(
      new Date(new Date(new Date().setHours(10)).setMinutes(30)).setDate(
        new Date().getDate() - 4
      )
    ),
    end: new Date(new Date(new Date().setHours(14)).setMinutes(0)),
    admin_id: 2,
  },
];

const BookingComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDataLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [events, setEvents] = useState([
    { date: "2024-08-20", name: "Meeting", time: "10:00 AM" },
    { date: "2024-08-22", name: "Conference", time: "02:00 PM" },
    // Add more events as needed
  ]);
  const [bookedEvents, setBookedEvents] = useState([]);

  const getListData = async () => {
    try {
      const data = await getCoachBookedSlots();
      // console.log(data.data.data, "data");
      setBookedEvents(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  // Handle date change
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  // Filter events between start and end dates
  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.date);
    return (
      (!startDate || eventDate >= new Date(startDate)) &&
      (!endDate || eventDate <= new Date(endDate))
    );
  });

  // Render the table
  const renderTable = () => {
    if (!startDate || !endDate)
      return <p>Select a date range to see events.</p>;

    return (
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Event Name</th>
            <th>Event Time</th>
          </tr>
        </thead>
        <tbody>
          {filteredEvents.map((event, index) => (
            <tr key={index}>
              <td>{event.date}</td>
              <td>{event.name}</td>
              <td>{event.time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
    <section className="coach_my_activity py-3">
      <div className="">
        <div className="date-selectors d-flex">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <InputGroup className="mb-3">
              <Form.Control
                type="date"
                value={startDate || ""}
                onChange={handleStartDateChange}
              />
              <InputGroup.Text>to</InputGroup.Text>
              <Form.Control
                type="date"
                value={startDate || ""}
                onChange={handleEndDateChange}
              />
            </InputGroup>
          </Form.Group>
        </div>
      </div>
      <Row className="mb-3 align-items-center">
        <Col xs="4">
          <div className="text-start hdng_a">
            <h2 className="m-0">My Booking</h2>
          </div>
        </Col>
        <Col>
          <Row className="gx-2 align-items-center">
            <Col>
              <Form.Select>
                <option>Clients</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select>
                <option>Status</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select>
                <option>Program Type</option>
              </Form.Select>
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <Button className="pe-0" variant="link">
                  <i className="text_brand_color2 fs-30 bi bi-calendar-week-fill"></i>
                </Button>
                <Button className="pe-0" variant="link">
                  <i className="text_brand_color2 fs-30 bi bi-list-task"></i>
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="booking-component">
        <ul className="booking_user_list">
          {bookedEvents.length > 0 ? (
            bookedEvents.map((item) => (
              <li>
                <div className="booking_user_card">
                  <Row className="align-items-center">
                    <Col sm="6">
                      <div className="p-2 booking_user_card_lt">
                        <div className="d-flex align-items-center">
                          <img
                            className="user_img"
                            src={
                              item.user_profile != null
                                ? process.env
                                    .REACT_APP_USER_PROFILE_FOLDER_PATH +
                                  item?.user_profile
                                : dummy_coach1_sqr
                            }
                          />
                          <div className="ps-2">
                            <h5>{item?.user_name}</h5>
                            <p>Saturday, 1st Aug 2024, 9:00 to 9:30</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div
                        className="p-2 booking_user_card_rt"
                        style={{ background: "#F5F5F5" }}
                      >
                        <span className="u_id">EICC25421</span>
                        <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                          <h5>{item?.program_title} </h5>
                          <Button variant="start-session" className="">
                            Start Session
                          </Button>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="d-flex align-items-center">
                            <span className="u_id">EICC254212</span>
                            <p className="ms-4"> {item.session_name} </p>
                          </div>
                          <span className="">30 minutes</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </li>
            ))
          ) : (
            <Col>
              <Lottie
                options={defaultOptions}
                height={300}
                width={400}
                isStopped={false}
                isPaused={false}
              />
            </Col>
          )}

          {/* <li>
            <div className="booking_user_card">
              <Row className="align-items-center">
                <Col sm="6">
                    <div className="p-2 booking_user_card_lt">
                      <div className="d-flex align-items-center">
                        <img className="user_img" src={dummy_coach1_sqr} />
                        <div className="ps-2">
                          <h5>Jatin Mishra</h5>
                          <p>Saturday, 1st Aug 2024, 9:00 to 9:30</p>
                        </div>
                      </div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="p-2 booking_user_card_rt" style={{background: '#CCDCDC'}}>
                      <span className="u_id">EICC25421</span>
                      <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                        <h5>Emotional Intelligence complete c...</h5>
                        <Button variant="cancle-session" className="">Cancle</Button>
                      </div>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center">
                          <span className="u_id">EICC254212</span>
                          <p className="ms-4">Basic Emotional Intelligence</p>
                        </div>
                        <span className="">30 minutes</span>
                      </div>
                    </div>
                </Col>
              </Row>
            </div>
          </li>

          <li>
            <div className="booking_user_card">
              <Row className="align-items-center">
                <Col sm="6">
                    <div className="p-2 booking_user_card_lt">
                      <div className="d-flex align-items-center">
                        <img className="user_img" src={dummy_coach1_sqr} />
                        <div className="ps-2">
                          <h5>Jatin Mishra</h5>
                          <p>Saturday, 1st Aug 2024, 9:00 to 9:30</p>
                        </div>
                      </div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="p-2 booking_user_card_rt" style={{background: '#964B0033'}}>
                      <span className="u_id">EICC25421</span>
                      <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                        <h5>Emotional Intelligence complete c...</h5>
                        <Button variant="cancle-session" className="">Cancle</Button>
                      </div>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center">
                          <span className="u_id">EICC254212</span>
                          <p className="ms-4">Basic Emotional Intelligence</p>
                        </div>
                        <span className="">30 minutes</span>
                      </div>
                    </div>
                </Col>
              </Row>
            </div>
          </li>
          <li>
            <div className="booking_user_card">
              <Row className="align-items-center">
                <Col sm="6">
                    <div className="p-2 booking_user_card_lt">
                      <div className="d-flex align-items-center">
                        <img className="user_img" src={dummy_coach1_sqr} />
                        <div className="ps-2">
                          <h5>Jatin Mishra</h5>
                          <p>Saturday, 1st Aug 2024, 9:00 to 9:30</p>
                        </div>
                      </div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="p-2 booking_user_card_rt" style={{background: '#CCDCDC'}}>
                      <span className="u_id">EICC25421</span>
                      <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                        <h5>Emotional Intelligence complete c...</h5>
                        <Button variant="cancle-session" className="">Cancle</Button>
                      </div>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center">
                          <span className="u_id">EICC254212</span>
                          <p className="ms-4">Basic Emotional Intelligence</p>
                        </div>
                        <span className="">30 minutes</span>
                      </div>
                    </div>
                </Col>
              </Row>
            </div>
          </li>

          <li>
            <div className="booking_user_card">
              <Row className="align-items-center">
                <Col sm="6">
                    <div className="p-2 booking_user_card_lt">
                      <div className="d-flex align-items-center">
                        <img className="user_img" src={dummy_coach1_sqr} />
                        <div className="ps-2">
                          <h5>Jatin Mishra</h5>
                          <p>Saturday, 1st Aug 2024, 9:00 to 9:30</p>
                        </div>
                      </div>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="p-2 booking_user_card_rt" style={{background: '#CCDCDC'}}>
                      <span className="u_id">EICC25421</span>
                      <div className="mb-2 d-flex justify-content-between align-items-center w-100">
                        <h5>Emotional Intelligence complete c...</h5>
                        <Button variant="cancle-session" className="">Cancle</Button>
                      </div>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center">
                          <span className="u_id">EICC254212</span>
                          <p className="ms-4">Basic Emotional Intelligence</p>
                        </div>
                        <span className="">30 minutes</span>
                      </div>
                    </div>
                </Col>
              </Row>
            </div>
          </li> */}
        </ul>
      </div>

      <Container>
        {/* <div>
        <Scheduler
        events={EVENTS}
        disableViewer
        onEventClick={() => {
          console.log("onEventClick");
        }}
      />
      </div> */}
      </Container>
      {/* <Container>
        <div className="booking-component">
          <div className="table-container">
            {renderTable()}
          </div>
        </div>
      </Container> */}
    </section>
    </>
  );
};

export default BookingComponent;
