import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import live_session_img2 from "../../../assets/images/dummy/live_session_img2.png";
import { Badge, Button, Col, Row } from "react-bootstrap";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useUserContext } from "../../../utils/hooks/UserContext";
import AgoraComponent from "../../Pages/agora/livestream";
import { toast } from "react-toastify";
import LoginComponent from "../Login/index";
import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";
import live_session_img1 from "../../../assets/images/dummy/live_session_img1.png";
import {
  addSessionInterest,
  createNewTransaction,
  getFreeSession,
} from "../../../utils/api";
import Lottie from "react-lottie";
import noDataLottie from "../../../assets/lottieFiles/noData.json";
import StripeCheckout from "react-stripe-checkout";
import Modal from "react-awesome-modal";

function ActivityStreamFreeLiveSessionComponent() {
  const { user, handleLogout } = useUserContext();
  const publishableKey =
    "pk_test_51PeB04RxzGBCno7VRNimXve86fQloq2C3ev3FNoV7om4R64hYqxgoAt7S9VdjBiqV6yCrAgSVa4tdgwkFBIkLbHK00VPS3Noig";

  const [allSession, setAllSession] = useState([]);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [sessionDetails, setSessionDetails] = useState({});
  const [sessionPrice, setSessionPrice] = useState(0);

  // AGORA HOOKS
  const [videoCallSessionId, setVideoCallSessionId] = useState("");
  const [videoCallSessionName, setVideoCallSessionName] = useState("");
  const [videoCall, setVideocall] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDataLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLiveSesions = async () => {
    try {
      const data = await getFreeSession();
      // console.log(data.data.data.slice(0, 2));
      setAllSession(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLiveSesions();
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
  }, []);

  function showSessionInterest(id, name, details) {
    // console.log("function called");
    setVideoCallSessionId(id);
    setSessionDetails(details);
    setVideoCallSessionName(name);
    setVideocall(true);
  }

  async function addInterestforSession(id, coach_id) {
    try {
      const payload = {
        session_id: id,
        coach_id,
      };

      const data = await addSessionInterest(payload);
      toast.success("Interest registered successfully!");
    } catch (error) {
      // console.log(error);
      toast.error("Something went wrong!");
    }
  }

  function convertUtcToIst(utcTime) {
    const date = new Date(utcTime);

    // Format options
    const options = {
      year: "2-digit",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short",
    };

    // Get the local time and time zone
    const localTimeString = date.toLocaleString("en-US", options);
    const localTimeParts = localTimeString.split(/, | /);

    // Extract and format parts of the date and time
    const [month, day, year, , time, timeZone] = localTimeParts;
    const [startHour, startMinute] = time.split(":");
    const endHour = (parseInt(startHour) + 1) % 24; // assuming the event is 1 hour long, with wrap-around

    // Ensure endHour is two digits
    const endHourFormatted = endHour.toString().padStart(2, "0");

    // Format the final string
    const formattedTimeString = `${day} ${month} '${year} @ ${startHour}:${startMinute} - ${endHourFormatted}:${startMinute} ${timeZone}`;

    return formattedTimeString;
  }

  function onCompletePayment(e, address) {
    // console.log(e, address);
    try {
      // alert("Payment complated!!!")
      const payload = {
        coach_id: sessionDetails.coach_id,
        stripe_transaction_id: e.id,
        total_payment: parseInt(sessionDetails.session_price),
        session_id: sessionDetails.id,
      };
      createNewTransaction(payload);
      addInterestforSession(sessionDetails.id, sessionDetails.coach_id);
      setShowPaymentModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <section className="">
        <Row className="justify-content-center">
          <Col md="12">
            <Row className="justify-content-center g-3">
              {allSession.length > 0 ? (
                allSession.map((item, index) => (
                  <Col md="4">
                    <NavLink>
                      <div className="frlv_session_card">
                        <img
                          alt=""
                          className="live_session_img"
                          width="100%"
                          src={
                            item.poster_url !== null && item.poster_url !== ""
                              ? process.env.REACT_APP_BASE_URL + item.poster_url
                              : live_session_img2
                          }
                        />
                        <div className="frlv_session_ff_column">
                          <div className="frlv_badge justify-content-between">
                            {item.session_status === "live" ? (
                              <>
                                <Badge>Live</Badge>
                                <Badge>50+ joined</Badge>
                              </>
                            ) : (
                              <>
                                <div className="frlv_session_card_hdr">
                                  <h4 className="fs-14 fw-500 m-0">
                                    Upcoming Live Session
                                  </h4>
                                  <p className="fs-10 fw-500 m-0">
                                    {convertUtcToIst(item.start_date)}T
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                          <Button
                            // variant={
                            //   item.userRegistered == true
                            //     ? "success"
                            //     : "warning"
                            // }
                            className="let_me_in_btn let_me_in_btn_trnsparent"
                            onClick={() => {
                              // console.log(user);
                              if (!user) {
                                setLoginModalShow(true);
                              } else if (user?.userType !== "user") {
                                alert("Please login as a user!");
                                localStorage.removeItem("accessToken");
                                setLoginModalShow(true);
                              } else if (item?.userRegistered === false) {
                                setSessionDetails(item);
                                if (
                                  item?.session_type === "paid_session" &&
                                  item?.userPaid === false
                                ) {
                                  setShowPaymentModal(true);
                                  setSessionPrice(
                                    parseInt(item?.session_price)
                                  );
                                } else {
                                  addInterestforSession(
                                    item?.id,
                                    item?.coach_id
                                  );
                                }
                              } else {
                                setShowPaymentModal(true);
                                setSessionPrice(parseInt(item?.session_price));
                                // showSessionInterest(
                                //   item.id,
                                //   item.session_name,
                                //   item
                                // );
                              }
                            }}
                          >
                            {item.userRegistered === false
                              ? "I am Interested"
                              : item.session_status === "live"
                              ? "Join now"
                              : "Let me In"}
                          </Button>
                          <div className="frlv_session_card_footer">
                            <div className="d-flex align-items-center">
                              <img
                                width="35"
                                height="35"
                                alt=""
                                className="user_img"
                                src={
                                  item.profile_pic !== null
                                    ? item.login_type === "Linkedin"
                                      ? item.profile_pic
                                      : process.env
                                          .REACT_APP_COACH_PROFILE_FOLDER_PATH +
                                        item.profile_pic
                                    : testimonials_img1
                                }
                              />
                              <div className="ms-2">
                                <h6 className="fs-16 fw-500 m-0">
                                  {" "}
                                  {item.name ? item.name : "Test Coach"}{" "}
                                </h6>
                                <div className="dot_lable">
                                  <label> {item?.professions} </label>
                                  {/* <label>IT Recruiter</label>
                                                        <label>Career</label> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                ))
              ) : (
                <Col>
                  <Lottie
                    options={defaultOptions}
                    height={300}
                    width={400}
                    isStopped={false}
                    isPaused={false}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </section>
      <LoginComponent
        show={loginModalShow}
        isCoach={true}
        onHide={() => setLoginModalShow(false)}
      />

      {videoCall && (
        <AgoraComponent
          videoCallSessionId={videoCallSessionId}
          videoCallSessionName={videoCallSessionName}
          sessionDetails={sessionDetails}
        />
      )}

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        visible={showPaymentModal}
        width="400"
        height="300"
        effect="fadeInUp"
        onClickAway={() => setShowPaymentModal(false)}
      >
        <div className="brand_bg_color1 my_light_modal">
          {/* <Row className="g-3"> */}

          <div className="frlv_session_card">
            <img
              alt=""
              className="live_session_img"
              width="100%"
              src={
                sessionDetails.poster_url !== null &&
                sessionDetails.poster_url !== ""
                  ? process.env.REACT_APP_BASE_URL + sessionDetails.poster_url
                  : live_session_img2
              }
            />
            <div className="frlv_session_ff_column">
              <div className="frlv_badge justify-content-between">
                {sessionDetails.session_status === "live" ? (
                  <>
                    <Badge>Live</Badge>
                    <Badge>50+ joined</Badge>
                  </>
                ) : (
                  <>
                    <div className="frlv_session_card_hdr">
                      <h4 className="fs-14 fw-500 m-0">
                        {sessionDetails?.session_name}
                      </h4>
                      <p className="fs-10 fw-500 m-0">
                        {/* {convertUtcToIst(sessionDetails.start_date)} */}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <Button className="let_me_in_btn let_me_in_btn_trnsparent">
                Price: ${sessionDetails.session_price}
              </Button>
              <div className="frlv_session_card_footer">
                <div className="d-flex align-items-center">
                  <img
                    width="35"
                    height="35"
                    alt=""
                    className="user_img"
                    src={
                      sessionDetails.profile_pic !== null
                        ? sessionDetails.login_type === "Linkedin"
                          ? sessionDetails.profile_pic
                          : process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH +
                            sessionDetails.profile_pic
                        : testimonials_img1
                    }
                  />
                  <div className="ms-2">
                    <h6 className="fs-16 fw-500 m-0">
                      {" "}
                      {sessionDetails.name
                        ? sessionDetails.name
                        : "Test Coach"}{" "}
                    </h6>
                    <div className="dot_lable">
                      <label>
                        {" "}
                        {sessionDetails.professions
                          ? sessionDetails.professions
                          : "Qoach Professional Admin"}{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </Row> */}
          <StripeCheckout
            label="Pay Now"
            name="Qoach International Private Limited"
            billingAddress
            shippingAddress
            description={`Your Total ₹${sessionPrice}`}
            amount={sessionPrice * 100}
            panelLabel="Pay Now"
            token={onCompletePayment}
            stripeKey={publishableKey}
          />
        </div>
      </Modal>
    </>
  );
}

export default ActivityStreamFreeLiveSessionComponent;
