import React from 'react'

const Camera = () => {
    return (
        <div><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2479_3673)">
                <path d="M15.74 3.34333L13.6625 0.649167C13.5068 0.447183 13.3068 0.283623 13.078 0.171077C12.8491 0.058532 12.5975 4.14574e-06 12.3425 0L7.9075 0C7.65262 0.000131611 7.40116 0.0587207 7.17247 0.171261C6.94377 0.283801 6.74394 0.447295 6.58833 0.649167L4.51 3.34333C3.33085 3.39441 2.21686 3.89838 1.40002 4.7503C0.583182 5.60222 0.12647 6.73642 0.125 7.91667L0.125 15.4167C0.126323 16.6318 0.609634 17.7969 1.46889 18.6561C2.32814 19.5154 3.49316 19.9987 4.70833 20H15.5417C16.7568 19.9987 17.9219 19.5154 18.7811 18.6561C19.6404 17.7969 20.1237 16.6318 20.125 15.4167V7.91667C20.1235 6.73642 19.6668 5.60222 18.85 4.7503C18.0331 3.89838 16.9191 3.39441 15.74 3.34333ZM17.625 15.4167C17.625 15.9692 17.4055 16.4991 17.0148 16.8898C16.6241 17.2805 16.0942 17.5 15.5417 17.5H4.70833C4.1558 17.5 3.62589 17.2805 3.23519 16.8898C2.84449 16.4991 2.625 15.9692 2.625 15.4167V7.91667C2.625 7.36413 2.84449 6.83423 3.23519 6.44353C3.62589 6.05283 4.1558 5.83333 4.70833 5.83333H15.5417C16.0942 5.83333 16.6241 6.05283 17.0148 6.44353C17.4055 6.83423 17.625 7.36413 17.625 7.91667V15.4167Z" fill="#00504E" />
                <path d="M10.1244 7.08301C9.21785 7.08301 8.33171 7.35182 7.57799 7.85544C6.82426 8.35906 6.2368 9.07488 5.8899 9.91238C5.543 10.7499 5.45224 11.6714 5.62909 12.5605C5.80593 13.4496 6.24245 14.2663 6.88344 14.9072C7.52444 15.5482 8.34111 15.9848 9.23019 16.1616C10.1193 16.3385 11.0408 16.2477 11.8783 15.9008C12.7158 15.5539 13.4316 14.9664 13.9353 14.2127C14.4389 13.459 14.7077 12.5728 14.7077 11.6663C14.7064 10.4512 14.2231 9.28615 13.3638 8.4269C12.5045 7.56764 11.3395 7.08433 10.1244 7.08301ZM10.1244 13.7497C9.71231 13.7497 9.30952 13.6275 8.96691 13.3986C8.62431 13.1696 8.35728 12.8443 8.1996 12.4636C8.04192 12.0829 8.00066 11.664 8.08105 11.2599C8.16143 10.8558 8.35985 10.4846 8.65121 10.1932C8.94257 9.90184 9.31379 9.70342 9.71791 9.62304C10.122 9.54265 10.5409 9.58391 10.9216 9.74159C11.3023 9.89927 11.6277 10.1663 11.8566 10.5089C12.0855 10.8515 12.2077 11.2543 12.2077 11.6663C12.2077 12.2189 11.9882 12.7488 11.5975 13.1395C11.2068 13.5302 10.6769 13.7497 10.1244 13.7497Z" fill="#00504E" />
            </g>
            <defs>
                <clipPath id="clip0_2479_3673">
                    <rect width="20" height="20" fill="white" transform="translate(0.125)" />
                </clipPath>
            </defs>
        </svg></div>
    )
}

export default Camera