import React from 'react'

const NotFound = () => {
  return (
    <div className='main-content'>
    <h5 className='main-content-title'>NotFound</h5>
    <p className='main-content-title-inner'>click here redirect to <a href='/'>Dashboard</a> page</p>
    </div>
  )
}

export default NotFound