
import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { coachGetCaseStudies } from "../../../../utils/api";
import moment from "moment";
import Pic from '../../../../assets/images/icons/user.png';

const CoachDetailsCaseStudiesComponent = ({ userData }) => {

    const [caseStudies, setCaseStudeis] = useState([]);
    const [expandedProblem, setExpandedProblem] = useState(false);
    const [expandedSolution, setExpandedSolution] = useState(false);
    const [expandedImplementation, setExpandedImplementation] = useState(false);
    
    const getData = async () => {
        const { data } = await coachGetCaseStudies(userData?.coach_id)
        setCaseStudeis(data?.data)
    }

    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData()
    }, [])

    const toggleExpanded = (section) => {
        if (section === "problem") {
            setExpandedProblem(!expandedProblem);
        } else if (section === "solution") {
            setExpandedSolution(!expandedSolution);
        } else if (section === "implementation") {
            setExpandedImplementation(!expandedImplementation);
        }
    };

    return (
        <>

            <Container className="py-4">
                {
                    caseStudies?.map((study) => {
                        return (
                            <div className="case_studies_card">
                                <div className="case_studies_card_header">
                                    <div className="d-flex align-items-center pic_labels sm">
                                        <div className="pic_labels_img">
                                            {study?.coach_profile && <img src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${study?.coach_profile}`} alt="" />}
                                            {!study?.coach_profile && <img src={Pic} alt="" />}
                                        </div>
                                        <div className="ms-1 w-100">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5 className="text-black fw-500 m-0">{study?.coach_name} <label className="text_light fs-10 ms-2">{moment(study?.created_at).fromNow(true)}</label></h5>
                                                    <p className="fs-14 fw-500 m-0 tags-text">{study?.tag_names?.split(',')?.map((i) => <label>{i}</label>)}</p>
                                                </div>
                                                {/* <Button variant="" className="p-0 h-auto fs-24 fw-700">&#8943;</Button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="asf_card_body">
                                    <div className="p10">
                                        <p className="fs-20 fw-600">
                                            (Problem or Issue){" "}
                                            {study?.problem_issues.length > 250 && !expandedProblem
                                                ? study?.problem_issues.substring(0, 250) + "..."
                                                : study?.problem_issues}
                                            {study?.problem_issues.length > 250 && (
                                                <button className="readmore-btn" onClick={() => toggleExpanded("problem")}>
                                                    {expandedProblem ? "Read Less" : "Read More"}
                                                </button>
                                            )}
                                        </p>
                                        <p className="fs-14 fw-400">
                                            (Solution){" "}
                                            {study?.solution.length > 1000 && !expandedSolution
                                                ? study?.solution.substring(0, 1000) + "..."
                                                : study?.solution}
                                            {study?.solution.length > 1000 && (
                                                <button className="readmore-btn" onClick={() => toggleExpanded("solution")}>
                                                    {expandedSolution ? "Read Less" : "Read More"}
                                                </button>
                                            )}
                                        </p>
                                        <p className="fs-14 fw-400">
                                            <i>(Implementation){" "}
                                                {study?.implementation.length > 1000 && !expandedImplementation
                                                    ? study?.implementation.substring(0, 1000) + "..."
                                                    : study?.implementation}
                                                {study?.implementation.length > 1000 && (
                                                    <button className="readmore-btn" onClick={() => toggleExpanded("implementation")}>
                                                        {expandedImplementation ? "Read Less" : "Read More"}
                                                    </button>
                                                )}
                                            </i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Container>
        </>
    )
}

export default CoachDetailsCaseStudiesComponent;