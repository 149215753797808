import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Badge, Button, Col, Row, Form, InputGroup, Modal } from "react-bootstrap";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useUserContext } from "../../../utils/hooks/UserContext";
import { addLike, getPosts, getCommentPostById, addComment } from "../../../utils/api";
import ex_years from '../../../assets/images/icons/ex_years.png';
import degree from '../../../assets/images/icons/degree.png';
import $per_time from '../../../assets/images/icons/$per_time.png';
import SocialFeedsPostComponent from "../../../Coach/Pages/Activities/socialFeedsPost";
import moment from 'moment';
import Comment from "../../../Coach/Common/Comments";
import { getBadgeColor } from "../../../utils/functions";
import { toast } from "react-toastify";
import { followUnfollowCoach, getFilteredCoaches } from "../../../utils/api";
import { PulseLoader } from "react-spinners";
import Pic from '../../../assets/images/icons/user.png';
import send_img from '../../../assets/images/icons/send.png';
import LoginComponent from '../Login/newindex';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import Lottie from 'react-lottie';
import noDataLottie from "../../../assets/lottieFiles/noData.json";


function ActivityStreamSocialFeedsComponent() {
    const navigate = useNavigate();
    const { user } = useUserContext()
    const [sfPostModalShow, setSfPostModalShow] = React.useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [coaches, setcoaches] = useState([])
    const [comment, setcomment] = useState("")
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [showSocial, setShowSocial] = useState(false);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: noDataLottie,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    useEffect(() => {
        getData();
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        window.scrollTo(0, 0);
    }, [])

    const getData = async () => {
        setisLoading(true)
        try {

            const { data } = await getFilteredCoaches()
            if (data?.status) {
                setcoaches(data?.data)
            }
            else {
                toast.error(data?.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message)
            // console.error("error++", error);
        }
        const { data } = await getPosts()
        setPosts(data?.data)
        setisLoading(false)
    }
    const onClickComment = async (id) => {
        const feedCommentsList = document.querySelectorAll('.feed-comments');
        // Loop through each element and add the class .d-none
        feedCommentsList.forEach(feedComment => {
            feedComment.classList.add('d-none');
        });
        const { data } = await getCommentPostById(id)
        setComments(data?.data || [])
        if (document.getElementById(id + "-feed-id")) {
            document.getElementById(id + "-feed-id").classList.remove('d-none');
        }
        // setShowModal(true)

    }
    const onClickFollow = async (id) => {
        if (!user) {
            // navigate('/')
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }

        const formdata = new FormData()
        formdata.append('coach_id', id)

        await followUnfollowCoach(formdata)
        await getData()
    }

    const onClickLike = async (id, totallikes, alreadylike) => {
        // console.log(user);
        if (!user) {
            // navigate('/')
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }
        if (user.userType === "coach") {
            // navigate('/')
            toast.error('Please login as a user')
            return
        }

        try {
            const formData = new FormData();
            formData.append('post_id', id)
            const { data } = await addLike(formData)
            const svgElement = document.getElementById(`heart-fill-icon-${id}`);
            // console.log(data.message);
            if (alreadylike === 1) {
                if (data.message === "Liked") {
                    svgElement.setAttribute('fill', '#FF0000');
                    document.getElementById(`feedlike-count-${id}`).innerText = totallikes + " Likes";
                } else {
                    svgElement.setAttribute('fill', 'none');
                    document.getElementById(`feedlike-count-${id}`).innerText = totallikes - 1 + " Likes";
                }
            } else {
                if (data.message === "Liked") {
                    svgElement.setAttribute('fill', '#FF0000');
                    document.getElementById(`feedlike-count-${id}`).innerText = totallikes + 1 + " Likes";
                } else {
                    svgElement.setAttribute('fill', 'none');
                    document.getElementById(`feedlike-count-${id}`).innerText = totallikes + " Likes";
                }
            }
            // await getData()
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message)
        }

    }

    const getImage = (image) => {
        if (image) {
            return `${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${image}`
        }

        return Pic
    }

    const onSendComment = async (id, comment_count) => {
        if (!user) {
            // navigate('/')
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }

        const formData = new FormData();
        formData.append('post_id', id)
        formData.append('comments', comment[id])
        await addComment(formData)

        setcomment({ ...comment, [id]: "" })
        const { data } = await getCommentPostById(id)
        setComments(data?.data || [])
        document.getElementById(`feedcomment-count-${id}`).innerText = comment_count + 1 + " Comments";
    }

    const onChangeComment = (e, id) => {
        setcomment({ ...comment, [id]: e?.target?.value })
    }

    const defaultUserImage = (e) => {
        e.target.src = Pic;
    }
    const likeButton = async (e) => {
        if (!user) {
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }
    }
    const defaultImage = (e) => {
        e.target.src = 'https://nadmin.qoach.io/assets/images/blog.png';
    }
    const displaySocialIcon = (id) => {
        setShowSocial(!showSocial)
        if (showSocial === false) {
            navigator.clipboard
                .writeText(`qoach.io/single-feed/` + id)
                .then(() => {
                    toast.success("Link Copied to Clipboard!");
                })
                .catch((err) => {
                    // console.error("Unable to copy text to clipboard", err);
                });
        }
    }
    return (
        <>
            <Modal dialogClassName="modal-dialog-centered"
                show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    {comments?.length < 1 && <div>No comments found</div>}
                    {comments?.length > 0 && comments.map(comment => (
                        <Comment
                            key={comment?.id}
                            commentId={comment?.id}
                            commenterName={comment?.name}
                            description={comment?.comments}
                            commenterId={comment?.user_id}
                            reload={() => onClickComment(comment?.social_id)}
                            commenterPic={comment?.profile_pic}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            <Row className="justify-content-center">
                <Col lg="12">
                    <NavLink onClick={() => setSfPostModalShow(true)}>
                        {user?.userType === "coach" && (
                            <Row className="d-flex justify-content-center mb-5">
                                <Col className="col-lg-8 d-flex ms-2">
                                    <Col className="col-auto">
                                        <div className="sp_user">
                                            {
                                                user ?
                                                    user.profile_pic ?
                                                        <img src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + user?.profile_pic} alt="" onError={(e) => defaultUserImage(e)} />
                                                        :
                                                        <img src={Pic} alt="" />
                                                    :
                                                    <img src={Pic} alt="" />
                                            }
                                            {/* {!user?.profile_pic && <img src={Pic} alt="" />} */}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="start_post_card ms-2">
                                            <span className="fs-14">Start a post</span>
                                            <div className="fs-14 d-flex align-items-center">
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.24 22.6295C22.35 24.0395 20.79 24.9795 19 24.9795H5C2.24 24.9795 0 22.7395 0 19.9795V17.3895L3.98 13.4095C4.54 12.8495 5.46 12.8495 6.03 13.4095L11.61 18.9895C12.93 20.3095 15.09 20.3095 16.41 18.9895L16.99 18.4095C17.54 17.8595 18.49 17.8595 19.04 18.4095L23.25 22.6195L23.24 22.6295ZM20.93 8.34949L21.63 6.60949L23.37 5.90949C23.75 5.75949 24 5.38949 24 4.97949C24 4.56949 23.75 4.19949 23.37 4.04949L21.63 3.34949L20.93 1.60949C20.78 1.22949 20.41 0.979492 20 0.979492C19.59 0.979492 19.22 1.22949 19.07 1.60949L18.38 3.33949L16.65 3.99949C16.27 4.13949 16.01 4.50949 16 4.91949C16 5.32949 16.23 5.69949 16.61 5.85949L18.37 6.59949L19.07 8.34949C19.22 8.72949 19.59 8.97949 20 8.97949C20.41 8.97949 20.78 8.72949 20.93 8.34949ZM24 7.80949V19.9795C24 20.1695 23.99 20.3495 23.97 20.5295L20.44 16.9995C19.1 15.6595 16.91 15.6595 15.56 16.9995L14.98 17.5795C14.44 18.1195 13.55 18.1195 13.01 17.5795L7.43 11.9995C6.09 10.6595 3.9 10.6595 2.55 11.9995L0 14.5695V5.97949C0 3.21949 2.24 0.979492 5 0.979492H17.17L16.85 1.77949L15.94 2.11949C14.79 2.55949 14.02 3.63949 14 4.86949C13.98 6.09949 14.7 7.20949 15.83 7.68949L16.82 8.10949L17.21 9.08949C17.67 10.2395 18.76 10.9795 20 10.9795C21.24 10.9795 22.33 10.2395 22.79 9.08949L23.17 8.13949L24 7.80949ZM9 6.47949C9 5.64949 8.33 4.97949 7.5 4.97949C6.67 4.97949 6 5.64949 6 6.47949C6 7.30949 6.67 7.97949 7.5 7.97949C8.33 7.97949 9 7.30949 9 6.47949ZM17 11.4795C17 11.0195 16.69 10.6195 16.24 10.5095L14.82 10.1595L14.47 8.73949C14.36 8.29949 13.96 7.97949 13.5 7.97949C13.04 7.97949 12.64 8.28949 12.53 8.73949L12.18 10.1495L10.78 10.4695C10.33 10.5695 10.01 10.9695 10.01 11.4295C10.01 11.8895 10.31 12.2895 10.75 12.4095L12.18 12.7995L12.54 14.2295C12.65 14.6695 13.05 14.9895 13.51 14.9895C13.97 14.9895 14.37 14.6795 14.48 14.2295L14.83 12.8095L16.25 12.4595C16.69 12.3495 17.01 11.9495 17.01 11.4895L17 11.4795Z" fill="#00504E" />
                                                </svg>
                                                <span className="text_brand_color2 ms-2">Photo/video</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                        )}
                    </NavLink>
                    <Row className="justify-content-between">
                        <Col className="me-md-4">
                            {isLoading &&
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100vh",
                                        width: "100vw",
                                    }}
                                >
                                    <p>
                                        <PulseLoader color="#00504E" size={20} />
                                    </p>
                                </div>
                            }
                            {(!isLoading && posts.length > 0) ? posts?.map((i) => {
                                return (
                                    <div className="asf_card">
                                        <div className="asf_card_header">
                                            <NavLink to={`/single-feed/${i.social_id}`}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div className="user_img_sm_rnd">
                                                            {i?.coach_profile && <img width={50} height={50} className="rounded-circle" src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + i?.coach_profile} alt="" />}
                                                            {!i?.coach_profile && <img width={50} height={50} className="rounded-circle" src={Pic} alt="" />}
                                                        </div>
                                                        <div className="ms-2">
                                                            <div className="d-flex align-items-center flex-wrap">
                                                                <h5 className="fs-16 fw-500 m-0">
                                                                    {i?.coach_name}
                                                                </h5>
                                                                <span className="hrs position-relative fs-10 fw-500 ps-3 ms-3">{moment(i?.created_at).fromNow(true)}</span>
                                                            </div>
                                                            {i?.skills?.map((i) => {
                                                                return <label>{i?.name}</label>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <svg width="20px" height="20px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#555" class="bi bi-three-dots">
                                                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                                    </svg>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="asf_card_body">
                                            <div className="p10">
                                                <p className="fs-14 fw-400 m-0" dangerouslySetInnerHTML={{ __html: i?.description }} />
                                            </div>
                                            {i?.file_url ? i?.type === "Photo" && <img className="asf_img" src={process.env.REACT_APP_COACH_POST_IMAGE_PATH + i?.file_url} alt="" /> : ""}
                                            {i?.file_url ? i?.type === "Video" && <video className="img_fit_contain asf_img" controls src={process.env.REACT_APP_COACH_POST_VIDEO_PATH + i?.file_url} alt=""></video> : ''}
                                        </div>
                                        <div className="asf_card_footer px-3 py-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center" onClick={() => onClickLike(i?.social_id, i?.like_count, i?.is_liked)} >
                                                    <NavLink class="align-items-center d-flex">
                                                        <svg width="20" height="20" viewBox="0 0 28 25" id={`heart-fill-icon-${i?.social_id}`} fill={i?.is_liked === 1 ? '#FF0000' : "none"} xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.6073 22.5423C-9.7256 9.64577 6.60771 -4.35423 13.6073 4.4985C20.6077 -4.35423 36.941 9.64577 13.6073 22.5423Z" stroke={i?.is_liked === 1 ? '#FF0000' : "#964B00"} stroke-width="3" />
                                                        </svg>
                                                        <span className="fs-14 ms-2" id={`feedlike-count-${i?.social_id}`}>{i?.like_count} likes</span>
                                                    </NavLink>
                                                </div>
                                                <div onClick={() => onClickComment(i?.social_id)} className="d-flex align-items-center">
                                                    <NavLink class="align-items-center d-flex">
                                                        <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M24.6074 14.3962C24.6114 15.936 24.2517 17.4551 23.5574 18.8295C22.7343 20.4766 21.4688 21.8619 19.9028 22.8304C18.3368 23.7988 16.532 24.3121 14.6908 24.3129C13.1509 24.3169 11.6319 23.9571 10.2574 23.2629L3.60742 25.4795L5.82409 18.8295C5.12984 17.4551 4.77007 15.936 4.77409 14.3962C4.7748 12.5549 5.28814 10.7502 6.25659 9.18417C7.22505 7.61815 8.61039 6.35269 10.2574 5.52953C11.6319 4.83528 13.1509 4.47551 14.6908 4.47953H15.2741C17.7058 4.61368 20.0026 5.64008 21.7248 7.36219C23.4469 9.08431 24.4733 11.3811 24.6074 13.8129V14.3962Z" stroke="#964B00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <span className="fs-14 ms-2" id={`feedcomment-count-${i?.social_id}`}>{i?.comment_count} comments</span>
                                                    </NavLink>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <NavLink class="align-items-center d-flex" onClick={() => displaySocialIcon(i?.social_id)}>
                                                        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.1068 19.9808C17.1068 22.7401 14.8674 24.9795 12.1082 24.9795H5.11C2.35073 24.9795 0.111328 22.7401 0.111328 19.9808V12.9827C0.111328 10.2234 2.35073 7.98399 5.11 7.98399H6.10974C6.65959 7.98399 7.10947 8.43387 7.10947 8.98373C7.10947 9.53358 6.65959 9.98346 6.10974 9.98346H5.11C3.46044 9.98346 2.1108 11.3331 2.1108 12.9827V19.9808C2.1108 21.6304 3.46044 22.98 5.11 22.98H12.1082C13.7577 22.98 15.1074 21.6304 15.1074 19.9808C15.1074 19.431 15.5572 18.9811 16.1071 18.9811C16.6569 18.9811 17.1068 19.431 17.1068 19.9808ZM23.2352 6.87429L17.8266 1.28577C17.4467 0.885874 16.8069 0.875876 16.417 1.26577C16.0271 1.65567 16.0071 2.2855 16.397 2.6754L21.5357 7.974H13.1079C10.3486 7.974 8.10921 10.2134 8.10921 12.9727V17.9713C8.10921 18.5212 8.55909 18.9711 9.10894 18.9711C9.6588 18.9711 10.1087 18.5212 10.1087 17.9713V12.9727C10.1087 11.3231 11.4583 9.97347 13.1079 9.97347H21.5257L16.387 15.2721C16.0071 15.672 16.0071 16.3018 16.407 16.6817C16.597 16.8716 16.8569 16.9616 17.1068 16.9616C17.3568 16.9616 17.6267 16.8616 17.8266 16.6617L23.2252 11.0932C24.3949 9.92348 24.3949 8.02398 23.2352 6.86429V6.87429Z" fill="#964B00" />
                                                        </svg>
                                                        <span className="fs-14 ms-2">Share</span>
                                                    </NavLink>
                                                    {showSocial === true && (
                                                        <div className="share position-absolute border bottom-0 bg-white p-2 start-50 z-2 rounded-pill">
                                                            <div className="mobile-share-details-link">
                                                                <FacebookShareButton className=""
                                                                    url={`${window.location}`}
                                                                >
                                                                    <FacebookIcon size={40} round={true} />
                                                                </FacebookShareButton>
                                                                <WhatsappShareButton className="ms-1"
                                                                    url={`${window.location}`}
                                                                >
                                                                    <WhatsappIcon size={40} round={true} />
                                                                </WhatsappShareButton>

                                                                <EmailShareButton className="ms-1"
                                                                    url={`${window.location}`}
                                                                >
                                                                    <EmailIcon size={40} round={true} />
                                                                </EmailShareButton>
                                                                <LinkedinShareButton className="ms-1"
                                                                    url={`${window.location}`}
                                                                >
                                                                    <LinkedinIcon size={40} round={true} />
                                                                </LinkedinShareButton>
                                                                <TelegramShareButton className="ms-1"
                                                                    url={`${window.location}`}
                                                                >
                                                                    <TelegramIcon size={40} round={true} />
                                                                </TelegramShareButton>
                                                                <TwitterShareButton className="ms-1"
                                                                    url={`${window.location}`}
                                                                >
                                                                    <TwitterIcon size={40} round={true} />
                                                                </TwitterShareButton>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div id={i?.social_id + `-feed-id`} className="d-none feed-comments">
                                            {comments?.length > 0 && comments.map(comment => (
                                                // <Comment
                                                //     key={comment?.id}
                                                //     commentId={comment?.id}
                                                //     commenterName={comment?.name}
                                                //     description={comment?.comments}
                                                //     commenterId={comment?.user_id}
                                                //     reload={() => onClickComment(comment?.social_id)}
                                                //     commenterPic={comment?.profile_pic}
                                                // />
                                                <div className="d-flex px-3">
                                                    <div className="user_img_sm_rnd me-2 mt-1">
                                                        <img src={getImage(user?.profile_pic)} alt="" className="" onError={(e) => defaultUserImage(e)} width={30} height={30} />
                                                    </div>
                                                    <div>
                                                        <span className="fw-600">{comment?.name}</span>
                                                        <p className="fs-12">{comment?.comments}</p>
                                                    </div>
                                                </div>
                                            ))
                                                // :
                                                // <div>No comments found</div>
                                            }
                                        </div>
                                        {user?.userType === "user" &&
                                            <div className="border-top py-3">
                                                <div className="d-flex align-items-center px-3">
                                                    <div className="user_img_sm_rnd me-2">
                                                        <img src={getImage(user?.profile_pic)} alt="" className="" onError={(e) => defaultUserImage(e)} width={30} height={30} />
                                                    </div>
                                                    <div className="w-100 position-relative">
                                                        <input type="text" placeholder="Write a comment..." value={comment[i?.social_id]} onChange={(e) => onChangeComment(e, i?.social_id)} name="comment" id="comment" className="rounded-pill w-100 border py-2 ps-3 fs-14 pe-5" />
                                                        <Button onClick={() => onSendComment(i?.social_id, i?.comment_count)} variant="outline-secondary" id="button-addon1" className="position-absolute end-0 top-50 translate-middle-y">
                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_429_11051)">
                                                                    <path d="M5 12L4.39589 6.56299C4.22297 5.0067 5.82469 3.86433 7.23983 4.53465L19.1842 10.1925C20.7093 10.9149 20.7093 13.0851 19.1842 13.8075L7.23983 19.4653C5.82469 20.1357 4.22297 18.9933 4.39589 17.437L5 12ZM5 12H12" stroke="#292929" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_429_11051">
                                                                        <rect width="24" height="24" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            // {/* // <div className="comment_area " >
                                            // //     <Row className="g-2 align-items-center">
                                            // //         <Col className="col-auto">
                                            // //             <div className="user_img_sm_rnd">
                                            // //                 <img src={getImage(user?.profile_pic)} alt="" className="rounded-circle-video" onError={(e) => defaultUserImage(e)} />
                                            // //             </div>
                                            // //         </Col>
                                            // //         <Col>
                                            // //             <InputGroup className="write_comment_txt_ar">
                                            // //                 <Form.Control className='fs-14' value={comment[i?.social_id]} onChange={(e) => onChangeComment(e, i?.social_id)} placeholder="Write a comment" as="textarea" />
                                            // //                 <Button onClick={() => onSendComment(i?.social_id)} variant="outline-secondary" id="button-addon1">
                                            // //                     <img src={send_img} alt="" />
                                            // //                 </Button>
                                            // //             </InputGroup>
                                            // //         </Col>
                                            // //     </Row>
                                            // // </div> */}
                                        }
                                    </div>
                                )
                            }) : (
                                <Col>
                                <Lottie options={defaultOptions}
                                    height={300}
                                    width={400}
                                    isStopped={false}
                                    isPaused={false}/>
                            </Col>
                            ) }

                        </Col>

                        <Col lg="3" className="p-0">
                            <h4 className="fs-20 fw-500">Recommended Coach</h4>
                            {coaches?.filter((_, ind) => ind < 3).map((i) => {
                                return (
                                    <div className="browse_coach_suggestion_list">
                                        <div className="browse_coach_suggestion_list_card">
                                            <div className="browse_coach_suggestion_list_card_header">
                                                <div className="user_img_sm_rnd mb-3">
                                                    {i?.profile_pic && <img width={50} height={50} className="rounded-circle" src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + i?.profile_pic} alt="" />}
                                                    {!i?.profile_pic && <img width={50} height={50} className="rounded-circle" src={Pic} alt="" />}
                                                </div>
                                                <div className="mt-3 lh-1">
                                                    <NavLink to={!user ? "" : `/browse-coach/coach-details/${i?.coach_id}`} onClick={!user ? (e) => likeButton() : ''}>
                                                        <h5 className="m-0 mb-2 fs-18">{i?.name}</h5>
                                                    </NavLink>
                                                    {i?.skills?.map((i) => {
                                                        return (<label className="fs-12 fw-400">{i?.name}</label>)
                                                    })}
                                                </div>
                                                {i?.coach_type && <Badge className={`bcl_badge_type ${getBadgeColor(i?.coach_type)}`}>{i?.coach_type}</Badge>}
                                            </div>

                                            <div className="browse_coach_suggestion_list_card_mid">
                                                <div className="ydm_row justify-content-center mt-3 flex-wrap">
                                                    <div className="d-flex align-items-center">
                                                        <span className="me-2">
                                                            <img src={ex_years} alt="" />
                                                        </span>
                                                        <span className="fw-500">
                                                            {posts.workexperience > 0
                                                                ? posts.workexperience + " Years"
                                                                : "0 Years"}{" "}
                                                        </span>
                                                    </div>
                                                    <div className="mx-2 d-flex align-items-center">
                                                        <span className="me-2">
                                                            <img src={degree} alt="" />
                                                        </span>
                                                        <span className="fw-500">423</span>
                                                    </div>
                                                    <div className="mx-2 d-flex align-items-center">
                                                        <span className="me-2">
                                                            <img src={$per_time} alt="" />
                                                        </span>
                                                        <span className="text_brand_color2 fw-600">$199</span>
                                                    </div>
                                                </div>
                                                {/* {i?.tags?.map((i) => {
                                                            return <p className="tagged_txt px-1 d-inline-flex m-0">#{i}</p>
                                                        })} */}
                                                <div className="d-grid mt-3">
                                                    {i?.is_following === 1 && <button onClick={() => onClickFollow(i?.coach_id)} className="fs-12 btn-success p-2 rounded" disabled={isLoading} variant="outline-success" size="md">Unfollow</button>}
                                                    {i?.is_following === 0 && <button onClick={() => onClickFollow(i?.coach_id)} className="fs-12 btn-success p-2 rounded" variant="success" size="md">Follow</button>}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }) }


                            <div className="want_be_coach_card">
                                <h5>If you want to achieve excellence, you can get there today</h5>
                                <p>Helping others achieve their dreams is an excellent way to start.</p>
                                <div className="d-grid">
                                    <Button variant="outline-light" onClick={() => setLoginModalShow(true)} className="px-4 fs-16">Become a Coach</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <SocialFeedsPostComponent show={sfPostModalShow} onHide={() => setSfPostModalShow(false)} />
            <LoginComponent show={loginModalShow} isCoach={true} onHide={() => setLoginModalShow(false)} />
        </>

    )
}

export default ActivityStreamSocialFeedsComponent