import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Col,
  Form,
  Image,
  Nav,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import excl_i from "../../../assets/images/icons/excl_i.png";
import trash from "../../../assets/images/icons/trash.svg";
import { useNavigate, NavLink } from "react-router-dom";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { WithContext as ReactTags } from "react-tag-input";
import Select from "react-select";
import Modal from "react-awesome-modal";
import {
  coachAddProgram,
  coachAddSkill,
  coachBenefits,
  coachDeleteProgram,
  coachDeleteSkill,
  coachProfessions,
  coachTags,
  getCategoryList,
} from "../../../utils/api";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";

const initialValues = {
  session_title: "",
  price: "",
  P_duration: "",
  no_of_session: "",
  duration: "",
  display_title: "",
  description: "",
};
const validationSchemaSingle = Yup.object().shape({
  session_title: Yup.string().required("Session Title is required"),
  price: Yup.number().required("Price is required"),
  duration: Yup.number().required("Duration is required"),
  display_title: Yup.string().required("Number of session is required"),
});

const validationSchemaMultiple = Yup.object().shape({
  session_title: Yup.string().required("Session Title is required"),
  price: Yup.number().required("Price is required"),
  duration: Yup.number().required("Duration is required"),
});

const CoachProfileCoachingDetailsComponent = (props) => {
  const navigate = useNavigate();
  const { getUserFromToken, user } = useUserContext();
  const [programs, setprograms] = useState([]);
  const [tags, setTags] = useState([]);
  const [deletedTags, setdeletedTags] = useState([]);
  const [skill, setSkill] = useState([]);
  const [benefits, setbenefits] = useState({});
  const [professions, setprofessions] = useState({});
  const [tagList, settagList] = useState("");
  const [sessionType, setSessionType] = useState("Single");
  const [dataArray, setDataArray] = useState([]);
  const [deletedSessions, setdeletedSessions] = useState([]);
  const [session_name, setSession_name] = useState("");
  const [session_duration, setSession_duration] = useState("");
  const [sessionNameError, setSessionNameError] = useState(false);
  const [sessionDuration, setSessionDuration] = useState(false);
  const [visible, setVisible] = useState(false);
  const [progVisible, setProgVisible] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const allDurations = [
    { label: "30 Minutes", value: 30 },
    { label: "1 Hour", value: 60 },
    { label: "1 hour 30 Minutes", value: 90 },
    { label: "2 Hour", value: 120 },
  ];

  const handleInputChange1 = (event) => {
    setSession_name(event.target.value);
    setSessionNameError(false);
  };

  const handleInputChange2 = (event) => {
    setSession_duration(event.target.value);
    setSessionDuration(false);
  };

  const handleAddButtonClick = () => {
    if (!session_name || !session_duration) {
      if (!session_name) setSessionNameError(true);
      if (!session_duration) setSessionDuration(true);
      return;
    }

    const newObject = {
      id: Math.random(),
      session_name: session_name,
      session_duration: session_duration,
    };

    setDataArray([...dataArray, newObject]);
    setSession_name("");
    setSession_duration("");
  };

  const handleAddButtonDelete = (id) => {
    setdeletedSessions([
      ...deletedSessions,
      dataArray?.find((i) => i?.id === id),
    ]);
    setDataArray(dataArray?.filter((i) => i?.id !== id));
  };

  useEffect(() => {
    setprograms(user?.programs);
    setTags(
      user?.skills?.map((i) => ({
        id: i?.name,
        text: i?.name,
        skill_id: i?.id,
      })) || []
    );
    settagList(user?.tags?.toString());

    const transformedBenefit = user?.benefits?.reduce((acc, benefit, index) => {
      acc[index + 1] = benefit;
      return acc;
    }, {});
    setbenefits(transformedBenefit);

    const transformedProfession = user?.professions?.reduce(
      (acc, benefit, index) => {
        acc[index + 1] = benefit;
        return acc;
      },
      {}
    );
    setprofessions(transformedProfession);
  }, [user]);

  const getData = async () => {
    const skill = await getCategoryList();
    setSkill(
      skill?.data?.data?.map((i) => ({
        id: i?.name,
        text: i?.name,
        skill_id: i?.id,
      }))
    );
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i) => {
    setdeletedTags([...deletedTags, tags.find((_, index) => index === i)]);
    setTags(tags.filter((tag, index) => index !== i));
  };

  const renderSuggestion = (event) => {
    return <div>{event?.id || event?.text || event?.name}</div>;
  };

  const handleAddition = (tag) => {
    const newTag = { ...tag, type: "new" };
    setTags([...tags, newTag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const onClickSave = async (values) => {
    try {
      // handle program
      setisLoading(true);
      const newPrograms = programs?.filter((i) => i?.status === "new");

      for (let index = 0; index < newPrograms?.length; index++) {
        const element = newPrograms[index];
        const formData = new FormData();
        if (element?.type === "Multiple") {
          formData.append("session_title", element?.session_title);
          formData.append("price", element?.price);
          formData.append("duration", element?.duration);
          formData.append("type", element?.type);
          formData.append(
            "sessions",
            JSON.stringify(
              element?.sessions?.map((i) => ({
                name: i?.session_name,
                duration: i?.session_duration,
              }))
            )
          );
          // formData.append("display_title", element?.display_title);
          formData.append("description", element?.description);
        } else {
          formData.append("session_title", element?.session_title);
          formData.append("price", element?.price);
          formData.append("duration", element?.duration);
          formData.append("type", element?.type);
          // formData.append("display_title", element?.display_title);
          formData.append("description", element?.description);
        }

        await coachAddProgram(formData);
      }

      const deletedPrograms = programs?.filter((i) => i?.status === "deleted");

      if (deletedPrograms?.length > 0) {
        const formData = new FormData();
        formData.append(
          "program_ids",
          `[${deletedPrograms?.map((i) => i?.id)}]`
        );

        await coachDeleteProgram(formData);
      }

      // handle skills
      if (tags?.length > 0) {
        const addSkill = new FormData();
        addSkill.append(
          "skill_ids",
          `[${tags?.filter((j) => j?.type !== "new")?.map((i) => i?.skill_id)}]`
        );
        await coachAddSkill(addSkill);
      }

      if (tags?.filter((i) => i?.type === "new")?.length > 0) {
        const addSkill = new FormData();
        addSkill.append(
          "new_skills",
          `[${tags
            ?.filter((j) => j?.type === "new")
            ?.map((i) => `"${i?.text}"`)}]`
        );
        await coachAddSkill(addSkill);
      }

      if (deletedTags?.length > 0) {
        const deleteSkill = new FormData();
        deleteSkill.append(
          "skill_ids",
          `[${deletedTags?.map((i) => i?.skill_id)}]`
        );

        await coachDeleteSkill(deleteSkill);
      }

      // handleTags
      const tagData = new FormData();
      tagData.append("tags", tagList);
      await coachTags(tagData);

      // handle benefits
      const benefitString = Object.values(benefits).join(", ");
      const benefitData = new FormData();
      benefitData.append("benefits", benefitString);
      await coachBenefits(benefitData);

      // handle professions
      const professionString = Object.values(professions).join(", ");
      const professionData = new FormData();
      professionData.append("professions", professionString);
      await coachProfessions(professionData);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setisLoading(false);
    }

    getUserFromToken();
    setisLoading(false);
    navigate("/coach/professional-details");
  };

  const onClickNext = () => {
    navigate("/coach/professional-details");
  };

  const handleSubmit = (values, { resetForm }) => {
    formik.setValues({
      ...formik.values,
      session_title: "",
      price: "",
      no_of_session: "",
      duration: "",
      display_title: "",
      description: "",
    });
    setprograms([
      ...programs,
      {
        id: new Date()?.getTime(),
        session_title: values.session_title,
        price: values.price,
        duration: values.duration,
        type: sessionType,
        sessions: dataArray,
        status: "new",
        display_title: values.display_title,
        description: values.description,
      },
    ]);
    setDataArray([]);
    resetForm();
  };
  const handleSessionTypeChange = (event) => {
    setSessionType(event.target.value);
  };

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement("link");
      link.setAttribute("rel", "canonical");
      document.head.appendChild(link);
    }
    link.setAttribute("href", canonicalUrl);

    getData();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema:
      sessionType === "Single"
        ? validationSchemaSingle
        : validationSchemaMultiple,
    onSubmit: handleSubmit,
  });

  const onRemoveProgram = (id) => {
    const results = JSON.parse(JSON.stringify(programs))?.map((i) => {
      if (id === i?.id) {
        i.status = "deleted";
      }
      return i;
    });
    setprograms(results);
    setProgVisible(false);
    setVisible(false);
  };

  const handleBenefits = (event) => {
    setbenefits({ ...benefits, [event.target.name]: event?.target?.value });
  };

  const handleProfessions = (event) => {
    setprofessions({
      ...professions,
      [event.target.name]: event?.target?.value,
    });
  };

  const onChangeTagList = (event) => {
    settagList(event?.target?.value);
  };

  const handleDeleteSection = (id, delete_type) => {
    if (delete_type === "programe") {
      setDeleteId(id);
      setProgVisible(true);
      setVisible(true);
    }
  };

  const tooltipSkill = (
    <Tooltip id="tooltip">
      <strong>Press enter </strong> after typing to create a new tag, or pick
      one from the list.
    </Tooltip>
  );

  const tooltipTag = (
    <Tooltip id="tooltip">
      Please input tags, separating each with <strong>commas.</strong>
    </Tooltip>
  );

  return (
    <>
      <section className="coach_my_activity py-3">
        <Container>
          <Row className="align-items-center justify-content-center mb-5">
            {window.innerWidth < 500 ? (
              <div>
                <Col className="col">
                  <Nav
                    variant="pills"
                    className="wizard_nav justify-content-center"
                  >
                    <Nav.Item className="active">
                      <NavLink to="/coach/coaching-details">
                        <span className="wn_num">3</span>
                        <span>Coaching Details</span>
                      </NavLink>
                    </Nav.Item>
                  </Nav>
                </Col>
              </div>
            ) : (
              <Col className="col">
                <Nav
                  variant="pills"
                  className="wizard_nav justify-content-between"
                >
                  <Nav.Item className="active">
                    <NavLink to="/coach">
                      <span className="wn_num">1</span>
                      <span> Personal Information</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item className="active">
                    <NavLink to="/coach/about-yourself">
                      <span className="wn_num">2</span>
                      <span>About Yourself</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item className="active">
                    <NavLink to="/coach/coaching-details">
                      <span className="wn_num">3</span>
                      <span>Coaching Details</span>
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item></Nav.Item>

                  <Nav.Item>
                    <NavLink to="/coach/professional-details">
                      <span className="wn_num">4</span>
                      <span>Professional Details</span>
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Col>
            )}
          </Row>
          <Form onSubmit={formik?.handleSubmit}>
            <>
              <Row>
                <Col sm="12">
                  <div className="d-flex justify-content-between">
                    <Form.Label className="title">
                      Add Program/Session
                    </Form.Label>
                    <span>
                      <Image src={excl_i} />
                    </span>
                  </div>
                </Col>

                <Col sm="12">
                  <Row>
                    <Col className="">
                      {programs
                        ?.filter((i) => i?.status !== "deleted")
                        ?.map((program) => (
                          <div className="mt-2 d-flex justify-content-between border p-3 rounded mb-3">
                            <div className="w-100">
                              <div className="w-100 session_price_name">
                                <h2 className="fs-16 fw-500 text_brand_color7">
                                  {program?.session_title}
                                </h2>
                                <div className="d-flex justify-conter-space  m-1">
                                  <span>
                                    <h3 className="fs-18 fw-500 px-2">
                                      {program?.type === "single"
                                        ? "1 Day"
                                        : "Multi Day"}
                                    </h3>
                                  </span>
                                  <span>
                                    <h2 className="fs-22 fw-700 text_brand_color2 m-0">
                                      ${program?.price}
                                    </h2>
                                  </span>
                                  <span>
                                    <Button
                                      className="mb-2"
                                      variant=""
                                      onClick={() =>
                                        handleDeleteSection(
                                          program?.id,
                                          "programe"
                                        )
                                      }
                                    >
                                      <Image src={trash} />
                                    </Button>
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className="fs-14" style={{ color: "#111" }}>
                                  {" "}
                                  {program?.description
                                    ? program.description
                                    : "Not Available description"}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label className="title">
                      Choose Session Type
                    </Form.Label>
                    <div className="d-flex py-2">
                      <Form.Check
                        type="radio"
                        name="sessionType"
                        label="Single"
                        value="Single"
                        className="mx-2"
                        checked={sessionType === "Single"}
                        onChange={handleSessionTypeChange}
                      />
                      <Form.Check
                        type="radio"
                        name="sessionType"
                        label="Multiple"
                        value="Multiple"
                        className="mx-2"
                        checked={sessionType === "Multiple"}
                        onChange={handleSessionTypeChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
                {sessionType === "Single" ? (
                  <Row>
                    <Col lg="12">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name={`session_title`}
                          placeholder="Enter Program Name"
                          value={formik.values.session_title}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik?.touched?.session_title &&
                            !!formik?.errors?.session_title
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik?.errors?.session_title &&
                            formik?.touched?.session_title && (
                              <div>{formik?.errors?.session_title}</div>
                            )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg="12">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter About Program"
                          value={formik.values.description}
                          name="description"
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg="3">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name={`price`}
                          value={formik.values.price}
                          placeholder="Price ($)"
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            // formik.handleChange(e.target.value.replace(/[^0-9]/g, ""))
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            formik.setFieldValue("price", numericValue);
                          }}
                          isInvalid={
                            formik?.touched?.price && !!formik?.errors?.price
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik?.errors?.price && formik?.touched?.price && (
                            <div>{formik?.errors?.price}</div>
                          )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name={`price`}
                          value={formik.values.price * 0.05}
                          placeholder="Price ($)"
                          // onChange={formik.handleChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg="8">
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name={`display_title`}
                          value={formik.values.display_title}
                          placeholder="Enter Session Title"
                          onChange={formik.handleChange}
                          isInvalid={
                            formik?.touched?.display_title &&
                            !!formik?.errors?.display_title
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik?.errors?.display_title &&
                            formik?.touched?.display_title && (
                              <div>{formik?.errors?.display_title}</div>
                            )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg="4">
                      <Form.Group className="mb-3" controlId="">
                        {/* <Form.Control
                          type="number"
                          name={`duration`}
                          value={formik.values.duration}
                          placeholder="Session Duration"
                          onChange={formik.handleChange}
                          isInvalid={
                            formik?.touched?.duration &&
                            !!formik?.errors?.duration
                          }
                        /> */}
                        <Select
                          defaultValue={selectedDuration}
                          onChange={setSelectedDuration}
                          options={allDurations}
                          placeholder="Select a Duration"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col lg="6">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            name={`session_title`}
                            placeholder="Program Name"
                            value={formik.values.session_title}
                            onChange={formik.handleChange}
                            isInvalid={
                              formik?.touched?.session_title &&
                              !!formik?.errors?.session_title
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik?.errors?.session_title &&
                              formik?.touched?.session_title && (
                                <div>{formik?.errors?.session_title}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg="6">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="number"
                            name={`duration`}
                            value={formik.values.duration}
                            placeholder="Program Duration(in Days)"
                            onChange={formik.handleChange}
                            isInvalid={
                              formik?.touched?.duration &&
                              !!formik?.errors?.duration
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik?.errors?.duration &&
                              formik?.touched?.duration && (
                                <div>{formik?.errors?.duration}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg="6">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="number"
                            name={`price`}
                            value={formik.values.price}
                            placeholder="Program price ($)"
                            onChange={formik.handleChange}
                            isInvalid={
                              formik?.touched?.price && !!formik?.errors?.price
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik?.errors?.price &&
                              formik?.touched?.price && (
                                <div>{formik?.errors?.price}</div>
                              )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    {dataArray?.map((i) => {
                      return (
                        <Row>
                          <Col lg="5">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control
                                type="text"
                                value={i?.session_name}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control
                                type="number"
                                value={i?.session_duration}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col className="mb-3" lg="1">
                            <Button
                              onClick={() => handleAddButtonDelete(i?.id)}
                              variant="danger"
                              className="px-4"
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                    <Row>
                      <Col lg="5">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            value={session_name}
                            onChange={handleInputChange1}
                            placeholder="Session title"
                            isInvalid={sessionNameError}
                          />
                          <Form.Control.Feedback type="invalid">
                            {sessionNameError && (
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="number"
                            placeholder="Duration(in minutes)"
                            value={session_duration}
                            onChange={handleInputChange2}
                            isInvalid={sessionDuration}
                          />
                          <Form.Control.Feedback type="invalid">
                            {sessionDuration && (
                              <span style={{ color: "red" }}>
                                This field is required.
                              </span>
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col className="mb-3" lg="1">
                        <Button
                          onClick={handleAddButtonClick}
                          variant="success"
                          className="px-4"
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                <Col sm="12">
                  <Form.Group className="text-center mb-4">
                    <Button type="submit" variant="success" className="px-4">
                      Add Program
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </>
          </Form>
          <Row className="mt-4">
            <Col lg="6">
              <Form.Label className="title">
                Benefits of you as coach
              </Form.Label>
              <ul className="bc_list">
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="1"
                    value={benefits?.["1"]}
                    onChange={handleBenefits}
                    placeholder="Benefits 1"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="2"
                    value={benefits?.["2"]}
                    onChange={handleBenefits}
                    placeholder="Benefits 2"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="3"
                    value={benefits?.["3"]}
                    onChange={handleBenefits}
                    placeholder="Benefits 3"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="4"
                    value={benefits?.["4"]}
                    onChange={handleBenefits}
                    placeholder="Benefits 4"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="5"
                    value={benefits?.["5"]}
                    onChange={handleBenefits}
                    placeholder="Benefits 5"
                  />
                </li>
              </ul>
            </Col>
            <Col lg="6">
              <Form.Label className="title">Clients you can coach</Form.Label>
              <ul className="bc_list">
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="1"
                    value={professions?.["1"]}
                    onChange={handleProfessions}
                    placeholder="Professions 1"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="2"
                    value={professions?.["2"]}
                    onChange={handleProfessions}
                    placeholder="Professions 2"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="3"
                    value={professions?.["3"]}
                    onChange={handleProfessions}
                    placeholder="Professions 3"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="4"
                    value={professions?.["4"]}
                    onChange={handleProfessions}
                    placeholder="Professions 4"
                  />
                </li>
                <li>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    rows={3}
                    name="5"
                    value={professions?.["5"]}
                    onChange={handleProfessions}
                    placeholder="Professions 5"
                  />
                </li>
              </ul>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="6">
              <div className="d-flex justify-content-start">
                <Form.Label className="title me-2">Skills</Form.Label>
                <span className="fs-14">
                  <OverlayTrigger placement="top" overlay={tooltipSkill}>
                    <Image src={excl_i} />
                  </OverlayTrigger>
                </span>
              </div>
              <div className="fs-14">
                <ReactTags
                  placeholder="Enter Your Skills"
                  tags={tags}
                  suggestions={skill}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  autocomplete
                  allowUnique={false}
                  renderSuggestion={renderSuggestion}
                  classNames={{
                    tags: "tagsClass",
                    tagInput: "tagInputClass",
                    tagInputField: "tagInputFieldClass",
                    selected: "selectedClass",
                    tag: "tagClass",
                    remove: "removeClass",
                    suggestions: "suggestionsClass",
                    activeSuggestion: "activeSuggestionClass",
                    editTagInput: "editTagInputClass",
                    editTagInputField: "editTagInputField",
                    clearAll: "clearAllClass",
                  }}
                />
              </div>
            </Col>
            <Col lg="6">
              <Form.Group className="mb-3" controlId="">
                <div className="d-flex justify-content-start">
                  <Form.Label className="title me-2">Tags</Form.Label>
                  <span>
                    <OverlayTrigger placement="top" overlay={tooltipTag}>
                      <Image src={excl_i} />
                    </OverlayTrigger>
                  </span>
                </div>
                <div className="react-tags-message fs-14">
                  <div className="tags_display d-flex flex-wrap mb-2">
                    {tagList.split(",").map((item) => (
                      <div className="tags_on_edit me-1">#{item} </div>

                      // <Button variant="outline-success" type="button" size="sm">
                      //   {item}
                      // </Button>
                    ))}
                  </div>
                  <Form.Control
                    onChange={onChangeTagList}
                    value={tagList}
                    as="textarea"
                    rows={3}
                    placeholder="Please input your tags, separated by commas."
                  />
                </div>
              </Form.Group>
            </Col>
            <Col sm="12">
              <div className="mt-3 d-flex justify-content-between">
                <Button
                  variant="outline-success"
                  className="px-4"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <div className="text-end">
                  {/* {window.innerWidth > 500 && ( */}
                  <Button
                    className="px-5"
                    variant="outline-success"
                    type="button"
                    onClick={() => onClickNext()}
                  >
                    Next
                  </Button>
                  {/* )} */}
                  <Button
                    className={window.innerWidth < 500 ? "px-3" : "px-5 m-2"}
                    variant="success"
                    onClick={onClickSave}
                  >
                    {isLoading ? <Loader /> : "Save & Continue"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        visible={visible}
        onClickAway={() => setVisible(false)}
        width="500px"
        height="250px"
        effect="fadeInUp"
      >
        <Row>
          <Col>
            <div className="modal" style={{ color: "black" }}>
              <h3
                classname="changeColor"
                style={{ color: "black", marginLeft: "13px", zIndex: "99999" }}
              >
                Are You sure you want to delete ?
              </h3>

              <Row>
                <Col>
                  <br />
                  <Button
                    style={{
                      margin: "10px",
                      backgroundColor: "#00504E",
                      color: "#fff",
                      textAlign: "center",
                    }}
                    onClick={() => onRemoveProgram(deleteId)}
                  >
                    <h5 style={{ margin: "auto" }}> Yes </h5>
                  </Button>
                  <Button
                    style={{
                      margin: "10px",
                      marginLeft: "4px",
                      // backgroundColor: "#00504E",
                      border: "1px solid #00504E",
                      color: "#111",
                      textAlign: "center",
                    }}
                    onClick={() => setVisible(false)}
                  >
                    <h5 style={{ margin: "auto" }}>No </h5>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CoachProfileCoachingDetailsComponent;
