import React, { useState, useEffect } from "react"
import { Outlet } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import logo from '../assets/images/logo_dark.png';
import { NavLink } from 'react-router-dom';
import HomeFooterComponent from "./Common/Footer";
import classNames from 'classnames';
import LoginComponent from './Pages/Login/newindex'
import HomeLayoutMenuList from "./Common/Header/MenuList";
import HomeHeaderOneComponent from "./Common/Header/HeaderOne";
import HomeHeaderTwoComponent from "./Common/Header/HeaderTwo";
import HomeHeaderThreeComponent from "./Common/Header/HeaderThree";
import HomeHeaderFourComponent from "./Common/Header/HeaderFour";
import HomeHeaderFiveComponent from "./Common/Header/HeaderFive";
import HomeHeaderSixComponent from "./Common/Header/HeaderSix";
import HomeHeaderSevenComponent from "./Common/Header/HeaderSeven";
import HomeHeaderEightComponent from "./Common/Header/HeaderEight";
import HomeHeaderNineComponent from "./Common/Header/HeaderNine";

function HomeLayoutComponent(props) {
    const [scroll, setScroll] = useState(false)
    const [isShowMenues, setisShowMenues] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            // setScroll(window.scrollY > 100)
            setScroll(window.scrollY > 0)
        })
    }, [])

    const onClickToggle = () => {
        setisShowMenues(!isShowMenues)
    }

    return (
        <>
            <div className={classNames({
                'common_hdr_style main_header_fixed scrolled': scroll,
                'common_hdr_style main_header': !scroll,
                'common_hdr_style main_header_fixed scrolled hdr_eight_show': isShowMenues && scroll
            })}>
                <div className="my_menu hdr_one"><HomeHeaderOneComponent /></div>
                <div className="my_menu hdr_four"><HomeHeaderFourComponent onClickToggle={onClickToggle} /></div>
                {/* <div className="my_menu hdr_five"><HomeHeaderFiveComponent /></div>
                <div className="my_menu hdr_six"><HomeHeaderSixComponent /></div>
                <div className="my_menu hdr_seven"><HomeHeaderSevenComponent /></div> */}
                <div className="my_menu hdr_eight"><HomeHeaderEightComponent onClickToggle={onClickToggle} /></div>
                <div className="my_menu hdr_nine"><HomeHeaderNineComponent /></div>
            </div>
            <div className={scroll ? "burger_header_scroll scrolled" : "burger_header"}>
                {/* <HomeHeaderOffcanvasComponent/> */}
                {/* burger_menu_fixed_top */}
            </div>
            <Outlet />
            <HomeFooterComponent />
        </>
    )
}

export default HomeLayoutComponent;