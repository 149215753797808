import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import earning from "../../../assets/images/icons/earning.png";
import {
  addNewBankDetails,
  getBankAccountList,
  myEarnings,
} from "../../../utils/api";
import Modal from "react-awesome-modal";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import noDataLottie from "../../../assets/lottieFiles/noData.json";

const CoachEarningComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDataLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [totalEarnings, setTotalEarnings] = useState(0);
  const [showBankModal, setShowBankModal] = useState(false);
  const [bank_name, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);

  const getAllEarnings = async () => {
    try {
      const data = await myEarnings();
      setTotalEarnings(data.data.earning_amount);

      const bankData = await getBankAccountList();
      //   console.log(bankData);
      setBankDetails(bankData.data.data[0]);
    } catch (error) {
      console.log(error);
    }
    // console.log(data);
  };

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    
    getAllEarnings();
  }, []);

  const onSubmitHandler = async () => {
    try {
      const payload = {
        account_holder_name: accountHolder,
        account_number: accountNumber,
        ifsc_code: ifscCode,
        bank_name: bank_name,
      };

      const data = await addNewBankDetails(payload);
      if (data) {
        setShowBankModal(false);
        toast.success("Bank Detaisl added!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="">
        <div className="qoach_user_dark_bg_card">
          <Container>
            <Row className="align-items-center">
              <Col>
                <div className="d-flex align-items-center">
                  <img width="60" src={earning} alt="images" />
                  <div className="text-white ms-2">
                    <h4 className="fs-40 fw-600 m-0"> {totalEarnings} </h4>
                    <h6 className="fs-12 fw-500 m-0">Earning</h6>
                  </div>
                </div>
              </Col>
              <Col sm="auto">
                <Button variant="" className="text-white">
                  Bank Details
                </Button>
                <Button
                  className="px-5"
                  variant="success"
                  onClick={() => {
                    setShowBankModal(true);
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        {bankDetails !== null ? (
          <Container>
            <Row className="align-items-center">
              <Col>
                <div className="d-flex align-items-center">
                  <div className="text-dark mt-2">
                    <h4 className="fs-20 fw-500 m-0">
                      {" "}
                      Bank Name: {bankDetails.bank_name}{" "}
                    </h4>
                    <h4 className="fs-20 fw-500 m-0">
                      {" "}
                      Account Holder Name: {
                        bankDetails.account_holder_name
                      }{" "}
                    </h4>
                    <h4 className="fs-20 fw-500 m-0">
                      {" "}
                      Account Number: {bankDetails.account_number}{" "}
                    </h4>
                    <h4 className="fs-20 fw-500 m-0">
                      {" "}
                      IFSC Code: {bankDetails.ifsc_code}{" "}
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Col>
            <Lottie
              options={defaultOptions}
              height={300}
              width={400}
              isStopped={false}
              isPaused={false}
            />
          </Col>
        )}
      </section>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        visible={showBankModal}
        width="400"
        height="450"
        effect="fadeInUp"
        onClickAway={() => setShowBankModal(false)}
      >
        <div className="streaming-create-modal">
          <h4 className="mx-5"> Add Bank Account </h4>
          <div className="row">
            <div className="col-md-11 ps-5">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Holder Name"
                  value={accountHolder}
                  onChange={(e) => setAccountHolder(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-11 ps-5">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bank Name"
                  value={bank_name}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-11 ps-5">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-11 ps-5">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="IFSC Code"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 text-center">
              <button
                className="px-5 btn primary-button"
                onClick={() => {
                  onSubmitHandler();
                }}
              >
                {isLoading ? <Loader /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CoachEarningComponent;
