import React, { useEffect, useState } from "react";
import "../../../assets/scss/_Header.scss";
import logo from "../../../assets/images/logo_dark.png";
import { NavLink } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Dropdown,
  Modal,
} from "react-bootstrap";
import LoginComponent from "../../Pages/Login/index";
import send_img from "../../../assets/images/icons/send.png";
import Pic from "../../../assets/images/icons/user.png";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../utils/hooks/UserContext";
// import Modal from "react-awesome-modal";

const HomeHeaderFourComponent = ({ onClickToggle }) => {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const { user, handleLogout } = useUserContext();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [typingText, setTypingText] = React.useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    console.log("inputValue", inputValue);
  };

  const getImage = (image) => {
    if (image && user?.userType === "coach") {
      return `${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${image}`;
    }

    if (image && user?.userType === "user") {
      return `${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${image}`;
    }

    return Pic;
  };

  const getURL = () => {
    if (user?.userType === "user") {
      return `/user/dashboard`;
    }
    return `/coach`;
  };

  const onSelect = (code) => {
    if (code === "0") {
      navigate(getURL());
    }

    if (code === "1") {
      handleLogout();
    }
  };

  useEffect(() => {
    
    // const speed = 200; // 2 seconds delay
    // const text = 'I am not able to scale up my IT business. How to generate leads and increase reven...........................\nWrite your problem';
    // const myArray = text.split("");
    // myArray.forEach(element => {
    //   setTypingText(element)
    // });
    // const typeWithDelay = async () => {
    //   const fullText = myArray.join('');
    //   for (let i = 0; i < fullText.length; i++) {
    //     setTypingText(fullText.substring(0, i + 1));
    //     await new Promise((resolve) => setTimeout(resolve, speed));
    //   }
    // };
    // typeWithDelay();
    try {
      const speed = 200;
      // const text = 's. Please Suggest A Coach. \nPlease write your problem here';
      const text =
        "I am not able to scale up my IT business. How to generate leads and increase Sales. Please Suggest A Coach. \nPlease write your problem here";
      const myArray = text.split("");
      const fullText = myArray.join("");

      let isMounted = true;

      const typeWithDelay = async () => {
        while (isMounted) {
          for (let i = 0; i < fullText.length && isMounted; i++) {
            setTypingText(fullText.substring(0, i + 1));
            await new Promise((resolve) => setTimeout(resolve, speed));
          }
          await new Promise((resolve) => setTimeout(resolve, speed * 5)); // Wait before resetting
          setTypingText("");
        }
      };

      typeWithDelay();

      return () => {
        isMounted = false;
      };
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onHide = () => {
    setModalOpen(false);
  };

  const openSite = (e) => {
    e.preventDefault();
    if (password === "Admin@123") {
      localStorage.setItem("websiteDisplay", "true");
      setModalOpen(false);
      if (localStorage.getItem("websiteDisplay") === "true") {
        // toast.success('Login successfully');
        // window.location.reload();
        navigate(0);
      } else {
        console.error('Unexpected localStorage value for "websiteDisplay"');
      }
    } else {
      // toast.error('Incorrect password');
    }
  };

  return (
    <>
      <Navbar sticky="top">
        <Container className="user_container">
          <Col className="col-auto">
            <NavLink className="navbar-brand" to="/">
              <img src={logo} />
            </NavLink>
          </Col>
          <Col md="5">
            <InputGroup className="">
              {/* <Form.Control className='fs-14' as="textarea" type='text' placeholder='I am not able to scale up my IT business. How to generate leads and increase reven...........................' value={inputValue} */}
              <Form.Control
                className="fs-14"
                as="textarea"
                type="text"
                placeholder={typingText}
                value={inputValue}
                onChange={handleInputChange}
              />
              <Button
                variant="outline-secondary"
                id="button-addon1"
                onClick={handleButtonClick}
              >
                <img src={send_img} />
              </Button>
            </InputGroup>
          </Col>
          <Col className="col-auto">
            <Nav className="justify-content-end flex-grow-1 align-items-center">
              <button
                className="navbar_toggler"
                type="button"
                onClick={onClickToggle}
              >
                <span className=""></span>
                <span className=""></span>
                <span className=""></span>
              </button>
              {user && (
                <Dropdown
                  onSelect={onSelect}
                  className="logged_user pt-0"
                  align="end"
                >
                  <Dropdown.Toggle
                    className="py-0"
                    variant=""
                    id="dropdown-basic"
                  >
                    <img src={getImage(user?.profile_pic)} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="0">
                      <img src={getImage(user?.profile_pic)} alt="" /> Profile
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_3735_6573)">
                          <path
                            d="M9.56333 12.5C9.34232 12.5 9.13036 12.5878 8.97408 12.7441C8.8178 12.9004 8.73 13.1123 8.73 13.3333V15.8333C8.73 16.4964 8.46661 17.1323 7.99777 17.6011C7.52893 18.0699 6.89304 18.3333 6.23 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667H6.23C6.89304 1.66667 7.52893 1.93006 7.99777 2.3989C8.46661 2.86774 8.73 3.50363 8.73 4.16667V6.66667C8.73 6.88768 8.8178 7.09964 8.97408 7.25592C9.13036 7.4122 9.34232 7.5 9.56333 7.5C9.78435 7.5 9.99631 7.4122 10.1526 7.25592C10.3089 7.09964 10.3967 6.88768 10.3967 6.66667V4.16667C10.3953 3.062 9.95593 2.00296 9.17482 1.22185C8.3937 0.440735 7.33466 0.00132321 6.23 0H4.16667C3.062 0.00132321 2.00296 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H6.23C7.33466 19.9987 8.3937 19.5593 9.17482 18.7782C9.95593 17.997 10.3953 16.938 10.3967 15.8333V13.3333C10.3967 13.1123 10.3089 12.9004 10.1526 12.7441C9.99631 12.5878 9.78435 12.5 9.56333 12.5Z"
                            fill="#14183E"
                          />
                          <path
                            d="M19.0542 8.23232L15.2325 4.41066C15.1557 4.33107 15.0637 4.26758 14.962 4.22391C14.8604 4.18023 14.751 4.15724 14.6404 4.15628C14.5297 4.15532 14.42 4.1764 14.3176 4.21831C14.2152 4.26021 14.1221 4.32208 14.0439 4.40033C13.9656 4.47857 13.9038 4.57161 13.8619 4.67403C13.82 4.77644 13.7989 4.88618 13.7998 4.99682C13.8008 5.10747 13.8238 5.21682 13.8675 5.31849C13.9111 5.42016 13.9746 5.51212 14.0542 5.58899L17.6059 9.14149L4.99837 9.16649C4.77736 9.16649 4.5654 9.25429 4.40912 9.41057C4.25284 9.56685 4.16504 9.77881 4.16504 9.99982C4.16504 10.2208 4.25284 10.4328 4.40912 10.5891C4.5654 10.7454 4.77736 10.8332 4.99837 10.8332L17.655 10.8073L14.0525 14.4107C13.9729 14.4875 13.9095 14.5795 13.8658 14.6812C13.8221 14.7828 13.7991 14.8922 13.7982 15.0028C13.7972 15.1135 13.8183 15.2232 13.8602 15.3256C13.9021 15.428 13.964 15.5211 14.0422 15.5993C14.1205 15.6776 14.2135 15.7394 14.3159 15.7813C14.4183 15.8232 14.5281 15.8443 14.6387 15.8434C14.7494 15.8424 14.8587 15.8194 14.9604 15.7757C15.062 15.7321 15.154 15.6686 15.2309 15.589L19.0525 11.7673C19.5214 11.2987 19.785 10.6631 19.7853 10.0002C19.7857 9.33726 19.5227 8.70136 19.0542 8.23232Z"
                            fill="#14183E"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3735_6573">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* {!user && <Button className='ms-3' size="sm" variant='outline-success' onClick={() => setLoginModalShow(true)}>Login</Button>} */}
              {!user && (
                <NavLink
                  className="nav-link login_btn"
                  onClick={() => {
                    console.log(
                      "string",
                      localStorage.getItem("websiteDisplay")
                    );
                    if (localStorage.getItem("websiteDisplay") == null) {
                      setModalOpen(true);
                      console.log("inside");
                    } else {
                      setLoginModalShow(true);
                    }
                  }}
                >
                  Login
                </NavLink>
              )}
            </Nav>
          </Col>
        </Container>
      </Navbar>

      <LoginComponent
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />

      <Modal
        onHide={() => onHide()}
        show={modalOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // visible={modalOpen}
        // onClickAway={() => setModalOpen(false)}
        // height="500"
        className="shortvideo-model h-90 my_light_modal default-popup"
      >
        <div className="brand_bg_color1 my_light_modal">
          <div className="ls_modal_conatiner p-4">
            <div class="hdng_a text-start">
              <h6 class="text-uppercase">Login</h6>
              <h3 class="my-3 fw-700" style={{ fontFamily: "volkhov" }}>
                Beta Version - Login for Access this site
              </h3>
            </div>
            <form class="px-lg-4">
              <div class="mb-3 mt-3">
                <input
                  name="password"
                  placeholder="Your Password"
                  type="password"
                  id="password"
                  class="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div class="invalid-feedback">Password is required</div>
              </div>
              <div>
                <button
                  onClick={(e) => openSite(e)}
                  className="btn btn-success"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HomeHeaderFourComponent;
