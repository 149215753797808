import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { coachUpdate } from "../../../utils/api";
import { Formik } from "formik";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  pwd: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z](?=.*[0-9])/, "Password Should be alphanumeric"),
  newPassword: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z](?=.*[0-9])/, "Password Should be alphanumeric"),
});

const UserSettingComponent = (props) => {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmitHandler = async (values) => {
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("password", values.pwd);
      formData.append("new_password", values.newPassword);

      const { data } = await coachUpdate(formData);

      if (data?.status) {
        toast.success(data?.message);
        navigate("/");
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      <section {...props}>
        <div className="text-start hdng_a">
          <h2>Change Password</h2>
        </div>
        <Row className="mt-5">
          <Col lg="6 m-auto">
            <Formik
              initialValues={{ pwd: "", newPassword: "", userType: "" }}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="old_password">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup className="password_vh mb-3">
                      <Form.Control
                        placeholder="xyzabe@123"
                        name="pwd"
                        type={isRevealPwd ? "text" : "password"}
                        value={values.pwd}
                        onChange={handleChange}
                        isInvalid={touched.pwd && !!errors.pwd}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <img
                          alt=""
                          width="20"
                          title={
                            isRevealPwd ? "Hide password" : "Show password"
                          }
                          src={isRevealPwd ? view : hidden}
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {errors.pwd}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="new_password">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup className="password_vh mb-3">
                      <Form.Control
                        placeholder="xyzabe@123"
                        name="pwdConfirmation"
                        type={isRevealConfirmPwd ? "text" : "password"}
                        value={values.pwdConfirmation}
                        onChange={handleChange}
                        isInvalid={
                          touched.pwdConfirmation && !!errors.pwdConfirmation
                        }
                      />
                      <InputGroup.Text id="basic-addon2">
                        <img
                          alt=""
                          width="20"
                          title={
                            isRevealConfirmPwd
                              ? "Hide password"
                              : "Show password"
                          }
                          src={isRevealConfirmPwd ? view : hidden}
                          onClick={ () =>setIsRevealConfirmPwd(!isRevealConfirmPwd)}
                        />
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="d-grid">
                    <Button
                      disabled={isLoading}
                      variant="success"
                      className=""
                      type="submit"
                    >
                      Update password
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default UserSettingComponent;
