import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import live_session_img1 from '../../../assets/images/dummy/live_session_img1.png';
import dummy_coach1_sqr from '../../../assets/images/dummy/dummy_coach1_sqr.png';
import mental_coach from '../../../assets/images/dummy/mental_coach.png';
import { NavLink } from "react-router-dom";
import BlogModalComponent from "./blog_details";
import { getAllBlogs, allCategories, allAuthor } from "../../../utils/api";
import send_wht from "../../../assets/images/icons/send_wht.png";
import quote_grn from '../../../assets/images/icons/quote_grn.png';
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";
// import { allCategories } from './../../../utils/api/index';
import { PulseLoader } from "react-spinners";
import { Helmet } from "react-helmet";

function BlogComponent() {
    var count = 0;
    var counter = 0;
    const [allBlogs, setAllBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [alltestimonial, setTestimonials] = useState([]);
    const [allSelectedCate, setAllSelectedCate] = useState([]);
    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    const [popularBlogs, setPopularBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectAuthors, setSelectAuthors] = useState([]);
    const [authorList, setAuthorList] = useState([]);
    const [loading, setLoading] = useState(true);

    function getDate(time) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Octr",
            "Nov",
            "Dec",
        ];

        const date = new Date(time).getDate();
        const year = new Date(time).getFullYear();
        const month = months[new Date(time).getMonth()];

        let hour = new Date(time).getHours();
        let minute = new Date(time).getMinutes();

        const am_pm = hour > 12 ? "PM" : "AM";
        const time_string = `${date} ${month} ${year}`;
        return time_string;
    }

    useEffect(() => {

        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);

        getData();
        window.scrollTo(0, 0);
    }, [])

    const getData = async () => {
        // const data = await getAllBlogs();
        // if (data.data) {
        //     if (data.data.data) {
        //         setAllBlogs(data.data.data.all)
        //         setLatestBlogs(data.data.data.latest)
        //         setFeaturedBlogs(data.data.data.featured)
        //         setPopularBlogs(data.data.data.popular)
        //     }
        //     setTestimonials(data.data.testimonial)
        // }
        let allSelectedCate = "";
        filterCate(allSelectedCate);
        const allcat = await allCategories();
        if (allcat) {
            console.log(allcat.data.data);
            setCategories(allcat.data.data)
        }
        const allAuthors = await allAuthor();
        if (allAuthors) {
            setAuthorList(allAuthors.data.data);
            console.log(allAuthors.data.data, 'allAuthor');
        }
        setLoading(false)
    }

    const [blogModalShow, setBlogModalShow] = React.useState(false);
    const [singleBlogDetails, setSingleBlogDetails] = React.useState('');
    console.log("alltestimonial", alltestimonial)
    const showModal = (e, blog) => {
        setBlogModalShow(true)
        setSingleBlogDetails(blog)
    }

    const removeHTMLTags = (str) => {
        return str.replace(/<[^>]+>/g, ''); // Remove HTML tags
    };
    const defaultUserImage = (e) => {
        // e.target.src = 'https://api.vaffix.com/uploads/images/avatar_dummy.webp';
        e.target.src = blog_card_img1;
    }
    const options = {
        items: 1,
        // nav: true,
        // navText: ["<span className='owl_nav_btn prev_slide'>Previous</span>", "<span className='owl_nav_btn next_slide'>Next</span>"],
        rewind: true,
        // autoplay: true,
        dots: true,
        dotsEach: true,
        dotData: true
    };
    const defaultImage = (e) => {
        e.target.src = 'https://nadmin.qoach.io/assets/images/blog.png';
    }
    const selectCate = (e) => {
        const index = allSelectedCate.indexOf(e.target.value);
        if (index !== -1) {
            allSelectedCate.splice(index, 1);
        } else {
            allSelectedCate.push(e.target.value);
        }
        console.log(allSelectedCate);
        filterCate(allSelectedCate, 'category');
        setAllSelectedCate(allSelectedCate);
    }
    const selectAuthor = (e) => {
        filterCate(e.target.value, 'author');
    }
    const filterCate = async (list, type) => {
        const formData = new FormData();
        // let cat = "";
        if (list !== "") {
            if (type === 'category') {
                const modifiedStrings = list.map(str => str.replace('cate', ''));
                const commaSeparatedString = modifiedStrings.join(',');
                formData.append('categories', commaSeparatedString)
            } else {
                const modifiedStrings = allSelectedCate.map(str => str.replace('cate', ''));
                const commaSeparatedString = modifiedStrings.join(',');
                formData.append('categories', commaSeparatedString)
            }
            if (type === "author") {
                formData.append('author', list)
            } else {
                formData.append('author', selectAuthors)
            }
        }
        const data = await getAllBlogs(formData);
        if (data.data) {
            if (data.data.data) {
                setAllBlogs(data.data.data.all)
                setLatestBlogs(data.data.data.latest)
                setFeaturedBlogs(data.data.data.featured)
                setPopularBlogs(data.data.data.popular)
            }
            setTestimonials(data.data.testimonial)
        }
    }
    const scrollLeft = () => {
        const container = document.getElementById('filterScroll');
        container.scrollLeft -= 100;
    }
    const scrollRight = () => {
        const container = document.getElementById('filterScroll');
        container.scrollLeft += 100;
    }
    return (
        <>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                title="Explore Insights and Wisdom | Qoach Blog Hub"
                meta={[
                    { name: 'description', content: "Discover a treasure trove of insights and wisdom from our expert coaches. Dive into our blog hub to gain valuable knowledge and inspiration on personal growth, career advancement, wellness, and more. Start your journey of discovery with Qoach today!" },
                ]}
            />
            {loading ? (
                // <PulseLoader color="#36D7B7" loading={loading} size={15} />
                <div
                    style={{
                        // textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <p>
                        <PulseLoader color="#00504E" size={20} />
                    </p>
                </div>
            ) : (
                <>
                    {/* <section className="brand_bg_color4 py-5">
                      <Container>
                          <Row className="justify-content-center">
                              <Col lg="12">
                                  <div className="text-white hdng_a poppins_fnt">
                                      <h6 className="text-white text-uppercase">NEWS & COMMUNITY</h6>
                                      <h2 className="text-white">Latest Blogs</h2>
                                      <p className="fs-16 fw-400 text_white_color_half m-0">Get Inspired: Explore What Our Coaches Have to Say in Their Latest Blogs</p>
                                  </div>
                              </Col>
                          </Row>
                      </Container>
                  </section> */}
                    <section className="blog-filter my-5">
                        <Container>
                            <Row>
                                <div className="col-12">
                                    <div className="d-flex align-items-start justify-content-between filter-buttons">
                                        {/* <div className="d-flex align-items-start justify-content-between cate-filter"> */}
                                        <button onClick={scrollLeft} className="prev-btn bg-transparent ps-2 pe-4">
                                            <svg width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.1795 3.26875C15.7889 2.87823 15.1558 2.87823 14.7652 3.26875L8.12078 9.91322C6.94952 11.0845 6.94916 12.9833 8.11996 14.155L14.6903 20.7304C15.0808 21.121 15.714 21.121 16.1045 20.7304C16.495 20.3399 16.495 19.7067 16.1045 19.3162L9.53246 12.7442C9.14194 12.3536 9.14194 11.7205 9.53246 11.33L16.1795 4.68297C16.57 4.29244 16.57 3.65928 16.1795 3.26875Z" fill="#964b00" />
                                            </svg>
                                        </button>
                                        <div className="d-flex overflow-x-scroll mx-2" id="filterScroll">
                                            {/* <div>
                                                      <input type="checkbox" className="d-none" id="cate1" name="cate1" value="cate1" onClick={(e) => selectCate(e)} />
                                                      <label for="cate1" className={`me-2 ${allSelectedCate.includes("cate1") === true ? 'active' : 'checkbox-btn'}`} > Cate 1</label>
                                                  </div>
                                                  <div>
                                                      <input type="checkbox" className="d-none" id="cate2" name="cate2" value="cate2" onClick={(e) => selectCate(e)} />
                                                      <label for="cate2" className={`me-2 ${allSelectedCate.includes("cate2") === true ? 'active' : 'checkbox-btn'}`} > Cate 2</label>
                                                  </div> */}
                                            {
                                                categories && categories.length > 0 ?
                                                    categories.map((post) => (
                                                        <div>
                                                            <input type="checkbox" className="d-none" id={`cate` + post.id} name={`cate` + post.id} value={`cate` + post.id} onClick={(e) => selectCate(e)} />
                                                            {
                                                                allSelectedCate.includes(`cate` + post.id) === true ?
                                                                    <>
                                                                        <label for={`cate` + post.id} className='me-2 active' >{post.name}</label>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <label for={`cate` + post.id} className='me-2 checkbox-btn'>{post.name}</label>
                                                                    </>
                                                            }
                                                            {/* // <label for={`cate` + post.id} className={`me-2 ${allSelectedCate.includes(`cate` + post.id) === true ? 'active' : 'checkbox-btn'}`} >{post.name}</label> */}
                                                        </div>
                                                    ))
                                                    : ''
                                            }
                                        </div>
                                        <button onClick={scrollRight} className="next-btn bg-transparent ps-2 pe-4">
                                            <svg width="20px" height="20px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.82054 20.7313C8.21107 21.1218 8.84423 21.1218 9.23476 20.7313L15.8792 14.0868C17.0505 12.9155 17.0508 11.0167 15.88 9.84497L9.3097 3.26958C8.91918 2.87905 8.28601 2.87905 7.89549 3.26958C7.50497 3.6601 7.50497 4.29327 7.89549 4.68379L14.4675 11.2558C14.8581 11.6464 14.8581 12.2795 14.4675 12.67L7.82054 19.317C7.43002 19.7076 7.43002 20.3407 7.82054 20.7313Z" fill="#964b00" />
                                            </svg>
                                        </button>
                                        {/* </div> */}
                                        <div className="ms-2 px-3 rounded auther-filter d-block">
                                            <select
                                                name="author"
                                                id="author"
                                                className="bg-transparent border-0"
                                                value={selectAuthors}
                                                onChange={(e) => setSelectAuthors(e.target.value)}
                                                onClick={(e) => selectAuthor(e)}
                                            >
                                                <option value="">Select Author</option>
                                                {authorList.map((element) => (
                                                    <option key={element.id} value={element.id}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-4 select-auther-filter d-none">
                                        <div className="ms-2 px-3 rounded auther-filter d-inline">
                                            <select
                                                name="author"
                                                id="author"
                                                className="bg-transparent border-0 d-inline"
                                                value={selectAuthors}
                                                onChange={(e) => setSelectAuthors(e.target.value)}
                                                onClick={(e) => selectAuthor(e)}
                                            >
                                                <option value="">Select Author</option>
                                                {authorList.map((element) => (
                                                    <option key={element.id} value={element.id}>
                                                        {element.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </section>
                    {
                        featuredBlogs ? featuredBlogs.length > 0 ?
                            <section className="blog_individual">
                                <Container>
                                    <OwlCarousel className="owl-theme"  {...options}>
                                        {
                                            featuredBlogs.map((blog, index) => {
                                                // if (counter < 3 && blog.is_featured == 'Yes') {
                                                console.log("ddddd", counter, blog.is_featured)

                                                counter = index + 1
                                                blog.content = (blog.content).replace(/<[^>]+>/g, '')
                                                blog.content = (blog.content).replace(/&nbsp;/g, ' ');
                                                return (
                                                    <>
                                                        <Row className="g-5 align-items-center">

                                                            <Col lg="5">

                                                                <div className="item">
                                                                    <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>
                                                                        <div className="latest_blog_sldr_card py-4">
                                                                            <Row className="justify-content-between mb-3">
                                                                                <Col>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="">
                                                                                            {blog.profile_pic ? (
                                                                                                <img src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`} alt="" onError={(e) => defaultUserImage(e)} />
                                                                                            ) : (
                                                                                                <img src={dummy_coach1_sqr} alt="" />
                                                                                            )}
                                                                                        </div>
                                                                                        <span className="ms-2 fs-15 fw-600">{blog.name}</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="col-auto">
                                                                                    <span className="ms-2 fs-15 fw-400">{new Date(blog.created_at).toLocaleDateString('en-IN', {
                                                                                        year: 'numeric',
                                                                                        month: 'short',
                                                                                        day: 'numeric'
                                                                                    })}
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                            <h4 className="fs-18 m-0">{blog.title}</h4>
                                                                            <p className="fs-14 fw-300 opacity_7 mb-3">
                                                                                {/* {blog.content.substring(0, 125)}... */}
                                                                                {/* {removeHTMLTags(blog.content).substring(0, 125)}... */}
                                                                                {removeHTMLTags(blog.content)}
                                                                            </p>
                                                                            <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>Read More...</NavLink>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>

                                                            </Col>
                                                            <Col lg="7" className="m-0">
                                                                {/* <img className="w-100" src={mental_coach} alt="" /> */}
                                                                <div className="overflow-hidden" style={{ height: '400px' }}>

                                                                    {blog.image ? (
                                                                        <img alt="" className="w-100 object-fit-cover" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} onError={(e) => defaultImage(e)} height="400px" />
                                                                    ) : (
                                                                        <img alt="" className="w-100 object-fit-cover" src={blog_card_img1} onError={(e) => defaultImage(e)} height="400px" />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )
                                                // }
                                            })
                                            // : ''
                                        }


                                        {/* <div className="item">
                                          <div className="latest_blog_sldr_card">
                                              <Row className="justify-content-between mb-3">
                                                  <Col>
                                                      <div className="d-flex align-items-center">
                                                          <div className="">
                                                              <img width={30} src={dummy_coach1_sqr} alt="" />
                                                          </div>
                                                          <span className="ms-2 fs-15 fw-600">Jack Mick</span>
                                                      </div>
                                                  </Col>
                                                  <Col className="col-auto">
                                                      <span className="ms-2 fs-15 fw-400">19 Jan 2022</span>
                                                  </Col>
                                              </Row>
                                              <h4 className="">How one Qoach user grew his single individual consultancy from $0-100K in 14 months</h4>
                                          </div>
                                      </div>
      
                                      <div className="item">
                                          <div className="latest_blog_sldr_card">
                                              <Row className="justify-content-between mb-3">
                                                  <Col>
                                                      <div className="d-flex align-items-center">
                                                          <div className="">
                                                              <img width={30} src={dummy_coach1_sqr} alt="" />
                                                          </div>
                                                          <span className="ms-2 fs-15 fw-600">Jack Mick</span>
                                                      </div>
                                                  </Col>
                                                  <Col className="col-auto">
                                                      <span className="ms-2 fs-15 fw-400">19 Jan 2022</span>
                                                  </Col>
                                              </Row>
                                              <h4 className="">How one Qoach user grew his single individual consultancy from $0-100K in 14 months</h4>
                                          </div>
                                      </div> */}
                                    </OwlCarousel>
                                </Container>
                            </section>
                            : ''
                            : ''
                    }
                    {
                        latestBlogs ? latestBlogs.length > 0 ?
                            <section>
                                <Container>
                                    <hr />
                                    <Row className="blogs_hdng">
                                        <Col>
                                            <h4>Latest</h4>
                                        </Col>
                                        {/* <Col className="col-auto">
                                      <NavLink to={'/latest-blogs'}>View All</NavLink>
      
                                  </Col> */}
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        {
                                            latestBlogs.map((blog, index) => {
                                                var l_count = index + 1;
                                                if (l_count < 7) {
                                                    blog.content = (blog.content).replace(/<[^>]+>/g, '')
                                                    blog.content = (blog.content).replace(/&nbsp;/g, ' ');


                                                    return (
                                                        // <Col lg="4" sm="6">
                                                        //     <div className="latest_blog_card">
                                                        //         <div className="latest_blog_card_header">
                                                        //             <Row className="align-items-center">
                                                        //                 <Col>
                                                        //                     <div className="d-flex align-items-center">
                                                        //                         <div className="">
                                                        //                             {blog.profile_pic ? (
                                                        //                                 <img src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`} alt="" />
                                                        //                             ) : (
                                                        //                                 <img src={live_session_img1} alt="" />
                                                        //                             )}
                                                        //                         </div>
                                                        //                         <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                        //                     </div>
                                                        //                 </Col>
                                                        //                 <Col className="col-auto">
                                                        //                     <span className="fs-14 fw-400">{new Date(blog.created_at).toLocaleDateString('en-IN', {
                                                        //                         year: 'numeric',
                                                        //                         month: 'short',
                                                        //                         day: 'numeric'
                                                        //                     })}
                                                        //                     </span>
                                                        //                 </Col>
                                                        //             </Row>

                                                        //         </div>
                                                        //         <img className="latest_blog_img" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt=""  onError={(e) => defaultImage(e) } />

                                                        //         <div className="latest_blog_card_body">
                                                        //             <h5 className="fs-14 fw-600">{blog.title}</h5>
                                                        //             <p>{(blog.content).substring(0, 150)}</p>
                                                        //             <NavLink to={`/blog-details/${blog.slug}`}>Read More</NavLink>

                                                        //         </div>
                                                        //     </div>
                                                        // </Col>
                                                        <Col sm="6" lg="4" className="mb-4">
                                                            <div className="blog_card border rounded-4 h-100 overflow-hidden">
                                                                <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>
                                                                    <div className="blog_card_img">
                                                                        {blog.image ? (
                                                                            <img alt="" className="w-100" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}`} onError={(e) => defaultImage(e)} />
                                                                        ) : (
                                                                            <img alt="" className="w-100" src={blog_card_img1} onError={(e) => defaultImage(e)} />
                                                                        )}
                                                                    </div>
                                                                    <div className="blog_card_body px-3">
                                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                                            <div className="d-flex align-items-center">
                                                                                {blog.profile_pic ? (
                                                                                    <img
                                                                                        width={30}
                                                                                        height={30}
                                                                                        alt=""
                                                                                        className="user_img rounded-circle me-2"
                                                                                        src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`}
                                                                                        style={{ borderRadius: '50%' }}
                                                                                        onError={(e) => defaultUserImage(e)}
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        width={30}
                                                                                        height={30}
                                                                                        alt=""
                                                                                        className="user_img me-2"
                                                                                        src={testimonials_img1}
                                                                                    />
                                                                                )}

                                                                                <h5 className="m-0 fs-18">{blog.name ? blog.name : 'Jack Mick'}</h5>
                                                                            </div>

                                                                            <span className="fs-12 fw-500 opacity_7">
                                                                                {getDate(
                                                                                    blog.blog_date !== null
                                                                                        ? blog.blog_date
                                                                                        : blog.updated_at
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines"> {blog?.title} </h5>

                                                                        <p className="fs-14 fw-300 opacity_7 mb-3">
                                                                            {/* {blog.content.substring(0, 125)}... */}
                                                                            {/* {removeHTMLTags(blog.content).substring(0, 125)}... */}
                                                                            {removeHTMLTags(blog.content)}
                                                                        </p>

                                                                        {/* <NavLink className="fs-16 fw-500 d-block">
                                                                  Read More
                                                              </NavLink> */}
                                                                        {/* <NavLink className="text_brand_color8 fw-500" onClick={(e) => showModal(e, blog)}>Read More</NavLink> */}
                                                                        <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>Read More</NavLink>
                                                                        {/* <NavLink className="text_brand_color8 fw-500"  onClick={() => setBlogModalShow(true)}>Read More</NavLink> */}
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                            })
                                        }
                                        {/* <Col lg="4" sm="6">
                                      <div className="latest_blog_card">
                                          <div className="latest_blog_card_header">
                                              <Row className="align-items-center">
                                                  <Col>
                                                      <div className="d-flex align-items-center">
                                                          <div className="">
                                                              <img src={dummy_coach1_sqr} alt="" />
                                                          </div>
                                                          <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                      </div>
                                                  </Col>
                                                  <Col className="col-auto">
                                                      <span className="fs-14 fw-400">19 Jan 2022</span>
                                                  </Col>
                                              </Row>
                                              
                                          </div>
                                          <img className="latest_blog_img" src={mental_coach} alt="" />
                                          <div className="latest_blog_card_body">
                                              <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                              <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                              <NavLink>Read More</NavLink>
                                          </div>
                                      </div>
                                  </Col>
      
                                  <Col lg="4" sm="6">
                                      <div className="latest_blog_card">
                                          <div className="latest_blog_card_header">
                                              <Row className="align-items-center">
                                                  <Col>
                                                      <div className="d-flex align-items-center">
                                                          <div className="">
                                                              <img src={dummy_coach1_sqr} alt="" />
                                                          </div>
                                                          <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                      </div>
                                                  </Col>
                                                  <Col className="col-auto">
                                                      <span className="fs-14 fw-400">19 Jan 2022</span>
                                                  </Col>
                                              </Row>
                                              
                                          </div>
                                          <img className="latest_blog_img" src={mental_coach} alt="" />
                                          <div className="latest_blog_card_body">
                                              <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                              <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                              <NavLink>Read More</NavLink>
                                          </div>
                                      </div>
                                  </Col> */}
                                    </Row>
                                </Container>
                            </section>
                            : ''
                            : ''
                    }
                    {
                        popularBlogs ? popularBlogs.length > 0 ?
                            <section className="most_popular">
                                <Container>
                                    <hr />
                                    <Row className="blogs_hdng">
                                        <Col>
                                            <h4>Most Popular</h4>
                                        </Col>
                                        {/* <Col className="col-auto">
                                          <NavLink to={'/latest-blogs'}>View All</NavLink>
                                      </Col> */}
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        {
                                            popularBlogs.map((blog, index) => {
                                                // if (count < 6 && blog.is_popular == 'Yes') {
                                                count = index + 1
                                                blog.content = (blog.content).replace(/<[^>]+>/g, '')
                                                blog.content = (blog.content).replace(/&nbsp;/g, ' ');
                                                return (
                                                    // <div>
                                                    //     <div className="mb-3 latest_blog_card p-1">
                                                    //         <Row className="align-items-center">
                                                    //             <Col md="6">
                                                    //                 <div className="latest_blog_card_header pb-0">
                                                    //                     <Row className="align-items-center">
                                                    //                         <Col>
                                                    //                             <div className="d-flex align-items-center">
                                                    //                                 <div className="">
                                                    //                                     {blog.profile_pic ? (
                                                    //                                         <img src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`} alt="" />
                                                    //                                     ) : (
                                                    //                                         <img src={live_session_img1} alt="" />
                                                    //                                     )}
                                                    //                                 </div>

                                                    //                                 {console.log(blog, 'Mohit')}
                                                    //                                 <span className="ms-2 fs-10 fw-600">{blog.name ? blog.name : 'Jack Mick'}</span>
                                                    //                             </div>
                                                    //                         </Col>
                                                    //                         <Col className="col-auto">
                                                    //                             <span className="fs-14 fw-400">{new Date(blog.created_at).toLocaleDateString('en-IN', {
                                                    //                                 year: 'numeric',
                                                    //                                 month: 'short',
                                                    //                                 day: 'numeric'
                                                    //                             })}
                                                    //                             </span>
                                                    //                         </Col>
                                                    //                     </Row>
                                                    //                 </div>
                                                    //                 <div className="latest_blog_card_body">
                                                    //                     <h5>{blog.title}</h5>
                                                    //                     <p>{(blog.content.replace(/<[^>]+>/g, '')).substring(0, 300)}</p>
                                                    //                     <NavLink to={`/blog-details/${blog.slug}`}>Read More</NavLink>

                                                    //                     {/* <NavLink onClick={(e) => showModal(e, blog)}>Read More</NavLink> */}
                                                    //                 </div>
                                                    //             </Col>
                                                    //             <Col md="6">
                                                    //                 {/* <img className="latest_blog_img" src={mental_coach} alt="" /> */}
                                                    //                 <img className="latest_blog_img" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" />

                                                    //                 {/* {blog.image ? (
                                                    //         <img className="latest_blog_img" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" />
                                                    //     ) : (
                                                    //         <img className="latest_blog_img" src={`${mental_coach}`} alt="" />
                                                    //     )} */}
                                                    //             </Col>
                                                    //         </Row>
                                                    //     </div>
                                                    //     <hr />
                                                    // </div>
                                                    <Col sm="6" lg="4" className="mb-4">
                                                        <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>
                                                            <div className="blog_card border rounded-4 h-100 overflow-hidden">
                                                                <div className="blog_card_img">
                                                                    {blog.image ? (
                                                                        <img alt="" className="w-100" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}`} onError={(e) => defaultImage(e)} />
                                                                    ) : (
                                                                        <img alt="" className="w-100" src={blog_card_img1} onError={(e) => defaultImage(e)} />
                                                                    )}
                                                                </div>
                                                                <div className="blog_card_body px-3">
                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            {blog.profile_pic ? (
                                                                                <img
                                                                                    width={30}
                                                                                    height={30}
                                                                                    alt=""
                                                                                    className="user_img rounded-circle me-2"
                                                                                    src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`}
                                                                                    style={{ borderRadius: '50%' }}
                                                                                    onError={(e) => defaultUserImage(e)}
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    width={30}
                                                                                    height={30}
                                                                                    alt=""
                                                                                    className="user_img me-2"
                                                                                    src={testimonials_img1}
                                                                                />
                                                                            )}
                                                                            <h5 className="m-0 fs-18">{blog.name ? blog.name : 'Jack Mick'}</h5>
                                                                        </div>
                                                                        <span className="fs-12 fw-500 opacity_7">
                                                                            {getDate(
                                                                                blog.blog_date !== null
                                                                                    ? blog.blog_date
                                                                                    : blog.updated_at
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines"> {blog?.title} </h5>
                                                                    <p className="fs-14 fw-300 opacity_7 mb-3">
                                                                        {/* {removeHTMLTags(blog.content).substring(0, 125)}... */}
                                                                        {removeHTMLTags(blog.content)}
                                                                    </p>
                                                                    <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>Read More</NavLink>
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </Col>
                                                )
                                                // }
                                            })
                                            // : ''
                                        }
                                    </Row>
                                </Container>
                            </section>
                            : ''
                            : ''
                    }
                    {
                        allBlogs ? allBlogs.length > 0 ?
                            <section className="most_popular">
                                <Container>
                                    <hr />
                                    <Row className="blogs_hdng">
                                        <Col>
                                            <h4>All</h4>
                                        </Col>
                                        {/* <Col className="col-auto">
                                  <NavLink to={'/latest-blogs'}>View All</NavLink>
                              </Col> */}
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        {
                                            allBlogs.map((blog, index) => {
                                                count = index + 1
                                                blog.content = (blog.content).replace(/<[^>]+>/g, '')
                                                blog.content = (blog.content).replace(/&nbsp;/g, ' ');
                                                return (
                                                    <Col sm="6" lg="4" className="mb-4">
                                                        <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>
                                                            <div className="blog_card border rounded-4 h-100 overflow-hidden">
                                                                <div className="blog_card_img">
                                                                    {blog.image ? (
                                                                        <img alt="" className="w-100" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}`} onError={(e) => defaultImage(e)} />
                                                                    ) : (
                                                                        <img alt="" className="w-100" src={blog_card_img1} onError={(e) => defaultImage(e)} />
                                                                    )}
                                                                </div>
                                                                <div className="blog_card_body px-3">
                                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                                        <div className="d-flex align-items-center">
                                                                            {blog.profile_pic ? (
                                                                                <img
                                                                                    width={30}
                                                                                    height={30}
                                                                                    alt=""
                                                                                    className="user_img rounded-circle me-2"
                                                                                    src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`}
                                                                                    style={{ borderRadius: '50%' }}
                                                                                    onError={(e) => defaultUserImage(e)}
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    width={30}
                                                                                    height={30}
                                                                                    alt=""
                                                                                    className="user_img me-2"
                                                                                    src={testimonials_img1}
                                                                                />
                                                                            )}

                                                                            <h5 className="m-0 fs-18">{blog.name ? blog.name : 'Jack Mick'}</h5>
                                                                        </div>

                                                                        <span className="fs-12 fw-500 opacity_7">
                                                                            {getDate(
                                                                                blog.blog_date !== null
                                                                                    ? blog.blog_date
                                                                                    : blog.updated_at
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines"> {blog?.title} </h5>
                                                                    <p className="fs-14 fw-300 opacity_7 mb-3">
                                                                        {/* {removeHTMLTags(blog.content).substring(0, 125)}... */}
                                                                        {removeHTMLTags(blog.content)}
                                                                    </p>
                                                                    <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>Read More</NavLink>
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </Col>
                                                )
                                            })
                                            // : ''
                                        }
                                    </Row>
                                </Container>
                            </section>
                            : ''
                            : ''
                    }
                    {
                        featuredBlogs && featuredBlogs.length === 0 ?
                            latestBlogs && latestBlogs.length === 0 ?
                                popularBlogs && popularBlogs.length === 0 ?
                                    allBlogs && allBlogs.length === 0 ?
                                        <Container>
                                            <div className="my-5 py-5">
                                                <p className="text-center">Blog not found</p>
                                            </div>
                                        </Container>
                                        :
                                        ""
                                    :
                                    ""
                                :
                                ""
                            :
                            ""
                    }

                    {/* Testimonials Start */}
                    {/* <section className="white_bg py-3 py-lg-5">
                      <Container>
                          <Row className="justify-content-center">
                              <Col lg="12" className="">
                                  <div className="text-start hdng_a">
                                      <h6 className="text-uppercase">Testimonials</h6>
                                      <h2>Some Honest Reviews</h2>
                                  </div>
                              </Col>
                          </Row>
                          <Row className="justify-content-center mt-4">
                              <Col lg="12">
      
                                  <OwlCarousel className="owl-theme" loop items="1" dots={true}>
                                      <div className="item">
                                          <div>
                                              <Row className="align-items-center">
                                                  <Col md="5">
                                                      <div className="testimonial_blog_card latest_blog_card">
                                                          <div className="latest_blog_card_header">
                                                              <Row className="align-items-center">
                                                                  <Col>
                                                                      <div className="d-flex align-items-center">
                                                                          <div className="">
                                                                              <img src={dummy_coach1_sqr} alt="" />
                                                                          </div>
                                                                          <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                                      </div>
                                                                  </Col>
                                                                  <Col className="col-auto">
                                                                      <span className="fs-14 fw-400">19 Jan 2022</span>
                                                                  </Col>
                                                              </Row>
      
                                                          </div>
                                                          <img className="latest_blog_img" src={mental_coach} alt="" onError={(e) => defaultImage(e) }  />
                                                          <div className="latest_blog_card_body">
                                                              <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                                              <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                                              <NavLink>Read More</NavLink>
                                                          </div>
                                                      </div>
                                                  </Col>
                                                  <Col md="7">
                                                      <div className="testimonial_blog_quotes_card">
                                                          <div>
                                                              <div className="d-flex mb-2">
                                                                  <span>
                                                                      <img className="quote_grn_img" src={quote_grn} alt="" />
                                                                  </span>
                                                                  <div>
                                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                      <h5>Cathy Wickersham</h5>
                                                                  </div>
                                                              </div>
      
                                                              <div className="d-flex mb-2">
                                                                  <span>
                                                                      <img className="quote_grn_img" src={quote_grn} alt="" />
                                                                  </span>
                                                                  <div>
                                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                      <h5>Cathy Wickersham</h5>
                                                                  </div>
                                                              </div>
                                                              <Form>
                                                                  <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                      <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment" value="" />
                                                                  </Form.Group>
                                                                  <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                      <Form.Control name="eail" type="text" placeholder="Enter Mail" value="" />
                                                                  </Form.Group>
                                                                  <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                                                      <Button variant="success" size="sm" className="px-4">Submit
                                                                          <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                                                          </svg>
                                                                      </Button>
                                                                  </Form.Group>
      
                                                              </Form>
                                                          </div>
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </div>
                                      </div>
      
                                      <div className="item">
                                          <div>
                                              <Row className="align-items-center">
                                                  <Col md="5">
                                                      <div className="testimonial_blog_card latest_blog_card">
                                                          <div className="latest_blog_card_header">
                                                              <Row className="align-items-center">
                                                                  <Col>
                                                                      <div className="d-flex align-items-center">
                                                                          <div className="">
                                                                              <img src={dummy_coach1_sqr} alt="" />
                                                                          </div>
                                                                          <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                                      </div>
                                                                  </Col>
                                                                  <Col className="col-auto">
                                                                      <span className="fs-14 fw-400">19 Jan 2022</span>
                                                                  </Col>
                                                              </Row>
      
                                                          </div>
                                                          <img className="latest_blog_img" src={mental_coach} alt=""  onError={(e) => defaultImage(e) } />
                                                          <div className="latest_blog_card_body">
                                                              <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                                              <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                                              <NavLink>Read More</NavLink>
                                                          </div>
                                                      </div>
                                                  </Col>
                                                  <Col md="7">
                                                      <div className="testimonial_blog_quotes_card">
                                                          <div>
                                                              <div className="d-flex mb-2">
                                                                  <span>
                                                                      <img className="quote_grn_img" src={quote_grn} alt="" />
                                                                  </span>
                                                                  <div>
                                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                      <h5>Cathy Wickersham</h5>
                                                                  </div>
                                                              </div>
      
                                                              <div className="d-flex mb-2">
                                                                  <span>
                                                                      <img className="quote_grn_img" src={quote_grn} alt="" />
                                                                  </span>
                                                                  <div>
                                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                      <h5>Cathy Wickersham</h5>
                                                                  </div>
                                                              </div>
                                                              <Form>
                                                                  <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                      <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment" value="" />
                                                                  </Form.Group>
                                                                  <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                      <Form.Control name="eail" type="text" placeholder="Enter Mail" value="" />
                                                                  </Form.Group>
                                                                  <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                                                      <Button variant="success" size="sm" className="px-4">Submit
                                                                          <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                                                          </svg>
                                                                      </Button>
                                                                  </Form.Group>
      
                                                              </Form>
                                                          </div>
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </div>
                                      </div>
      
                                      <div className="item">
                                          <div>
                                              <Row className="align-items-center">
                                                  <Col md="5">
                                                      <div className="testimonial_blog_card latest_blog_card">
                                                          <div className="latest_blog_card_header">
                                                              <Row className="align-items-center">
                                                                  <Col>
                                                                      <div className="d-flex align-items-center">
                                                                          <div className="">
                                                                              <img src={dummy_coach1_sqr} alt="" />
                                                                          </div>
                                                                          <span className="ms-2 fs-10 fw-600">Jack Mick</span>
                                                                      </div>
                                                                  </Col>
                                                                  <Col className="col-auto">
                                                                      <span className="fs-14 fw-400">19 Jan 2022</span>
                                                                  </Col>
                                                              </Row>
      
                                                          </div>
                                                          <img className="latest_blog_img" src={mental_coach} alt=""  onError={(e) => defaultImage(e) } />
                                                          <div className="latest_blog_card_body">
                                                              <h5 className="fs-14 fw-600">How one Qoach user grew his single person consultancy from $0-100K in 14 months</h5>
                                                              <p>See how pivoting to Webflow changed one person’s sales strategy and allowed him to attract</p>
                                                              <NavLink>Read More</NavLink>
                                                          </div>
                                                      </div>
                                                  </Col>
                                                  <Col md="7">
                                                      <div className="testimonial_blog_quotes_card">
                                                          <div>
                                                              <div className="d-flex mb-2">
                                                                  <span>
                                                                      <img className="quote_grn_img" src={quote_grn} alt="" />
                                                                  </span>
                                                                  <div>
                                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                      <h5>Cathy Wickersham</h5>
                                                                  </div>
                                                              </div>
      
                                                              <div className="d-flex mb-2">
                                                                  <span>
                                                                      <img className="quote_grn_img" src={quote_grn} alt="" />
                                                                  </span>
                                                                  <div>
                                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                                      <h5>Cathy Wickersham</h5>
                                                                  </div>
                                                              </div>
                                                              <Form>
                                                                  <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                      <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment" value="" />
                                                                  </Form.Group>
                                                                  <Form.Group className="mb-3 mt-3" controlId="comments">
                                                                      <Form.Control name="eail" type="text" placeholder="Enter Mail" value="" />
                                                                  </Form.Group>
                                                                  <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                                                      <Button variant="success" size="sm" className="px-4">Submit
                                                                          <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                                                          </svg>
                                                                      </Button>
                                                                  </Form.Group>
      
                                                              </Form>
                                                          </div>
                                                      </div>
                                                  </Col>
                                              </Row>
                                          </div>
                                      </div>
      
                                  </OwlCarousel>
      
                              </Col>
                          </Row>
                      </Container>
                  </section> */}
                    {/* Testimonials End */}


                    {/* <section className="py-5">
                      <Container>
                          <Row className="justify-content-center">
                              <Col lg="12">
                                  {
                                      allBlogs && allBlogs.length > 0 ?
                                          allBlogs.map((blog) => (
                                              <Row>
                                                  <Col lg="6" className="order-last order-md-first  mt-3">
                                                      <div className="blogs_card">
                                                          <div className="d-flex align-items-center justify-content-between">
                                                              <div className="blog_user">
                                                                  <span>                                                                
                                                                      {blog.profile_pic ? (
                                                                          <img src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`} alt="" style={{ borderRadius: '50%' }}/>
                                                                      ) : (
                                                                          <img src={live_session_img1} alt="" />
                                                                      )}
                                                                  </span>
                                                                  {console.log(blog, 'Mohit')}
                                                                  <p className="fs-16 fw-400 mb-0 ms-2">{blog.name ? blog.name : 'Jack Mick'}</p>
                                                              </div>
                                                              <div className="blog_dt">
                                                                  <p className="fs-16 fw-600 mb-0 text_brand_color8 opacity_7">{new Date(blog.created_at).toLocaleDateString('en-IN', {
                                                                          year: 'numeric',
                                                                          month: 'short',
                                                                          day: 'numeric'
                                                                      })} 
                                                                  </p>
                                                              </div>
                                                          </div>
                                                          <h4 className="fs-20 fw-600 text_brand_color8">{blog.title}</h4>
                                                          <p className="m-0">{blog.content.replace(/<[^>]+>/g, '')}</p>
                                                          <NavLink className="text_brand_color8 fw-500" onClick={(e) => showModal(e, blog)}>Read More</NavLink>
                                                      </div>
                                                  </Col>
                                                  <Col lg="6" className="mt-3">
                                                      {blog.image ? (
                                                          <img width="100%" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" />
                                                      ) : (
                                                          <img width="100%" src={`${mental_coach}`} alt="" />
                                                      )}
                                                  </Col>
                                              </Row>
                                          ))
      
                                          : ''
                                  }
                                  <hr />
                              </Col>
                          </Row>
                      </Container>
                  </section> */}
                    {/* <BlogModalComponent singleBlogDetails={singleBlogDetails} /> */}

                    {/* <BlogModalComponent show={blogModalShow} onHide={() => setBlogModalShow(false)} singleBlogDetails={singleBlogDetails} /> */}
                </>
            )
            }
        </>
    )
}

export default BlogComponent;