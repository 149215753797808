
import React, { useState, useEffect } from "react";
import { Player, ControlBar, PlayToggle } from 'video-react';
import { Button, Col, Container, Row, Form, InputGroup, Dropdown } from "react-bootstrap";
import poster from '../../../../assets/images/dummy/poster.png';
import ShortVideoComponent from "../../../../Coach/components/ShortVideo";
import { addCommentShortVideo, getCommentShortVideoById, getShortVIdeoByCoachId } from "../../../../utils/api";
import { Carousel } from 'react-responsive-carousel';
import send_img from '../../../../assets/images/icons/send.png';
import { NavLink, useNavigate } from "react-router-dom";
import LikeFill from '../../../../assets/images/icons/like_fill';
import LikeEmpty from '../../../../assets/images/icons/like_empty';
import { addLikeInShort } from '../../../../utils/api';
import Pic from '../../../../assets/images/icons/user.png';
import { toast } from "react-toastify";
import { useUserContext } from "../../../../utils/hooks/UserContext";
import { deleteVideoComment } from "../../../../utils/api";
import { confirmAlert } from 'react-confirm-alert';



const CoachDetailsShortVideosComponent = ({ userData, commenterId }) => {

    const [VideoModalShow, setVideoModalShow] = useState(false);
    const [data, setData] = useState([])
    const [comment, setcomment] = useState('')
    const { user } = useUserContext();
    const [isLoading, setisLoading] = useState(false);
    const navigate = useNavigate()

    const [video, setVideo] = useState([]);

    const getData = async () => {
        const { data } = await getShortVIdeoByCoachId(userData?.coach_id)
        setVideo(data?.data)
    }

    const onClickLike = async (id) => {

        if (!user) {
            // navigate('/')
            toast.error('Please login to do this action')
            return
        }

        const formdata = new FormData();
        formdata.append('short_video_id', id)
        await addLikeInShort(formdata)
        getData()
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        fetchData(video?.[0]?.short_video_id);
    }, []);

    const fetchData = async (id) => {
        try {
            const response = await getCommentShortVideoById(id);
            setData(response?.data?.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const onChange = (e) => {
        const data = video?.find((_, index) => index === e)
        fetchData(data?.short_video_id)
    }

    const onClickComment = async (id) => {
        try {

            const formdata = new FormData()
            formdata.append('short_video_id', id)
            formdata.append('comments', comment)

            const res = await addCommentShortVideo(formdata)
            if (!res?.data?.status) {
                toast.error(res.data?.message)
                return
            }
            setcomment('')
            toast.success('New comment added.')
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message)
        } finally {
            fetchData(id)
        }
    }

    const handleDeleteClick = (id, videoId) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this Comment?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await deleteVideoComment(id);
                            toast.success('Deleted successfully!!')
                            fetchData(videoId)
                        } catch (error) {
                            console.error('Error deleting item', error);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <>
            <Container className="py-4">
                <Row className="mb-4" >
                    <Carousel showArrows={true} onChange={onChange} // Display arrows
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        (
                            <button
                                type="button"
                                onClick={onClickHandler}
                                title={label}
                                className="carousel-arrow prev-arrow"
                            >

                                <span>&#x2190;</span>
                            </button>
                        )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                        (
                            <button
                                type="button"
                                onClick={onClickHandler}
                                title={label}
                                className="carousel-arrow next-arrow"
                            >
                                <span>&#x2192;</span>
                            </button>
                        )
                        }
                    >
                        {
                            video?.map((i) => {
                                return (
                                    <Row className='justify-content-center'>
                                        <Col lg="10">
                                            <Row className="d-flex align-items-end">
                                                <Col lg="5">
                                                    <div className="my_video">
                                                        <Player  src={process.env.REACT_APP_COACH_SHORT_VIDEO_PATH + i?.file_url} className='pt-0'>
                                                            <ControlBar autoHide={false} disableDefaultControls={true}>
                                                                <PlayToggle />
                                                            </ControlBar>
                                                        </Player>
                                                        <div className="activity_short_video_cntnt">
                                                            <div className="activity_short_video_uploader">
                                                                <img src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${i?.coach_profile}`} alt="" />
                                                                <h5>{i?.coach_name}</h5>
                                                            </div>
                                                            <div>
                                                                <p className="fs-14 fw-500 m-0 tags-text">{i?.tags?.split(',')?.map((i) => <label>#{i}</label>)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="1" className='text-right p-0'>
                                                    <div className="asf_card_footer-inner">
                                                        <div className="svg_grn_btn video-btn">
                                                            <div className="mt-3 like_btn d-flex justify-content-center" onClick={() => onClickLike(i?.short_video_id)} >
                                                                <NavLink>
                                                                    {i?.is_liked ? <span className="liked"><LikeFill /></span> : <span className="unlike"><LikeEmpty /></span>}
                                                                    <div className='fs-12 fw-400 ' > {i?.like_count}</div>
                                                                </NavLink>
                                                            </div>

                                                            <div className="mt-3 comment_btn d-flex justify-content-center">
                                                                <NavLink>
                                                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M24.6074 14.3962C24.6114 15.936 24.2517 17.4551 23.5574 18.8295C22.7343 20.4766 21.4688 21.8619 19.9028 22.8304C18.3368 23.7988 16.532 24.3121 14.6908 24.3129C13.1509 24.3169 11.6319 23.9571 10.2574 23.2629L3.60742 25.4795L5.82409 18.8295C5.12984 17.4551 4.77007 15.936 4.77409 14.3962C4.7748 12.5549 5.28814 10.7502 6.25659 9.18417C7.22505 7.61815 8.61039 6.35269 10.2574 5.52953C11.6319 4.83528 13.1509 4.47551 14.6908 4.47953H15.2741C17.7058 4.61368 20.0026 5.64008 21.7248 7.36219C23.4469 9.08431 24.4733 11.3811 24.6074 13.8129V14.3962Z" stroke="#964B00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    <div className='fs-12 fw-400'>{i?.comment_count}</div>
                                                                </NavLink>
                                                            </div>
                                                            <div className="mt-3 share_btn d-flex justify-content-center">
                                                                <NavLink>
                                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17.1068 19.9808C17.1068 22.7401 14.8674 24.9795 12.1082 24.9795H5.11C2.35073 24.9795 0.111328 22.7401 0.111328 19.9808V12.9827C0.111328 10.2234 2.35073 7.98399 5.11 7.98399H6.10974C6.65959 7.98399 7.10947 8.43387 7.10947 8.98373C7.10947 9.53358 6.65959 9.98346 6.10974 9.98346H5.11C3.46044 9.98346 2.1108 11.3331 2.1108 12.9827V19.9808C2.1108 21.6304 3.46044 22.98 5.11 22.98H12.1082C13.7577 22.98 15.1074 21.6304 15.1074 19.9808C15.1074 19.431 15.5572 18.9811 16.1071 18.9811C16.6569 18.9811 17.1068 19.431 17.1068 19.9808ZM23.2352 6.87429L17.8266 1.28577C17.4467 0.885874 16.8069 0.875876 16.417 1.26577C16.0271 1.65567 16.0071 2.2855 16.397 2.6754L21.5357 7.974H13.1079C10.3486 7.974 8.10921 10.2134 8.10921 12.9727V17.9713C8.10921 18.5212 8.55909 18.9711 9.10894 18.9711C9.6588 18.9711 10.1087 18.5212 10.1087 17.9713V12.9727C10.1087 11.3231 11.4583 9.97347 13.1079 9.97347H21.5257L16.387 15.2721C16.0071 15.672 16.0071 16.3018 16.407 16.6817C16.597 16.8716 16.8569 16.9616 17.1068 16.9616C17.3568 16.9616 17.6267 16.8616 17.8266 16.6617L23.2252 11.0932C24.3949 9.92348 24.3949 8.02398 23.2352 6.86429V6.87429Z" fill="#964B00" />
                                                                    </svg>
                                                                </NavLink>
                                                            </div>
                                                            {/* <div className="mt-3 dot_btn d-flex justify-content-center">
                                                                <NavLink className="">
                                                                    <Button variant="" className="p-0 h-auto fs-24 fw-700">&#8943;</Button>
                                                                </NavLink>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="6" >
                                                    <div className="social_feeds_card-inner">
                                                        <ul className='social_feeds_chat_cntnt'>
                                                            {
                                                                data?.map((i) => {
                                                                    return (
                                                                        <li>
                                                                            <Row className='g-2 d-flex align-items-center'>
                                                                                <Col className='social_feeds_chat_cntnt_usr_img col-auto'>
                                                                                    {i?.profile_pic && <img src={`${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${i?.profile_pic}`} className="rounded-circle-video" alt="" />}
                                                                                    {!i?.profile_pic && <img src={Pic} className="rounded-circle-video" alt="" />}
                                                                                </Col>
                                                                                <Col className='text-start'>
                                                                                    <div className='sf_chat_cntnt'>
                                                                                        <h5>{i?.name}</h5>
                                                                                        <p>{i?.comments}</p>
                                                                                    </div>
                                                                                </Col>
                                                                                {user?.userType === "user" && user?.user_id == i?.user_id && <Col sm='auto'>
                                                                                    <Dropdown >
                                                                                        {user?.userType === "user" && user?.coach_id === commenterId && (
                                                                                            <>
                                                                                                <Dropdown.Toggle variant="ellipsis" as={Button} className="p-0 h-auto fs-24 fw-700">
                                                                                                    &#8943;
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item onClick={() => handleDeleteClick(i?.id, i?.short_video_id)} disabled={isLoading} >Delete</Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </>
                                                                                        )}
                                                                                    </Dropdown>
                                                                                </Col>}
                                                                            </Row>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                        {user?.userType === "coach" && <div>
                                                            <h6>Only user are allowed to add Comment</h6>
                                                        </div>}
                                                        {user?.userType === "user" && (
                                                            <div className="comment_area-inner">
                                                                <Row className="g-2 d-flex align-items-center">
                                                                    <Col className="col-auto">
                                                                        <div className="userData_img_sm_rnd">
                                                                            {user?.profile_pic && <img src={`${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${user?.profile_pic}`} className="rounded-circle-video" alt="" />}
                                                                            {!user?.profile_pic && <img src={Pic} className="rounded-circle-video" alt="" />}
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <InputGroup className="write_comment_txt_ar">
                                                                            <Form.Control className='fs-14' placeholder="Write a comment" as="textarea" value={comment} onChange={(e) => setcomment(e?.target?.value)} />
                                                                            <Button variant="outline-secondary" id="button-addon1" onClick={() => onClickComment(i?.short_video_id)} >
                                                                                <img src={send_img} alt="" />
                                                                            </Button>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Carousel>

                </Row>
                <Col>
                    <div className="text-left hdng_a">
                        <h6 className="text-uppercase">Skills</h6>
                        <h2 className="m-0">Area of my Expertise </h2>
                    </div>
                    <div className="mt-3">
                        {
                            userData?.skills?.map((i) => {
                                return <Button className="m-2 ml-0" variant="outline_color4">{i?.name}</Button>
                            })
                        }
                    </div>
                </Col>
                <ShortVideoComponent getData={getData} videos={video} show={VideoModalShow} onHide={() => setVideoModalShow(false)} />
            </Container >
        </>
    )
}

export default CoachDetailsShortVideosComponent;