import React, { useState } from "react";
import close from "../../../assets/images/icons/close.png";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import logo from "../../../assets/images/logo_dark.png";
import * as Yup from "yup";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { updatePassword } from "../../../utils/api";
import { Formik } from "formik";
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    pwd: Yup.string().required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-zA-Z](?=.*[0-9])/, 'Password Should be alphanumeric'),
    pwdConfirmation: Yup.string().oneOf([Yup.ref('pwd'), null], 'Passwords must match').required('Please confirm password.')
});

function UpdatepasswordComponent(props) {
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isLoading, setisLoading] = useState(false)

    const navigate = useNavigate()
    const { token } = useParams()

    const onSubmitHandler = async (values) => {
        setisLoading(true)
        try {
            const formData = new FormData();
            formData.append("password", values.pwd);
            formData.append("token", token);

            const { data } = await updatePassword(formData);


            if (data?.status) {
                toast.success(data?.message)
                navigate('/')
            } else {
                toast.error(data?.message || 'Something went wrong');
            }

        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message)
        } finally {
            setisLoading(false)
        }
    };


    return (
        <>
            <div
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="brand_bg_color1 my_light_modal update-password-form">
                    <div className="container">
                        <div className="">
                            <Row className="justify-content-center g-0">
                                <Col lg="5">
                                    <div className="brand_bg_color2 signup_grn_card">
                                        <NavLink className="d-inline-flex" to="/">
                                            <img alt="" src={logo} />
                                        </NavLink>
                                        <div className="signup_grn_card_content">
                                            <h2>Start your journey with us.</h2>
                                            <p>
                                                Discover the world’s best platform to find coaches around
                                                the world in three easy steps.
                                            </p>
                                        </div>
                                        {/* Rest of the code */}
                                    </div>
                                </Col>
                                <Col lg="7">
                                    <div className="ls_modal_conatiner px-4">
                                        <Button className="ls_mdl_close_btn p-0" onClick={props.onHide}>
                                            <img alt="" src={close} />
                                        </Button>
                                        <div className="hdng_a text-start">
                                            <h6 className="text-uppercase">Updated password</h6>
                                            <h2 className="mb-4">Get Matched with the Perfect Coach</h2>
                                        </div>
                                        <Formik
                                            initialValues={{ pwd: "", pwdConfirmation: "", userType: "" }}
                                            validationSchema={validationSchema}
                                            onSubmit={onSubmitHandler}
                                        >
                                            {({ values, errors, touched, handleChange, handleSubmit }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group className="mb-3" controlId="password">
                                                        <Form.Label>Password</Form.Label>
                                                        <InputGroup className="password_vh mb-4">
                                                            <Form.Control
                                                                placeholder="xyzabe@123"
                                                                name="pwd"
                                                                type={isRevealPwd ? "text" : "password"}
                                                                value={values.pwd}
                                                                onChange={handleChange}
                                                                isInvalid={touched.pwd && !!errors.pwd}
                                                            />
                                                            <InputGroup.Text id="basic-addon2">
                                                                <img
                                                                    alt=""
                                                                    width="20"
                                                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                                                    src={isRevealPwd ? view : hidden}
                                                                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                                                                />
                                                            </InputGroup.Text>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.pwd}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="mb-5" controlId="password">
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <InputGroup className="password_vh mb-4">
                                                            <Form.Control
                                                                placeholder="xyzabe@123"
                                                                name="pwdConfirmation"
                                                                type={isRevealPwd ? "text" : "password"}
                                                                value={values.pwdConfirmation}
                                                                onChange={handleChange}
                                                                isInvalid={touched.pwdConfirmation && !!errors.pwdConfirmation}
                                                            />
                                                            <InputGroup.Text id="basic-addon2">
                                                                <img
                                                                    alt=""
                                                                    width="20"
                                                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                                                    src={isRevealPwd ? view : hidden}
                                                                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                                                                />
                                                            </InputGroup.Text>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.pwdConfirmation}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="d-grid">
                                                        <Button disabled={isLoading} variant="success" className="" type="submit">
                                                            Update password
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UpdatepasswordComponent;