import React, { useRef, useState, useEffect } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    Nav,
    Row,
    Tab
} from "react-bootstrap";
function CoachCalendarComponent() {

    const [modalVisiable, setModalVisiable] = useState(false);

    const getData = () => {

    }
    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData();
    }, []);

    const [timeInputs, setTimeInputs] = useState([{ id: Date.now(), time: '' }]);

    const createTimeInputField = () => {
        setTimeInputs([...timeInputs, { id: Date.now(), time: '' }]);
    };

    const handleTimeChange = (id, newTime) => {
        setTimeInputs(timeInputs.map(input => input.id === id ? { ...input, time: newTime } : input));
    };
    return (
        <>
            <section className="coach_my_activity py-3">
                {/* <div className="text-start hdng_a mb-3 d-flex justify-content-between">
                    <h2>My Calendar</h2>
                </div> */}
                <Container>
                    <Row className="justify-content-center align-items-end">
                        <Col lg='12 mb-4'>
                            <div>
                                <h3 className="mb-0 primary-text-color">Update your availability</h3>
                                <span className="fs-12">Set your availability for each day of week at once </span>
                            </div>
                        </Col>
                        <Col lg='8'>
                            <div>
                                <h5 className="fs-18 m-0">Slots for available hours</h5>
                                <p className="fs-12">Each Slot will be of 30 mins</p>
                            </div>
                            {timeInputs.map((input, index) => (
                                <div key={input.id}>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="form-group w-100">
                                            <input
                                                type="time"
                                                className="form-control"
                                                value={input.time}
                                                onChange={(e) => handleTimeChange(input.id, e.target.value)}
                                            />
                                        </div>
                                        <div className="ms-2">
                                            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.4974 3.83332H13.9141C13.5154 1.89465 11.81 0.5025 9.8307 0.5H8.16402C6.18477 0.5025 4.47937 1.89465 4.0807 3.83332H1.49738C1.03715 3.83332 0.664062 4.20641 0.664062 4.66664C0.664062 5.12687 1.03715 5.5 1.49738 5.5H2.3307V16.3333C2.33348 18.6334 4.19734 20.4973 6.49738 20.5H11.4974C13.7974 20.4973 15.6613 18.6334 15.6641 16.3333V5.5H16.4974C16.9576 5.5 17.3307 5.12691 17.3307 4.66668C17.3307 4.20645 16.9576 3.83332 16.4974 3.83332ZM8.16406 14.6667C8.16406 15.1269 7.79098 15.5 7.33074 15.5C6.87047 15.5 6.49738 15.1269 6.49738 14.6667V9.66668C6.49738 9.20645 6.87047 8.83336 7.3307 8.83336C7.79094 8.83336 8.16402 9.20645 8.16402 9.66668V14.6667H8.16406ZM11.4974 14.6667C11.4974 15.1269 11.1243 15.5 10.6641 15.5C10.2038 15.5 9.83074 15.1269 9.83074 14.6667V9.66668C9.83074 9.20645 10.2038 8.83336 10.6641 8.83336C11.1243 8.83336 11.4974 9.20645 11.4974 9.66668V14.6667ZM5.80656 3.83332C6.16102 2.83523 7.10492 2.16793 8.16406 2.16664H9.83074C10.8899 2.16793 11.8338 2.83523 12.1882 3.83332H5.80656Z" fill="#C1000E" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Col>
                        <Col lg="4">
                            <button onClick={createTimeInputField} className="mb-3 px-5 btn btn-success">Add More</button>
                        </Col>
                        <Col lg="12 mt-4">
                            <span className="mb-3 d-block">Days your are available</span>
                            <div className="d-flex justify-content-left align-items-center flex-wrap">
                                <div className="select-availability-day text-center border rounded px-3 py-3 fs-12">
                                    <input className="d-block w-100" type="checkbox" id="monday" name="monday" value="monday" />
                                    <label for="monday">Monday</label>
                                </div>
                                <div className="select-availability-day text-center border rounded px-3 py-3 fs-12">
                                    <input className="d-block w-100" type="checkbox" id="tuesday" name="tuesday" value="tuesday" />
                                    <label for="tuesday">Tuesday</label>
                                </div>
                                <div className="select-availability-day text-center border rounded px-3 py-3 fs-12">
                                    <input className="d-block w-100" type="checkbox" id="wednesday" name="wednesday" value="wednesday" />
                                    <label for="wednesday">Wednesday</label>
                                </div>
                                <div className="select-availability-day text-center border rounded px-3 py-3 fs-12">
                                    <input className="d-block w-100" type="checkbox" id="thursday" name="thursday" value="thursday" />
                                    <label for="thursday">Thursday</label>
                                </div>
                                <div className="select-availability-day text-center border rounded px-3 py-3 fs-12">
                                    <input className="d-block w-100" type="checkbox" id="friday" name="friday" value="friday" />
                                    <label for="friday">Friday</label>
                                </div>
                                <div className="select-availability-day text-center border rounded px-3 py-3 fs-12 active">
                                    <input className="d-block w-100" type="checkbox" id="saturday" name="saturday" value="saturday" />
                                    <label for="saturday">Saturday</label>
                                </div>
                                <div className="select-availability-day text-center border rounded px-3 py-3 fs-12 active">
                                    <input className="d-block w-100" type="checkbox" id="sunday" name="sunday" value="sunday" />
                                    <label for="sunday">Sunday</label>
                                </div>
                            </div>
                            <div className="text-end">
                                <button className="mt-5 px-5 btn btn-success">Update</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default CoachCalendarComponent;
