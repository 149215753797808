// export const LinkedInApi = {
//     clientId: process.env.REACT_APP_LINKEDIN_CLIENTID,
//     redirectUrl: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
//     oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
//     scope: 'r_liteprofile%20r_emailaddress',
//     state: '123456'
// };
export const LinkedInApi = {
    clientId: process.env.REACT_APP_LINKEDIN_CLIENTID,
    redirectUrl: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
    // redirectUrl: 'https://qoach.io',
    // clientId: "78ag6nkj5or88a",
    // clientId: "77oquhcl8r2g5v",
    oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    // scope: 'r_liteprofile%20r_emailaddress',
    scope: ["profile", 'email', 'openid'],
    state: '123456'
};