import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addPlan } from '../utils/api';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    mobile: Yup.string().required('Solution is required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    amount: Yup.string().required('amount is required'),
});

const SubscriptionForm = () => {

    const onSubmit = async (values, { setSubmitting }) => {
        const formData = {
            "product": {
                "name": values?.name,
                "type": "service"
            },
            "plan": {
                "amount": values?.amount,
                "currency": "inr",
                "interval": "month",
                "usage_type": "licensed",
                "name": values?.name
            }
        }

        try {
            await addPlan(formData)
            setSubmitting(false);
            toast.success(`Product added successfully!!`)
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message)
        }
    }
    return (
        <>
            <Container>
                <Row>
                    <Col sm="8">
                        <div className="text-start">
                            <h6 className="fs-20 fw-500 text_brand_color2">Submit Form</h6>
                        </div>
                    </Col>
                    <Formik
                        initialValues={{
                            name: "",
                            mobile: "",
                            email: "",
                            amount: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, setFieldValue, values, handleSubmit }) => (
                            <Form onSubmit={handleSubmit} >
                                <Col sm="8">
                                    <Form.Group className="mb-3 mt-2" controlId="text">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            rows="4"
                                            name="name"
                                            type="text"
                                            placeholder="name"
                                            value={values.name}
                                            isInvalid={touched.name && !!errors.name}
                                            onChange={(e) => setFieldValue("name", e?.target?.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm="8">
                                    <Form.Group className="mb-3 mt-2" controlId="text">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control
                                            rows="4"
                                            name="mobile"
                                            type="number"
                                            placeholder="enter your Number"
                                            value={values.mobile}
                                            isInvalid={touched.mobile && !!errors.mobile}
                                            onChange={(e) => setFieldValue("mobile", e?.target?.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.mobile}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm="8">
                                    <Form.Group className="mb-3 mt-2" controlId="text">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            rows="4"
                                            name="email"
                                            type="email"
                                            placeholder="Your Email Address"
                                            value={values.email}
                                            isInvalid={touched.email && !!errors.email}
                                            onChange={(e) => setFieldValue("email", e?.target?.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col sm="8">
                                    <Form.Group className="mb-3 mt-2" controlId="text">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            rows="4"
                                            name="amount"
                                            type="number"
                                            placeholder="add your amount"
                                            value={values.amount}
                                            isInvalid={touched.amount && !!errors.amount}
                                            onChange={(e) => setFieldValue("amount", e?.target?.value)
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.amount}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm="8 text-end">
                                    <Button className='px-5' variant='success' type='submit'>Post</Button>
                                </Col>
                            </Form>
                        )}
                    </Formik>
                </Row>
            </Container>
        </>
    );
};

export default SubscriptionForm;
