import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "../../../utils/api";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Pic from "../../../assets/images/icons/user.png";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";

const UserMyCoachComponent = () => {
    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    return (
        <>
            <section className="p-5 bg-body-tertiary">
                <div className="text-start hdng_a">
                    <h2 className="mb-4">My Coach</h2>
                </div>
                <div className="">
                    <div className="border py-3 px-4 rounded position-relative d-flex align-items-center user-info mt-2 mb-3 justify-content-between bg-white">
                        <div>
                            <div className="position-relative d-flex align-items-center user-info mb-2">
                                {/* defaultUserImage */}
                                <img width={'80px'} height={'80px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                                <div className="user-name lh-1">
                                    <span className="fs-18 fw-600">Jack Mick</span>
                                    <div className="d-flex mt-1">
                                        {/* {
                                                            post.tag_names !== null ? */}
                                        {/* <small className="fs-10 me-1 d-inline-block overflow-hidden" style={{ width: '200px' }}>{post.tag_names}</small> */}
                                        <small className="fs-14 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                        {/* : ''
                                                        } */}
                                        {/* <small className="fs-10 me-1 d-inline-block text-white">Manager</small> */}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="fs-12 me-1">Active Package:</span>
                                <b className="primary-text-color">6 Session Program</b>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="">
                                <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <button className="mt-2 px-5 btn btn-warning fs-12">Book Slot</button>
                        </div>
                    </div>
                    <div className="border py-3 px-4 rounded position-relative d-flex align-items-center user-info mt-2 mb-3 justify-content-between bg-white">
                        <div>
                            <div className="position-relative d-flex align-items-center user-info mb-2">
                                {/* defaultUserImage */}
                                <img width={'80px'} height={'80px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                                <div className="user-name lh-1">
                                    <span className="fs-18 fw-600">Jack Mick</span>
                                    <div className="d-flex mt-1">
                                        {/* {
                                                            post.tag_names !== null ? */}
                                        {/* <small className="fs-10 me-1 d-inline-block overflow-hidden" style={{ width: '200px' }}>{post.tag_names}</small> */}
                                        <small className="fs-14 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                        {/* : ''
                                                        } */}
                                        {/* <small className="fs-10 me-1 d-inline-block text-white">Manager</small> */}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="fs-12 me-1">Active Package:</span>
                                <b className="primary-text-color">6 Session Program</b>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="">
                                <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <button className="mt-2 px-5 btn btn-warning fs-12">Book Slot</button>
                        </div>
                    </div>
                    <div className="border py-3 px-4 rounded position-relative d-flex align-items-center user-info mt-2 mb-3 justify-content-between bg-white">
                        <div>
                            <div className="position-relative d-flex align-items-center user-info mb-2">
                                {/* defaultUserImage */}
                                <img width={'80px'} height={'80px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                                <div className="user-name lh-1">
                                    <span className="fs-18 fw-600">Jack Mick</span>
                                    <div className="d-flex mt-1">
                                        {/* {
                                                            post.tag_names !== null ? */}
                                        {/* <small className="fs-10 me-1 d-inline-block overflow-hidden" style={{ width: '200px' }}>{post.tag_names}</small> */}
                                        <small className="fs-14 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                        {/* : ''
                                                        } */}
                                        {/* <small className="fs-10 me-1 d-inline-block text-white">Manager</small> */}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="fs-12 me-1">Active Package:</span>
                                <b className="primary-text-color">6 Session Program</b>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="">
                                <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <button className="mt-2 px-5 btn btn-warning fs-12">Book Slot</button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default UserMyCoachComponent;
