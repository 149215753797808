import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import update_availability from '../../../assets/images/icons/update_availability.png';

import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { useUserContext } from "../../../utils/hooks/UserContext";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';

function UserUpdateAvailabilityComponent (){
    const { user } = useUserContext()

    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    console.log(user);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    return(
        <>
        <Row className="justify-content-between">
            <Col sm="auto">
                <Button variant="success" className="rounded-circle">
                <i className="bi bi-chevron-left"></i>
                </Button>
            </Col>
            <Col sm="auto">
                <div className="d-flex align-items-center">
                    <span>
                        <img src={update_availability} />
                    </span>
                    <h4 className="fs-20 fw-500 text_brand_color2 ms-2 m-0">Update Availability</h4>
                </div>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col sm="12">
                <h4 className="fs-24 fw-500 text_brand_color2">Update your availability</h4>
                <p className="fs-12 fw-400">Set your availability for each day of week at once </p>
            </Col>
        </Row>
        <Row className="g-4 justify-content-">
            <Col sm="7">
                <h4 className="fs-16 fw-500 mb-3">Slots for available hours</h4>
                <div className="slot_avlble">
                    <div className="slot_list">
                        <InputGroup className="mb-3">
                            <Form.Control type="time" />
                            <InputGroup.Text>To</InputGroup.Text>
                            <Form.Control type="time" />
                        </InputGroup>
                    </div>
                    <Button variant="">
                        <i className="text-danger fs-24 bi bi-trash"></i>
                    </Button>
                </div>
                <div className="slot_avlble">
                    <div className="slot_list">
                        <InputGroup className="mb-3">
                            <Form.Control type="time" />
                            <InputGroup.Text>To</InputGroup.Text>
                            <Form.Control type="time" />
                        </InputGroup>
                    </div>
                    <Button variant="">
                        <i className="text-danger fs-24 bi bi-trash"></i>
                    </Button>
                </div>
                <div className="slot_avlble">
                    <div className="slot_list">
                        <InputGroup className="mb-3">
                            <Form.Control type="time" />
                            <InputGroup.Text>To</InputGroup.Text>
                            <Form.Control type="time" />
                        </InputGroup>
                    </div>
                    <Button variant="success" className="ms-2">
                        <i className="bi bi-plus-lg"></i>
                    </Button>
                </div>
            </Col>
            <Col sm="auto">
                <div className="mb-3">
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                        selectsRange
                        inline
                    />
                </div>
                <div className="text-end">
                    <Button className="px-4" variant="success">Update</Button>
                </div>
            </Col>
        </Row>
        </>
    )
}


export default UserUpdateAvailabilityComponent;