import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Nav, Form, Row, Modal } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import live_session_img1 from '../../../assets/images/dummy/live_session_img1.png';
import dummy_coach1_sqr from '../../../assets/images/dummy/dummy_coach1_sqr.png';
import mental_coach from '../../../assets/images/dummy/mental_coach.png';
import BlogModalComponent from "./blog_modal";
import { getAllBlogs, addBlogComment, allBlogComment, deleteBlogComment, singleBlogDetails, blogLikeDislike, shareBlog, allAuthor } from "../../../utils/api";
import send_wht from "../../../assets/images/icons/send_wht.png";
import quote_grn from '../../../assets/images/icons/quote_grn.png';
import { useParams } from "react-router-dom";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import LoginComponent from '../Login/newindex';
import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";
import { PulseLoader } from "react-spinners";
// import { useHistory } from 'react-router-dom'; // Assuming you're using React Router
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import noblogs from "../../../assets/images/no-blogs.png";

function BlogDetailsComponent() {
    const navigate = useNavigate();
    const { user } = useUserContext();
    const { id } = useParams()
    const [allBlogs, setAllBlogs] = useState([]);
    const [showSocial, setShowSocial] = useState(false);
    const [commentPopup, setCommentPopup] = useState(false);
    const [newComments, setNewComments] = useState('');
    // const [email, setEmail] = useState('');
    const [userAllBlogs, setUserAllBlogs] = useState('');
    const [showUserAllBlogs, setShowUserAllBlogs] = useState(false);
    const [singleBlog, setSingleBlog] = useState('')
    const [blogId, setBlogId] = useState('')
    const [allComments, setAllComments] = useState([])
    const [loginModalShow, setLoginModalShow] = React.useState(false);
    const [latestBlog, setLatestBlog] = React.useState([]);
    const [currentBlogUrl, setCurrentBlogUrl] = useState('');
    const [authorList, setAuthorList] = React.useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData();
        window.scrollTo(0, 0);
    }, [])

    const getData = async () => {
        // const data = await getAllBlogs();
        // setAllBlogs(data.data.data.all)
        const url = window.location.pathname;
        // console.log(url);
        // Extract the number from the URL using regular expression
        // const numberPattern = /\d+/;
        // const match = url.match(numberPattern);

        // console.log(url);
        const allauthor = await allAuthor();
        console.log(allauthor, 'allauthor');
        // setAllAuthor(allauthor);
        if (url) {
            // console.log('2');
            const parts = url.split('/');
            const lastPart = parts[parts.length - 1];
            // const id = parseInt(lastPart, 10);
            setCurrentBlogUrl(lastPart);
            // console.log('3');
            const data = await singleBlogDetails(lastPart);
            // console.log(data, 'lastPart');
            setSingleBlog(data.data.data);
            setLatestBlog(data.data.data.latest_blogs)
            // console.log('4');
            if (data.data.data) {
                const comment = await allBlogComment(data.data.data.id);
                setAllComments(comment.data.data);
                setBlogId(data.data.data.id);
                setUserAllBlogs()
            }
        }
        setLoading(false)
    }

    // console.log("singleBlogDetails", allBlogs)

    // let blogs = [];
    // if (allBlogs.length > 0) {
    //     allBlogs.map((blog) => {
    //         if (blog.id == id) {
    //             // blog.content = (blog.content).replace(/<[^>]+>/g, '')
    //             // blog.content = (blog.content).replace(/&nbsp;/g, ' ');
    //             blogs.push(blog);
    //         }
    //     });
    // }
    const defaultUserImage = (e) => {
        e.target.src = live_session_img1;
    }
    const commentModal = () => {
        if (!user) {
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }
        setCommentPopup(true);
    }
    const showAllBlogs = () => {
        setShowUserAllBlogs(true)
    }
    const saveComment = async () => {
        if (!user) {
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }

        const formData = new FormData();
        formData.append('blog_id', blogId)
        formData.append('comment', newComments)

        console.log(formData);

        const data = await addBlogComment(formData)
        if (data.data) {
            if (data.data.success === true) {
                toast.success(data.data.msg)
                setNewComments('')
                setCommentPopup(false)
                getData()
            } else {
                toast.error(data.data.msg)
            }
        } else {
            toast.error(data.data.msg)
        }
        // if(data)
        // getData()
    }
    const scrollToSection = () => {
        const section = document.getElementById('commentsection');
        if (section) {
            // Get the section's offset top position
            const sectionOffsetTop = section.offsetTop;

            // Scroll to the section smoothly
            window.scrollTo({
                top: sectionOffsetTop,
                behavior: 'smooth'
            });
        }
    }
    const likedislike = async () => {
        if (!user) {
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }
        const formData = new FormData();
        formData.append('blog_id', blogId)

        const data = await blogLikeDislike(formData)
        if (data.data) {
            if (data.data.success === true) {
                getData()
            } else {
                toast.error(data.data.msg)
            }
        } else {
            toast.error(data.data.msg)
        }
    }
    const shareBlogs = async () => {
        shareBlog(blogId);
        getData();
    }
    const displaySocialIcon = () => {
        setShowSocial(!showSocial)
        if (showSocial === false) {
            navigator.clipboard
                .writeText(window.location.href)
                .then(() => {
                    toast.success("Link Copied to Clipboard!");
                })
                .catch((err) => {
                    console.error("Unable to copy text to clipboard", err);
                });
        }
    }
    const defaultImage = (e) => {
        e.target.src = 'https://nadmin.qoach.io/assets/images/blog.png';
    }
    // const history = useHistory();

    const goBack = () => {
        // history.goBack(); // This will navigate back to the previous page in history
        window.history.back(); // This will navigate back to the previous page in history
    };
    const getSingleBlog = (e) => {
        const timer = setTimeout(() => {
            getData();
            window.scrollTo(0, 0);
        }, 500);
        return () => clearTimeout(timer);
    }
    function getDate(time) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Octr",
            "Nov",
            "Dec",
        ];

        const date = new Date(time).getDate();
        const year = new Date(time).getFullYear();
        const month = months[new Date(time).getMonth()];

        let hour = new Date(time).getHours();
        let minute = new Date(time).getMinutes();

        const am_pm = hour > 12 ? "PM" : "AM";
        const time_string = `${date} ${month} ${year}`;
        return time_string;
    }
    const removeHTMLTags = (str) => {
        return str.replace(/<[^>]+>/g, ''); // Remove HTML tags
    };
    return (

        <>
            {loading ? (
                // <PulseLoader color="#36D7B7" loading={loading} size={15} />
                <div
                    style={{
                        // textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <p>
                        <PulseLoader color="#00504E" size={20} />
                    </p>
                </div>
            ) : (
                <>
                    {/* <Helmet htmlAttributes>
                <html lang="en" />
                <title>Hello from React Helmet</title>
                <meta name="description" content="Basic example" />
            </Helmet> */}
                    {/* singleBlog.content */}
                    <Helmet
                        htmlAttributes={{ lang: 'en' }}
                        title={singleBlog.title}
                        meta={[
                            { name: 'description', content: 'Basic example' },
                            { property: 'og:title', content: singleBlog.title },
                            { property: 'og:description', content: singleBlog.content },
                            { property: 'og:type', content: 'https://qoach.io/' }, // Example: 'article', 'video', etc.
                            { property: 'og:url', content: window.location.href }, // URL of the page
                            { property: 'og:image', content: `${process.env.REACT_APP_SOCKET_URL}/upload/cab/${singleBlog.image}` }, // URL of the image
                            { property: 'og:image:width', content: '100%' }, // Width of the image in pixels
                            { property: 'og:image:height', content: 'auto' }, // Height of the image in pixels
                            { property: 'og:image:alt', content: 'Blog image' }, // Alt text for the image
                        ]}
                    />
                    <div className="patten_bg_white">
                        {/* <section>
                    <Container>
                        {blogs.length > 0 ? (

                        <Row className="g-5 mt-3 justify-content-center">
                            <div className="col-md-8">
                                <Col lg="5">
                                        <div className="latest_blog_sldr_card" style={{ height: "95%" }}>
                                            <Row className="justify-content-between mb-3">
                                                <Col>
                                                    <div className="d-flex align-items-center">
                                                        <div className="">
                                                            {blogs[0].profile_pic ? (
                                                                <img width={30} src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blogs[0].profile_pic}`} alt="" />
                                                            ) : (
                                                                <img width={30} src={live_session_img1} alt="" />
                                                            )}
                                                        </div>
                                                        <span className="ms-2 fs-15 fw-600">{blogs[0].name}</span>
                                                    </div>
                                                </Col>
                                                <Col className="col-auto">
                                                    <span className="ms-2 fs-15 fw-400">
                                                        {blogs[0].created_at ? (new Date(blogs[0].created_at).toLocaleDateString('en-IN', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        })) : ''}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <h4 className="">{blogs[0].title}</h4>
                                        </div>
                                    </Col>
                                <h2 className="fw-800 fs-40">{singleBlog.title}</h2>
                            </div>
                        </Row>
                         ) : ''} 
                    </Container>
                </section> */}
                        <section className="pb-3 pb-lg-5 single-blog">
                            <Container className="mt-5">
                                {/* <div className="mb-4">
                            <button className="back-button d-flex align-items-center fs-16 px-4 py-3 rounded-pill border-0" onClick={goBack}>
                                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width='22px' className="me-2"><title /><g data-name="Layer 2" id="Layer_2"><path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" /></g></svg>
                                Back
                            </button>
                        </div> */}
                                <div className="row justify-content-center">
                                    <div className="col-md-8 pe-4">
                                        {/* <hr /> */}
                                        {/* {blogs.length > 0 ? ( */}
                                        <Row>
                                            {/* <Col lg="7">
                                            <img className="w-100" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blogs[0].image}`} alt="" />
                                            <img className="w-100" src={mental_coach} alt="" />
                                        </Col> */}
                                            {
                                                // allBlogs && allBlogs.length > 0 ?
                                                //     allBlogs.map((blog) => (
                                                <Col lg="12">
                                                    <div className="">
                                                        <div className="w-100 mx-auto">
                                                            {
                                                                singleBlog.image ?
                                                                    <img className="w-100 mb-5" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/original/${singleBlog.image}`} alt="blog pic" max-width="100%" onError={(e) => defaultImage(e)} />
                                                                    : ''
                                                            }
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-4 user-details">
                                                            <div className="d-flex align-items-center">
                                                                <div className="">
                                                                    {singleBlog.profile_pic ? (
                                                                        <img width={50} height={50} src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${singleBlog.profile_pic}`} alt="" onError={(e) => defaultUserImage(e)} className="rounded-circle" />
                                                                    ) : (
                                                                        <img width={50} height={50} src={live_session_img1} alt="" onError={(e) => defaultUserImage(e)} className="rounded-circle" />
                                                                    )}
                                                                </div>
                                                                <div className="">
                                                                    {/* <span className="d-block ms-2 fs-16 fw-600">{singleBlog.name}</span> */}
                                                                    <span className="d-block ms-2 fs-16 fw-600">{singleBlog.name ? singleBlog.name : "Nick Johnas"}</span>
                                                                    <span className="d-block ms-2 fs-12 fw-400">
                                                                        {singleBlog.created_at ? (new Date(singleBlog.created_at).toLocaleDateString('en-IN', {
                                                                            year: 'numeric',
                                                                            month: 'short',
                                                                            day: 'numeric'
                                                                        })) : ''}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between position-relative blog-like-share">
                                                                {/* <Button
                                                            variant=""
                                                            className="fw-600"
                                                            onClick={showAllBlogs}
                                                        >
                                                            <span className="me-1">{singleBlog.blogs_count}</span>
                                                            Blogs
                                                        </Button> */}
                                                                <div className="ms-4 shadow-sm  bg-white rounded">
                                                                    <Button
                                                                        variant=""
                                                                        className=""
                                                                        onClick={likedislike}
                                                                    >
                                                                        <svg width="20px" height="20px" className="me-2" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5817 3.18042C12.5817 1.71045 11.1294 0.730469 9.99454 0.730469C9.20468 0.730469 9.14294 1.33022 9.02142 2.51403C8.96752 3.03342 8.90284 3.66453 8.75977 4.4054C8.38149 6.36732 7.0742 8.87411 5.82374 9.62379V15.4302C5.81982 17.6351 6.55481 18.3701 9.73975 18.3701H13.4372C15.5697 18.3701 16.0861 16.9658 16.2782 16.4454L16.2909 16.4102C16.4026 16.1103 16.6417 15.8741 16.9161 15.6066C17.2199 15.3067 17.5669 14.9666 17.8246 14.4502C18.1294 13.8397 18.0892 13.2968 18.0529 12.8136C18.0304 12.5206 18.0098 12.2501 18.0696 12.0002C18.1323 11.7356 18.2127 11.5348 18.2901 11.3427C18.4302 10.9938 18.5596 10.6704 18.5596 10.0403C18.5596 8.57031 17.8265 7.59229 16.2909 7.59229H12.1897C12.1897 7.59229 12.5817 4.65039 12.5817 3.18042ZM2.38989 8.57031C2.00003 8.57031 1.62614 8.72518 1.35047 9.00086C1.07479 9.27653 0.919922 9.65042 0.919922 10.0403V16.9001C0.919922 17.29 1.07479 17.6639 1.35047 17.9396C1.62614 18.2152 2.00003 18.3701 2.38989 18.3701C2.77975 18.3701 3.15365 18.2152 3.42932 17.9396C3.70499 17.6639 3.85986 17.29 3.85986 16.9001V10.0403C3.85986 9.65042 3.70499 9.27653 3.42932 9.00086C3.15365 8.72518 2.77975 8.57031 2.38989 8.57031Z" fill={singleBlog.is_liked == true ? "#00504E" : "#aaa"} />
                                                                        </svg>
                                                                        <span>{singleBlog.like_count}</span>
                                                                    </Button>
                                                                    <Button
                                                                        variant=""
                                                                        className=""
                                                                        onClick={scrollToSection}
                                                                    >
                                                                        <svg width="20px" height="20px" className="me-2" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.961 10.3227H6.66664C6.26075 10.3227 5.93133 9.99405 5.93133 9.58742C5.93133 9.18153 6.26075 8.85211 6.66664 8.85211H16.961C17.3669 8.85211 17.6963 9.18153 17.6963 9.58742C17.6963 9.99405 17.3669 10.3227 16.961 10.3227ZM15.4904 14.7346H8.13727C7.73137 14.7346 7.40195 14.4059 7.40195 13.9993C7.40195 13.5927 7.73137 13.264 8.13727 13.264H15.4904C15.8963 13.264 16.2257 13.5927 16.2257 13.9993C16.2257 14.4059 15.8963 14.7346 15.4904 14.7346ZM11.8138 0.763672C5.31661 0.763672 0.0488281 5.37335 0.0488281 11.058C0.0488281 14.3074 1.77314 17.2009 4.4607 19.0869V24.2937L9.61451 21.1664C10.3278 21.284 11.0616 21.3524 11.8138 21.3524C18.3111 21.3524 23.5788 16.7435 23.5788 11.058C23.5788 5.37335 18.3111 0.763672 11.8138 0.763672Z" fill="black" />
                                                                        </svg>
                                                                        <span>{singleBlog.comment_count}</span>
                                                                    </Button>
                                                                    <Button
                                                                        variant=""
                                                                        className=""
                                                                        // onClick={() => setShowSocial(!showSocial)}
                                                                        onClick={displaySocialIcon}
                                                                    >
                                                                        <svg width="20px" height="20px" className="me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" fill="#1C274C" />
                                                                        </svg>
                                                                        <span>{singleBlog.share_count}</span>
                                                                    </Button>
                                                                    {showSocial === true && (
                                                                        <div className="share position-absolute d-flex top-100 end-0 justify-content-right p-3 border rounded-5 z-1 bg-white">
                                                                            <div className="mobile-share-details-link" style={{ width: '2.4em' }}>
                                                                                <FacebookShareButton
                                                                                    url={`${window.location}`}
                                                                                    className="mb-2"
                                                                                    onClick={shareBlogs}
                                                                                >
                                                                                    <FacebookIcon size={40} round={true} />
                                                                                </FacebookShareButton>
                                                                                <WhatsappShareButton
                                                                                    url={`${window.location}`}
                                                                                    className="mb-2"
                                                                                    onClick={shareBlogs}
                                                                                >
                                                                                    <WhatsappIcon size={40} round={true} />
                                                                                </WhatsappShareButton>

                                                                                <EmailShareButton
                                                                                    url={`${window.location}`}
                                                                                    className="mb-2"
                                                                                    onClick={shareBlogs}
                                                                                >
                                                                                    <EmailIcon size={40} round={true} />
                                                                                </EmailShareButton>
                                                                                <LinkedinShareButton
                                                                                    url={`${window.location}`}
                                                                                    className="mb-2"
                                                                                    onClick={shareBlogs}
                                                                                >
                                                                                    <LinkedinIcon size={40} round={true} />
                                                                                </LinkedinShareButton>
                                                                                <TelegramShareButton
                                                                                    url={`${window.location}`}
                                                                                    className="mb-2"
                                                                                    onClick={shareBlogs}
                                                                                >
                                                                                    <TelegramIcon size={40} round={true} />
                                                                                </TelegramShareButton>
                                                                                <TwitterShareButton
                                                                                    url={`${window.location}`}
                                                                                    onClick={shareBlogs}
                                                                                >
                                                                                    <TwitterIcon size={40} round={true} />
                                                                                </TwitterShareButton>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {/* <Col lg="7">
                                                            <img className="w-100" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blogs[0].image}`} alt="" />
                                                            <img className="w-100" src={mental_coach} alt="" />
                                                        </Col> */}
                                                        </div>
                                                        <div>
                                                            <h2 className="fw-500 fs-36 mb-3">{singleBlog.title}</h2>
                                                        </div>
                                                        <div className="text-justify blog-content">
                                                            {console.log(singleBlog.content)}
                                                            {/* {(singleBlog.content)} */}
                                                            <div dangerouslySetInnerHTML={{ __html: singleBlog.content }} />
                                                        </div>
                                                    </div>
                                                </Col>

                                                // )): ''
                                            }
                                        </Row>
                                        <div className="py-3 py-lg-5 single-blog-comment" id="commentsection">

                                            <div className="text-start hdng_a d-flex justify-content-between align-items-center mb-4">
                                                <h6 className="text-uppercase mb-0">Comments</h6>
                                                <div className="px-2 py-1 edit-button ms-2 rounded text-white fs-12" onClick={commentModal}>
                                                    Add Comment
                                                    <svg className="" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.0952 1.62889C14.8691 1.10144 14.417 0.649342 13.8896 0.423292C12.7593 -0.0288074 11.4784 0.498642 10.9509 1.55354L7.48483 9.31458C7.18343 9.99273 7.10808 10.8216 7.18343 11.4997L7.48483 13.1574C7.56018 13.3835 7.71088 13.6095 7.93693 13.6849C8.01228 13.7602 8.16298 13.7602 8.23833 13.7602C8.38903 13.7602 8.53973 13.6849 8.61508 13.6095L10.0467 12.7053C10.7249 12.2532 11.177 11.7258 11.4784 10.9723L14.9445 3.21124C15.3212 2.75914 15.3212 2.15634 15.0952 1.62889ZM10.2728 10.5202C10.0467 10.9723 9.74532 11.2737 9.36857 11.5751L8.84113 11.8765L8.76578 11.349C8.69043 10.8969 8.76578 10.4448 8.91648 9.99273L11.5537 3.96474L12.91 4.49219L10.2728 10.5202ZM13.7389 2.68379L13.5882 3.06054L12.2319 2.53309L12.3826 2.15634C12.5333 1.85494 12.7593 1.70424 13.0607 1.70424C13.1361 1.70424 13.2868 1.70424 13.3621 1.77959C13.5128 1.85494 13.6635 2.00564 13.7389 2.15634C13.8142 2.38239 13.8142 2.53309 13.7389 2.68379Z" fill="white" />
                                                        <path d="M0.929281 11.048H3.94328C4.39538 11.048 4.69678 10.7466 4.69678 10.2945C4.69678 9.84242 4.39538 9.54102 3.94328 9.54102H0.929281C0.477181 9.54102 0.175781 9.84242 0.175781 10.2945C0.175781 10.7466 0.477181 11.048 0.929281 11.048Z" fill="white" />
                                                        <path d="M5.45028 12.5547H0.929281C0.477181 12.5547 0.175781 12.8561 0.175781 13.3082C0.175781 13.7603 0.477181 14.0617 0.929281 14.0617H5.45028C5.90238 14.0617 6.20378 13.7603 6.20378 13.3082C6.20378 12.8561 5.90238 12.5547 5.45028 12.5547Z" fill="white" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="testimonial_blog_quotes_card h-auto">
                                                <div>
                                                    {
                                                        allComments && allComments.length > 0 ?
                                                            allComments.map((comment) => (

                                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                                    <span className="me-1">
                                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                                    </span>
                                                                    <div>
                                                                        <p>{comment.comment}</p>
                                                                        <h5>{comment.name}</h5>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            <div className="py-4">
                                                                <p className="m-0 text-center fs-14">No Comment yet</p>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* ) : ''} */}
                                    </div>
                                    <div className="col-md-4">
                                        {
                                            singleBlog.blogs && singleBlog.blogs.length > 0 ?
                                                <>
                                                    <h6 className="mb-4 fs-18 fw-600">User related blog</h6>
                                                    {
                                                        singleBlog.blogs.map((blog, index) => {
                                                            // <NavLink to={`/blog-details/${blog.slug}`} className="row mb-3" onClick={(e) => getSingleBlog(e)}>
                                                            //     <div className="col-4">
                                                            //         {/* <img src="" alt="" width="100%" onError={(e) => defaultImage(e)} /> */}
                                                            //         {blog.image ? (
                                                            //             <img width="100%" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" onError={(e) => defaultImage(e)} />
                                                            //         ) : (
                                                            //             <img src={mental_coach} alt="" />
                                                            //         )}
                                                            //     </div>
                                                            //     <div className="col-8">
                                                            //         <b className="fs-14 fw-500 mb-0 d-block overflow-hidden text-nowrap text-truncate">{blog.title}</b>
                                                            //         <span className="fs-12 d-block opacity-3 opacity-75 overflow-hidden text-nowrap text-truncate">lorem</span>
                                                            //     </div>
                                                            // </NavLink>
                                                            <Col sm="12" lg="12" className="mb-3">
                                                                <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`} onClick={(e) => getSingleBlog(e)}>
                                                                    <div className="blog_card border rounded-4 h-100 overflow-hidden">
                                                                        <div className="blog_card_img">
                                                                            {blog.image ? (
                                                                                <img alt="" className="w-100" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}`} onError={(e) => defaultImage(e)} />
                                                                            ) : (
                                                                                <img alt="" className="w-100" src={blog_card_img1} onError={(e) => defaultImage(e)} />
                                                                            )}
                                                                        </div>
                                                                        <div className="blog_card_body px-3">
                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                <div className="d-flex align-items-center">
                                                                                    {blog.profile_pic ? (
                                                                                        <img
                                                                                            width={30}
                                                                                            height={30}
                                                                                            alt=""
                                                                                            className="user_img rounded-circle me-2"
                                                                                            src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`}
                                                                                            style={{ borderRadius: '50%' }}
                                                                                            onError={(e) => defaultUserImage(e)}
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            width={30}
                                                                                            height={30}
                                                                                            alt=""
                                                                                            className="user_img me-2"
                                                                                            src={testimonials_img1}
                                                                                        />
                                                                                    )}

                                                                                    <h5 className="m-0 fs-18">{blog.name ? blog.name : 'Jack Mick'}</h5>
                                                                                </div>

                                                                                <span className="fs-12 fw-500 opacity_7">
                                                                                    {getDate(
                                                                                        blog.blog_date !== null
                                                                                            ? blog.blog_date
                                                                                            : blog.updated_at
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines"> {blog?.title} </h5>

                                                                            <p className="fs-14 fw-300 opacity_7 mb-3">
                                                                                {/* {removeHTMLTags(blog.content).substring(0, 125)}... */}
                                                                                {removeHTMLTags(blog.content)}
                                                                            </p>
                                                                            <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`} onClick={(e) => getSingleBlog(e)}>Read More</NavLink>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            </Col>
                                                        })
                                                    }
                                                </>
                                                : ''
                                        }
                                        {
                                            latestBlog && latestBlog.length > 0 ?
                                                <>
                                                    <h6 className="mb-4 fs-18 fw-600">Latest blog</h6>
                                                    {
                                                        latestBlog.map((blog, index) => (

                                                            // <NavLink to={`/blog-details/${blog.slug}`} className="row mb-3" onClick={(e) => getSingleBlog(e)}>
                                                            //     <div className="col-4" style={{height: '45px'}}>
                                                            //         {blog.image ? (
                                                            //             <img width="100%" height="45px" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" className="object-fit-cover" onError={(e) => defaultImage(e)} />
                                                            //         ) : (
                                                            //             <img src={mental_coach} height="45px" alt="" width="100%" className="object-fit-cover" />
                                                            //         )}
                                                            //     </div>
                                                            //     <div className="col-8 ps-0">
                                                            //         <b className="fs-14 fw-500 mb-0 d-block overflow-hidden text-nowrap text-truncate">{blog.title}</b>
                                                            //         <span className="fs-12 d-block opacity-3 opacity-75 overflow-hidden text-nowrap text-truncate">lorem</span>
                                                            //     </div>
                                                            // </NavLink>
                                                            <Col sm="12" lg="12" className="mb-3">
                                                                <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`} onClick={(e) => getSingleBlog(e)}>
                                                                    <div className="blog_card border rounded-4 h-100 overflow-hidden">
                                                                        <div className="blog_card_img">
                                                                            {blog.image ? (
                                                                                <img alt="" className="w-100" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}`} onError={(e) => defaultImage(e)} />
                                                                            ) : (
                                                                                <img alt="" className="w-100" src={blog_card_img1} onError={(e) => defaultImage(e)} />
                                                                            )}
                                                                        </div>
                                                                        <div className="blog_card_body px-3">
                                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                                <div className="d-flex align-items-center">
                                                                                    {blog.profile_pic ? (
                                                                                        <img
                                                                                            width={30}
                                                                                            height={30}
                                                                                            alt=""
                                                                                            className="user_img rounded-circle me-2"
                                                                                            src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`}
                                                                                            style={{ borderRadius: '50%' }}
                                                                                            onError={(e) => defaultUserImage(e)}
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            width={30}
                                                                                            height={30}
                                                                                            alt=""
                                                                                            className="user_img me-2"
                                                                                            src={testimonials_img1}
                                                                                        />
                                                                                    )}

                                                                                    <h5 className="m-0 fs-18">{blog.name ? blog.name : 'Jack Mick'}</h5>
                                                                                </div>

                                                                                <span className="fs-12 fw-500 opacity_7">
                                                                                    {getDate(
                                                                                        blog.blog_date !== null
                                                                                            ? blog.blog_date
                                                                                            : blog.updated_at
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines"> {blog?.title} </h5>

                                                                            <p className="fs-14 fw-300 opacity_7 mb-3">
                                                                                {/* {removeHTMLTags(blog.content).substring(0, 125)}... */}
                                                                                {removeHTMLTags(blog.content)}
                                                                            </p>
                                                                            <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`} onClick={(e) => getSingleBlog(e)}>Read More</NavLink>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            </Col>
                                                        ))
                                                    }
                                                </>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </Container>
                        </section>
                        {/* Testimonials Start */}
                        {/* <section className="py-3 py-lg-5 single-blog-comment" id="commentsection">
                    <Container>
                        <Row className="justify-content-start">
                            <Col lg="8" className="">
                                <div className="text-start hdng_a d-flex justify-content-between align-items-center">
                                    <h6 className="text-uppercase mb-0">Comments</h6>
                                    <div className="px-2 py-1 edit-button ms-2 rounded text-white fs-12" onClick={commentModal}>
                                        Add Comment
                                        <svg className="" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.0952 1.62889C14.8691 1.10144 14.417 0.649342 13.8896 0.423292C12.7593 -0.0288074 11.4784 0.498642 10.9509 1.55354L7.48483 9.31458C7.18343 9.99273 7.10808 10.8216 7.18343 11.4997L7.48483 13.1574C7.56018 13.3835 7.71088 13.6095 7.93693 13.6849C8.01228 13.7602 8.16298 13.7602 8.23833 13.7602C8.38903 13.7602 8.53973 13.6849 8.61508 13.6095L10.0467 12.7053C10.7249 12.2532 11.177 11.7258 11.4784 10.9723L14.9445 3.21124C15.3212 2.75914 15.3212 2.15634 15.0952 1.62889ZM10.2728 10.5202C10.0467 10.9723 9.74532 11.2737 9.36857 11.5751L8.84113 11.8765L8.76578 11.349C8.69043 10.8969 8.76578 10.4448 8.91648 9.99273L11.5537 3.96474L12.91 4.49219L10.2728 10.5202ZM13.7389 2.68379L13.5882 3.06054L12.2319 2.53309L12.3826 2.15634C12.5333 1.85494 12.7593 1.70424 13.0607 1.70424C13.1361 1.70424 13.2868 1.70424 13.3621 1.77959C13.5128 1.85494 13.6635 2.00564 13.7389 2.15634C13.8142 2.38239 13.8142 2.53309 13.7389 2.68379Z" fill="white" />
                                            <path d="M0.929281 11.048H3.94328C4.39538 11.048 4.69678 10.7466 4.69678 10.2945C4.69678 9.84242 4.39538 9.54102 3.94328 9.54102H0.929281C0.477181 9.54102 0.175781 9.84242 0.175781 10.2945C0.175781 10.7466 0.477181 11.048 0.929281 11.048Z" fill="white" />
                                            <path d="M5.45028 12.5547H0.929281C0.477181 12.5547 0.175781 12.8561 0.175781 13.3082C0.175781 13.7603 0.477181 14.0617 0.929281 14.0617H5.45028C5.90238 14.0617 6.20378 13.7603 6.20378 13.3082C6.20378 12.8561 5.90238 12.5547 5.45028 12.5547Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-start mt-4">
                            <Col lg="9">
                                <OwlCarousel className="owl-theme" loop items="1" dots={true}>
                                    <div className="item">
                                        <div className="testimonial_blog_quotes_card h-auto">
                                            <div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial_blog_quotes_card h-auto">
                                            <div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial_blog_quotes_card h-auto">
                                            <div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                    <span className="me-1">
                                                        <img className="quote_grn_img" src={quote_grn} alt="" />
                                                    </span>
                                                    <div>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                        <h5>Cathy Wickersham</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </OwlCarousel>
                                <div className="testimonial_blog_quotes_card h-auto">
                                    <div>
                                        {
                                            allComments && allComments.length > 0 ?
                                                allComments.map((comment) => (

                                                    <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                                        <span className="me-1">
                                                            <img className="quote_grn_img" src={quote_grn} alt="" />
                                                        </span>
                                                        <div>
                                                            <p>{comment.comment}</p>
                                                            <h5>{comment.name}</h5>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <div className="py-4">
                                                    <p className="m-0 text-center fs-14">No Comment yet</p>
                                                </div>
                                        }
                                        <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                            <span className="me-1">
                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                            </span>
                                            <div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                <h5>Cathy Wickersham</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                            <span className="me-1">
                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                            </span>
                                            <div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                <h5>Cathy Wickersham</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-2 border-bottom mb-4 pb-2">
                                            <span className="me-1">
                                                <img className="quote_grn_img" src={quote_grn} alt="" />
                                            </span>
                                            <div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                <h5>Cathy Wickersham</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="7">
                                <Form>
                                    <Form.Group className="mb-3 mt-3" controlId="comments">
                                        <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment..." value="" />
                                    </Form.Group>
                                    <Form.Group className="mb-3 mt-3" controlId="comments">
                                        <Form.Control name="eail" type="text" placeholder="Enter Mail" value="" />
                                    </Form.Group>
                                    <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                        <Button variant="success" size="sm" className="px-4">Submit
                                            <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                            </svg>
                                        </Button>
                                    </Form.Group>

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                        {/* Testimonials End */}
                    </div>
                    <Modal dialogClassName="modal-dialog-centered"
                        show={commentPopup}
                        onHide={() => setCommentPopup(false)}
                        size="lg"
                    >
                        <Form className="p-4">
                            <h6 className="text_brand_color2">Write Comment</h6>
                            <Form.Group className="mb-3 mt-3" controlId="comments">
                                <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment..." value={newComments} className="fs-14 form-control" onChange={(e) => setNewComments(e.target.value)} />
                            </Form.Group>
                            {/* <Form.Group className="mb-3 mt-3" controlId="comments">
                        <Form.Control name="eail" type="text" placeholder="Enter Mail" value={email} className="fs-14 form-control" onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group> */}
                            <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                                <Button variant="success" size="sm" className="px-4" onClick={saveComment}>Submit
                                    <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                    </svg>
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal>
                    <Modal dialogClassName="modal-dialog-centered"
                        show={showUserAllBlogs}
                        onHide={() => setShowUserAllBlogs(false)}
                        size="lg"
                        className=""
                    >
                        <div className="p-4">
                            {/* {singleBlog.blogs} */}
                            <Row className="blogs_hdng pb-4">
                                <Col>
                                    <h4 className="border-bottom pb-2">Latest</h4>
                                </Col>
                            </Row>
                            <Row className="g-4">
                                {singleBlog.blogs && singleBlog.blogs.length > 0 ?
                                    singleBlog.blogs.map((blog, index) => {
                                        if (blog.is_popular == 'Yes') {
                                            return (
                                                <Col lg="4" sm="6">
                                                    <div className="latest_blog_card">
                                                        <div className="latest_blog_card_header">
                                                            <Row className="align-items-center">
                                                                <Col>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="">
                                                                            {blog.profile_pic ? (
                                                                                <img src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`} alt="" onError={(e) => defaultUserImage(e)} />
                                                                            ) : (
                                                                                <img src={dummy_coach1_sqr} alt="" />
                                                                            )}
                                                                        </div>
                                                                        <span className="ms-2 fs-10 fw-600">{blog.name}</span>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-auto">
                                                                    <span className="fs-14 fw-400">
                                                                        {blog.created_at ? (new Date(blog.created_at).toLocaleDateString('en-IN', {
                                                                            year: 'numeric',
                                                                            month: 'short',
                                                                            day: 'numeric'
                                                                        })) : ''}
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                        {blog.image ? (
                                                            <img className="latest_blog_img" src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/${blog.image}`} alt="" />
                                                        ) : (
                                                            <img src={mental_coach} alt="" />
                                                        )}
                                                        <div className="latest_blog_card_body">
                                                            <h5 className="fs-14 fw-600">{blog.title}</h5>
                                                            <p>{(blog.content.replace(/<[^>]+>/g, '')).substring(0, 200)}</p>

                                                            <NavLink to={`/blog-details/${blog.id}`}>Read More</NavLink>
                                                        </div>
                                                    </div>
                                                </Col>)
                                        }
                                    })
                                    :
                                    <div className="text-center">
                                        <img src={noblogs} alt="no blogs" />
                                        <p>No Published Blogs Yet</p>
                                    </div>
                                }
                            </Row>
                            {/* <Form className="p-4">
                        <h6 className="text_brand_color2">Write Comment</h6>
                        <Form.Group className="mb-3 mt-3" controlId="comments">
                            <Form.Control as="textarea" rows="4" name="comments" type="text" placeholder="Write a comment..." value={comments} className="fs-14 form-control" onChange={(e) => setComments(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 mt-3" controlId="comments">
                            <Form.Control name="eail" type="text" placeholder="Enter Mail" value={email} className="fs-14 form-control" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="text-end mb-3 mt-3" controlId="comments">
                            <Button variant="success" size="sm" className="px-4">Submit
                                <svg className="ms-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.7324 4.43823L2.34562 0.493302C1.77283 0.215717 1.09968 0.310693 0.627244 0.736127C0.152853 1.16303 -0.012131 1.82688 0.206706 2.42611C0.218455 2.45745 2.21687 6.21243 2.21687 6.21243C2.21687 6.21243 0.256152 9.96447 0.244892 9.99531C0.0270344 10.5955 0.193487 11.2584 0.668367 11.6843C0.962597 11.9477 1.3332 12.0838 1.70674 12.0838C1.93684 12.0838 2.16742 12.0324 2.38185 11.9267L10.7329 7.98417C11.4286 7.65763 11.8604 6.97762 11.8599 6.20949C11.8589 5.44136 11.4266 4.76233 10.7324 4.43774V4.43823ZM1.57945 1.89346C1.57749 1.87486 1.58288 1.85234 1.60981 1.82835C1.65289 1.78968 1.68667 1.80534 1.70527 1.81513C1.7087 1.81709 1.71261 1.81856 1.71555 1.82052L9.48694 5.47563H3.48827L1.57945 1.89346ZM1.74395 10.6043C1.72632 10.6136 1.69205 10.6303 1.64946 10.5916C1.62204 10.5671 1.61715 10.5436 1.61911 10.525L3.49268 6.94433H9.49771L1.74444 10.6048L1.74395 10.6043Z" fill="white" />
                                </svg>
                            </Button>
                        </Form.Group>
                    </Form> */}
                        </div>
                    </Modal>
                    <LoginComponent show={loginModalShow} onHide={() => setLoginModalShow(false)} />

                </>
            )
            }
        </>

    )
}

export default BlogDetailsComponent;