import React from 'react';
import '../../../assets/scss/_Header.scss';
import {Col, Container,Nav,Navbar,NavDropdown,Offcanvas} from 'react-bootstrap';
import logo from '../../../assets/images/logo_dark.png';
import { NavLink } from 'react-router-dom';
import LoginComponent from '../../Pages/Login/index';

function HomeHeaderSevenComponent() {

  const [loginModalShow, setLoginModalShow] = React.useState(false);

  return (
    <>
      {[ 'lg'].map((expand) => (
        <Navbar key={expand} expand={expand} sticky="top">
          <Container className="user_container">
            <Col lg="auto">
              <NavLink className="navbar-brand" to="/">
                <img src={logo} />
              </NavLink>
            </Col>
            <Col lg="auto">
              <NavLink className="navbar-brand" to="/" >
                <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" width="40" height="40" rx="20" fill="#00504E"/>
                  <g clip-path="url(#clip0_2348_3075)">
                      <path d="M28 19.5833V22.5H25.5C24.837 22.5 24.2011 22.7634 23.7322 23.2322C23.2634 23.7011 23 24.337 23 25V27.5H14.25C13.9185 27.5 13.6005 27.3683 13.3661 27.1339C13.1317 26.8995 13 26.5815 13 26.25V13.75C13 13.4185 13.1317 13.1005 13.3661 12.8661C13.6005 12.6317 13.9185 12.5 14.25 12.5H21.75C22.0815 12.5 22.3995 12.3683 22.6339 12.1339C22.8683 11.8995 23 11.5815 23 11.25C23 10.9185 22.8683 10.6005 22.6339 10.3661C22.3995 10.1317 22.0815 10 21.75 10H14.25C13.2554 10 12.3016 10.3951 11.5983 11.0983C10.8951 11.8016 10.5 12.7554 10.5 13.75V26.25C10.5 27.2446 10.8951 28.1984 11.5983 28.9016C12.3016 29.6049 13.2554 30 14.25 30H24.2367C25.2308 30 26.1843 29.6052 26.8875 28.9025L29.4017 26.3883C30.1047 25.6853 30.4998 24.7318 30.5 23.7375V19.5833C30.5 19.2518 30.3683 18.9339 30.1339 18.6994C29.8995 18.465 29.5815 18.3333 29.25 18.3333C28.9185 18.3333 28.6005 18.465 28.3661 18.6994C28.1317 18.9339 28 19.2518 28 19.5833Z" fill="white"/>
                      <path d="M25.3268 11.4937L14.9102 21.9103C14.7539 22.0666 14.6661 22.2785 14.666 22.4995V24.9995C14.666 25.2205 14.7538 25.4325 14.9101 25.5888C15.0664 25.745 15.2783 25.8328 15.4993 25.8328H17.9993C18.2203 25.8328 18.4323 25.745 18.5885 25.5887L28.8643 15.3128C29.2121 14.9775 29.4677 14.5584 29.6066 14.0958C29.7455 13.6332 29.7631 13.1426 29.6577 12.6712C29.5397 12.2328 29.3089 11.8329 28.9883 11.5115C28.6677 11.19 28.2684 10.9582 27.8303 10.8391C27.3922 10.72 26.9305 10.7177 26.4913 10.8326C26.0521 10.9474 25.6506 11.1754 25.3268 11.4937Z" fill="white"/>
                  </g>
                  <defs>
                      <clipPath id="clip0_2348_3075">
                          <rect width="20" height="20" fill="white" transform="translate(10.5 10)"/>
                      </clipPath>
                  </defs>
                </svg>
              </NavLink>
            </Col>
            <Col lg="auto">
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end" >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <NavLink to="/">
                      <img src={logo} />
                    </NavLink>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 align-items-center">
                    {/* <NavDropdown className='p-0' title="Browse Coach" id={`offcanvasNavbarDropdown-expand-${expand}`} >
                      <NavDropdown.Item href="#action3">Browse Coach</NavDropdown.Item>
                      <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                      <NavDropdown.Item href="#action5">Something</NavDropdown.Item>
                    </NavDropdown> */}
                    <NavLink className="nav-link" to="/about">About Us</NavLink>
                    <NavLink className="nav-link" to="/browse-coach/list-view">Browse Coach</NavLink>
                    <NavLink className="nav-link" to="/activity-stream">Activity Stream</NavLink>
                    <NavLink className="nav-link" to="/become-a-coach">Become  a Coach</NavLink>
                    <NavLink className="nav-link login_btn" onClick={() => setLoginModalShow(true)}>Login</NavLink>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Col>
          </Container>
        </Navbar>


      ))}
      <LoginComponent show={loginModalShow} onHide={() => setLoginModalShow(false)}/>
    </>
  );
}

export default HomeHeaderSevenComponent;