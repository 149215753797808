import React, { useEffect, useState } from "react";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { sessionForUsers } from "../../../utils/api";
import Modal from "react-awesome-modal";
import AgoraUIKit, { layout } from "agora-react-uikit";
import axios from "axios";
import AgoraComponent from "./../../../Front/Pages/agora/livestream";
import {
  Badge
} from "react-bootstrap";

const UserSessionActivitiesComponent = () => {
  const { user } = useUserContext();
  //   console.log(user);
  const navigate = useNavigate();
  const [sessionDetails, setSessionDetails] = useState({})
  const [allSession, setAllSessions] = useState([]);

  // AGORA Hooks
  const [channelName, setChanelName] = useState("");
  const [agoraToken, setAgoratoken] = useState("");
  const [videoCall, setVideocall] = useState(false);
  const [rtmToken, setRtmToken] = useState("");

  const [videoCallSessionId, setVideoCallSessionId] = useState('');
  const [videoCallSessionName, setVideoCallSessionName] = useState('');
  const app_id = "890c456575c247c7aab98108ca312bad";

  useEffect(() => {
    
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
    link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    
    getSessions();
  }, []);

  const getSessions = async () => {
    try {
      const data = await sessionForUsers();
      //   console.log(data);
      if (data) {
        setAllSessions(data.data.data);
      }
    } catch (error) {
      // toast.error("Something went wrong!");
    }
  };
  const generateToken = async (id, name, data) => {
    // console.log(data);
    setVideoCallSessionId(id)
    setVideoCallSessionName(name)
    setSessionDetails(data)
    setVideocall(true);
  };
  const styles = {
    container: {
      width: "100%",
      height: "100%",
      display: "grid",
      backgroundColor: "#111",
      objectFit: "cover",
    },
    heading: { textAlign: "center", marginBottom: 0 },
    videoContainer: {
      display: "grid",
      flexDirection: "column",
      backgroundColor: "#ffffff",
      width: "100%",
      height: "100%",
    },
    nav: { display: "block", justifyContent: "space-around" },

    btn: {
      backgroundColor: "#007bff",
      cursor: "pointer",
      borderRadius: 5,
      padding: 5,
      color: "#ffffff",
      fontSize: 20,
    },
    styleProps: {
      height: "50vh",
      width: "50vw",
      backgroundColor: "#FFF",
      maxViewStyles: {
        // marginTop:  "15rem",
        // height: "100%",
        width: "100%",
      },
    },
  };

  const completeSession = async () => {
    setVideocall(false);
  };
  let props = {
    rtcProps: {
      appId: app_id,
      channel: "",
      token: "",
    },
    callbacks: {
      EndCall: completeSession,
    },
  };

  // const generateToken = async (user_id) => {
  //   try {
  //     if (user_id == user?.user_id) {
  //       const data = await axios.post(
  //         "https://napi.qoach.io/api/v1/agora/token",
  //         {
  //           channelName: "testing",
  //           user_id: user.coach_id ? `${user.coach_id}` : `${user?.user_id}`,
  //         }
  //       );
  //       console.log(data);
  //       if (data) {
  //         console.log(data);
  //         setVideocall(true);
  //         setAgoratoken(data.data.data);
  //         setRtmToken(data.data.rtm_token);
  //       }
  //     } else {
  //       toast.error("Not Allowed to join the session!");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const defaultUserImage = (e) => {
    e.target.src = blog_card_img1;
  };
  return (
    <>
      <div className="mb-5">
        {/*  */}
        <div className="border-bottom">
          <b>Upcoming Live Session</b>

          {allSession.length > 0 ? (
            allSession.map((sec) => (
              <div className="position-relative d-flex align-items-center user-info mt-3 mb-2 justify-content-between">
                <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                  <img
                    width={"50px"}
                    height={"50px"}
                    alt="user pic"
                    className="object-fit-cover rounded-circle user_img me-2 position-relative z-2"
                    src={
                      sec?.coach_profile !== null
                        ? process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH +
                        sec.coach_profile
                        : blog_card_img1
                    }
                  // onError={(e) => defaultUserImage(e)}
                  />
                  <div className="user-name lh-1">
                    <span className="fs-16 fw-500">
                      {" "}
                      {sec.coach_name ? sec.coach_name : "Test User"}{" "}
                    </span>
                    {sec.session_status === "live" && (
                              <Badge style={{backgroundColor: "#fff"}}>Live</Badge>
                            )}
                    <div className="d-flex mt-1">
                      <small className="fs-12 me-1 d-inline-block overflow-hidden">
                        {/* {sec.professions
                          ? sec.professions
                          : "Career Guidance Coach"} */}
                        {sec.session_name
                          ? sec.session_name
                          : "Career Guidance Coach"}
                      </small>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="fs-16 fw-600 btn btn-success btn-md"
                    onClick={() => {
                      if(sec.session_status === "live"){
                        generateToken(sec?.id, sec?.session_name, sec);

                      }else{
                        alert("Session is not live!")
                      }
                    }}
                  >
                    {sec.session_status === "live" ? "Join" : "Yet to live" }
                  </button>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="text-center my-5 pt-5">
                <p>No live session available yet.</p>
              </div>
            </>
          )}
        </div>
        <div className="mt-4 border-bottom">
          {/* <b>Upcoming Scheduled Sessions</b>
          <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
            <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
              <img
                width={"50px"}
                height={"50px"}
                alt="user pic"
                className="object-fit-cover rounded-circle user_img me-2 position-relative z-2"
                src={blog_card_img1}
                onError={(e) => defaultUserImage(e)}
              />
              <div className="user-name lh-1">
                <span className="fs-16 fw-500">Jack Mick</span>
                <div className="d-flex mt-1"> */}
          {/* {
                                                    post.tag_names !== null ? */}
          {/* <small className="fs-10 me-1 d-inline-block overflow-hidden" style={{ width: '200px' }}>{post.tag_names}</small> */}
          {/* <small className="fs-12 me-1 d-inline-block overflow-hidden">
                    Project Manager, IT Recruiter, Career
                  </small>
                
                  <small className="fs-10 me-1 d-inline-block text-white">
                    Manager
                  </small>
                </div>
              </div>
            </div>
            <div>
              <span className="text-body-secondary fs-14">
                25 March’23 @ 08:00 - 09:00 IST
              </span>
            </div>
          </div> */}

          {/* <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                          
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                                    
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                   
                                </div>
                            </div>
                        </div>
                        <div>
                            <span className="text-body-secondary fs-14">25 March’23 @ 08:00 - 09:00 IST</span>
                        </div>
                    </div> */}

          {/* <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                                   
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                   
                                </div>
                            </div>
                        </div>
                        <div>
                            <span className="text-body-secondary fs-14">25 March’23 @ 08:00 - 09:00 IST</span>
                        </div>
                    </div> */}
        </div>
        {/* <div className="mt-4 border-bottom">
                    <b>Upcoming Unscheduled Sessions</b>
                    <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                             
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                  
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="px-5 btn btn-warning">Schedule Now</button>
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                            
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                                 
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                  
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="px-5 btn btn-warning">Schedule Now</button>
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                         
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                                 
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                    
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="px-5 btn btn-warning">Schedule Now</button>
                        </div>
                    </div>
                </div> */}
        {/* <div className="mt-4 border-bottom">
                    <b>Past Attended Sessions</b>
                    <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                              
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                         
                                </div>
                            </div>
                        </div>
                        <div>
                            <span className="text-body-secondary fs-14">25 March’23 @ 08:00 - 09:00 IST</span>
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                                 
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                  
                                </div>
                            </div>
                        </div>
                        <div>
                            <span className="text-body-secondary fs-14">25 March’23 @ 08:00 - 09:00 IST</span>
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center user-info mt-2 mb-2 justify-content-between">
                        <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                            <img width={'50px'} height={'50px'} alt="user pic" className="object-fit-cover rounded-circle user_img me-2 position-relative z-2" src={blog_card_img1} onError={(e) => defaultUserImage(e)} />
                            <div className="user-name lh-1">
                                <span className="fs-16 fw-500">Jack Mick</span>
                                <div className="d-flex mt-1">
                             
                                    <small className="fs-12 me-1 d-inline-block overflow-hidden">Project Manager, IT Recruiter, Career</small>
                                   
                                </div>
                            </div>
                        </div>
                        <div>
                            <span className="text-body-secondary fs-14">25 March’23 @ 08:00 - 09:00 IST</span>
                        </div>
                    </div>
                </div> */}
      </div>
      {
        videoCall ?
        <AgoraComponent
        videoCallSessionId={videoCallSessionId}
        videoCallSessionName={videoCallSessionName}
        sessionDetails={sessionDetails}
      />
          : ''
      }
    </>
  );
};

export default UserSessionActivitiesComponent;
