
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CreateCaseStudyModal from "./CreateCaseStudy";
import EditCaseStudyModal from "./EditCaseStudy"
import { useUserContext } from "../../../utils/hooks/UserContext";
import { coachGetMyCaseStudies } from "../../../utils/api";
import moment from 'moment';
import { toast } from 'react-toastify';
import Pic from '../../../assets/images/icons/user.png';
import { coachDeleteCaseStudies } from "../../../utils/api";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function CoachMyActivityCaseStudyComponent({ onHide }) {
    const { user } = useUserContext()
    const [studies, setStudies] = useState([]);
    const [caseStudyModalShow, setCaseStudyModalShow] = React.useState(false);

    const [expandedProblem, setExpandedProblem] = useState(false);
    const [expandedSolution, setExpandedSolution] = useState(false);
    const [expandedImplementation, setExpandedImplementation] = useState(false);
    const [editData, setEditData] = useState(false)
    const [showCaseStudyPopup, setShowCaseStudyPopup] = useState(false);


    const toggleCaseStudyPopup = () => {
        setShowCaseStudyPopup(!showCaseStudyPopup);
    };

    const onClickEdit = (i) => {
        setEditData(i)
        setShowCaseStudyPopup(true)
    }
    const handleDeleteClick = (id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this case study?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await coachDeleteCaseStudies(id);
                            toast.success('Deleted successfully!!')
                            getData()
                        } catch (error) {
                            console.error('Error deleting item', error);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const getData = async () => {
        const { data } = await coachGetMyCaseStudies()
        setStudies(data?.data)
    }

    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData()
    }, [])

    const toggleExpanded = (section) => {
        if (section === "problem") {
            setExpandedProblem(!expandedProblem);
        } else if (section === "solution") {
            setExpandedSolution(!expandedSolution);
        } else if (section === "implementation") {
            setExpandedImplementation(!expandedImplementation);
        }
    };

    return (
        <>
            <Row className="justify-content-center">
                <Col lg="12">
                    <Row className="justify-content-center">
                        <Col lg="8">

                            <NavLink onClick={() => setCaseStudyModalShow(true)}>
                                <Row className="my-4">
                                    <Col className="col-auto">
                                        <div className="sp_user">
                                            {user?.profile_pic && <img src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + user?.profile_pic} alt="" />}
                                            {!user?.profile_pic && <img src={Pic} alt="" />}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="start_post_card">
                                            <span>Write a case study...</span>
                                        </div>
                                    </Col>
                                </Row>
                            </NavLink>
                            {
                                studies && studies.length > 0 ?
                                    studies?.map((study, i) => {
                                        return (
                                            <div className="asf_card">
                                                <div className="asf_card_header casestudy-main">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <div className="user_img_sm_rnd">
                                                                {user?.profile_pic && <img width={45} height={45} className="rounded-circle" src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + user?.profile_pic} alt="" />}
                                                                {!user?.profile_pic && <img width={45} height={45} className="rounded-circle" src={Pic} alt="" />}
                                                            </div>
                                                            <div className="ms-2">
                                                                <h5 className="fs-16 fw-500 m-0">
                                                                    {user.name}
                                                                    <span className="hrs position-relative fs-10 fw-500 ps-3 ms-3">{moment(study?.created_at).fromNow(true)}</span>
                                                                </h5>
                                                                {user?.tags?.map((i) => {
                                                                    return <label>{i}</label>
                                                                })}
                                                            </div>
                                                        </div>
                                                        <Col sm='auto'>
                                                            <Dropdown >
                                                                <Dropdown.Toggle variant="ellipsis" as={Button} className="p-0 h-auto fs-24 fw-700">
                                                                    &#8943;
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => onClickEdit(study)}>Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => handleDeleteClick(study?.id)}>Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Col>
                                                    </div>
                                                </div>
                                                <div className="asf_card_body">
                                                    <div className="p10">
                                                        <p className="fs-20 fw-600">
                                                            (Problem or Issue){" "}
                                                            {study?.problem_issues.length > 250 && !expandedProblem
                                                                ? study?.problem_issues.substring(0, 250) + "..."
                                                                : study?.problem_issues}
                                                            {study?.problem_issues.length > 250 && (
                                                                <button className="readmore-btn" onClick={() => toggleExpanded("problem")}>
                                                                    {expandedProblem ? "Read Less" : "Read More"}
                                                                </button>
                                                            )}
                                                        </p>
                                                        <p className="fs-14 fw-400">
                                                            (Solution){" "}
                                                            {study?.solution.length > 1000 && !expandedSolution
                                                                ? study?.solution.substring(0, 1000) + "..."
                                                                : study?.solution}
                                                            {study?.solution.length > 1000 && (
                                                                <button className="readmore-btn" onClick={() => toggleExpanded("solution")}>
                                                                    {expandedSolution ? "Read Less" : "Read More"}
                                                                </button>
                                                            )}
                                                        </p>
                                                        <p className="fs-14 fw-400">
                                                            <i>(Implementation){" "}
                                                                {study?.implementation.length > 1000 && !expandedImplementation
                                                                    ? study?.implementation.substring(0, 1000) + "..."
                                                                    : study?.implementation}
                                                                {study?.implementation.length > 1000 && (
                                                                    <button className=" readmore-btn" onClick={() => toggleExpanded("implementation")}>
                                                                        {expandedImplementation ? "Read Less" : "Read More"}
                                                                    </button>
                                                                )}
                                                            </i>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    :
                                    <div className="text-center my-5 pt-5">
                                        <p>No case study available yet.</p>
                                    </div>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <EditCaseStudyModal editData={editData} getData={getData} show={showCaseStudyPopup} onHide={() => setShowCaseStudyPopup(false)} />
            <CreateCaseStudyModal getData={getData} show={caseStudyModalShow} onHide={() => setCaseStudyModalShow(false)} />
        </>
    )
}

export default CoachMyActivityCaseStudyComponent;