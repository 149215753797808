import { createContext, useState, useEffect, useContext } from 'react';
import { getLoggedInUser } from '../api';

// Create the UserContext
const UserContext = createContext();

// Custom hook to use the UserContext
export const useUserContext = () => useContext(UserContext);

// Provider component to wrap the app and provide the UserContext data
export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    // Check if there's a token in the local storage when the app mounts
    const token = localStorage.getItem('token');
    if (token) {
      // Call the function to get user data from the token
      getUserFromToken(token);
    }

    if(!token){
      setisLoading(false)
    }
  }, []);

  // Function to get user data from the token
  const getUserFromToken = async (token = localStorage.getItem("token")) => {
    setisLoading(true)
    const formData = new FormData()
    formData.append('token', token)

    try {
      const userFromToken = await getLoggedInUser(formData);
      setUser(userFromToken?.data?.data);
    } catch (error) {
      console.info("error",error)
    } finally {
      setisLoading(false)
    }
  };

  // Function to handle user logout
  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem('token');
    // Clear the user data from the state
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ isLoading, user, getUserFromToken, handleLogout }}>
      {children}
    </UserContext.Provider>
  );
};
