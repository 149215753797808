import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import contact_bg from '../../../assets/images/contact_bg.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2';
import { toast } from "react-toastify";
import { contactus } from "../../../utils/api";
import { Helmet } from "react-helmet";

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().matches(/^[0-9]{10}$/, "Invalid phone number"),
    message: Yup.string().required("Message is required"),
});


const ContactUsComponent = () => {
    const [phone, setPhone] = useState("");



    
    const onSubmitHandler = async (values, { resetForm }) => {
        try {
            const formData = new FormData();
            formData.append("first_name", values.firstName);
            formData.append("last_name", values.lastName);
            formData.append("email_id", values.email);
            formData.append("phone", phone);
            formData.append("message", values.message);


            await contactus(formData);
            
            toast.success('Thank you for contacting us!!, we will contact you soon')
            
            resetForm();
            setPhone("");
            
        } catch (error) {

        }
    }

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            message: "",
        },
        validationSchema: validationSchema,
        onSubmit: onSubmitHandler
    });

    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                title="Get in Touch with Qoach | Contact Us Today"
                meta={[
                    { name: 'description', content: "Have questions or feedback? We'd love to hear from you! Contact the Qoach team today for personalized assistance, inquiries about our services, or partnership opportunities. We're here to support you on your journey to success!" },
                ]}
            />
            <section className="white_bg">
                <Container fluid className="p-0">
                    <Row className="justify-content-center g-0">
                        <Col lg="auto">
                            <Image src={contact_bg} />
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                    <Col lg="11" className="p-3 p-md-5">
                                        <div className="hdng_a">
                                            <h6 className="text-uppercase">Contact Us</h6>
                                            <h2 className="">We’d Love to Help</h2>
                                        </div>
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Row className="mt-3">
                                                <Col sm="12" lg="6">
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter First Name"
                                                            name="firstName"
                                                            value={formik.values.firstName}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.firstName && formik.errors.firstName && (
                                                            <div className="error">{formik.errors.firstName}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm="12" lg="6">
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Last Name"
                                                            name="lastName"
                                                            value={formik.values.lastName}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.lastName && formik.errors.lastName && (
                                                            <div className="error">{formik.errors.lastName}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm="12" lg="6">
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Email*</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="ss100ev@gmail.com"
                                                            name="email"
                                                            value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.email && formik.errors.email && (
                                                            <div className="error">{formik.errors.email}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm="12" lg="6">
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Phone Number</Form.Label>
                                                        <PhoneInput
                                                            country={"us"}
                                                            enableSearch={true}
                                                            name="phoneNumber"
                                                            value={phone}
                                                            onChange={(phone) => setPhone(phone)}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                                            <div className="error">{formik.errors.phoneNumber}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm="12" lg="12">
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label>Message</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows="4"
                                                            type="text"
                                                            placeholder="Write your message here......"
                                                            name="message"
                                                            value={formik.values.message}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.message && formik.errors.message && (
                                                            <div className="error">{formik.errors.message}</div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm="12" lg="12">
                                                    <div className="text-center mt-4">
                                                        <Button className="px-5" variant="success" type="submit">
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default ContactUsComponent;