import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "../../../utils/api";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const validationSchema = Yup.object().shape({
  pwd: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z](?=.*[0-9])/, "Password Should be alphanumeric"),
  pwdConfirmation: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z](?=.*[0-9])/, "Password Should be alphanumeric"),
});

const UserSettingComponent = () => {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmitHandler = async (values) => {
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("password", values.pwd);
      formData.append("new_password", values.pwdConfirmation);

      const { data } = await userUpdate(formData);

      if (data?.status) {
        toast.success(data?.message);
        // navigate("/");
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title=" Your Qoach Settings | Customize Your Coaching Experience"
        meta={[
          { name: 'description', content: "Tailor your coaching experience to your preferences with Qoach Settings. From notification preferences to privacy settings, take control of how you interact with your coaches and manage your account. Empower your journey with personalized settings today!" },
        ]}
      />
      <section>
        <div className="text-start hdng_a">
          <h2>Change Password</h2>
        </div>
        <Row className="mt-5">
          <Col lg="6 m-auto">
            <Formik
              initialValues={{ pwd: "", pwdConfirmation: "", userType: "" }}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="old_password">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup className="password_vh mb-3">
                      <Form.Control
                        placeholder="xyzabe@123"
                        name="pwd"
                        type={isRevealPwd ? "text" : "password"}
                        value={values.pwd}
                        onChange={handleChange}
                        isInvalid={touched.pwd && !!errors.pwd}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <img
                          alt=""
                          width="20"
                          title={
                            isRevealPwd ? "Hide password" : "Show password"
                          }
                          src={isRevealPwd ? view : hidden}
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {errors.pwd}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="new_password">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup className="password_vh mb-3">
                      <Form.Control
                        placeholder="xyzabe@123"
                        name="pwdConfirmation"
                        type={isRevealConfirmPwd ? "text" : "password"}
                        value={values.pwdConfirmation}
                        onChange={handleChange}
                        isInvalid={
                          touched.pwdConfirmation && !!errors.pwdConfirmation
                        }
                      />
                      <InputGroup.Text id="basic-addon2">
                        <img
                          alt=""
                          width="20"
                          title={
                            isRevealConfirmPwd
                              ? "Hide password"
                              : "Show password"
                          }
                          src={isRevealConfirmPwd ? view : hidden}
                          onClick={() => setIsRevealConfirmPwd(!isRevealConfirmPwd)}
                        />
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {errors.pwdConfirmation}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="d-grid mb-3">
                    <Button
                      disabled={isLoading}
                      variant="success"
                      className=""
                      type="submit"
                    >
                      Update password
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default UserSettingComponent;
