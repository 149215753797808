import React, { useState, useRef } from 'react';
import close from '../../assets/images/icons/close.png';
import { Button, Col, Container, Modal, Row, Form } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { coachAddShortVideo } from '../../utils/api';



const validationSchema = Yup.object().shape({
    tags: Yup.string().required('tags is required'),
    video: Yup.mixed().test('videoRequired', 'Video is required', (value) => {
        return value && (value.type.includes('video'));
    }),
});

function ShortVideoUploadComponent(props) {

    // FIles States
    const [videoPreview, setVideoPreview] = useState(null);
    const [file, setfile] = useState(null)

    // FIle Picker Ref because we are not useing the standard File picker input
    const filePicekerRef = useRef(null);

    function previewFile(e) {
        // Reading New File (open file Picker Box)
        const reader = new FileReader();

        // Gettting Selected File (user can select multiple but we are choosing only one)
        const selectedFile = e.target.files[0];
        setfile(selectedFile)
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = (readerEvent) => {
            if (selectedFile.type.includes("video")) {
                setVideoPreview(readerEvent.target.result);
            }
        };
    }
    function clearFiles() {
        setVideoPreview(null);
    }
    const onSubmit = async (values, { setSubmitting }) => {
        const formData = new FormData()
        formData.append('file',file)
        formData.append('tags',values?.tags)
        await coachAddShortVideo(formData)
        props.getData()

        // Clear form and close modal after successful submission
        setSubmitting(false);
        setVideoPreview(null);
        setfile(null)
        props.onHide();
    }


    return (
        <div >
            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className='shortvideo-model' >
                <div className="white_bg my_light_modal ">
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm="12">
                                <div className="ls_modal_conatiner">
                                    <Button className="ls_mdl_close_btn p-0" onClick={props.onHide}>
                                        <img alt="" src={close} />
                                    </Button>
                                    <div className="text-start text_brand_color2">
                                        <h6 className="fs-20 fw-500">Upload short video</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <Formik
                                    initialValues={{
                                        video: null,
                                        tags: ""
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ errors, touched, setFieldValue, values, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <div>
                                                <div className="">
                                                    <input
                                                        ref={filePicekerRef}
                                                        accept=" video/*"
                                                        onChange={(e) => {
                                                            setFieldValue("video", e.currentTarget.files[0]);
                                                            previewFile(e);
                                                        }}
                                                        type="file"
                                                        hidden
                                                    />
                                                </div>
                                                <div className="short_video preview">
                                                    {videoPreview != null && <video controls src={videoPreview}></video>}
                                                    {(videoPreview) && (
                                                        <Button className="preview_close_btn" onClick={clearFiles}>
                                                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18.3713 0.724455L11.3455 8.52748L3.54244 1.5016C2.82419 0.854895 1.71766 0.912885 1.07095 1.63113C0.424245 2.34937 0.482235 3.4559 1.20048 4.10261L9.0035 11.1285L1.97763 18.9315C1.33092 19.6497 1.38891 20.7563 2.10715 21.403C2.8254 22.0497 3.93192 21.9917 4.57863 21.2735L11.6045 13.4704L19.4075 20.4963C20.1258 21.143 21.2323 21.085 21.879 20.3668C22.5257 19.6485 22.4677 18.542 21.7495 17.8953L13.9465 10.8694L20.9723 3.06641C21.619 2.34817 21.5611 1.24164 20.8428 0.59493C20.1246 -0.0517789 19.018 0.00621183 18.3713 0.724455Z" fill="#00504E" />
                                                            </svg>
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            {!videoPreview && <div className=' mt-3 d-flex justify-content-center'>
                                                <div className='Upload-btn'>
                                                    <Button variant='' onClick={() => filePicekerRef.current.click()} className='d-block height-auto'>
                                                        <svg width="50" height="51" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M25.8639 22.9381L35.1335 13.6653L35.2035 59.1033C35.2035 61.8646 37.442 64.1031 40.2033 64.1031C42.9645 64.1031 45.203 61.8646 45.203 59.1033L45.133 13.722L54.3494 22.9383C56.2677 24.9245 59.4329 24.9795 61.4191 23.0611C63.4052 21.1428 63.4602 17.9776 61.5419 15.9914C61.5016 15.9497 61.4606 15.9088 61.4191 15.8686L50.7128 5.16235C44.8552 -0.695367 35.3582 -0.695367 29.5004 5.16219L29.5003 5.16235L18.7942 15.8685C16.8758 17.8546 16.9308 21.0198 18.917 22.9381C20.8546 24.8095 23.9263 24.8095 25.8639 22.9381Z" fill="#14183E" />
                                                            <path d="M74.9968 49.1035C72.2355 49.1035 69.997 51.342 69.997 54.1033V69.4059C69.9951 70.1581 69.3859 70.7674 68.6338 70.7691H11.3628C10.6106 70.7673 10.0013 70.1581 9.99957 69.4059V54.1033C9.99957 51.342 7.76107 49.1035 4.99978 49.1035C2.2385 49.1035 0 51.342 0 54.1033V69.4059C0.00734343 75.6785 5.09041 80.7614 11.3628 80.7687H68.6336C74.9062 80.7614 79.9891 75.6783 79.9964 69.4059V54.1033C79.9966 51.342 77.7581 49.1035 74.9968 49.1035Z" fill="#14183E" />
                                                        </svg>
                                                        <div className="inner-title ms-2">Upload the file / Drag the file</div>
                                                    </Button>
                                                </div>
                                            </div>}
                                            {errors.video && touched.video && (
                                                <div className="text-danger">{errors.video}</div>
                                            )}
                                            <Form.Group className="mb-3 mt-2" controlId="email">
                                                <Form.Label>Tags</Form.Label>
                                                <Form.Control
                                                    name="tags"
                                                    type="text"
                                                    placeholder="Write tags you want, Seperated By commas"
                                                    value={values.tags}
                                                    isInvalid={touched.tags && !!errors.tags}
                                                    onChange={(e) => setFieldValue("tags", e?.target?.value)
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.tags}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <div className='post-btn'>
                                                <Button variant='success' type='submit' size='md' className='d-block'>Post</Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal>
        </div>
    )
}

export default ShortVideoUploadComponent;