import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { blogList, allCategories, editBlog } from "../../../utils/api";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";
import noblogs from "../../../assets/images/no-blogs.png";
import Modal from "react-awesome-modal";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function DraftComponent() {
    const [modalvisible, setModalVisible] = useState(false);
    const [popularBlogs, setPopularBlogs] = useState([]);
    const [allBlogInfo, setAllBlogInfo] = useState('');

    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [blogDate, setBlogDate] = useState('');
    const [images, setImage] = useState('');
    const [blogId, setBlogId] = useState('');
    const [content, setContent] = useState('');
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [editorLoaded, setEditorLoaded] = useState(false);

    useEffect(() => {
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        setEditorLoaded(true); // Set editorLoaded to true when the component is fully rendered
        const fetchData = async () => {
            try {
                const data = await blogList();
                if (data.data.data) {
                    // setPopularBlogs(data.data.data.published)
                    setPopularBlogs(data.data.data.draft)
                    // setPopularBlogs(data.data.data.pending)
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
        getData();
    }, []);
    const getData = async () => {
        const allcat = await allCategories();
        if (allcat) {
            setCategories(allcat.data.data)
        }
    }
    const removeHTMLTags = (str) => {
        return str.replace(/<[^>]+>/g, '');
    };
    const defaultUserImage = (e) => {
        e.target.src = testimonials_img1;
    };
    const defaultImage = (e) => {
        e.target.src = blog_card_img1;
    };
    function getDate(time) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const date = new Date(time).getDate();
        const year = new Date(time).getFullYear();
        const month = months[new Date(time).getMonth()];
        let hour = new Date(time).getHours();
        let minute = new Date(time).getMinutes();
        const am_pm = hour > 12 ? "PM" : "AM";
        const time_string = `${date} ${month} ${year}`;
        return time_string;
    }
    const openModal = (data) => {
        // console.log(data);
        setModalVisible(true)
        // setAllBlogInfo(data)
        setTitle(data.title)
        setCategory(data.category)
        setBlogDate(data.blogDate)
        setImage(data.images)
        setContent(data.content)
        setBlogId(data.id)
    }
    const handleFileChange = (event) => {
        // console.log(event.target.files[0]);
        const files = event.target.files[0];
        setImage(files);
    };
    const handleChange = (event, editor) => {
        const data = editor.getData();
        if (data) {
            setContent(data)
        }
    };
    const saveBlogs = async (type) => {
        const formData = new FormData()
        formData.append('title', title)
        formData.append('category_id', category)
        formData.append('blog_date', blogDate)
        formData.append('images', images)
        formData.append('content', content)
        formData.append('status', type)
        formData.append('id', blogId)

        const newdata = await editBlog(formData);
        // console.log(newdata.data.status);
        if (newdata.data.status) {
            toast.success('Blog created successfully')
            navigate("/coach/my-blogs");
        } else {
            toast.success('Somthing went wrong')
        }
    }
    return (
        <>
            <Row>
                {
                    popularBlogs && popularBlogs.length > 0 ?
                        popularBlogs.map((blog, index) => (
                            <Col sm="6" lg="4" className="mb-4" key={index}>
                                <svg onClick={(e) => openModal(blog)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" className="edit-btn position-absolute bg-white z-1 rounded-circle p-1" width="35px" height="35px"><path d="M38.406 22.234l11.36 11.36L28.784 54.576l-12.876 4.307c-1.725.577-3.367-1.065-2.791-2.79l4.307-12.876L38.406 22.234zM41.234 19.406l5.234-5.234c1.562-1.562 4.095-1.562 5.657 0l5.703 5.703c1.562 1.562 1.562 4.095 0 5.657l-5.234 5.234L41.234 19.406z" /></svg>
                                <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>
                                    <div className="blog_card border rounded-4 h-100 overflow-hidden">
                                        <div className="blog_card_img">
                                            <img
                                                alt=""
                                                className="w-100"
                                                src={blog.image ? `${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}` : blog_card_img1}
                                                onError={defaultImage}
                                            />
                                        </div>
                                        <div className="blog_card_body px-3">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        width={30}
                                                        height={30}
                                                        alt=""
                                                        className="user_img rounded-circle me-2"
                                                        src={blog.profile_pic ? `${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}` : testimonials_img1}
                                                        style={{ borderRadius: '50%' }}
                                                        onError={defaultUserImage}
                                                    />
                                                    <h5 className="m-0 fs-18">{blog.name ? blog.name : 'Jack Mick'}</h5>
                                                </div>
                                                <span className="fs-12 fw-500 opacity_7">
                                                    {getDate(blog.blog_date !== null ? blog.blog_date : blog.updated_at)}
                                                </span>
                                            </div>
                                            <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines">{blog?.title}</h5>
                                            {/* <p className="fs-14 fw-300 opacity_7 mb-3">{removeHTMLTags(blog.content).substring(0, 125)}...</p> */}
                                            <p className="fs-14 fw-300 opacity_7 mb-3">{removeHTMLTags(blog.content)}</p>
                                            <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>Read More</NavLink>
                                        </div>
                                    </div>
                                </NavLink>
                            </Col>
                        ))
                        :
                        <div className="text-center">
                            <img src={noblogs} alt="no blogs" />
                            <p>No Published Blogs Yet</p>
                        </div>
                }
            </Row>
            <Modal
                visible={modalvisible}
                onClickAway={() => setModalVisible(false)}
                width="800px"
                height="90%"
            >
                <div className="bg-body-tertiary p-4">
                    <div className="d-flex align-items-center ">
                        <b className="d-block text-center w-100">Edit blog</b>
                    </div>
                    <div className="my-5">
                        <div className="mb-3">
                            <label className="form-label fw-500" for="title">Title</label>
                            <input type="text" className="form-control fs-14" id="title" name="title" placeholder="title" onChange={(e) => setTitle(e.target.value)} value={title} required />
                        </div>
                        {/* <div className="mb-3 form-group">
                        <label for="user_id" className="form-label fw-500">Select User</label>
                        <select name="user_id" id="user_id"
                            className="form-control fs-14" required>
                            <option value="">Choose User</option>
                        </select>
                    </div> */}
                        <div className="mb-3 form-group">
                            <label for="category_id" className="form-label fw-500">Category</label>
                            {
                                console.log(categories, 'categories')
                            }
                            <select name="category" id="category_id"
                                className="form-control fs-14" required onChange={(e) => setCategory(e.target.value)} value={category} >
                                <option value="">Select</option>
                                {
                                    categories && categories.length > 0 ?
                                        categories.map((post) => (
                                            <option value={post.id}>{post.name}</option>
                                        ))
                                        : ''
                                }
                            </select>
                        </div>
                        <div className="mb-3 form-group">
                            <label for="blog_date" className="form-label fw-500">Blog Date</label>
                            <input type="date" name="blogdate" value={blogDate} id="blogdate" onChange={(e) => setBlogDate(e.target.value)} className="form-control fs-14 batch_timing_to" />
                            <label className="d-block error-label batch-timing-to-error" for=""></label>
                        </div>
                        <div className="mb-3 form-group">
                            <label className="form-label fw-500" for="car_model">Blog Image</label>
                            <input
                                type="file" accept="image/png, image/jpeg"
                                className="form-control fs-14"
                                id="images"
                                name="images"
                                placeholder="Enter image"
                                onChange={(e) => handleFileChange(e)}
                            />
                            <small className="mt-2 fs-12">We recommend uploading image size 1920 x 1080 pixels.</small>
                        </div>
                        <div className="form-group">
                            <label for="" className="form-label fw-500">Content</label>
                            {/* <textarea name="content" id="content" className="form-control" required> </textarea>
                        <label
                            className="d-block error-label"
                            for="content"
                            id="nmt_text_label"
                        ></label> */}
                            {/* {editorLoaded && (
                        <CKEditor
                            className="fs-14"
                            // editor={ClassicEditor}
                            // data="<p>Hello from CKEditor&nbsp;5!</p>"
                            onReady={(editor) => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={() => handleChange() }
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                        )} */}
                            {editorLoaded ? (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data="<p>Hello from CKEditor 5!</p>"
                                    onReady={(editor) => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={handleChange}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                            ) : (
                                <p>Loading Editor...</p>
                            )}
                        </div>
                        <div className="mt-3 text-end">
                            {/* <button className="btn btn-success me-2">
                            <svg className="me-2" width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.1971 6.51893C19.3577 2.80086 15.5222 0.217773 11.0996 0.217773C6.67708 0.217773 2.84159 2.80086 1.00212 6.51893C0.884709 6.75376 0.884709 7.02772 1.00212 7.22341C2.84159 10.9415 6.67708 13.5246 11.0996 13.5246C15.5222 13.5246 19.3577 10.9415 21.1971 7.22341C21.3145 6.98858 21.3145 6.75376 21.1971 6.51893ZM11.0996 11.1763C8.71223 11.1763 6.79449 9.25856 6.79449 6.87117C6.79449 4.48377 8.71223 2.56603 11.0996 2.56603C13.487 2.56603 15.4048 4.48377 15.4048 6.87117C15.4048 9.25856 13.487 11.1763 11.0996 11.1763Z" fill="white" />
                            </svg>
                            Preview</button> */}
                            <button className="btn btn-success me-3" onClick={(e) => saveBlogs('Draft')}>Save Draft</button>
                            <button className="btn btn-success" onClick={(e) => saveBlogs('Active')}>Save</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default DraftComponent;
