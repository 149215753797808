import React from 'react';
import '../../../assets/scss/_Header.scss';
import {Button, Col, Container,Dropdown,Nav,NavDropdown,Navbar,Offcanvas} from 'react-bootstrap';
import logo from '../../../assets/images/logo_dark.png';
import user from '../../../assets/images/icons/user.png';
import { NavLink } from 'react-router-dom';

function HomeHeaderThreeComponent() {
  return (
    <>

      {[ 'lg'].map((expand) => (
        <Navbar key={expand} expand={expand} sticky="top">
          <Container className="user_container">
            <Col lg="auto">
              <NavLink className="navbar-brand" to="/">
                <img src={logo} alt='' />
              </NavLink>
            </Col>
            <Col lg="auto">
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end" >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <NavLink to="/">
                      <img src={logo} alt="" />
                    </NavLink>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 align-items-center">
                    {/*<NavDropdown className='p-0' title="Browse Coach" id={`offcanvasNavbarDropdown-expand-${expand}`} >
                      <NavDropdown.Item href="#action3">Browse Coach</NavDropdown.Item>
                      <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                      <NavDropdown.Item href="#action5">Something</NavDropdown.Item>
                    </NavDropdown>*/}
                    <NavLink className="nav-link" to="/about">About Us</NavLink>
                    <NavLink className="nav-link" to="/browse-coach/list-view">Browse Coach</NavLink>
                    <NavLink className="nav-link" to="/activity-stream">Activity Stream</NavLink>
                    <NavLink className="nav-link" to="/become-a-coach">Become  a Coach</NavLink>
                    {/* <NavLink className="nav-link" onClick={() => setLoginModalShow(true)}>Shubham</NavLink> */}
                    <NavLink className="nav-link notification">
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2765_545)">
                          <path d="M3.89777 15.731H16.9444C17.346 15.731 17.7416 15.6343 18.0978 15.449C18.4541 15.2638 18.7605 14.9955 18.9911 14.6668C19.2217 14.3381 19.3697 13.9586 19.4227 13.5606C19.4756 13.1626 19.4319 12.7577 19.2953 12.3801L16.9186 5.80929C16.5127 4.34905 15.6397 3.06195 14.433 2.14485C13.2264 1.22775 11.7525 0.731134 10.2369 0.730957C8.65921 0.730932 7.12864 1.26895 5.89795 2.25617C4.66725 3.24339 3.81001 4.6208 3.46777 6.16096L1.50944 12.4943C1.39414 12.8682 1.36822 13.264 1.43376 13.6497C1.49931 14.0355 1.65449 14.4005 1.88681 14.7153C2.11914 15.0302 2.42213 15.2861 2.77141 15.4625C3.12068 15.6389 3.50648 15.7309 3.89777 15.731Z" fill="white"/>
                          <path d="M6.4248 17.3977C6.61607 18.3396 7.12708 19.1864 7.87126 19.7947C8.61543 20.4029 9.54701 20.7352 10.5081 20.7352C11.4693 20.7352 12.4008 20.4029 13.145 19.7947C13.8892 19.1864 14.4002 18.3396 14.5915 17.3977H6.4248Z" fill="white"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_2765_545">
                            <rect width="20" height="20" fill="white" transform="translate(0.507812 0.730957)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </NavLink>
                    <NavLink  className="nav-link available_credit">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                        <path d="M17.5 3.99935H4.16667C3.45083 3.99935 2.77333 3.68935 2.30417 3.16685C2.7625 2.65518 3.42833 2.33268 4.16667 2.33268H19.1667C20.2567 2.32768 20.2558 0.670182 19.1667 0.666016H4.16667C1.86583 0.666016 0 2.53185 0 4.83268V13.166C0 15.4668 1.86583 17.3327 4.16667 17.3327H17.5C18.8808 17.3327 20 16.2135 20 14.8327V6.49935C20 5.11852 18.8808 3.99935 17.5 3.99935ZM16.6667 11.4993C15.5767 11.4943 15.5767 9.83768 16.6667 9.83268C17.7567 9.83768 17.7567 11.4943 16.6667 11.4993Z" fill="white"/>
                      </svg>
                      <div className='ps-2 text-start'>
                        <small style={{fontSize: "6px"}}>Available Credit</small>
                        <p style={{fontSize: "11px"}} className='m-0'>326</p>
                      </div>
                    </NavLink>
                    {/* <NavLink className="nav-link logged_user">
                      <img src={user} alt='' />
                      <div className='logged_user_nm'>Shubham</div>
                    </NavLink> */}
                    <Dropdown className='logged_user pt-0' align="end">
                      <Dropdown.Toggle className='py-0' variant="" id="dropdown-basic" >
                        <img src={user} alt='' />
                      </Dropdown.Toggle>
                      <Dropdown.Menu >
                        <Dropdown.Item href="">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 10C12.7614 10 15 7.76142 15 5C15 2.23858 12.7614 0 10 0C7.23858 0 5 2.23858 5 5C5 7.76142 7.23858 10 10 10Z" fill="#14183E"/>
                            <path d="M10 11.666C5.85977 11.6706 2.50461 15.0258 2.5 19.166C2.5 19.6262 2.87309 19.9993 3.33332 19.9993H16.6666C17.1269 19.9993 17.5 19.6262 17.5 19.166C17.4954 15.0258 14.1402 11.6706 10 11.666Z" fill="#14183E"/>
                          </svg> Profile
                        </Dropdown.Item>
                        <Dropdown.Item href=""> 
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_3735_6573)">
                              <path d="M9.56333 12.5C9.34232 12.5 9.13036 12.5878 8.97408 12.7441C8.8178 12.9004 8.73 13.1123 8.73 13.3333V15.8333C8.73 16.4964 8.46661 17.1323 7.99777 17.6011C7.52893 18.0699 6.89304 18.3333 6.23 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667H6.23C6.89304 1.66667 7.52893 1.93006 7.99777 2.3989C8.46661 2.86774 8.73 3.50363 8.73 4.16667V6.66667C8.73 6.88768 8.8178 7.09964 8.97408 7.25592C9.13036 7.4122 9.34232 7.5 9.56333 7.5C9.78435 7.5 9.99631 7.4122 10.1526 7.25592C10.3089 7.09964 10.3967 6.88768 10.3967 6.66667V4.16667C10.3953 3.062 9.95593 2.00296 9.17482 1.22185C8.3937 0.440735 7.33466 0.00132321 6.23 0H4.16667C3.062 0.00132321 2.00296 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H6.23C7.33466 19.9987 8.3937 19.5593 9.17482 18.7782C9.95593 17.997 10.3953 16.938 10.3967 15.8333V13.3333C10.3967 13.1123 10.3089 12.9004 10.1526 12.7441C9.99631 12.5878 9.78435 12.5 9.56333 12.5Z" fill="#14183E"/>
                              <path d="M19.0542 8.23232L15.2325 4.41066C15.1557 4.33107 15.0637 4.26758 14.962 4.22391C14.8604 4.18023 14.751 4.15724 14.6404 4.15628C14.5297 4.15532 14.42 4.1764 14.3176 4.21831C14.2152 4.26021 14.1221 4.32208 14.0439 4.40033C13.9656 4.47857 13.9038 4.57161 13.8619 4.67403C13.82 4.77644 13.7989 4.88618 13.7998 4.99682C13.8008 5.10747 13.8238 5.21682 13.8675 5.31849C13.9111 5.42016 13.9746 5.51212 14.0542 5.58899L17.6059 9.14149L4.99837 9.16649C4.77736 9.16649 4.5654 9.25429 4.40912 9.41057C4.25284 9.56685 4.16504 9.77881 4.16504 9.99982C4.16504 10.2208 4.25284 10.4328 4.40912 10.5891C4.5654 10.7454 4.77736 10.8332 4.99837 10.8332L17.655 10.8073L14.0525 14.4107C13.9729 14.4875 13.9095 14.5795 13.8658 14.6812C13.8221 14.7828 13.7991 14.8922 13.7982 15.0028C13.7972 15.1135 13.8183 15.2232 13.8602 15.3256C13.9021 15.428 13.964 15.5211 14.0422 15.5993C14.1205 15.6776 14.2135 15.7394 14.3159 15.7813C14.4183 15.8232 14.5281 15.8443 14.6387 15.8434C14.7494 15.8424 14.8587 15.8194 14.9604 15.7757C15.062 15.7321 15.154 15.6686 15.2309 15.589L19.0525 11.7673C19.5214 11.2987 19.785 10.6631 19.7853 10.0002C19.7857 9.33726 19.5227 8.70136 19.0542 8.23232Z" fill="#14183E"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_3735_6573">
                                <rect width="20" height="20" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Col>
          </Container>
        </Navbar>


      ))}
    </>
  );
}

export default HomeHeaderThreeComponent;