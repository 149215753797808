import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "../../../utils/api";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Pic from "../../../assets/images/icons/user.png";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";

import UserSessionActivitiesComponent from './session';
import UserLikeCommentActivitiesComponent from './likecomments';

const UserActivitiesComponent = () => {

    const [currentTab, setCurrentTab] = useState('sessions')

    const changeTabs = (tab) => {
        setCurrentTab(tab)
    }
    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    return (
        <>
            <section>
                <div className="text-start hdng_a">
                    <h2>My Activities</h2>
                </div>
                <ul class="d-flex p-0 justify-content-center align-items-center" id="">
                    <li class="list-group-item">
                        <button class={currentTab === 'sessions' ? `fs-16 fw-600 btn btn-success btn-md rounded-pill` : 'btn-md bg-transparent border-0'} onClick={() => changeTabs('sessions')}>
                            <svg className="me-1" width="18" height="18" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.6588 11.7914C2.79998 11.7914 2.89404 11.7443 2.98826 11.6502C3.17655 11.4619 3.17655 11.1796 2.98826 10.9914C0.211901 8.21508 0.211901 3.74439 2.98826 1.0153C3.17655 0.827017 3.17655 0.54467 2.98826 0.356546C2.79998 0.168261 2.51763 0.168261 2.32951 0.356546C-0.776476 3.46221 -0.776476 8.54462 2.32935 11.6504C2.42341 11.7445 2.56458 11.7916 2.65881 11.7916L2.6588 11.7914Z" fill={currentTab === 'sessions' ? '#fff' : '#14183E'} />
                                <path d="M13.0123 0.356422C12.824 0.544708 12.824 0.827055 13.0123 1.01518C15.7887 3.79154 15.7887 8.26223 13.0123 10.9913C12.824 11.1796 12.824 11.462 13.0123 11.6501C13.1064 11.7441 13.2475 11.7913 13.3418 11.7913C13.4358 11.7913 13.577 11.7441 13.6712 11.6501C16.777 8.54425 16.777 3.462 13.6712 0.308988C13.4829 0.167972 13.2006 0.167971 13.0123 0.356257V0.356422Z" fill={currentTab === 'sessions' ? '#fff' : '#14183E'} />
                                <path d="M5.0104 2.33262C4.82211 2.14433 4.53977 2.14433 4.35164 2.33262C2.3281 4.35616 2.3281 7.65003 4.35164 9.67365C4.4457 9.76772 4.58688 9.81483 4.6811 9.81483C4.77516 9.81483 4.91634 9.76772 5.01056 9.67365C5.19885 9.48537 5.19885 9.20302 5.01056 9.0149C3.36359 7.36792 3.36359 4.6857 5.01056 3.03841C5.19869 2.85012 5.19869 2.52066 5.0104 2.33254L5.0104 2.33262Z" fill={currentTab === 'sessions' ? '#fff' : '#14183E'} />
                                <path d="M11.6457 2.33262C11.4574 2.14433 11.175 2.14433 10.9869 2.33262C10.7986 2.52091 10.7986 2.80325 10.9869 2.99138C12.6339 4.63835 12.6339 7.32057 10.9869 8.96787C10.7986 9.15615 10.7986 9.4385 10.9869 9.62662C11.081 9.72068 11.2222 9.7678 11.3164 9.7678C11.4104 9.7678 11.5516 9.72068 11.6458 9.62662C13.6692 7.65019 13.6692 4.356 11.6457 2.33278L11.6457 2.33262Z" fill={currentTab === 'sessions' ? '#fff' : '#14183E'} />
                                <path d="M10.3756 6.00348C10.3756 4.6858 9.34028 3.65063 8.02276 3.65063C6.70509 3.65063 5.66992 4.68596 5.66992 6.00348C5.66992 7.32115 6.70525 8.35632 8.02276 8.35632C9.34028 8.35632 10.3756 7.32099 10.3756 6.00348ZM6.61103 6.00348C6.61103 5.20355 7.22283 4.59174 8.02276 4.59174C8.82269 4.59174 9.4345 5.20355 9.4345 6.00348C9.4345 6.80341 8.82269 7.41521 8.02276 7.41521C7.22267 7.41521 6.61103 6.80341 6.61103 6.00348Z" fill={currentTab === 'sessions' ? '#fff' : '#14183E'} />
                            </svg>
                            Session
                        </button>
                    </li>
                    <li class="list-group-item">
                        <button class={currentTab === 'likes-comments' ? `fs-16 fw-600 btn btn-success btn-md rounded-pill` : 'btn-md bg-transparent border-0'} onClick={() => changeTabs('likes-comments')}>
                            <svg className="me-1" width="18" height="18" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.00047 13.0361C-5.3326 5.66669 4.00073 -2.33331 8.00047 2.72539C12.0007 -2.33331 21.334 5.66669 8.00047 13.0361Z" stroke={currentTab === 'likes-comments' ? '#fff' : '#14183E'} stroke-width="2" />
                            </svg>
                            Likes/Comments
                        </button>
                    </li>
                </ul>
                <div className="mb-5">
                    {
                        currentTab === 'sessions' ?
                            <>
                                <UserSessionActivitiesComponent />
                            </>
                            :
                            currentTab === 'likes-comments' ?
                                <>
                                    <UserLikeCommentActivitiesComponent />
                                </>
                                : ''
                    }
                </div>
            </section>

        </>
    );
};

export default UserActivitiesComponent;
