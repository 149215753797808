import React, { useEffect, useState } from "react";
import $ from "jquery"; // Import jQuery
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Row,
} from "react-bootstrap";

import Lottie from "react-lottie";
import Modal from "react-awesome-modal";
import OwlCarousel from "react-owl-carousel";
import StripeCheckout from "react-stripe-checkout";

import brand_icon from "../../../assets/images/brand_icon.png";
import coach_in3_easy_step from "../../../assets/images/coach_in3_easy_step.png";
import hire_coach_main from "../../../assets/images/hire-coach-main.png";

import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import desire_be_coach from "../../../assets/images/dummy/desire_be_coach.png";
import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";

import quotes from "../../../assets/images/icons/quotes.png";
import live_session_img1 from "../../../assets/images/dummy/live_session_img1.png";
import live_session_img2 from "../../../assets/images/dummy/live_session_img2.png";
import live_session_img3 from "../../../assets/images/dummy/live_session_img3.png";
import ex_years from "../../../assets/images/icons/ex_years.png";
import degree from "../../../assets/images/icons/degree.png";
import $per_time from "../../../assets/images/icons/$per_time.png";
import bg_play from "../../../assets/images/icons/bg_play.png";
import herovideo from "../../../assets/images/home-hero.webm";
import shortvideo from "../../../assets/images/shortvideo.png";
import shortvideo1 from "../../../assets/images/shortvideo1.png";
import socialfeedtest from "../../../assets/images/dummy/feed_1.png";
import AgoraComponent from "../../Pages/agora/livestream";

import send_img from "../../../assets/images/icons/send.png";
import state_ur_problem from "../../../assets/images/icons/state_ur_problem.png";
import know_ur_coach from "../../../assets/images/icons/know_ur_coach.png";
import get_best from "../../../assets/images/icons/get_best.png";
import Loader from "../../../Loader/Loader";
import connect_ur_coach from "../../../assets/images/icons/connect_ur_coach.png";

import {
  getCategoryList,
  getSocialPosts,
  getCommentPostById,
  getFreeSession,
  addSessionInterest,
  createNewTransaction,
} from "../../../utils/api";
import { getTestimonials } from "../../../utils/api";
import {
  searchCoachWithText,
  getAllBlogs,
  shortListHome,
} from "../../../utils/api";
import { followUnfollowCoach, getFilteredCoaches } from "../../../utils/api";
import { getTrendingCoach } from "../../../utils/api";
import { getBadgeColor } from "../../../utils/functions";
import Pic from "../../../assets/images/icons/user.png";
import { PulseLoader } from "react-spinners";

import { NavLink, useNavigate } from "react-router-dom";
import LoginComponent from "../Login/newindex";
import BlogModalComponent from "../Blog/blog_modal";
import { useUserContext } from "../../../utils/hooks/UserContext";

import { toast } from "react-toastify";
import "../../../assets/scss/_Style.scss"; // Import your CSS file
import "../../../assets/scss/_Style2.scss"; // Import your CSS file

import ShortVideos from "../Activity-stream/shortvideo";

import qoachsmalllogo from "../../../assets/images/qoach-q.png";
import SocialFeedComment from "./socialFeedComment";
import noDataLottie from "../../../assets/lottieFiles/noData.json"
import dummySession from "../../../assets/images/dummySession.png"
import { preloadImage } from '../../../utils/preloadImage'; 

import SearchEverythingComponent from "./searchTypingText";

// http://localhost:3000/api/v1/coah/category-list
function HomeComponent() {
  const publishableKey =
    "pk_test_51PeB04RxzGBCno7VRNimXve86fQloq2C3ev3FNoV7om4R64hYqxgoAt7S9VdjBiqV6yCrAgSVa4tdgwkFBIkLbHK00VPS3Noig";

  const [allSocialFeed, setAllSocialFeed] = useState([]);
  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);

    preloadImage('../../../assets/image');
    
    $(".coach_name").hover(
      function () {
        $(this).attr("title", "");
      },
      function () {
        $(this).attr("title", $(this).text());
      }
    );
    window.scrollTo(0, 0);
    getAllData();
  }, []);

  const getAllData = async () => {
    const pagenumber = {
      limit: 5,
      page: 1,
    };

    const queryParams = new URLSearchParams(pagenumber).toString();
    const data = await getSocialPosts(queryParams);
    setAllSocialFeed(data?.data?.data);
    // setPosts(data?.data)
  };

  const [data, setData] = useState([]);
  const [password, setPassword] = useState("");
  const [shortVideo, setShortVideo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [coach, setCoaches] = useState([]);
  const [trending, setTrending] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loginModalShow, setLoginModalShow] = useState(false);
  const { user, handleLogout } = useUserContext();
  const [filteredCoach, setFilteredCoach] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const navigate = useNavigate();
  const [blogModalShow, setBlogModalShow] = React.useState(false);
  const [singleBlogDetails, setSingleBlogDetails] = React.useState("");
  const [typingText, setTypingText] = React.useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(2);
  const [loading, setLoading] = useState(true);
  const [allSession, setAllSession] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [sessionDetails, setSessionDetails] = useState({});
  const [sessionPrice, setSessionPrice] = useState(0);

  // AGORA HOOKS
  const [videoCallSessionId, setVideoCallSessionId] = useState("");
  const [videoCallSessionName, setVideoCallSessionName] = useState("");
  const [videoCall, setVideocall] = useState(false);

  // let allDummySession = new Array(6).fill(dummySession);
  let allDummySession = new Array();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDataLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const showModal = (e, blog) => {
    setBlogModalShow(true);
    setSingleBlogDetails(blog);
  };
  const onHide = () => {
    setModalOpen(false);
  };

  const openSite = (e) => {
    e.preventDefault();
    if (password === "Admin@123") {
      localStorage.setItem("websiteDisplay", "true");
      setModalOpen(false);
      if (localStorage.getItem("websiteDisplay") === "true") {
        // toast.success('Login successfully');
        // window.location.reload();
        navigate(0);
      } else {
        console.error('Unexpected localStorage value for "websiteDisplay"');
      }
    } else {
      // toast.error('Incorrect password');
    }
  };
  function getDate(time) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Octr",
      "Nov",
      "Dec",
    ];

    const date = new Date(time).getDate();
    const year = new Date(time).getFullYear();
    const month = months[new Date(time).getMonth()];

    let hour = new Date(time).getHours();
    let minute = new Date(time).getMinutes();

    const am_pm = hour > 12 ? "PM" : "AM";
    const time_string = `${date} ${month} ${year}`;
    return time_string;
  }

  function getOnlyTime(time) {
    let hour = new Date(time).getHours();
    let minute = new Date(time).getMinutes();
    const am_pm = hour > 12 ? "PM" : "AM";
    const time_string = `${
      hour > 12 ? "0" + hour - 12 : hour
    }:${minute} ${am_pm}`;
    return time_string;
  }

  const getImage = (image) => {
    if (image && user?.userType === "coach") {
      return `${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${image}`;
    }

    if (image && user?.userType === "user") {
      return `${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${image}`;
    }

    return Pic;
  };

  const removeHTMLTags = (str) => {
    return str.replace(/<[^>]+>/g, ""); // Remove HTML tags
  };

  const getURL = () => {
    if (user?.userType === "user") {
      return `/user/dashboard`;
    }
    return `/coach`;
  };

  const onSelect = (code) => {
    if (code === "0") {
      navigate(getURL());
    }

    if (code === "1") {
      handleLogout();
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleButtonClick = async () => {
    if (inputValue !== "") {
      setisLoading(true);
      await searchCoachWithText(inputValue)
        .then((response) => {
          if (response.data.status === true) {
            // console.log(response.data.data);
            setFilteredCoach(response.data.data);
            navigate({
              pathname: "/browse-coach/list-view",
              state: { filteredData: response.data.data, input: inputValue },
            });
            // console.log(coaches);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong!!");
        });
      setisLoading(false);
    } else {
      toast.error("Please enter text for better search result !");
    }
  };

  const options = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: true,
  };
  const trendingData = async () => {
    setisLoading(true);
    try {
      const { data } = await getTrendingCoach();
      if (data?.status) {
        setTrending(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      // console.error("error++", error);
    } finally {
      setisLoading(false);
    }
    setLoading(false);
  };
  const getData = async () => {
    try {
      const response = await getCategoryList();
      const coachdata = await getTestimonials();
      const latest_blogs = await getAllBlogs();
      // const TrendingCoach = await getTrendingCoach();
      // console.log(latest_blogs.data.data.slice(0, 3));
      setData(response.data?.data);
      setCoaches(coachdata.data?.data);
      // setTrending(TrendingCoach.data?.data);
      setLatestBlogs(latest_blogs.data?.data?.latest.slice(0, 3));
      // console.log("TrendingCoach++", TrendingCoach);
      setLoading(false);
    } catch (error) {}
  };

  const getLiveSesions = async () => {
    try {
      const data = await getFreeSession();
      // console.log(data.data.data);
      // setAllSession(data.data.data);

      let sessions = data.data.data;

      if (sessions.length < 6) {
        // console.log("inside session", 7 - sessions.length );
        for (let i = 0; i <= 6; i++) {
          sessions.push({
            poster_url: dummySession,
            no_Session: true,
          });
          // console.log(sessions.length)
          setAllSession(sessions);
        }
        // allDummySession = new Array(6 - sessions.length).fill(dummySession);
      } else {
        // allDummySession = new Array(0).fill(dummySession)
        setAllSession(sessions);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getData();
    trendingData();
    // shortList();
    getLiveSesions();
  }, []);

  const defaultImage = (e) => {
    e.target.src = "https://nadmin.qoach.io/assets/images/blog.png";
  };
  const defaultUserImage = (e) => {
    e.target.src = blog_card_img1;
  };
  const onClickFollow = async (id) => {
    if (!user) {
      // navigate('/')
      setLoginModalShow(true);
      toast.error("Please login to do this action");
      return;
    }

    const formdata = new FormData();
    formdata.append("coach_id", id);

    await followUnfollowCoach(formdata);
    await getData();
  };

  const likeButton = async (e) => {
    if (!user) {
      setLoginModalShow(true);
      toast.error("Please login to do this action");
      return;
    }
  };
  // const handelchildData = async (data) => {
  //   setPageNumber(data + pageNumber);
  //   let pagecount = data + pageNumber;
  //   // shortList();
  //   const dataa = {
  //     pageNumber: pagecount,
  //     pageLimit: pageLimit,
  //   };

  //   // const newdata = await shortListHome(dataa);
  //   // if (newdata) {
  //   //   setShortVideo(newdata.data.data);
  //   // }
  // };
  function removeHtmlTags(input) {
    return input.replace(/<[^>]*>/g, "");
  }

  function showSessionInterest(id, name, details) {
    // console.log("function called");
    setVideoCallSessionId(id);
    setVideoCallSessionName(name);
    setSessionDetails(details);
    setVideocall(true);
  }

  async function addInterestforSession(id, coach_id) {
    setisLoading(true);
    try {
      const payload = {
        session_id: id,
        coach_id,
      };

      const data = await addSessionInterest(payload);
      toast.success("Interest registered successfully!");
      setisLoading(false);
      getLiveSesions();
    } catch (error) {
      // console.log(error);
      setisLoading(false);
      toast.error("Something went wrong!");
    }
  }

  function convertUtcToIst(utcTime) {
    const date = new Date(utcTime);

    // Format options
    const options = {
      year: "2-digit",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short",
    };

    // Get the local time and time zone
    const localTimeString = date.toLocaleString("en-US", options);
    const localTimeParts = localTimeString.split(/, | /);

    // Extract and format parts of the date and time
    const [month, day, year, , time, timeZone] = localTimeParts;
    const [startHour, startMinute] = time.split(":");
    const endHour = (parseInt(startHour) + 1) % 24; // assuming the event is 1 hour long, with wrap-around

    // Ensure endHour is two digits
    const endHourFormatted = endHour.toString().padStart(2, "0");

    // Format the final string
    const formattedTimeString = `${day} ${month} '${year} @ ${startHour}:${startMinute} - ${endHourFormatted}:${startMinute} ${timeZone}`;

    return formattedTimeString;
  }
  // const commentSection = async (id) =>{

  function onCompletePayment(e, address) {
    // console.log(e, address);
    try {
      // alert("Payment complated!!!")
      const payload = {
        coach_id: sessionDetails.coach_id,
        stripe_transaction_id: e.id,
        total_payment: parseInt(sessionDetails.session_price),
        session_id: sessionDetails.id,
      };
      createNewTransaction(payload);
      addInterestforSession(sessionDetails.id, sessionDetails.coach_id);
      setShowPaymentModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {loading ? (
        // <PulseLoader color="#36D7B7" loading={loading} size={15} />
        <div
          style={{
            // textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <p>
            <PulseLoader color="#00504E" size={20} />
          </p>
        </div>
      ) : (
        <>
          <section className="home_banner">
            <div className="video-container">
              <video
                className="fullscreen-video"
                autoPlay
                loop
                muted
                playsInline
                preload="auto"
              >
                <source src={herovideo} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
              <Container className="container_bg container position-absolute z-3 position-absolute top-50 start-50 translate-middle">
                <Row className="justify-content-center">
                  <Col lg="8">
                    {/* <h2>Find the Best Coach<br />on a Click</h2> */}
                    {/* <h2 className="fs-26">Your Need is unique, so your coach should be too!</h2> */}
                    <h1 className="fs-50 fw-600 text-white">
                      Your Need is Unique, So Your Coach Should Be Too!
                    </h1>
                    {/* <h3>Connect with coaches who've been through what you're facing and can guide you to success.</h3> */}
                  </Col>
                </Row>
                <Row className="justify-content-center g-3">
                  <Col lg="8">
                    <Row className="justify-content-between align-items-center g-3 mt-2">
                      <Col lg="12">
                        <div className="white_bg_msg_strip">
                          <InputGroup
                            className="justify-content-between"
                            style={{ flexWrap: "nowrap" }}
                          >
                            {/* <Form.Control
                              className="fs-16 bg-transparent"
                              as="textarea"
                              type="text"
                              // placeholder="I am not able to scale up my IT business. How to generate leads and increase reven..........................."
                              placeholder={typingText}
                              value={inputValue}
                              onChange={handleInputChange}
                            /> */}
                            <SearchEverythingComponent
                              onInputChange={handleInputChange}
                            />
                            {/* <p className="m-0" id="typing-text">I am not able to scale up my IT business. How to generate leads and increase reven...........................</p> */}
                            {/* <p className="m-0">{typingText}</p> */}
                            <Button
                              variant="outline-secondary"
                              id="button-addon1"
                              className="bg-transparent"
                              onClick={handleButtonClick}
                            >
                              {/* <img src={send_img} /> */}
                              <svg
                                width="28"
                                height="29"
                                viewBox="0 0 28 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_6469_6028)">
                                  <path
                                    d="M25.3126 10.0514L5.32641 0.650398C3.96141 -0.0111019 2.35724 0.215231 1.23141 1.22906C0.10091 2.2464 -0.292257 3.8284 0.229243 5.2564C0.257243 5.33107 5.01958 14.2794 5.01958 14.2794C5.01958 14.2794 0.347076 23.2207 0.320243 23.2942C-0.198924 24.7246 0.197743 26.3042 1.32941 27.3192C2.03058 27.9469 2.91374 28.2712 3.80391 28.2712C4.35224 28.2712 4.90174 28.1487 5.41274 27.8967L25.3137 18.5016C26.9716 17.7234 28.0006 16.1029 27.9994 14.2724C27.9971 12.4419 26.9669 10.8237 25.3126 10.0502V10.0514ZM3.50058 3.98706C3.49591 3.94273 3.50874 3.88907 3.57291 3.8319C3.67558 3.73973 3.75608 3.77706 3.80041 3.8004C3.80858 3.80506 3.81791 3.80856 3.82491 3.81323L22.3446 12.5236H8.04941L3.50058 3.98706ZM3.89258 24.7456C3.85058 24.7677 3.76891 24.8074 3.66741 24.7152C3.60208 24.6569 3.59041 24.6009 3.59508 24.5566L8.05991 16.0236H22.3702L3.89374 24.7467L3.89258 24.7456Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_6469_6028">
                                    <rect
                                      width="28"
                                      height="28"
                                      fill="white"
                                      transform="translate(0 0.271484)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </Button>
                          </InputGroup>
                        </div>
                      </Col>
                      {/* <Col lg="5" className="d-none d-md-block">
                  <div className="semi_bg_strip h-100">
                    <p className="m-0">
                      “How do I make an mental fitness appointment in XYZ?”
                    </p>
                  </div>
                </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          {/* Banner End */}

          {/* banner bottom counter Start */}
          <section className="banner_total_counter z-2 position-relative">
            <Container className="d-none d-sm-block">
              <Row className="g-sm-1 justify-content-center justify-content-lg-between">
                <Col className="">
                  <div className="text-center">
                    <h3>10,000+</h3>
                    <h4>Session Conducted</h4>
                  </div>
                </Col>
                <Col className="">
                  <div className="text-center">
                    <h3>40,000+</h3>
                    <h4>Users Enrolled</h4>
                  </div>
                </Col>
                <Col className="">
                  <div className="text-center">
                    <h3>1000+</h3>
                    <h4>Experienced Coaches</h4>
                  </div>
                </Col>
                <Col className="">
                  <div className="text-center">
                    <h3>200+</h3>
                    <h4>Became Coaches</h4>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container className="d-block d-sm-none">
              <OwlCarousel
                className="owl-theme"
                autoplay
                loop
                margin={15}
                items="1"
                dots={false}
              >
                <div className="item">
                  <div className="text-center">
                    <h3>10,000+</h3>
                    <h4>session conducted</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="text-center">
                    <h3>40,000+</h3>
                    <h4>users enrolled</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="text-center">
                    <h3>1000+</h3>
                    <h4>experienced coaches</h4>
                  </div>
                </div>
              </OwlCarousel>
            </Container>
          </section>
          {/* banner bottom counter End */}

          {/* Offer the Best COACH Start */}
          <section className="white_bg py-3 py-lg-5">
            <Container>
              <Row>
                <Col className="">
                  <div className="text-center hdng_a">
                    <h6 className="text-uppercase">CATEGORY</h6>
                    <h2>We Offer the Best COACH</h2>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container className="">
              <Row className="mt-3 g-3">
                <Col sm="6" lg="3">
                  <div className="best_coach_card">
                    <img src={state_ur_problem} alt="image" />
                    <h5>State Your Problem</h5>
                    <p>
                      Describe your goals or challenges, and our algorithm will
                      find a suitable match for you.
                    </p>
                  </div>
                </Col>
                <Col sm="6" lg="3">
                  <div className="best_coach_card">
                    <img src={get_best} alt="image" />
                    <h5>Filter to Get the Best</h5>
                    <p>
                      Swipe left or right and answer a few questions to refine
                      your search based on your interests.
                    </p>
                  </div>
                </Col>
                <Col sm="6" lg="3">
                  <div className="best_coach_card">
                    <img src={know_ur_coach} alt="image" />
                    <h5>Know Your Coach</h5>
                    <p>
                      Explore coach activities such as shorts, social feeds,
                      live sessions, and short listings.
                    </p>
                  </div>
                </Col>
                <Col sm="6" lg="3">
                  <div className="best_coach_card">
                    <img src={connect_ur_coach} alt="image" />
                    <h5>Connect with Your Coach</h5>
                    <p>
                      Once you’ve been matched, connect with your coach in the
                      way that suits you best.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Offer the Best COACH End */}

          {/* why qoach Start */}
          <section className="brand_bg_color2 brand_patten_bg_color2 py-5 text-xl-left text-lg-left text-md-center text-center">
            <Container>
              <Row className="justify-content-center g-4">
                <Col xs="5" lg="5">
                  <img alt="" className="w-100" src={brand_icon} />
                </Col>
                <Col
                  sm="12"
                  lg="7"
                  className="text-xxl-start text-xl-start text-lg-start text-md-center text-sm-center text-center hdng_a poppins_fnt"
                >
                  <div className="hdng_a poppins_fnt">
                    <h6 className="text-uppercase">About qoach</h6>
                    <h2 className="mt-4">
                      Find the most suitable coach for your specific needs
                    </h2>
                  </div>
                  <div className="brand_bg_color1 p-3 p-md-4 bdr-5 my-2 my-md-3 mb-3">
                    <p className="fs-14 fw-400 m-0">
                      We match you with the ideal coach for your specific needs,
                      offering a unique chance to get to know them through free
                      live sessions, feeds, and shorts before making your
                      selection.
                    </p>
                  </div>
                  {/* {user === null || user?.userType === "user" ? ( */}
                  <NavLink
                    variant="outline-light"
                    size="md"
                    className="fs-16 mt-4 btn btn-outline-light btn-md"
                    to="/browse-coach/list-view"
                    // onClick={() => setLoginModalShow(true)}
                  >
                    Find A Coach
                  </NavLink>
                  {/* ) : (
                <NavLink
                  variant="outline-light"
                  size="md"
                  className="fs-16 mt-4"
                  to="/browse-coach/list-view"
                  onClick={() => setLoginModalShow(true)}
                >
                  Find A Coach
                </NavLink>
              )} */}
                </Col>
              </Row>
            </Container>
          </section>
          {/* why qoach End */}

          {/* Top COACH Start */}
          <section className="white_bg py-5">
            <Container className="">
              <Row className="">
                <Col className="">
                  <div className="text-center hdng_a">
                    <h6 className="text-uppercase">TOP COACHES</h6>
                    <h2>Top COACH</h2>
                    {/* <h2 className="mb-5 mt-4">
                      Each coach is unique, specializing in specific <br /> areas and
                      paired with individuals through <br /> our advanced AI algorithm.
                    </h2> */}
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center g-2 mt-1">
                {trending && trending?.length > 0 ? (
                  trending.map((i) => {
                    // console.log(i);
                    return (
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="browse_coach_suggestion_list_card h-100">
                          <div className="browse_coach_suggestion_list_card_header">
                            <div className="user_img_sm_rnd">
                              {i?.profile_pic && (
                                <img
                                  width={50}
                                  height={50}
                                  className="rounded-circle"
                                  src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${i?.profile_pic}`}
                                  alt=""
                                  onError={(e) => defaultUserImage(e)}
                                />
                              )}
                              {!i?.profile_pic && (
                                <img
                                  width={50}
                                  height={50}
                                  className="rounded-circle"
                                  src={Pic}
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="lh-1 mt-3">
                              <NavLink
                                to={
                                  !user
                                    ? ""
                                    : `/browse-coach/coach-details/${i?.id}`
                                }
                                onClick={!user ? (e) => likeButton() : ""}
                              >
                                <h5 className="m-0 mb-2 fs-18">{i?.name}</h5>
                              </NavLink>
                              {i?.skills?.map((i) => {
                                return (
                                  <label className="fs-12 fw-400">
                                    {i?.name}
                                  </label>
                                );
                              })}
                            </div>
                            {i?.coach_type && (
                              <Badge
                                className={`bcl_badge_type ${getBadgeColor(
                                  i?.coach_type
                                )}`}
                              >
                                {i?.coach_type}
                              </Badge>
                            )}
                          </div>
                          <div className="browse_coach_suggestion_list_card_mid">
                            <div className="ydm_row justify-content-center mt-3 flex-wrap">
                              <div className="d-flex align-items-center">
                                <span className="me-2">
                                  <img src={ex_years} alt="" />
                                </span>
                                <span className="fw-500">
                                  {/* 1 years */}
                                  {i.workexperience > 0
                                    ? i.workexperience + " Years"
                                    : "0 Years"}{" "}
                                </span>
                              </div>
                              <div className="mx-2 d-flex align-items-center">
                                <span className="me-2">
                                  <img src={degree} alt="" />
                                </span>
                                <span className="fw-500">
                                  {/* 423 */}
                                  {i.followers_count
                                    ? i.followers_count
                                    : 0}{" "}
                                </span>
                              </div>
                              <div className="mx-2 d-flex align-items-center">
                                <span className="me-2">
                                  <img src={$per_time} alt="" />
                                </span>
                                <span className="text_brand_color2 fw-600">
                                  $199
                                </span>
                              </div>
                            </div>
                            {/* {i?.tags?.map((i) => {
                                  return <p className="tagged_txt px-1 d-inline-flex m-0">#{i}</p>
                              })} */}
                            <div className="d-grid mt-3">
                              {/* {console.log(i?.is_following)} */}
                              {!user ? (
                                <Button
                                  onClick={() => onClickFollow("")}
                                  className="fs-12 btn-success p-2 h-100 rounded"
                                >
                                  FOLLOW
                                </Button>
                              ) : (
                                <>
                                  {i?.is_following === true && (
                                    <Button
                                      onClick={() => onClickFollow(i?.id)}
                                      className="fs-12 btn-success p-2 h-100 rounded"
                                    >
                                      UNFOLLOW
                                    </Button>
                                  )}
                                  {i?.is_following === false && (
                                    <Button
                                      onClick={() => onClickFollow(i?.id)}
                                      className="fs-12 btn-success p-2 h-100 rounded"
                                    >
                                      FOLLOW
                                    </Button>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Col>
                    <Lottie
                      options={defaultOptions}
                      height={300}
                      width={400}
                      isStopped={false}
                      isPaused={false}
                    />
                  </Col>
                )}
              </Row>
              {trending?.length > 0 && (
                <Row className="justify-content-center mt-5 w-100">
                  <Col sm="6" md="3" className="d-grid justify-content-center">
                    <NavLink to="/browse-coach/list-view">
                      <Button
                        variant="success"
                        size="md"
                        className="fs-16 fw-600"
                      >
                        Show All
                      </Button>
                    </NavLink>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
          {/* Top COACH End */}

          <section className="brand_bg_color2 brand_patten_bg_color2 py-5 home-short-video">
            <Container>
              <Row className="align-items-center mb-5">
                <Col>
                  <div className="text-center text-md-start hdng_a">
                    <h6 className="mb-3 fw-600">
                      explore your coach's activities
                    </h6>
                    <h2>Coach's Social Feed</h2>
                  </div>
                </Col>
                <Col lg="auto" className="d-none d-sm-block">
                  <div className="text-center">
                    <NavLink
                      to={`activity-stream?active=social_feeds`}
                      variant="link"
                      size="md"
                      className="p-0 text_brand_color2 fs-16 fw-600"
                    >
                      <Button
                        variant="success"
                        size="md"
                        className="fs-16 fw-600"
                      >
                        Explore More
                      </Button>
                    </NavLink>
                  </div>
                </Col>
              </Row>
              <div className="mt-2 social_feed_slider">
                <OwlCarousel
                  className="owl-theme"
                  autoplay={true}
                  center
                  loop
                  dots={false}
                  responsive={{
                    1: {
                      items: 1.6,
                      center: true,
                      margin: 10,
                    },
                    800: {
                      items: 2,
                      margin: 10,
                    },
                    1025: {
                      items: 3,
                      margin: 10,
                    },
                  }}
                >
                  {allSocialFeed && allSocialFeed.length > 0
                    ? allSocialFeed.map((post) => (
                        <>
                          <div className="item">
                            <div className="socialfeed-page-card">
                              <div className="h-100 overflow-y-scroll bg-white p-1">
                                <div className="d-flex justify-content-between align-items-center card-header p-2">
                                  <img
                                    style={{ width: "40px", height: "40px" }}
                                    src={qoachsmalllogo}
                                  />
                                  <button class="navbar_toggler" type="button">
                                    <span class=""></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                  </button>
                                </div>
                                <div className="position-relative d-flex align-items-center user-info mt-3 mb-2">
                                  <img
                                    style={{ width: "30px", height: "30px" }}
                                    alt=""
                                    className="ms-2 user_img me-2 position-relative z-2"
                                    src={
                                      post?.coach_profile
                                        ? process.env
                                            .REACT_APP_COACH_PROFILE_FOLDER_PATH +
                                          post?.coach_profile
                                        : blog_card_img1
                                    }
                                    onError={(e) => defaultUserImage(e)}
                                  />
                                  <div className="ps-5 user-name lh-1">
                                    <span className="fs-14 fw-500 text-white">
                                      {post.coach_name}
                                    </span>
                                    <div className="d-flex mt-1">
                                      {post.tag_names !== null ? (
                                        <small
                                          className="fs-10 me-1 d-inline-block text-white overflow-hidden"
                                          style={{ width: "200px" }}
                                        >
                                          {post.tag_names}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p className="fs-12 mb-2">
                                    {removeHtmlTags(post.description)}
                                  </p>
                                </div>
                                {post.file_url !== null ? (
                                  <img
                                    src={post.file_url}
                                    alt=""
                                    className="w-100"
                                    onError={(e) => defaultImage(e)}
                                  />
                                ) : (
                                  <img
                                    src="https://nadmin.qoach.io/assets/images/blog.png"
                                    alt=""
                                    className="w-100"
                                  />
                                )}
                                <div className="col-2 p-0 like-comment-btns d-flex align-items-center justify-content-between w-100 my-2">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 28 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M13.6073 22.5423C-9.7256 9.64577 6.60771 -4.35423 13.6073 4.4985C20.6077 -4.35423 36.941 9.64577 13.6073 22.5423Z"
                                        stroke="#964B00"
                                        stroke-width="3"
                                      ></path>
                                    </svg>
                                    <span className="fs-10 ms-1">
                                      {post.like_count ?? 0} Likes
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 29 29"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M24.6074 14.3962C24.6114 15.936 24.2517 17.4551 23.5574 18.8295C22.7343 20.4766 21.4688 21.8619 19.9028 22.8304C18.3368 23.7988 16.532 24.3121 14.6908 24.3129C13.1509 24.3169 11.6319 23.9571 10.2574 23.2629L3.60742 25.4795L5.82409 18.8295C5.12984 17.4551 4.77007 15.936 4.77409 14.3962C4.7748 12.5549 5.28814 10.7502 6.25659 9.18417C7.22505 7.61815 8.61039 6.35269 10.2574 5.52953C11.6319 4.83528 13.1509 4.47551 14.6908 4.47953H15.2741C17.7058 4.61368 20.0026 5.64008 21.7248 7.36219C23.4469 9.08431 24.4733 11.3811 24.6074 13.8129V14.3962Z"
                                        stroke="#964B00"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                    <span className="fs-10 ms-1">
                                      {post.comment_count ?? 0} Comments
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 25 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M17.1068 19.9808C17.1068 22.7401 14.8674 24.9795 12.1082 24.9795H5.11C2.35073 24.9795 0.111328 22.7401 0.111328 19.9808V12.9827C0.111328 10.2234 2.35073 7.98399 5.11 7.98399H6.10974C6.65959 7.98399 7.10947 8.43387 7.10947 8.98373C7.10947 9.53358 6.65959 9.98346 6.10974 9.98346H5.11C3.46044 9.98346 2.1108 11.3331 2.1108 12.9827V19.9808C2.1108 21.6304 3.46044 22.98 5.11 22.98H12.1082C13.7577 22.98 15.1074 21.6304 15.1074 19.9808C15.1074 19.431 15.5572 18.9811 16.1071 18.9811C16.6569 18.9811 17.1068 19.431 17.1068 19.9808ZM23.2352 6.87429L17.8266 1.28577C17.4467 0.885874 16.8069 0.875876 16.417 1.26577C16.0271 1.65567 16.0071 2.2855 16.397 2.6754L21.5357 7.974H13.1079C10.3486 7.974 8.10921 10.2134 8.10921 12.9727V17.9713C8.10921 18.5212 8.55909 18.9711 9.10894 18.9711C9.6588 18.9711 10.1087 18.5212 10.1087 17.9713V12.9727C10.1087 11.3231 11.4583 9.97347 13.1079 9.97347H21.5257L16.387 15.2721C16.0071 15.672 16.0071 16.3018 16.407 16.6817C16.597 16.8716 16.8569 16.9616 17.1068 16.9616C17.3568 16.9616 17.6267 16.8616 17.8266 16.6617L23.2252 11.0932C24.3949 9.92348 24.3949 8.02398 23.2352 6.86429V6.87429Z"
                                        fill="#964B00"
                                      ></path>
                                    </svg>
                                    <span className="fs-10 ms-1">
                                      {post.share_count ?? 0} Share
                                    </span>
                                  </div>
                                </div>
                                <SocialFeedComment id={post.social_id} />
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : ""}
                </OwlCarousel>
              </div>
              <Col lg="auto" className="d-block d-sm-none">
                <div className="text-center">
                  <Button variant="success" size="md" className="fs-16 fw-600">
                    Explore More
                  </Button>
                </div>
              </Col>
            </Container>
          </section>

          {/* Amazing coach inspiration End */}

          {/* 3 easy steps Start */}
          <section className="white_bg py-5 py-lg-6">
            <Container className="d-none d-sm-block">
              <Row className="g-3 justify-content-between">
                <Col sm="12" lg="12" className="order-lg-first order-last">
                  <Row className="align-items-center">
                    <Col>
                      <div className="hdng_a">
                        <h6 className="text-uppercase">Easy and Fast</h6>
                        <h2 className="mb-5">
                          Connect with your Coach in 4 easy steps
                        </h2>
                      </div>
                      <div className="coach_in3_easy_step">
                        <ul>
                          <li>
                            <div className="d-flex">
                              <div className="mt-3">
                                <span className="order_list me-3">1</span>
                              </div>
                              <div>
                                <h6 className="mb-1 fw-600">
                                  State your problem
                                </h6>
                                <p className="fs-14">
                                  Describe your goal or challenges, tell us your
                                  specific problem and the algorithm will find a
                                  suitable match.{" "}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="mt-3">
                                <span className="order_list me-3">2</span>
                              </div>
                              <div>
                                <h6 className="mb-1 fw-600">
                                  Filter out to get the best
                                </h6>
                                <p className="fs-14">
                                  Swipe left or right and answer few questions
                                  to filter the results based on your interest.{" "}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="mt-3">
                                <span className="order_list me-3">3</span>
                              </div>
                              <div>
                                <h6 className="mb-1 fw-600">Know your Coach</h6>
                                <p className="fs-14">
                                  Explore coach activities such as shorts,
                                  social feeds, free live sessions and short
                                  listings.{" "}
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="mt-3">
                                <span className="order_list me-3">4</span>
                              </div>
                              <div>
                                <h6 className="mb-1 fw-600">
                                  Connect with a suitable coach
                                </h6>
                                <p className="fs-14">
                                  Connect with your coach after you’ve been
                                  matched however you want.{" "}
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col>
                      <div className="coach_in3_easy_step">
                        <ul>
                          <img alt="" className="w-100" src={hire_coach_main} />
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col sm="10" lg="6" className={window.innerWidth > 500 ? "mt-6" : ""}>
              <div>
                <img alt="" className="w-100" src={coach_in3_easy_step} />
              </div>
            </Col> */}
              </Row>
            </Container>

            <Container className="d-block d-sm-none">
              <Row className="g-3 justify-content-between">
                <Col sm="12">
                  <div>
                    <img alt="" className="w-100" src={coach_in3_easy_step} />
                  </div>
                </Col>
                <Col sm="12">
                  <div className="text-center text-md-start hdng_a">
                    <h6 className="text-uppercase">Easy and Fast</h6>
                    <h2 className="fs-50">Hire your Coach in 3 easy steps</h2>
                  </div>
                </Col>
              </Row>
              <div className="coach_in3_easy_step">
                <OwlCarousel
                  className="owl-theme"
                  autoplay
                  loop
                  items="1.1"
                  dots={false}
                >
                  <div className="item">
                    <ul>
                      <li>
                        <h6>Explore Coach</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Urna, tortor tempus.{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="item">
                    <ul>
                      <li>
                        <h6>Swipe Right</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Urna, tortor tempus.{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="item">
                    <ul>
                      <li>
                        <h6>Hire Coach</h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Urna, tortor tempus.{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                </OwlCarousel>
              </div>
            </Container>
          </section>
          {/* 3 easy steps End */}

          {/* experienced Start */}
          <section className="top_coach_experienced">
            <Container>
              <Row className="justify-content-center">
                <Col sm="8" md="7">
                  <div className="text-center hdng_a">
                    {/* <NavLink>
                      <img className="mb-3" width={90} src={bg_play} alt="" />
                    </NavLink>
                    <h6 className="text-white fs-18 text-uppercase mb-4">
                      click here to watch our demo
                    </h6> */}
                    <h2 className="text-white text-capitalize fs-50">
                      We Have Suitable & experienced Coaches here.
                    </h2>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* experienced End */}

          {/* Social Feed Start */}
          <section className="white_bg py-3 py-lg-5  home-short-video">
            <Container className="position-relative" style={{ height: "75vh" }}>
              <Row className="align-items-center position-absolute top-50 start-50 translate-middle w-100">
                <Col
                  sm="4"
                  md="5"
                  lg="5"
                  className="order-lg-first order-last my-4 offset-md-1"
                >
                  <div className="text-left hdng_a poppins_fnt">
                    <h6 className="text-uppercase">
                      SWIPE, WATCH AND SHORTLIST YOUR COACH
                    </h6>
                    <h2>Explore Coaches Amazing Moments</h2>
                  </div>
                  <NavLink to={`activity-stream?active=short_video`}>
                    <Button
                      // variant="outline-light"
                      className="fs-16 fw-600 btn btn-success btn-md"
                    >
                      Explore Moments
                    </Button>
                  </NavLink>
                </Col>
                <ShortVideos />
              </Row>
            </Container>
          </section>
          {/* Social Feed End */}

          {/* Live Session Start */}
          <section className="live_session  py-3 py-lg-5">
            <Container className="container_bg ">
              <Row className="justify-content-center">
                <Col lg="12">
                  <div className="text-center hdng_a">
                    <h6 className="text-uppercase">Free Session</h6>
                    {/* <h2 className="fs-30">
                  See what real coaching sessions looks like by joining free
                  live session.
                </h2> */}
                    <h5 className="mt-3 lh-15 fs-20 fw-400">
                      Take advantage of a free live session to familiarize
                      yourself with coaches approach, area of expertise and also
                      decide if a coach is suitable for your specific need.
                    </h5>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container className="mt-3">
              <Row className="g-3">
                <Col className="d-none d-sm-block">
                  <Row className="g-3">
                    {allSession.length > 0 &&
                      allSession.slice(0, 6).map((item, index) => (
                        <Col md="4" key={index}>
                          <NavLink>
                            {item?.no_Session === true ? (
                              <Col md="4" key={index}>
                                <img
                                  src={item?.poster_url}
                                  height="350"
                                  alt={`dummy-${index}`}
                                />
                              </Col>
                            ) : (
                              <div className="frlv_session_card">
                                <img
                                  alt=""
                                  className="live_session_img"
                                  width="100%"
                                  src={
                                    item.poster_url !== null &&
                                    item.poster_url !== ""
                                      ? process.env.REACT_APP_BASE_URL +
                                        item.poster_url
                                      : live_session_img2
                                  }
                                />
                                <div className="frlv_session_ff_column">
                                  <div className="frlv_badge justify-content-between">
                                    {item.session_status === "live" ? (
                                      <>
                                        <Badge>Live</Badge>
                                        <Badge>50+ joined</Badge>
                                      </>
                                    ) : (
                                      <>
                                        <div className="frlv_session_card_hdr">
                                          <h4 className="fs-14 fw-500 m-0">
                                            Upcoming Live Session
                                          </h4>
                                          <p className="fs-10 fw-500 m-0">
                                            {convertUtcToIst(item?.start_date)}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <Button
                                    // variant={
                                    //   item.userRegistered == true
                                    //     ? "success"
                                    //     : "warning"
                                    // }
                                    className="let_me_in_btn let_me_in_btn_trnsparent"
                                    onClick={() => {
                                      // console.log(user);
                                      if (!user) {
                                        setLoginModalShow(true);
                                      } else if (user?.userType !== "user") {
                                        alert("Please login as a user!");
                                        localStorage.removeItem("accessToken");
                                        setLoginModalShow(true);
                                      } else if (
                                        item?.userRegistered === false
                                      ) {
                                        setSessionDetails(item);
                                        if (
                                          item?.session_type ===
                                            "paid_session" &&
                                          item?.userPaid === false
                                        ) {
                                          setShowPaymentModal(true);
                                          setSessionPrice(
                                            parseInt(item?.session_price)
                                          );
                                        } else {
                                          addInterestforSession(
                                            item?.id,
                                            item?.coach_id
                                          );
                                        }
                                      } else {
                                        setShowPaymentModal(true);
                                        setSessionPrice(
                                          parseInt(item?.session_price)
                                        );
                                        // showSessionInterest(
                                        //   item.id,
                                        //   item.session_name,
                                        //   item
                                        // );
                                      }
                                    }}
                                  >
                                    {item?.userRegistered === false ? (
                                      isLoading ? (
                                        <Loader />
                                      ) : (
                                        "I am Interested"
                                      )
                                    ) : item?.session_status === "live" ? (
                                      isLoading ? (
                                        <Loader />
                                      ) : (
                                        "Join now"
                                      )
                                    ) : isLoading ? (
                                      <Loader />
                                    ) : (
                                      "Let me In"
                                    )}
                                  </Button>
                                  <div className="frlv_session_card_footer">
                                    <div className="d-flex align-items-center">
                                      <img
                                        width="35"
                                        height="35"
                                        alt=""
                                        className="user_img"
                                        src={
                                          item.profile_pic !== null
                                            ? item.login_type === "Linkedin"
                                              ? item.profile_pic
                                              : process.env
                                                  .REACT_APP_COACH_PROFILE_FOLDER_PATH +
                                                item.profile_pic
                                            : testimonials_img1
                                        }
                                      />
                                      <div className="ms-2">
                                        <h6 className="fs-16 fw-500 m-0">
                                          {" "}
                                          {item.name
                                            ? item.name
                                            : "Test Coach"}{" "}
                                        </h6>
                                        <div className="dot_lable">
                                          <label>
                                            {" "}
                                            {item.professions
                                              ? item.professions
                                              : "Qoach Professional Admin"}{" "}
                                          </label>
                                          {/* <label>IT Recruiter</label>
                                                        <label>Career</label> */}
                                        </div>
                                        {/* <p className="fs-10 fw-500 m-0">
                                    #finanace#commerce#expert{" "}
                                    <span>+3more</span>
                                  </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </NavLink>
                        </Col>
                      ))}

                    {/* // <Row className="g-3">
                      // {allDummySession.map((image, index) => ( */}
                    {/* //   <Col md="4" key={index}>
                      //     <img src={image} height="300" alt={`dummy-${index}`} />
                      //   </Col>
                      // ))}
                      // </Row> */}
                    {/* <Col sm="4">
                            <NavLink>
                            <div className="frlv_session_card">
                                    <img alt="" className="live_session_img" width="100%" src={live_session_img1} />
                                    <div className="frlv_session_ff_column">
                                        <div className="frlv_session_card_hdr">
                                            <h4 className="fs-14 fw-500 m-0">Upcoming Live Session</h4>
                                            <p className="fs-10 fw-500 m-0">25 March’23 @ 08:00 - 09:00 IST</p>
                                        </div>
                                        <Button variant="warning" className="px-5">I am intrested</Button>
                                        <div className="frlv_session_card_footer">
                                            <div className="d-flex align-items-center">
                                                <img width="35" height="35" alt="" className="user_img" src={testimonials_img1} />
                                                <div className="ms-2">
                                                    <h6 className="fs-16 fw-500 m-0">Jack Mick</h6>
                                                    <div className="dot_lable">
                                                        <label>Project Manager</label>
                                                        <label>IT Recruiter</label>
                                                        <label>Career</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="fs-10 fw-500 m-0">#finanace#commerce#expert <span>+3more</span></p>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </Col> */}
                  </Row>
                  {allSession.length > 6 && (
                    <div className="text-center mt-5">
                      <NavLink to={`activity-stream?active=free_live_session`}>
                        <Button
                          variant="success"
                          size="md"
                          className="fs-16 fw-600 btn"
                        >
                          View All
                        </Button>
                      </NavLink>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
          {/* Live Session End */}

          {/* Testimonials Start */}
          <section className="white_bg py-3 py-lg-5 testimonial-main">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12" className="">
                  <div className="text-center hdng_a">
                    <h6 className="text-uppercase">Testimonials</h6>
                    <h2>See how people have benefited from our platform</h2>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center mt-4">
                <Col lg="12">
                  {coach && coach?.length > 0 && (
                    <OwlCarousel
                      className="autoHeight_owlCarousel testimonials_slider owl-theme"
                      loop
                      margin={0}
                      dots={false}
                      responsive={{
                        1: {
                          items: 1,
                        },
                        1000: {
                          items: 2,
                        },
                        1200: {
                          items: 3,
                        },
                      }}
                    >
                      {coach.map((i) => (
                        <div className="item h-100">
                          <div className="testimonials_card h-100">
                            <div className="testimonials_img">
                              <img
                                alt=""
                                className="object-fit-cover"
                                src={process.env.REACT_APP_SOCKET_URL + i.image}
                                onError={(e) => defaultUserImage(e)}
                              />
                            </div>
                            <div className="testimonials_card_body h-100">
                              <p className="fs-16 fw-400">{i.description}</p>
                              <Row className="g-0 align-items-center">
                                <Col>
                                  <h5 className="fs-18 fw-600">{i.author}</h5>
                                  <h6 className="fs-12 fw-400 opacauthority_7">
                                    {i.designation}
                                  </h6>
                                </Col>
                                <Col className="col-auto">
                                  <div className="quotes_img">
                                    <img alt="" src={quotes} />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
          {/* Testimonials End */}

          {/* Desire to be a Coach Start */}
          <section className="brand_bg_color2 brand_patten_bg_color2 py-5">
            <Container className="container_bg">
              <Row className="gx-lg-4 align-items-center">
                <Col lg="12" className="mb-3">
                  <div className="text-center hdng_a poppins_fnt">
                    <h6 className="text-uppercase">Become a Coach</h6>
                    <h2 className="text-white">
                      Your Expertise Can Make a Difference
                    </h2>
                  </div>
                </Col>
                <Col sm="12" lg="4">
                  <img alt="" className="w-100" src={desire_be_coach} />
                </Col>
                <Col sm="12" lg="8">
                  <div className="py-2">
                    <div className="text-left hdng_a poppins_fnt">
                      {/* <h6 className="text-uppercase">Your Expertise Can Make a Difference </h6>
                        <h2 className="fs-20">Overcome Hesitation and Become a Coach</h2> */}
                      <p className="fs-16 fw-400 text-white">
                        Are you a talented individual with expertise in a
                        specific area? Do you have a passion for helping others
                        succeed? If so, Qoach is the perfect platform for you to
                        become a coach.
                      </p>
                      <p className="fs-16 fw-400 text-white">
                        At Qoach, we believe who has unique expertise can become
                        a coach, regardless of their formal coaching experience,
                        certificate, or degree. If you have the ability to
                        transform someone's life or business, then you're
                        qualified.
                      </p>
                      <p className="fs-16 fw-400 text-white">
                        Qoach is an inclusive journey, where we welcome everyone
                        with specialized knowledge to become a coach. We're
                        breaking down barriers and making coaching more about
                        your expertise and less about formalities.
                      </p>
                    </div>
                    <div className="text-xxl-start text-xl-start text-lg-start text-md-center text-sm-center text-center">
                      {/* <Button
                        variant="outline-light"
                        onClick={() => setLoginModalShow(true)}
                        className="px-4 fs-16"
                      >
                        Give It A Try
                      </Button> */}
                      <NavLink
                        to={`/become-a-coach`}
                        className="px-4 fs-16 btn btn-outline-light"
                      >
                        Give It A Try
                      </NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Desire to be a Coach Start */}

          {/* Our Latest Blogs Start */}
          <section className="white_bg py-3 py-lg-5">
            <Container>
              <Row className="align-items-center">
                <Col>
                  <div className="text-center text-center hdng_a">
                    <h6 className="text-uppercase">Wisdom Box</h6>
                    <h2>Our Latest Blogs</h2>
                    <h4 className="fs-20 fw-400 lh-15 mb-3">
                      Read a rich collection of knowledge, experiences, and life
                      lessons <br /> from our coaches and mentors.
                    </h4>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container className="d-none d-sm-block">
              <Row className="mt-2 g-5">
                {latestBlogs.length > 0 &&
                  latestBlogs.map((blog) => (
                    <Col sm="6" lg="4">
                      <NavLink
                        className="text_brand_color8 fw-500 fs-14"
                        to={`/blog-details/${blog.slug}`}
                      >
                        <div className="blog_card border rounded-4 h-100 overflow-hidden">
                          <div className="blog_card_img">
                            {blog.image ? (
                              <img
                                alt=""
                                className="w-100"
                                src={`${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}`}
                                onError={(e) => defaultImage(e)}
                              />
                            ) : (
                              <img
                                alt=""
                                className="w-100"
                                src={blog_card_img1}
                                onError={(e) => defaultImage(e)}
                              />
                            )}
                          </div>
                          <div className="blog_card_body px-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="d-flex align-items-center">
                                {blog.profile_pic ? (
                                  <img
                                    width={30}
                                    height={30}
                                    alt=""
                                    className="user_img rounded-circle me-2"
                                    src={`${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}`}
                                    style={{ borderRadius: "50%" }}
                                    onError={(e) => defaultUserImage(e)}
                                  />
                                ) : (
                                  <img
                                    width={30}
                                    height={30}
                                    alt=""
                                    className="user_img me-2"
                                    src={testimonials_img1}
                                  />
                                )}

                                <h5 className="m-0 fs-18">
                                  {blog.name ? blog.name : "Jack Mick"}
                                </h5>
                              </div>

                              <span className="fs-12 fw-500 opacity_7">
                                {getDate(
                                  blog.blog_date !== null
                                    ? blog.blog_date
                                    : blog.updated_at
                                )}
                              </span>
                            </div>
                            <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines">
                              {" "}
                              {blog?.title}{" "}
                            </h5>

                            <p className="fs-14 fw-300 opacity_7 mb-3">
                              {/* {blog.content.substring(0, 125)}... */}
                              {/* {removeHTMLTags(blog.content).substring(0, 125)} */}
                              {removeHTMLTags(blog.content)}
                              ...
                            </p>

                            {/* <NavLink className="fs-16 fw-500 d-block">
                        Read More
                      </NavLink> */}
                            {/* <NavLink className="text_brand_color8 fw-500" onClick={(e) => showModal(e, blog)}>Read More</NavLink> */}
                            <NavLink
                              className="text_brand_color8 fw-500 fs-14"
                              to={`/blog-details/${blog.slug}`}
                            >
                              Read More
                            </NavLink>
                            {/* <NavLink className="text_brand_color8 fw-500"  onClick={() => setBlogModalShow(true)}>Read More</NavLink> */}
                          </div>
                        </div>
                      </NavLink>
                    </Col>
                  ))}
              </Row>
              <div class="text-center mt-4">
                <NavLink
                  to={
                    localStorage.getItem("websiteDisplay") != null && "/blogs"
                  }
                  >
                  <Button
                    variant="success"
                    size="md"
                    className="fs-16 fw-600 btn"
                    onClick={() => {
                      console.log("clicked")
                      if (localStorage.getItem("websiteDisplay") == null) {
                        setModalOpen(true);
                        console.log("inside");
                      }
                      
                    }}
                  >
                    View All
                  </Button>
                </NavLink>
              </div>
            </Container>
            <Container>
              <OwlCarousel
                className="d-block d-sm-none owl-theme"
                loop
                margin={5}
                items="1"
                dots={false}
              >
                <div className="item">
                  <div className="blog_card p-1">
                    <div className="blog_card_img">
                      <img alt="" className="w-100" src={blog_card_img1} />
                    </div>
                    <div className="blog_card_body py-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <span>
                            <img
                              width={30}
                              height={30}
                              alt=""
                              className="user_img"
                              src={testimonials_img1}
                            />
                          </span>
                          <h5 className="m-1">Jack Mick</h5>
                        </div>
                        <span className="fs-16 fw-500 opacity_7">
                          19 Jan 2022
                        </span>
                      </div>
                      <p className="fs-14 fw-500 mb-1">
                        How one Qoach user grew his single person consultancy
                        from $0-100K in 14 months
                      </p>
                      <p className="fs-12 fw-400 opacity_7 mb-1">
                        See how pivoting to Webflow changed one person’s sales
                        strategy and allowed him to attract
                      </p>
                      <NavLink className="fs-16 fw-500 d-block">
                        Read More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
              <Row>
                <Col className="d-block d-sm-none" lg="auto">
                  <div className="text-center">
                    <Button
                      variant="success"
                      size="md"
                      className="fs-16 fw-600"
                    >
                      Read All Blogs
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Our Latest Blogs End */}
          <LoginComponent
            show={loginModalShow}
            isCoach={true}
            onHide={() => setLoginModalShow(false)}
          />
          <BlogModalComponent
            show={blogModalShow}
            onHide={() => setBlogModalShow(false)}
            singleBlogDetails={singleBlogDetails}
          />

          {videoCall ? (
            <AgoraComponent
              videoCallSessionId={videoCallSessionId}
              videoCallSessionName={videoCallSessionName}
              sessionDetails={sessionDetails}
            />
          ) : (
            ""
          )}

          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            visible={showPaymentModal}
            width="400"
            height="300"
            effect="fadeInUp"
            onClickAway={() => setShowPaymentModal(false)}
          >
            <div className="brand_bg_color1 my_light_modal">
              {/* <Row className="g-3"> */}

              <div className="frlv_session_card">
                <img
                  alt=""
                  className="live_session_img"
                  width="100%"
                  src={
                    sessionDetails.poster_url !== null &&
                    sessionDetails.poster_url !== ""
                      ? process.env.REACT_APP_BASE_URL +
                        sessionDetails.poster_url
                      : live_session_img2
                  }
                />
                <div className="frlv_session_ff_column">
                  <div className="frlv_badge justify-content-between">
                    {sessionDetails.session_status === "live" ? (
                      <>
                        <Badge>Live</Badge>
                        <Badge>50+ joined</Badge>
                      </>
                    ) : (
                      <>
                        <div className="frlv_session_card_hdr">
                          <h4 className="fs-14 fw-500 m-0">
                            {sessionDetails?.session_name}
                          </h4>
                          <p className="fs-10 fw-500 m-0">
                            {/* {convertUtcToIst(sessionDetails.start_date)} */}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <Button className="let_me_in_btn let_me_in_btn_trnsparent">
                    Price: ${sessionDetails.session_price}
                  </Button>
                  <div className="frlv_session_card_footer">
                    <div className="d-flex align-items-center">
                      <img
                        width="35"
                        height="35"
                        alt=""
                        className="user_img"
                        src={
                          sessionDetails.profile_pic !== null
                            ? sessionDetails.login_type === "Linkedin"
                              ? sessionDetails.profile_pic
                              : process.env
                                  .REACT_APP_COACH_PROFILE_FOLDER_PATH +
                                sessionDetails.profile_pic
                            : testimonials_img1
                        }
                      />
                      <div className="ms-2">
                        <h6 className="fs-16 fw-500 m-0">
                          {" "}
                          {sessionDetails.name
                            ? sessionDetails.name
                            : "Test Coach"}{" "}
                        </h6>
                        <div className="dot_lable">
                          <label>
                            {" "}
                            {sessionDetails.professions
                              ? sessionDetails.professions
                              : "Qoach Professional Admin"}{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* </Row> */}
              {sessionDetails.userPaid === true && (
                <StripeCheckout
                  label="Pay Now"
                  name="Qoach International Private Limited"
                  billingAddress
                  shippingAddress
                  description={`Your Total ₹${sessionPrice}`}
                  amount={sessionPrice * 100}
                  panelLabel="Pay Now"
                  token={onCompletePayment}
                  stripeKey={publishableKey}
                />
              )}
            </div>
          </Modal>

        
          <Modal
            // onHide={() => onHide()}
            // show={true}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            visible={modalOpen}
            onClickAway={()=> setModalOpen(false)}
            // height="500"
            className="shortvideo-model h-90 my_light_modal default-popup"
          >
            <div className="brand_bg_color1 my_light_modal">
              <div className="ls_modal_conatiner p-4">
                <div className="hdng_a text-start">
                  <h6 className="text-uppercase">Login</h6>
                  <h3 className="my-3 fw-700" style={{ fontFamily: "volkhov" }}>
                    Beta Version - Login for Access this site
                  </h3>
                </div>
                <form clasName="px-lg-4">
                  <div className="mb-3 mt-3">
                    <input
                      name="password"
                      placeholder="Your Password"
                      type="password"
                      id="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="invalid-feedback">Password is required</div>
                  </div>
                  <div>
                    <button
                      onClick={(e) => openSite(e)}
                      className="btn btn-success"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default HomeComponent;
