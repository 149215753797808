import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from "react-bootstrap";
import { toast } from 'react-toastify';
import { deletePostComment, coachGetMyPost } from "../../utils/api";
import { confirmAlert } from 'react-confirm-alert';
import { useUserContext } from "../../utils/hooks/UserContext";
import Pic from '../../assets/images/icons/user.png';


const Comment = ({ commenterName, description, commentId, commenterId, commenterPic, reload }) => {
    const { user } = useUserContext()

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this comment?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await deletePostComment(id);
                            reload()
                            toast.success('Deleted successfully!!')
                        } catch (error) {
                            console.error('Error deleting item', error);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <>
            <div className="d-flex align-items-start comment-section mb-4">
                <div className="rounded-circle p-2 mr-3">
                    {commenterPic && <img width={40} height={40} className="rounded-circle" src={process.env.REACT_APP_USER_PROFILE_FOLDER_PATH + commenterPic} alt="" />}
                    {!commenterPic && <img width={40} height={40} className="rounded-circle" src={Pic} alt="" />}
                </div>

                <div className="flex-grow-1 bg-light p-3 rounded">
                    <strong>{commenterName}</strong>

                    <p className="mb-0">{description}</p>
                </div>

                <Dropdown >
                    {user?.userType === "user" && user?.user_id === commenterId && (
                        <>
                            <Dropdown.Toggle variant="ellipsis" as={Button} className="p-0 h-auto fs-24 fw-700">
                                &#8943;
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleDeleteClick(commentId)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </>
                    )}
                </Dropdown>

                {/* <div className="ml-3">  
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-three-dots"
                    viewBox="0 0 16 16"
                    style={{ cursor: 'pointer' }}
                >
                    <path d="M8 1.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z" />
                </svg>
            </div> */}
            </div>
        </>
    );
};

export default Comment;
