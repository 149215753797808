import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Modal, Row, Dropdown } from "react-bootstrap";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SocialFeedsPostComponent from "./socialFeedsPost";
import EditsocialFeedsPostComponent from "./editsocialFeedsPost";
import { coachGetMyPost, getCommentPostById } from "../../../utils/api";
import { useUserContext } from "../../../utils/hooks/UserContext";
import moment from 'moment';
import Comment from "../../Common/Comments";
import Pic from '../../../assets/images/icons/user.png';
import { coachDeletePost } from "../../../utils/api";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoginComponent from '../../../Front/Pages/Login/newindex';
import { PulseLoader } from "react-spinners";

function CoachMyActivitySocialFeedsComponent() {

    const { user } = useUserContext()
    const [sfPostModalShow, setSfPostModalShow] = useState(false);
    const [posts, setPosts] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [comments, setComments] = useState([])
    const [showEditDeletePopup, setShowEditDeletePopup] = useState(false);
    const [editData, setEditData] = useState(false)
    const [loginModalShow, setLoginModalShow] = React.useState(false);

    const getData = async () => {
        setisLoading(true)
        const { data } = await coachGetMyPost()
        setPosts(data?.data)
        setisLoading(false)
    }

    useEffect(() => {
        getData()
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
    }, [])


    const onClickComment = async (id) => {
        const { data } = await getCommentPostById(id)
        setComments(data?.data || [])
        setShowModal(!showModal)
    }

    const toggleEditDeletePopup = () => {
        setShowEditDeletePopup(!showEditDeletePopup);
    };

    const onClickEdit = (i) => {
        setEditData(i)
        setShowEditDeletePopup(true)
    }

    const handleDeleteClick = (id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this Social Feed?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await coachDeletePost(id);
                            toast.success('Deleted successfully!!')
                            getData()
                        } catch (error) {
                            console.error('Error deleting item', error);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const likeButton = async (e) => {
        if (!user) {
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }
    }
    const defaultImage = (e) => {
        e.target.src = 'https://nadmin.qoach.io/assets/images/blog.png';
    }
    const defaultUserImage = (e) => {
        e.target.src = Pic;
    }
    return (
        <>
            <Modal dialogClassName="modal-dialog-centered"
                show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    {comments?.length < 1 && <div>No comments found</div>}
                    {comments?.length > 0 && comments.map(comment => (
                        <Comment
                            key={comment.id}
                            commenterName={comment.name}
                            description={comment.comments}
                            likes={comment.likes}
                            reload={() => null}
                            commenterPic={comment?.profile_pic}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            <Row className="justify-content-center">
                <Col lg="12">
                    <Row className="justify-content-center">
                        <Col lg="8">

                            <NavLink onClick={() => setSfPostModalShow(true)}>
                                <Row className="my-4">
                                    <Col className="col-auto">
                                        <div className="sp_user">
                                            {/* {<img src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + user?.profile_pic} alt="" />}
                                            {!user?.profile_pic && <img src={Pic} alt="" />} */}
                                            {
                                                user ?
                                                    user.profile_pic ?
                                                        <img src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + user?.profile_pic} alt="" onError={(e) => defaultUserImage(e)} />
                                                        :
                                                        <img src={Pic} alt="" />
                                                    :
                                                    <img src={Pic} alt="" />
                                            }
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="start_post_card">
                                            <span>Start a post</span>
                                            <div>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.24 22.6295C22.35 24.0395 20.79 24.9795 19 24.9795H5C2.24 24.9795 0 22.7395 0 19.9795V17.3895L3.98 13.4095C4.54 12.8495 5.46 12.8495 6.03 13.4095L11.61 18.9895C12.93 20.3095 15.09 20.3095 16.41 18.9895L16.99 18.4095C17.54 17.8595 18.49 17.8595 19.04 18.4095L23.25 22.6195L23.24 22.6295ZM20.93 8.34949L21.63 6.60949L23.37 5.90949C23.75 5.75949 24 5.38949 24 4.97949C24 4.56949 23.75 4.19949 23.37 4.04949L21.63 3.34949L20.93 1.60949C20.78 1.22949 20.41 0.979492 20 0.979492C19.59 0.979492 19.22 1.22949 19.07 1.60949L18.38 3.33949L16.65 3.99949C16.27 4.13949 16.01 4.50949 16 4.91949C16 5.32949 16.23 5.69949 16.61 5.85949L18.37 6.59949L19.07 8.34949C19.22 8.72949 19.59 8.97949 20 8.97949C20.41 8.97949 20.78 8.72949 20.93 8.34949ZM24 7.80949V19.9795C24 20.1695 23.99 20.3495 23.97 20.5295L20.44 16.9995C19.1 15.6595 16.91 15.6595 15.56 16.9995L14.98 17.5795C14.44 18.1195 13.55 18.1195 13.01 17.5795L7.43 11.9995C6.09 10.6595 3.9 10.6595 2.55 11.9995L0 14.5695V5.97949C0 3.21949 2.24 0.979492 5 0.979492H17.17L16.85 1.77949L15.94 2.11949C14.79 2.55949 14.02 3.63949 14 4.86949C13.98 6.09949 14.7 7.20949 15.83 7.68949L16.82 8.10949L17.21 9.08949C17.67 10.2395 18.76 10.9795 20 10.9795C21.24 10.9795 22.33 10.2395 22.79 9.08949L23.17 8.13949L24 7.80949ZM9 6.47949C9 5.64949 8.33 4.97949 7.5 4.97949C6.67 4.97949 6 5.64949 6 6.47949C6 7.30949 6.67 7.97949 7.5 7.97949C8.33 7.97949 9 7.30949 9 6.47949ZM17 11.4795C17 11.0195 16.69 10.6195 16.24 10.5095L14.82 10.1595L14.47 8.73949C14.36 8.29949 13.96 7.97949 13.5 7.97949C13.04 7.97949 12.64 8.28949 12.53 8.73949L12.18 10.1495L10.78 10.4695C10.33 10.5695 10.01 10.9695 10.01 11.4295C10.01 11.8895 10.31 12.2895 10.75 12.4095L12.18 12.7995L12.54 14.2295C12.65 14.6695 13.05 14.9895 13.51 14.9895C13.97 14.9895 14.37 14.6795 14.48 14.2295L14.83 12.8095L16.25 12.4595C16.69 12.3495 17.01 11.9495 17.01 11.4895L17 11.4795Z" fill="#00504E" />
                                                </svg>
                                                <span className="text_brand_color2 ms-2">Photo/video</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </NavLink>

                            {isLoading ?
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // height: "100vh",
                                        // width: "100vw",
                                        marginTop: "5em"
                                    }}
                                >
                                    <p>
                                        <PulseLoader color="#00504E" size={20} />
                                    </p>
                                </div>
                                :
                                posts && posts.length > 0 ?
                                    !isLoading && posts?.map((i) => {
                                        return (
                                            <div className="asf_card">
                                                <div className="asf_card_header socialfeed-main">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <div className="user_img_sm_rnd">
                                                                <img width={45} height={45} className="rounded-circle" src={process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + user?.profile_pic} alt="" />
                                                            </div>
                                                            <div className="ms-2">
                                                                <h5 className="fs-16 fw-500 m-0">
                                                                    {user?.name}
                                                                    <span className="hrs position-relative fs-10 fw-500 ps-3 ms-3">{moment(i?.created_at).fromNow(true)}</span>
                                                                </h5>
                                                                {user?.skills?.map((i) => {
                                                                    return <label>{i?.name}</label>
                                                                })}
                                                            </div>
                                                        </div>
                                                        {/* <Button variant="" className="p-0 h-auto fs-24 fw-700">&#8943;</Button> */}
                                                        <Dropdown >
                                                            <Dropdown.Toggle variant="ellipsis" as={Button} className="p-0 h-auto fs-24 fw-700">
                                                                &#8943;
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => onClickEdit(i)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleDeleteClick(i?.id)}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="asf_card_body">
                                                    <div className="p10">
                                                        <p className="fs-16 fw-400 m-0" dangerouslySetInnerHTML={{ __html: i?.description }} ></p>
                                                    </div>
                                                    {i?.type === "Photo" && <img className="asf_img" src={process.env.REACT_APP_COACH_POST_IMAGE_PATH + i?.file_url} alt="" onError={(e) => defaultImage(e)} />}
                                                    {i?.type === "Video" && <video className="img_fit_contain asf_img" controls src={process.env.REACT_APP_COACH_POST_VIDEO_PATH + i?.file_url} alt=""></video>}
                                                </div>
                                                <div className="asf_card_footer">
                                                    <div className="d-flex align-items-center justify-content-around">
                                                        <div className="d-flex align-items-center">
                                                            <NavLink onClick={likeButton}>
                                                                <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.6073 22.5423C-9.7256 9.64577 6.60771 -4.35423 13.6073 4.4985C20.6077 -4.35423 36.941 9.64577 13.6073 22.5423Z" stroke="#964B00" stroke-width="3" />
                                                                </svg>
                                                                <span className="mx-1">{i?.like_count} likes</span>
                                                            </NavLink>
                                                        </div>
                                                        <div onClick={() => onClickComment(i?.id)} className="d-flex align-items-center">
                                                            <NavLink>
                                                                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M24.6074 14.3962C24.6114 15.936 24.2517 17.4551 23.5574 18.8295C22.7343 20.4766 21.4688 21.8619 19.9028 22.8304C18.3368 23.7988 16.532 24.3121 14.6908 24.3129C13.1509 24.3169 11.6319 23.9571 10.2574 23.2629L3.60742 25.4795L5.82409 18.8295C5.12984 17.4551 4.77007 15.936 4.77409 14.3962C4.7748 12.5549 5.28814 10.7502 6.25659 9.18417C7.22505 7.61815 8.61039 6.35269 10.2574 5.52953C11.6319 4.83528 13.1509 4.47551 14.6908 4.47953H15.2741C17.7058 4.61368 20.0026 5.64008 21.7248 7.36219C23.4469 9.08431 24.4733 11.3811 24.6074 13.8129V14.3962Z" stroke="#964B00" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                                <span className="mx-1">{i?.comment_count} comments</span>
                                                            </NavLink>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <NavLink>
                                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17.1068 19.9808C17.1068 22.7401 14.8674 24.9795 12.1082 24.9795H5.11C2.35073 24.9795 0.111328 22.7401 0.111328 19.9808V12.9827C0.111328 10.2234 2.35073 7.98399 5.11 7.98399H6.10974C6.65959 7.98399 7.10947 8.43387 7.10947 8.98373C7.10947 9.53358 6.65959 9.98346 6.10974 9.98346H5.11C3.46044 9.98346 2.1108 11.3331 2.1108 12.9827V19.9808C2.1108 21.6304 3.46044 22.98 5.11 22.98H12.1082C13.7577 22.98 15.1074 21.6304 15.1074 19.9808C15.1074 19.431 15.5572 18.9811 16.1071 18.9811C16.6569 18.9811 17.1068 19.431 17.1068 19.9808ZM23.2352 6.87429L17.8266 1.28577C17.4467 0.885874 16.8069 0.875876 16.417 1.26577C16.0271 1.65567 16.0071 2.2855 16.397 2.6754L21.5357 7.974H13.1079C10.3486 7.974 8.10921 10.2134 8.10921 12.9727V17.9713C8.10921 18.5212 8.55909 18.9711 9.10894 18.9711C9.6588 18.9711 10.1087 18.5212 10.1087 17.9713V12.9727C10.1087 11.3231 11.4583 9.97347 13.1079 9.97347H21.5257L16.387 15.2721C16.0071 15.672 16.0071 16.3018 16.407 16.6817C16.597 16.8716 16.8569 16.9616 17.1068 16.9616C17.3568 16.9616 17.6267 16.8616 17.8266 16.6617L23.2252 11.0932C24.3949 9.92348 24.3949 8.02398 23.2352 6.86429V6.87429Z" fill="#964B00" />
                                                                </svg>
                                                                <span className="mx-1">Share</span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                    :
                                    <div className="text-center my-5 pt-5">
                                        <p>No feeds available yet.</p>
                                    </div>
                            }

                        </Col>

                    </Row>
                </Col>
            </Row>
            <SocialFeedsPostComponent getData={getData} show={sfPostModalShow} onHide={() => setSfPostModalShow(false)} />
            <EditsocialFeedsPostComponent editData={editData} getData={getData} show={showEditDeletePopup} onHide={() => setShowEditDeletePopup(false)} />
            <LoginComponent show={loginModalShow} onHide={() => setLoginModalShow(false)} />
        </>
    )
}

export default CoachMyActivitySocialFeedsComponent;