import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { userSignUp, coachSignUp, coachLinkedin } from "../../../utils/api";
import { LinkedInApi } from "../../../utils/linkedinConfig";
import close from "../../../assets/images/icons/close.png";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import linkedIn from "../../../assets/images/icons/linkedin.png";
import LoginComponent from "../Login/index";

const validationSchemaUser = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "Name can only contain Latin letters."
    ),
  email: Yup.string().email("Invalid email").required("Email is required"),
  pwd: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z](?=.*[0-9])/, "Password Should be alphanumeric"),
  userType: Yup.string().required("User type is required"),
});

const validationSchemaLinkedIn = Yup.object().shape({
  userType: Yup.string().required("Please select a user type"),
});

const SignUpComponent = (props) => {
  const { getUserFromToken } = useUserContext();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLinkedInClicked, setIsLinkedInClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: "code", code: code }, "*");
      window.close();
    }
    window.addEventListener("message", handlePostMessage);
  }, []);

  const getCodeFromWindowURL = (url) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const handlePostMessage = (event) => {
    if (event.data.type === "code") {
      const { code } = event.data;
      getUserCredentials(code);
    }
  };

  const getUserCredentials = async (code) => {
    const type = localStorage.getItem("temp_userType");
    setisLoading(true);
    try {
      const res = await coachLinkedin(code, type);
      if (res?.data?.data?.is_login) {
        toast.success("Sign Up successfully!");
        localStorage.setItem("token", res?.data?.data?.token);
        if (type === "coach") {
          navigate("/coach");
        } else {
          if (getUserFromToken(res?.data.data?.token)) {
            navigate("/user/profile");
          }
        }
      } else {
        toast.error(res?.data?.data?.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setisLoading(false);
    }
  };

  const signUpWithLinkedin = (values) => {
    localStorage.setItem("temp_userType", values.userType);
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrlWithParams = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}&userType=${values.userType}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlWithParams,
      "Linkedin",
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=${width},height=${height},top=${top},left=${left}`
    );
  };

  const onSubmitHandler = async (values) => {
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("emailid", values.email);
      formData.append("password", values.pwd);

      let res = null;

      if (values.userType === "user") {
        const { data } = await userSignUp(formData);
        res = data;
      } else {
        const { data } = await coachSignUp(formData);
        res = data;
      }

      if (res?.status) {
        toast.success(res?.message || "Account created successfully");
        props.onHide();
      } else {
        toast.error(res?.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="brand_bg_color1 my_light_modal">
          <div className="my_light_modal">
            <Row className="justify-content-center">
              <Col lg="12">
                <div className="ls_modal_conatiner">
                  <Button
                    className="ls_mdl_close_btn p-0"
                    onClick={props.onHide}
                  >
                    <img alt="" src={close} />
                  </Button>
                  <div className="hdng_a text-start">
                    <h6 className="text-uppercase">SIGN UP</h6>
                    <h3 className="fw-700">Create An Account</h3>
                    <p className="fs-16 fw-500">Have an account?
                      <NavLink className="text_brand_color2 fw-700" onClick={props.onHide}>
                        <span className="ms-2">Login</span>
                      </NavLink>
                    </p>
                  </div>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      pwd: "",
                      userType: "",
                    }}
                    validationSchema={
                      isLinkedInClicked ?
                        validationSchemaLinkedIn
                        :
                        userType === "user"
                          ? validationSchemaUser
                          : validationSchemaLinkedIn
                    }
                    onSubmit={(values) => {
                      if (isLinkedInClicked) {
                        signUpWithLinkedin(values);
                      } else {
                        onSubmitHandler(values);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form className="" onSubmit={handleSubmit}>
                        <Row className="user_coach_rd_btn my-4">
                          <Col>
                            <Form.Check
                              label="User"
                              name="userType"
                              type="radio"
                              id="user_coach-user"
                              value="user"
                              onChange={(e) => {
                                handleChange(e);
                                setUserType(e.target.value);
                              }}
                              isInvalid={
                                touched.userType && !!errors.userType
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              label="Coach"
                              name="userType"
                              type="radio"
                              id="user_coach-coach"
                              value="coach"
                              onChange={(e) => {
                                handleChange(e);
                                setUserType(e.target.value);
                              }}
                              isInvalid={
                                touched.userType && !!errors.userType
                              }
                            />
                          </Col>
                          <Form.Control.Feedback type="invalid">
                            {errors.userType}
                          </Form.Control.Feedback>
                        </Row>
                        {/* {userType === "user" && !isLinkedInClicked && ( */}
                        {/* {userType === "user" && ( */}
                          <>
                            <Form.Group className="mb-3 mt-3" controlId="name">
                              <Form.Control
                                name="name"
                                type="text"
                                placeholder="Your Name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={
                                  touched.name && !!errors.name
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                              <Form.Control
                                name="email"
                                type="email"
                                placeholder="Your Email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={
                                  touched.email && !!errors.email
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="pwd">
                              <InputGroup>
                                <Form.Control
                                  name="pwd"
                                  placeholder="Your Password"
                                  type={isRevealPwd ? "text" : "password"}
                                  value={values.pwd}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.pwd && !!errors.pwd
                                  }
                                  className="border-end-0"
                                />
                                <InputGroup.Text id="basic-addon2" className="border-start-0 bg-transparent">
                                  <img
                                    alt=""
                                    width="20"
                                    title={
                                      isRevealPwd
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                    src={isRevealPwd ? view : hidden}
                                    onClick={() =>
                                      setIsRevealPwd((prevState) => !prevState)
                                    }
                                  />
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                  {errors.pwd}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </>
                        {/* )} */}
                        {/* <div className="signup-text my-3">
                          By Logging in, you agree to the{" "}
                          <a
                            className="signup-text-inner"
                            href="/terms-of-services"
                          >
                            terms of use
                          </a>{" "}
                          and{" "}
                          <a
                            className="signup-text-inner"
                            href="/privacy-policy"
                          >
                            privacy Statement
                          </a>
                        </div> */}
                        {/* {userType === "user" && ( */}
                          <>
                            <Form.Group className="d-grid mx-auto">
                              <Button
                                disabled={isLoading}
                                variant="success"
                                type="submit"
                                onClick={() => {
                                  setIsLinkedInClicked(false);
                                }}
                              >
                                Create Account
                              </Button>
                            </Form.Group>
                            <Form.Group className="divider">Or</Form.Group>
                          </>
                        {/* )} */}
                        <Form.Group className="text-center">
                          <Button
                            variant="linkedin"
                            type="button"
                            onClick={() => {
                              setIsLinkedInClicked(true);
                              handleSubmit();
                            }}
                          >
                            <img
                              alt=""
                              className="me-2"
                              width="20"
                              src={linkedIn}
                            />{" "}
                            Sign up with LinkedIn
                          </Button>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      {/* <LoginComponent
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      /> */}
    </>
  );
};

export default SignUpComponent;
