import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { useUserContext } from "../../../../utils/hooks/UserContext";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { PulseLoader } from "react-spinners";
import { blogList, getSingleCoachBlogList } from "../../../../utils/api";
import { NavLink } from "react-router-dom";
import blog_card_img1 from "../../../../assets/images/dummy/blog_card_img1.png";
import testimonials_img1 from "../../../../assets/images/dummy/testimonials_img1.png";
import noblogs from "../../../../assets/images/no-blogs.png";
import LoginComponent from "../../Login/newindex";

function getDate(time) {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const date = new Date(time).getDate();
    const year = new Date(time).getFullYear();
    const month = months[new Date(time).getMonth()];
    let hour = new Date(time).getHours();
    let minute = new Date(time).getMinutes();
    const am_pm = hour > 12 ? "PM" : "AM";
    const time_string = `${date} ${month} ${year}`;
    return time_string;
}
const CoachDetailsBlogComponent = ({ userData }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [allBlogs, setAllBlogs] = useState([]);
    const [loginModalShow, setLoginModalShow] = useState(false);

    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        getData()
    }, [])

    const getData = async () => {
        const { data } = await getSingleCoachBlogList(userData?.coach_id)
        setAllBlogs(data.data)
        setLoading(false)
    }
    const defaultImage = (e) => {
        e.target.src = 'https://nadmin.qoach.io/assets/images/blog.png';
    }
    const defaultUserImage = (e) => {
        e.target.src = testimonials_img1;
    };
    const removeHTMLTags = (str) => {
        return str.replace(/<[^>]+>/g, '');
    };
    return (
        <>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <p>
                        <PulseLoader color="#00504E" size={20} />
                    </p>
                </div>
            ) : (
                <>
                    <Container className="coach_details_about">
                        <Row>
                            {
                                allBlogs && allBlogs.length > 0 ?
                                    allBlogs.map((blog, index) => (
                                        <Col sm="6" lg="4" className="mb-4" key={index}>
                                            <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>
                                                <div className="blog_card border rounded-4 h-100 overflow-hidden">
                                                    <div className="blog_card_img">
                                                        <img
                                                            alt=""
                                                            className="w-100"
                                                            src={blog.image ? `${process.env.REACT_APP_SOCKET_URL}/upload/cab/resized/${blog.image}` : blog_card_img1}
                                                            onError={defaultImage}
                                                        />
                                                    </div>
                                                    <div className="blog_card_body px-3">
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    width={30}
                                                                    height={30}
                                                                    alt=""
                                                                    className="user_img rounded-circle me-2"
                                                                    src={blog.profile_pic ? `${process.env.REACT_APP_SOCKET_URL}/upload/users/profile/${blog.profile_pic}` : testimonials_img1}
                                                                    style={{ borderRadius: '50%' }}
                                                                    onError={defaultUserImage}
                                                                />
                                                                <h5 className="m-0 fs-18">{blog.name ? blog.name : 'Jack Mick'}</h5>
                                                            </div>
                                                            <span className="fs-12 fw-500 opacity_7">
                                                                {getDate(blog.blog_date !== null ? blog.blog_date : blog.updated_at)}
                                                            </span>
                                                        </div>
                                                        <h5 className="m-0 mt-2 mb-1 fs-18 fw-500 truncate-two-lines">{blog?.title}</h5>
                                                        {/* <p className="fs-14 fw-300 opacity_7 mb-3">{removeHTMLTags(blog.content).substring(0, 125)}...</p> */}
                                                        <p className="fs-14 fw-300 opacity_7 mb-3">{removeHTMLTags(blog.content)}</p>
                                                        <NavLink className="text_brand_color8 fw-500 fs-14" to={`/blog-details/${blog.slug}`}>Read More</NavLink>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </Col>
                                    ))
                                    :
                                    <div className="text-center mb-5">
                                        <img src={noblogs} alt="no blogs" />
                                        <p>No Published Blogs Yet</p>
                                    </div>
                            }
                        </Row>
                    </Container>
                    <LoginComponent show={loginModalShow} isCoach={true} onHide={() => setLoginModalShow(false)} />

                </>
            )
            }
        </>
    );
};

export default CoachDetailsBlogComponent;
