import React, { useState, useRef } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import close from '../../../assets/images/icons/close.png';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { coachEditCaseStudy } from "../../../utils/api";




const validationSchema = Yup.object().shape({
  problem_issues: Yup.string().required('Problem or issues are required'),
  solution: Yup.string().required('Solution is required'),
  implementation: Yup.string().required('Implementation is required'),
});

function EditCaseStudyModal(props) {


  const onSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData()
    formData.append('problem_issues', values?.problem_issues)
    formData.append('solution', values?.solution)
    formData.append('implementation', values?.implementation)
    await coachEditCaseStudy(props?.editData?.id, formData)
    props.getData()

    // Clear form and close modal after successful submission
    setSubmitting(false);
    props.onHide();
  }
  return (

    <Modal {...props} size="md" aria-labelledby="contained-modal-problem_issues-vcenter" centered >
      <div className="brand_bg_color1 my_light_modal">
        <Container>
          <Row className="justify-content-center">
            <Col sm="12">
              <div className="ls_modal_conatiner">
                <Button className="ls_mdl_close_btn p-0" onClick={props.onHide}>
                  <img src={close} alt="" />
                </Button>
                <div className="text-start">
                  <h6 className="fs-20 fw-500 text_brand_color2">Edit Case study</h6>
                </div>
              </div>
            </Col>
            <Formik
              initialValues={props?.editData}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, setFieldValue, values, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Col sm="12">
                    <Form.Group className="mb-3 mt-2" controlId="text">
                      <Form.Label>Problem or Issue</Form.Label>
                      <Form.Control
                        rows="4"
                        name="problem_issues"
                        type="text"
                        as="textarea"
                        placeholder="Problem you solved"
                        value={values.problem_issues}
                        isInvalid={touched.problem_issues && !!errors.problem_issues}
                        onChange={(e) => setFieldValue("problem_issues", e?.target?.value)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.problem_issues}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="mb-3 mt-2" controlId="text">
                      <Form.Label>Solution</Form.Label>
                      <Form.Control
                        rows="4"
                        name="solution"
                        type="text"
                        as="textarea"
                        placeholder="Solution to address the problem or issue"
                        value={values.solution}
                        isInvalid={touched.solution && !!errors.solution}
                        onChange={(e) => setFieldValue("solution", e?.target?.value)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.solution}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="mb-3 mt-2" controlId="text">
                      <Form.Label>Implementation</Form.Label>
                      <Form.Control
                        rows="4"
                        name="implementation"
                        type="text"
                        as="textarea"
                        placeholder="Outline the steps that need to be taken to implement the recommended solution"
                        value={values.implementation}
                        isInvalid={touched.implementation && !!errors.implementation}
                        onChange={(e) => setFieldValue("implementation", e?.target?.value)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.implementation}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12 text-end">
                    <Button className='px-5' variant='success' type='submit'>Post</Button>
                  </Col>
                </Form>
              )}
            </Formik>
          </Row>
        </Container>
      </div>
    </Modal>
  );
}

export default EditCaseStudyModal;