import React from "react";
import { Outlet } from "react-router-dom";


function BrowseCoachComponent(){
    return(
        <>

            <Outlet/>
        </>
    )
}

export default BrowseCoachComponent