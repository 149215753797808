import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import HomeFooterComponent from "../Front/Common/Footer";
import { Container } from "react-bootstrap";
import CoachHeaderComponent from "./Common/Header";
import CoachSideMenuComponent from "./Common/Sidemenu";
import CoachSideMenuMobileComponent from "./Common/Sidemenu/SideMenuMobile";

function CoachLayoutComponent(props) {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
      const canonicalUrl = window.location.origin + window.location.pathname;
      let link = document.querySelector("link[rel='canonical']");
      if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
      }
      link.setAttribute('href', canonicalUrl);
  
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);
  const [closeSideBarToggle, setCloseSideBarToggle] = useState(true);
  const changeToggle = () => {
    setCloseSideBarToggle(!closeSideBarToggle);
  };
  return (
    <>
      <CoachHeaderComponent />
      <Container className="user_container">
        {/* {window.innerWidth > 500 ? ( */}
        <div className="user_dash_container">
          {/* <div className="side_menu_col"> */}
          {/* <div className={`side_menu_col`} style={`${closeSideBarToggle === false ? 'width: 0' : 'width: 230px'}`}> */}
          {/* <div className={`side_menu_col z-3 p-0 ${closeSideBarToggle === false ? 'bg-transparent' : 'bg-white position-absolute top-0 start-0'}`} style={{ width: closeSideBarToggle ? '230px' : '0' }}> */}
          <div
            className={`side_menu_col z-3 p-0 mt-4 ${
              closeSideBarToggle === false
                ? "bg-transparent"
                : window.innerWidth > 768
                ? "bg-white top-0 start-0"
                : "bg-white position-absolute top-0 start-0"
            }`}
            style={{ width: closeSideBarToggle ? "230px" : "0" }}
          >
            <button
              onClick={() => changeToggle()}
              className="bg-transparent border-0 close-toggle"
            >
              {closeSideBarToggle === false ? (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.75 9.51904H1.25C0.559648 9.51904 0 10.0787 0 10.769C0 11.4594 0.559648 12.019 1.25 12.019H18.75C19.4404 12.019 20 11.4594 20 10.769C20 10.0787 19.4404 9.51904 18.75 9.51904Z"
                    fill="#000"
                  />
                  <path
                    d="M1.25 6.18579H18.75C19.4404 6.18579 20 5.62614 20 4.93579C20 4.24544 19.4404 3.68579 18.75 3.68579H1.25C0.559648 3.68579 0 4.24544 0 4.93579C0 5.62614 0.559648 6.18579 1.25 6.18579Z"
                    fill="#000"
                  />
                  <path
                    d="M18.75 15.3525H1.25C0.559648 15.3525 0 15.9122 0 16.6025C0 17.2929 0.559648 17.8525 1.25 17.8525H18.75C19.4404 17.8525 20 17.2929 20 16.6025C20 15.9122 19.4404 15.3525 18.75 15.3525Z"
                    fill="#000"
                  />
                </svg>
              ) : (
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.9908 1.20417L9.62681 7.56813L3.26285 1.20417C2.67707 0.618389 1.72731 0.618389 1.14153 1.20417C0.555748 1.78996 0.555749 2.73971 1.14153 3.32549L7.50549 9.68945L1.14153 16.0534C0.555749 16.6392 0.555748 17.589 1.14153 18.1747C1.72731 18.7605 2.67707 18.7605 3.26285 18.1747L9.62681 11.8108L15.9908 18.1747C16.5766 18.7605 17.5263 18.7605 18.1121 18.1747C18.6979 17.589 18.6979 16.6392 18.1121 16.0534L11.7481 9.68945L18.1121 3.32549C18.6979 2.73971 18.6979 1.78995 18.1121 1.20417C17.5263 0.618389 16.5766 0.61839 15.9908 1.20417Z"
                    fill="#00504E"
                  />
                </svg>
              )}
            </button>
            <div
              className={`${closeSideBarToggle === false ? "closeToggle" : ""}`}
            >
              <CoachSideMenuComponent />
            </div>
          </div>
          {/* <div className="user_dash_container_outlet"> */}
          <div
            className={`mt-4 user_dash_container_outlet ${
              closeSideBarToggle === false ? "openFullSection" : ""
            }`}
            
          >
            <Outlet />
          </div>
        </div>
        {/* ) : (
          <div>
            <CoachSideMenuMobileComponent />
            <Outlet />
          </div>
        )} */}
      </Container>
      <HomeFooterComponent />
    </>
  );
}

export default CoachLayoutComponent;
