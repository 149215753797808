import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from '../../../assets/images/logo_dark.png';
import './Footer.scss';

function HomeFooterComponent(){
    return(
        <>
            <footer>
                <Container className="d-none d-sm-block mb-4">
                    <hr/>
                    <Row className="justify-content-between g-lg-5">
                        <Col lg="5">
                            <div className="footer_details">
                                <NavLink className="navbar-brand" to="/">
                                <img src={logo} alt="" />
                                </NavLink>
                                <p className="fs-14" style={{ whiteSpace: 'pre-line' }}>
                                    Qoach goes beyond traditional<br/>
                                    coaching platform, prioritizing<br/>
                                    precision and personalization.
                                </p>

                                {/* <p>
                                    Everyone is encouraged to become a<br/>
                                    coach.
                                </p> */}
                                
                                {/* <div className="social_link my-4">
                                    <NavLink target="_blank" to="https://www.facebook.com/">
                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.14269 13.8502V7.03605H7.19067L7.46214 4.63778H5.14269L5.14613 3.43734C5.14613 2.81181 5.20948 2.4768 6.16733 2.4768H7.44778V0.078125H5.39934C2.9388 0.078125 2.07289 1.24149 2.07289 3.19818V4.63796H0.539062V7.03645H2.07289V13.7536C2.67043 13.8653 3.28818 13.9243 3.92069 13.9243C4.3293 13.9243 4.73748 13.8996 5.14269 13.8502Z" fill="white"/>
                                        </svg>
                                    </NavLink>
                                    <NavLink target="_blank" to="https://www.linkedin.com/">
                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.0761719 1.68652C0.0761719 1.22031 0.239898 0.835691 0.567336 0.53267C0.894774 0.229636 1.32046 0.078125 1.84436 0.078125C2.35892 0.078125 2.77523 0.2273 3.09332 0.525677C3.42076 0.83337 3.58449 1.23429 3.58449 1.72847C3.58449 2.17603 3.42545 2.54898 3.10736 2.84736C2.77992 3.15505 2.34956 3.30889 1.8163 3.30889H1.80226C1.28771 3.30889 0.871395 3.15505 0.553303 2.84736C0.235211 2.53966 0.0761719 2.15271 0.0761719 1.68652ZM0.258604 13.9243V4.58162H3.37399V13.9243H0.258604ZM5.10008 13.9243H8.21546V8.7075C8.21546 8.38115 8.25289 8.1294 8.32773 7.95225C8.4587 7.63523 8.65751 7.36716 8.92414 7.14806C9.19078 6.92894 9.52523 6.81938 9.92752 6.81938C10.9753 6.81938 11.4992 7.52334 11.4992 8.93127V13.9243H14.6146V8.56764C14.6146 7.18768 14.2872 6.14106 13.6323 5.42778C12.9774 4.71449 12.112 4.35785 11.0362 4.35785C9.82929 4.35785 8.88906 4.87533 8.21546 5.91029V5.93827H8.20143L8.21546 5.91029V4.58162H5.10008C5.11879 4.87999 5.12815 5.80772 5.12815 7.36484C5.12815 8.92194 5.11879 11.1084 5.10008 13.9243Z" fill="white"/>
                                        </svg>
                                    </NavLink>
                                    <NavLink target="_blank" to="https://twitter.com">
                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 1.7173C17.3306 1.99528 16.6174 2.17954 15.8737 2.26901C16.6388 1.83659 17.2226 1.15706 17.4971 0.338007C16.7839 0.74061 15.9964 1.02499 15.1571 1.18369C14.4799 0.500965 13.5146 0.078125 12.4616 0.078125C10.4186 0.078125 8.77387 1.64807 8.77387 3.57268C8.77387 3.8496 8.79862 4.11588 8.85938 4.36937C5.7915 4.22771 3.07687 2.83564 1.25325 0.715048C0.934875 1.23801 0.748125 1.83659 0.748125 2.48097C0.748125 3.69091 1.40625 4.76345 2.38725 5.3844C1.79437 5.37375 1.21275 5.21079 0.72 4.9541C0.72 4.96475 0.72 4.9786 0.72 4.99244C0.72 6.6902 1.99912 8.10037 3.6765 8.42523C3.37612 8.50298 3.04875 8.54025 2.709 8.54025C2.47275 8.54025 2.23425 8.52747 2.01038 8.48061C2.4885 9.86416 3.84525 10.8813 5.4585 10.9143C4.203 11.8442 2.60888 12.4044 0.883125 12.4044C0.5805 12.4044 0.29025 12.3916 0 12.3565C1.63462 13.3545 3.57188 13.9243 5.661 13.9243C12.4515 13.9243 16.164 8.59883 16.164 3.98274C16.164 3.8283 16.1584 3.67919 16.1505 3.53114C16.8829 3.03907 17.4982 2.42452 18 1.7173Z" fill="white"/>
                                        </svg>
                                    </NavLink>
                                </div> */}
                            </div>
                        </Col>
                        <Col lg="auto">
                            <div className="menu_link">
                                <h4>Menu</h4>                                
                                <NavLink className="fs-14 d-block" to="/browse-coach/list-view">Browse Coach</NavLink>
                                <NavLink className="fs-14 d-block" to="/activity-stream">Activity Stream</NavLink>
                                <NavLink className="fs-14 d-block" to="/become-a-coach">Become Qoach</NavLink>
                                <NavLink className="fs-14 d-block" to="/blogs">Blogs</NavLink>
                            </div>
                        </Col>
                        <Col lg="auto">
                            <div className="menu_link">
                                <h4>Support</h4>
                                <NavLink className="fs-14 d-block" to="/contact-us">Contact Us</NavLink>
                                <NavLink className="fs-14 d-block" to="/privacy-policy">Privacy Policy</NavLink>
                                <NavLink className="fs-14 d-block" to="/terms-of-services">Terms of Services</NavLink>
                            </div>
                        </Col>
                            
                    </Row>
                    <hr/>
                    {/* <Row className="align-items-center justify-content-center pb-3">
                        <Col className="col-auto">
                            <Row>
                                <Col>
                                    <div className="copyright">
                                        <p>Copyright @Qoach 2022</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row className="align-items-center g-2 pb-3">
                        <Col>
                            <div className="social_link ">
                                <NavLink target="_blank" to="https://www.facebook.com/profile.php?id=61557180556606">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.14269 13.8502V7.03605H7.19067L7.46214 4.63778H5.14269L5.14613 3.43734C5.14613 2.81181 5.20948 2.4768 6.16733 2.4768H7.44778V0.078125H5.39934C2.9388 0.078125 2.07289 1.24149 2.07289 3.19818V4.63796H0.539062V7.03645H2.07289V13.7536C2.67043 13.8653 3.28818 13.9243 3.92069 13.9243C4.3293 13.9243 4.73748 13.8996 5.14269 13.8502Z" fill="white"/>
                                    </svg>
                                </NavLink>
                                <NavLink target="_blank" to="https://www.linkedin.com/company/qoach/">
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.0761719 1.68652C0.0761719 1.22031 0.239898 0.835691 0.567336 0.53267C0.894774 0.229636 1.32046 0.078125 1.84436 0.078125C2.35892 0.078125 2.77523 0.2273 3.09332 0.525677C3.42076 0.83337 3.58449 1.23429 3.58449 1.72847C3.58449 2.17603 3.42545 2.54898 3.10736 2.84736C2.77992 3.15505 2.34956 3.30889 1.8163 3.30889H1.80226C1.28771 3.30889 0.871395 3.15505 0.553303 2.84736C0.235211 2.53966 0.0761719 2.15271 0.0761719 1.68652ZM0.258604 13.9243V4.58162H3.37399V13.9243H0.258604ZM5.10008 13.9243H8.21546V8.7075C8.21546 8.38115 8.25289 8.1294 8.32773 7.95225C8.4587 7.63523 8.65751 7.36716 8.92414 7.14806C9.19078 6.92894 9.52523 6.81938 9.92752 6.81938C10.9753 6.81938 11.4992 7.52334 11.4992 8.93127V13.9243H14.6146V8.56764C14.6146 7.18768 14.2872 6.14106 13.6323 5.42778C12.9774 4.71449 12.112 4.35785 11.0362 4.35785C9.82929 4.35785 8.88906 4.87533 8.21546 5.91029V5.93827H8.20143L8.21546 5.91029V4.58162H5.10008C5.11879 4.87999 5.12815 5.80772 5.12815 7.36484C5.12815 8.92194 5.11879 11.1084 5.10008 13.9243Z" fill="white"/>
                                    </svg>
                                </NavLink>
                                <NavLink target="_blank" to="https://twitter.com/Qoach_io">
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 1.7173C17.3306 1.99528 16.6174 2.17954 15.8737 2.26901C16.6388 1.83659 17.2226 1.15706 17.4971 0.338007C16.7839 0.74061 15.9964 1.02499 15.1571 1.18369C14.4799 0.500965 13.5146 0.078125 12.4616 0.078125C10.4186 0.078125 8.77387 1.64807 8.77387 3.57268C8.77387 3.8496 8.79862 4.11588 8.85938 4.36937C5.7915 4.22771 3.07687 2.83564 1.25325 0.715048C0.934875 1.23801 0.748125 1.83659 0.748125 2.48097C0.748125 3.69091 1.40625 4.76345 2.38725 5.3844C1.79437 5.37375 1.21275 5.21079 0.72 4.9541C0.72 4.96475 0.72 4.9786 0.72 4.99244C0.72 6.6902 1.99912 8.10037 3.6765 8.42523C3.37612 8.50298 3.04875 8.54025 2.709 8.54025C2.47275 8.54025 2.23425 8.52747 2.01038 8.48061C2.4885 9.86416 3.84525 10.8813 5.4585 10.9143C4.203 11.8442 2.60888 12.4044 0.883125 12.4044C0.5805 12.4044 0.29025 12.3916 0 12.3565C1.63462 13.3545 3.57188 13.9243 5.661 13.9243C12.4515 13.9243 16.164 8.59883 16.164 3.98274C16.164 3.8283 16.1584 3.67919 16.1505 3.53114C16.8829 3.03907 17.4982 2.42452 18 1.7173Z" fill="white"/>
                                    </svg>
                                </NavLink>
                                <NavLink target="_blank" to="https://www.instagram.com/qoach.io/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram">
                                        <rect x="2" y="2" width="20" height="20" rx="5" ry="5" fill="transparent" stroke="white"></rect>
                                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37zM17.5 6.5" stroke="white"></path>
                                    </svg>
                                </NavLink>
                            </div>
                        </Col>
                        <Col lg="auto">
                            <div className="copyright">
                                <p>Copyright @Qoach 2022</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                
                <Container className="d-block d-sm-none mb-4">
                    <hr/>
                    <Row className="justify-content-between g-lg-5">
                        <Col lg="auto">
                            <div className="menu_link">
                                <h4>Menu</h4>                                
                                <NavLink to="/browse-coach/list-view">Browse Coach</NavLink>
                                <NavLink to="/activity-stream">Activity Stream</NavLink>
                                <NavLink to="/become-a-coach">Become Qoach</NavLink>
                                <NavLink to="/blogs">Blogs</NavLink>
                            </div>
                        </Col>
                        <Col lg="auto">
                            <div className="menu_link">
                                <h4>Support</h4>
                                <NavLink to="/contact-us">Contact Us</NavLink>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                                <NavLink to="/terms-of-services">Terms of Services</NavLink>
                            </div>
                        </Col>
                            
                    </Row>
                    <Row className="align-items-center justify-content-center g-2 pb-3">
                        <Col sm="12" className="text-center">
                            <div className="social_link ">
                                <NavLink target="_blank" to="https://www.facebook.com/">
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.14269 13.8502V7.03605H7.19067L7.46214 4.63778H5.14269L5.14613 3.43734C5.14613 2.81181 5.20948 2.4768 6.16733 2.4768H7.44778V0.078125H5.39934C2.9388 0.078125 2.07289 1.24149 2.07289 3.19818V4.63796H0.539062V7.03645H2.07289V13.7536C2.67043 13.8653 3.28818 13.9243 3.92069 13.9243C4.3293 13.9243 4.73748 13.8996 5.14269 13.8502Z" fill="white"/>
                                    </svg>
                                </NavLink>
                                <NavLink target="_blank" to="https://www.linkedin.com/">
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.0761719 1.68652C0.0761719 1.22031 0.239898 0.835691 0.567336 0.53267C0.894774 0.229636 1.32046 0.078125 1.84436 0.078125C2.35892 0.078125 2.77523 0.2273 3.09332 0.525677C3.42076 0.83337 3.58449 1.23429 3.58449 1.72847C3.58449 2.17603 3.42545 2.54898 3.10736 2.84736C2.77992 3.15505 2.34956 3.30889 1.8163 3.30889H1.80226C1.28771 3.30889 0.871395 3.15505 0.553303 2.84736C0.235211 2.53966 0.0761719 2.15271 0.0761719 1.68652ZM0.258604 13.9243V4.58162H3.37399V13.9243H0.258604ZM5.10008 13.9243H8.21546V8.7075C8.21546 8.38115 8.25289 8.1294 8.32773 7.95225C8.4587 7.63523 8.65751 7.36716 8.92414 7.14806C9.19078 6.92894 9.52523 6.81938 9.92752 6.81938C10.9753 6.81938 11.4992 7.52334 11.4992 8.93127V13.9243H14.6146V8.56764C14.6146 7.18768 14.2872 6.14106 13.6323 5.42778C12.9774 4.71449 12.112 4.35785 11.0362 4.35785C9.82929 4.35785 8.88906 4.87533 8.21546 5.91029V5.93827H8.20143L8.21546 5.91029V4.58162H5.10008C5.11879 4.87999 5.12815 5.80772 5.12815 7.36484C5.12815 8.92194 5.11879 11.1084 5.10008 13.9243Z" fill="white"/>
                                    </svg>
                                </NavLink>
                                <NavLink target="_blank" to="https://twitter.com">
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 1.7173C17.3306 1.99528 16.6174 2.17954 15.8737 2.26901C16.6388 1.83659 17.2226 1.15706 17.4971 0.338007C16.7839 0.74061 15.9964 1.02499 15.1571 1.18369C14.4799 0.500965 13.5146 0.078125 12.4616 0.078125C10.4186 0.078125 8.77387 1.64807 8.77387 3.57268C8.77387 3.8496 8.79862 4.11588 8.85938 4.36937C5.7915 4.22771 3.07687 2.83564 1.25325 0.715048C0.934875 1.23801 0.748125 1.83659 0.748125 2.48097C0.748125 3.69091 1.40625 4.76345 2.38725 5.3844C1.79437 5.37375 1.21275 5.21079 0.72 4.9541C0.72 4.96475 0.72 4.9786 0.72 4.99244C0.72 6.6902 1.99912 8.10037 3.6765 8.42523C3.37612 8.50298 3.04875 8.54025 2.709 8.54025C2.47275 8.54025 2.23425 8.52747 2.01038 8.48061C2.4885 9.86416 3.84525 10.8813 5.4585 10.9143C4.203 11.8442 2.60888 12.4044 0.883125 12.4044C0.5805 12.4044 0.29025 12.3916 0 12.3565C1.63462 13.3545 3.57188 13.9243 5.661 13.9243C12.4515 13.9243 16.164 8.59883 16.164 3.98274C16.164 3.8283 16.1584 3.67919 16.1505 3.53114C16.8829 3.03907 17.4982 2.42452 18 1.7173Z" fill="white"/>
                                    </svg>
                                </NavLink>
                            </div>
                        </Col>
                        <Col>
                    <hr/></Col>
                        <Col sm="12" className="text-center">
                            <div className="copyright">
                                <p className="fs-14">Copyright @Qoach 2022</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default HomeFooterComponent;