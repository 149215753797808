const getBadgeColor = (type) => {
    if (type === "Elite") {
        return 'yellow'
    }

    if (type === 'Master') {
        return 'purple'
    }

    if (type === 'Novice') {
        return 'green'
    }
}

const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
        // Remove any non-numeric characters from the phone number
        const numericPhoneNumber = phoneNumber?.replace(/\D/g, '');

        // Check the length of the numeric phone number to determine the country code
        let formattedPhoneNumber = '';
        if (numericPhoneNumber?.length === 10) {
            formattedPhoneNumber = `(${numericPhoneNumber?.slice(0, 3)}) ${numericPhoneNumber?.slice(3, 6)}-${numericPhoneNumber?.slice(6)}`;
        } else if (numericPhoneNumber?.length === 11) {
            formattedPhoneNumber = `${numericPhoneNumber?.slice(0, 1)} (${numericPhoneNumber?.slice(1, 4)}) ${numericPhoneNumber?.slice(4, 7)}-${numericPhoneNumber.slice(7)}`;
        } else if (numericPhoneNumber?.length > 11) {
            const countryCode = numericPhoneNumber?.slice(0, numericPhoneNumber?.length - 10);
            const remainingDigits = numericPhoneNumber?.slice(countryCode?.length);
            formattedPhoneNumber = `+${countryCode} (${remainingDigits?.slice(0, 3)}) ${remainingDigits?.slice(3, 6)}-${remainingDigits?.slice(6)}`;
        } else {
            // Handle other cases or display as-is if it doesn't match expected formats
            formattedPhoneNumber = numericPhoneNumber;
        }

        return formattedPhoneNumber;
    } else {
        return phoneNumber
    }
}


module.exports = {
    getBadgeColor,
    formatPhoneNumber
}